import { Container } from 'inversify';
import ICustomFieldApiService from '../domain/abstractions/ICustomFieldApiService';
import { CUSTOM_FIELDS_TYPES } from './types';
import CustomFieldApiService from '../data/network/CustomFieldApiService';
import { ICustomFieldRepository } from '../domain/abstractions/ICustomFieldRepository';
import CustomFieldRepository from '../data/CustomFieldRepository';
import { ICustomFieldUseCase } from '../domain/abstractions/ICustomFieldUseCase';
import CustomFieldUseCase from '../domain/CustomFieldUseCase';
import { ICustomFieldDao } from '../domain/abstractions/ICustomFieldDao';
import CustomFieldDao from '../data/CustomFieldDao';

export const bindCustomFieldsModule = (container: Container) => {
  container
    .bind<ICustomFieldApiService>(CUSTOM_FIELDS_TYPES.CustomFieldApiService)
    .to(CustomFieldApiService);
  container
    .bind<ICustomFieldRepository>(CUSTOM_FIELDS_TYPES.CustomFieldRepository)
    .to(CustomFieldRepository);
  container
    .bind<ICustomFieldUseCase>(CUSTOM_FIELDS_TYPES.CustomFieldUseCase)
    .to(CustomFieldUseCase);
  container.bind<ICustomFieldDao>(CUSTOM_FIELDS_TYPES.CustomFieldDao).to(CustomFieldDao);
};
