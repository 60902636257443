import { Container } from 'inversify';

import OnboardingUseCases from '../domain/OnboardingUseCases';
import IOnboardingUseCases from '../domain/abstractions/IOnboardingUseCases';

import OnboardingRepository from '../data/OnboardingRepository';
import IOnboardingRepository from '../domain/abstractions/IOnboardingRepository';

import { TYPES } from './types';

export const bindOnboardingModule = (container: Container) => {
  container
    .bind<IOnboardingUseCases>(TYPES.OnboardingUseCases)
    .to(OnboardingUseCases)
    .inSingletonScope();
  container.bind<IOnboardingRepository>(TYPES.OnboardingRepository).to(OnboardingRepository);
};
