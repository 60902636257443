import React from 'react';
import clsx from 'clsx';
import MUIDrawer, { DrawerProps as MUIDrawerProps } from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';

import BackIcon from 'icons/menu.svg';
import styles from './DrawerWithHeader.module.scss';

export default function Drawer(
  props: Omit<MUIDrawerProps, 'onClose'> & {
    title: string;
    contentClassName?: string;
    onClose: (
      event: Record<string, unknown>,
      reason: 'buttonClick' | 'backdropClick' | 'escapeKeyDown'
    ) => void;
  }
) {
  const { title, contentClassName, onClose, ...restProps } = props;

  const handleCloseClick = (_) => {
    onClose(_, 'buttonClick');
  };

  const handleCloseMUIDrawer: MUIDrawerProps['onClose'] = (event, reason) => {
    onClose(event, reason);
  };

  return (
    <MUIDrawer {...restProps} onClose={handleCloseMUIDrawer} disableEnforceFocus>
      <div className={styles.header}>
        <h6>{title}</h6>
        <IconButton onClick={handleCloseClick} className={styles.exitIcon}>
          <BackIcon />
        </IconButton>
      </div>
      <div className={clsx(styles.content, contentClassName)}>{props.children}</div>
    </MUIDrawer>
  );
}
