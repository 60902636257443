import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import DrawerWithHeader from 'components/UI/DrawerWithHeader/DrawerWithHeader';
import CreateForm, { CreateFormOptions } from '../ProposalForm/CreateForm';
import UpdateForm, { UpdateFormOptions } from '../ProposalForm/UpdateForm';
import DeleteDialog from '../../../components/UI/Dialogs/DeleteDialog';
import styles from './styles.module.scss';

import { FormOptions, Mode } from './ProposalDrawerProvider';

export default function ProposalDrawer({
  mode,
  formOptions,
  onClose,
  type,
}: {
  mode: Mode;
  formOptions: FormOptions;
  onClose: () => void;
  type?: string;
}) {
  const { t } = useTranslation('proposal-form');

  const [formIsDirty, setFormIsDirty] = useState(false);
  const handleFormDataChanged = useCallback(() => {
    setFormIsDirty(true);
  }, []);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  useEffect(() => {
    setFormIsDirty(false);
  }, [formOptions]);

  const handleClose = () => {
    if (formIsDirty) {
      setConfirmDialogOpen(true);
    } else {
      onClose();
    }
  };

  return (
    <DrawerWithHeader
      title={t(`title.${mode}`, {
        number: (formOptions as UpdateFormOptions)?.data?.number,
      })}
      open={!!mode}
      onClose={handleClose}
      anchor="right"
    >
      {mode === 'create' && (
        <CreateForm
          type={type}
          defaultValues={(formOptions as CreateFormOptions).defaultValues}
          onSuccess={formOptions.onSuccess}
          onFormDataChanged={handleFormDataChanged}
          shouldPinContact={(formOptions as CreateFormOptions).shouldPinContact}
        />
      )}
      {mode === 'update' && (
        <UpdateForm
          data={(formOptions as UpdateFormOptions).data}
          onSuccess={formOptions.onSuccess}
          onFormDataChanged={handleFormDataChanged}
        />
      )}
      {confirmDialogOpen && (
        <DeleteDialog
          title={t('closeDialog.title')}
          description={t('closeDialog.body')}
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onSuccess={onClose}
          cancelButtonText={t('duplicates:Cancel_action')}
          deleteButtonText={t('common:action.discard')}
          titleClassName={styles.Title}
        />
      )}
    </DrawerWithHeader>
  );
}
