import { Observable, from, map } from 'rxjs';
import {
  CreateCustomFieldValueData,
  ICustomFieldValueApiService,
} from '../../domain/abstractions/ICustomFieldValueApiService';
import { inject, injectable } from 'inversify';
import axios from '../../../../../app/axios';
import { TYPES } from 'src/app/ioc/types';
import type { IPersistentRepository } from 'src/services/PersistentRepository';
import RequestHeaders from 'src/enums/RequestHeaders';

const apiPath = 'api/v1/custom-fields/values';

@injectable()
export default class CustomFieldValueApiService implements ICustomFieldValueApiService {
  @inject(TYPES.PersistentRepository) private localStorageRepository: IPersistentRepository;

  addValue(data: CreateCustomFieldValueData): Observable<string> {
    const request = axios.post(
      apiPath,
      {
        uuid: data.uuid,
        custom_field_uuid: data.customFieldUuid,
        contact_uuid: data.contactUuid,
        value: data.value,
      },
      {
        headers: {
          [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
        },
      }
    );

    return from(request).pipe(map((res) => res.data));
  }
}
