import { Drawer, Toolbar } from '@material-ui/core';
import React, { useEffect } from 'react';
import clsx from 'clsx';

import { SidebarButtonType, sidebarData } from './sidebarData';
import styles from './styles.module.scss';
import SidebarLink from './components/SidebarLink/index';
import LogoRegular from '../../../../../public/images/icons/sidebar/logo.svg';
import LogoSmall from '../../../../../public/images/icons/sidebar/logo-small.svg';
import { useAppDispatch, useAppStore } from 'src/app/hooks';
import { selectCurrentContact, setCurrentContact } from 'slices/contactsSlice';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import useRedirectEffect from '../../../../app/hooks/ui/useRedirectEffect';
import RedirectEffectTargetType from '../../../../enums/RedirectEffectTargetType';
import { TRedirectEffect } from '../../../../../types/ui';
import { setReportHistory } from 'slices/fullReportSlice';
import useSidebarViewModel from './useSidebarViewModel';
import PlansPromo from 'src/features2/billing/ui/promos/PlansPromo/PlansPromo';

interface ISidebarProps {
  isOpened: boolean;
  handleToggleDrawer: () => void;
  pathName: string;
}

const Sidebar: React.FC<ISidebarProps> = ({ isOpened, handleToggleDrawer, pathName }) => {
  const { isPlansPageLinkShown } = useSidebarViewModel();

  const store = useAppStore();
  const dispatch = useAppDispatch();
  const redirectEffect = useRedirectEffect(RedirectEffectTargetType.COMPANY) as TRedirectEffect;

  useEffect(() => {
    const relatedSidebarData = sidebarData.find((item) => item.link.includes(pathName));

    dispatch(setCurrentPageName(relatedSidebarData?.title));
  }, [pathName]);

  return (
    <Drawer
      classes={{
        paper: clsx(styles.Drawer, {
          [styles.DrawerOpened]: isOpened,
          [styles.DrawerClosed]: !isOpened,
        }),
      }}
      variant={'permanent'}
    >
      <Toolbar classes={{ root: styles.Toolbar }}>
        <button onClick={handleToggleDrawer} className={styles.LogoSection}>
          {isOpened ? <LogoRegular /> : <LogoSmall />}
        </button>
        <div className={clsx(styles.ButtonsWrapper, { [styles.SidebarClosed]: !isOpened })}>
          {sidebarData
            .filter((data) => (data.type === SidebarButtonType.PLANS ? isPlansPageLinkShown : true))
            .map(({ icon, link, title, type }, idx) => {
              const isActive = pathName === link;
              let onClick;

              if (type === SidebarButtonType.CALLS || type === SidebarButtonType.CONTACTS) {
                onClick = () => {
                  const currentContact = selectCurrentContact(store.getState());

                  if (
                    redirectEffect &&
                    redirectEffect.targetType === RedirectEffectTargetType.COMPANY
                  ) {
                    dispatch(setRedirectEffect(null));
                    dispatch(setReportHistory([]));
                  }

                  if (currentContact) {
                    dispatch(setCurrentContact(null));
                  }
                };
              }
              return (
                <SidebarLink
                  onClick={onClick}
                  key={link}
                  tabIndex={idx + 1}
                  withTooltip={!isOpened}
                  isActive={isActive}
                  isSidebarOpened={isOpened}
                  title={title}
                  link={link}
                  icon={icon}
                />
              );
            })}
        </div>
        <PlansPromo />
      </Toolbar>
    </Drawer>
  );
};

export default Sidebar;
