import ProposalSettingsDC, { ProposalCustomersDetailsNamesDC } from './ProposalSettingsDC';
import ProposalSettingsEntity, { CustomersDetailsNames } from './ProposalSettingsEntity';

export const mapProposalSettingsDСtoEntity = (dc: ProposalSettingsDC): ProposalSettingsEntity => {
  const customerDetails: ProposalSettingsEntity['customerDetails'] = [
    {
      name: CustomersDetailsNames.EMAIL,
      enabled: !dc.customer_details
        ? false
        : dc.customer_details.find((detail) => detail.name === 'email').is_enabled,
    },
    {
      name: CustomersDetailsNames.FAX,
      enabled: !dc.customer_details
        ? false
        : dc.customer_details.find((detail) => detail.name === 'fax').is_enabled,
    },
    {
      name: CustomersDetailsNames.ADDRESS,
      enabled: !dc.customer_details
        ? false
        : dc.customer_details.find((detail) => detail.name === 'address').is_enabled,
    },
    {
      name: CustomersDetailsNames.PHONE,
      enabled: !dc.customer_details
        ? false
        : dc.customer_details.find((detail) => detail.name === 'phone').is_enabled,
    },
  ];

  return {
    uuid: dc.uuid,
    workspaceId: dc.workspace_id,
    startPrice: dc.start_price,
    validity: dc.validity,
    currency: dc.currency,
    includesTax: dc.includes_tax,
    taxRate: dc.tax_rate,
    enteringPriceIncludesTax: dc.entering_price_includes_tax,
    footerText: dc.footer_text,
    customerDetails: customerDetails,
    includeTotalPrice: Boolean(dc.include_total_price),
    lang: dc.language,
    updatedAt: dc.updated_at ? dc.updated_at * 1000 : null,
    createdAt: dc.created_at ? dc.created_at * 1000 : null,
    businessDetails: {
      uuid: dc.business_details.uuid,
      imageUrl: dc.business_details.image_url || '',
      name: dc.business_details.name || '',
      description: dc.business_details.description || '',
      phone: dc.business_details.phone || '',
      email: dc.business_details.email || '',
      address: dc.business_details.address || '',
      website: dc.business_details.website || '',
      fax: dc.business_details.fax || '',
      moreDetails: dc.business_details.more_details || '',
    },
  };
};

export const mapProposalSettingsEntityToDC = (
  settings: ProposalSettingsEntity
): ProposalSettingsDC => {
  return {
    uuid: settings.uuid,
    business_details: {
      uuid: settings.businessDetails.uuid,
      address: settings.businessDetails.address || null,
      description: settings.businessDetails.description || null,
      email: settings.businessDetails.email || null,
      fax: settings.businessDetails.fax || null,
      image_url: settings.businessDetails.imageUrl || null,
      more_details: settings.businessDetails.moreDetails || null,
      name: settings.businessDetails.name || null,
      phone: settings.businessDetails.phone || null,
      website: settings.businessDetails.website || null,
    },
    customer_details: [
      {
        name: ProposalCustomersDetailsNamesDC.EMAIL,
        is_enabled: settings.customerDetails.find((detail) => detail.name === 'email').enabled,
      },
      {
        name: ProposalCustomersDetailsNamesDC.FAX,
        is_enabled: settings.customerDetails.find((detail) => detail.name === 'fax').enabled,
      },
      {
        name: ProposalCustomersDetailsNamesDC.ADDRESS,
        is_enabled: settings.customerDetails.find((detail) => detail.name === 'address').enabled,
      },
      {
        name: ProposalCustomersDetailsNamesDC.PHONE,
        is_enabled: settings.customerDetails.find((detail) => detail.name === 'phone').enabled,
      },
    ],
    currency: settings.currency,
    entering_price_includes_tax: settings.enteringPriceIncludesTax,
    footer_text: settings.footerText,
    includes_tax: settings.includesTax,
    start_price: settings.startPrice,
    tax_rate: settings.taxRate,
    include_total_price: settings.includeTotalPrice,
    validity: settings.validity,
    language: settings.lang || null,
    workspace_id: settings.workspaceId,
    created_at: Math.floor(settings.createdAt / 1000),
    updated_at: Math.floor(settings.updatedAt / 1000),
  };
};
