import { inject, injectable } from 'inversify';
import { Observable, switchMap } from 'rxjs';

import { TYPES } from '../di/types';
import { IProfileApiService } from './network/ProfileApiService';
import { IProfileDao } from './ProfileDao';

import { TYPES as GLOBAL_TYPES } from 'src/app/ioc/types';
import { DbManager } from 'src/db/dbManager';
import ProfileEntity from 'src/db/entities/profile/ProfileEntity';
import { mapProfileDCToEntity, mapProfileEntityToDC } from 'src/db/entities/profile/mapper';

export interface IProfileRepository {
  fetchProfile(): Observable<ProfileEntity>;
  updateProfile(profileData: ProfileEntity): void;
}

@injectable()
export default class ProfileRepository implements IProfileRepository {
  @inject(TYPES.ProfileApiService) private api: IProfileApiService;
  @inject(TYPES.ProfileDao) private dao: IProfileDao;
  @inject(GLOBAL_TYPES.DbManager) dbManager: DbManager;

  updateProfile(profile: ProfileEntity): void {
    this.api.patchProfile(mapProfileEntityToDC(profile)).subscribe(() => {
      this.dao.upsertProfile(profile).subscribe();
    });
  }

  fetchProfile = () => {
    return this.api
      .getProfile()
      .pipe(switchMap((profile) => this.dao.upsertProfile(mapProfileDCToEntity(profile))));
  };
}
