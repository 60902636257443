import GenericForm, { GenericProposalFormProps } from '../GenericForm';
import useCreateProposal from './useCreateProposal';
import useGeneratePdf from '../useGeneratePdf';
import { useProposalDrawer } from '../../ProposalDrawer';
import downloadFileByLink from '../../../../utils/downloadFileByLink';
import { useAppStore, useAppDispatch } from '../../../../app/hooks';
import { selectProposalSettings, updateProposal } from '../../../../slices/proposalsSlice';
import {
  selectCurrentUserMemberData,
  selectCurrentWorkspaceId,
} from '../../../../slices/workspacesSlice';

import { FormValues } from '../subForms/MainSubForm/useMainSubForm';
import { selectContactsMap } from 'slices/contactsSlice';
import { hideCallsBatch } from 'slices/callsSlice';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import { useSnackbar } from 'notistack';
import ProposalEntity, { DISCOUNT_TYPES } from 'src/db/entities/proposal/ProposalEntity';
import { trackEvent } from 'src/analytics/amplitude';
import { useCurrentUser } from 'src/features2/user/ui/hooks';

export interface CreateFormOptions {
  defaultValues?: Partial<ProposalEntity>;
  shouldPinContact?: boolean;
  onFormDataChanged?: () => void;
  onSuccess?: () => void;
  type?: string;
}

export default function CreateForm(props: CreateFormOptions) {
  const {
    defaultValues: defaultValuesProps = {},
    onFormDataChanged,
    onSuccess,
    shouldPinContact,
    type,
  } = props;
  const { currentUser } = useCurrentUser();
  const store = useAppStore();
  const dispatch = useAppDispatch();

  const create = useCreateProposal();

  const proposalSettings = selectProposalSettings(store.getState());
  const defaultValues: FormValues = {
    title: defaultValuesProps.title || '',
    contactId: defaultValuesProps.contactId,
    client: defaultValuesProps.client || '',
    items: defaultValuesProps.items || [{ description: '', qty: undefined, unitPrice: undefined }],
    comment: defaultValuesProps.description || '',
    includesTax: defaultValuesProps.settings?.includesTax || proposalSettings?.includesTax,
    currency: defaultValuesProps.settings?.currency || proposalSettings?.currency,
    discountAmount: defaultValuesProps.discountAmount,
    discountType: defaultValuesProps.discountType || DISCOUNT_TYPES.VALUE,
  };

  const { close } = useProposalDrawer();

  const generatePdf = useGeneratePdf();
  const { enqueueSnackbar } = useSnackbar();

  const handleDownload: GenericProposalFormProps['onDownload'] = (formValues) => {
    withErrorSnackbar({
      onTry: async () => {
        const contactsMap = selectContactsMap(store.getState());
        const contact = contactsMap[formValues.contactId];
        const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
        dispatch(hideCallsBatch(contactPhones));
      },
      enqueueSnackbar,
    });
    const handleSuccess = async (data: ProposalEntity) => {
      const tempUrl = await generatePdf(data);

      downloadFileByLink(tempUrl, data.title);

      await dispatch(
        updateProposal({ ...data, isDraft: false, updatedAt: Date.now(), sentAt: Date.now() })
      );
      close();
    };
    if (type != '_') trackEvent('Add_quote', { type, sent: 'Sent' });
    return create(formValues, handleSuccess);
  };

  const handlePreview: GenericProposalFormProps['onPreview'] = async (formValues) => {
    const settings = selectProposalSettings(store.getState());
    const { email } = selectCurrentUserMemberData(store.getState());
    const params = {
      uuid: 'temp-uuid',
      number: 0,
      contactId: formValues.contactId,
      client: formValues.client,
      title: formValues.title,
      items: formValues.items,
      description: formValues.comment,
      discountAmount: formValues.discountAmount,
      discountType: formValues.discountType,
      isDraft: true,
      visible: true,
      sentAt: null,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      workspaceId: selectCurrentWorkspaceId(store.getState()),
      createdBy: email,
      updatedBy: email,
      settings: {
        ...settings,
        lang: currentUser.settings.lang,
        currency: formValues.currency,
        includesTax: formValues.includesTax,
      },
    };
    const tempUrl = await generatePdf(params);

    if (tempUrl) {
      window.open(tempUrl);
    }
  };

  const handleSubmit: GenericProposalFormProps['onSubmit'] = (formValues) => {
    withErrorSnackbar({
      onTry: async () => {
        const contactsMap = selectContactsMap(store.getState());
        const contact = contactsMap[formValues.contactId];
        const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
        dispatch(hideCallsBatch(contactPhones));
      },
      enqueueSnackbar,
    });
    if (type != '_') trackEvent('Add_quote', { type, sent: 'Unsent' });
    return create(formValues, onSuccess);
  };

  return (
    <GenericForm
      defaultValues={defaultValues}
      contactPinned={shouldPinContact}
      onFormDataChanged={onFormDataChanged}
      onDownload={handleDownload}
      onPreview={handlePreview}
      onSubmit={handleSubmit}
    />
  );
}
