import axios from '../app/axios';
import { endpoints } from '../utils/constants';
import flatten from 'lodash/flatten';
import Contact from '../../types/contact';
import RequestHeaders from '../enums/RequestHeaders';

async function fetchContactsList(): Promise<{ data: { [key: string]: any } }> {
  const contactsData = (
    await axios.get(endpoints.contacts.createUpdateContact, {
      params: { start: 0 },
    })
  ).data;
  const contactsCount = contactsData.count;
  const contactPerTime = 250;
  const promissesArr = [];

  for (let i = contactPerTime; i <= contactsCount; i += contactPerTime) {
    promissesArr.push(
      axios.get(endpoints.contacts.createUpdateContact, {
        params: { start: i, length: contactPerTime },
      })
    );
  }
  const contactsArr = [...contactsData.contacts];
  //@ts-ignore
  return Promise.all(promissesArr).then((values) => {
    return [...contactsArr, ...flatten(values.map(({ data }) => data.contacts))];
  });
}

async function createContactAPI(data: Contact): Promise<void> {
  await axios.post(endpoints.contacts.createUpdateContact, data, {
    headers: { [RequestHeaders.WORKSPACE_ID]: data.workspace_id },
  });
}

async function patchContactAPI({
  uuid,
  data,
  workspaceId,
}: {
  uuid: Contact['uuid'];
  data: Partial<Contact>;
  workspaceId: Contact['workspace_id'];
}) {
  const headers = workspaceId ? { [RequestHeaders.WORKSPACE_ID]: workspaceId } : undefined;
  await axios.patch<Contact>(
    endpoints.contacts.patch,
    {
      ...data,
      uuid,
    },
    { headers }
  );
}

async function patchManyContactsAPI({
  entities,
  workspaceId,
}: {
  entities: Array<{ uuid: Contact['uuid']; updated_at: number } & Partial<Contact>>;
  workspaceId: Contact['workspace_id'];
}) {
  const headers = workspaceId ? { [RequestHeaders.WORKSPACE_ID]: workspaceId } : undefined;
  const { data } = await axios.patch<
    Array<{
      item_uuid: string;
      message: null | string;
      result: Contact;
      status: number;
    }>
  >(endpoints.contacts.patch, entities, { headers });
  const failed = data.filter((item) => item.status !== 200);

  return failed.map(({ item_uuid }) => item_uuid);
}

async function deleteContactAPI({
  uuid,
  workspaceId,
}: {
  uuid: Contact['uuid'];
  workspaceId: Contact['workspace_id'];
}) {
  const headers = workspaceId ? { [RequestHeaders.WORKSPACE_ID]: workspaceId } : undefined;
  return await axios.delete(`${endpoints.contacts.createUpdateContact}`, {
    data: uuid,
    headers,
  });
}

export {
  fetchContactsList,
  createContactAPI,
  deleteContactAPI,
  patchContactAPI,
  patchManyContactsAPI,
};
