import CallDC from './CallDC';
import CallEntity from './CallEntity';
import { mapCallerDCtoEntity, mapCallerEntityToDC } from '../caller/mapper';
import { mapNoteDCToEntity, mapPartialNoteEntityToDC } from '../note/mapper';
import NoteDC from '../note/NoteDC';

export const mapCallDCtoEntity = (dc: CallDC): CallEntity => {
  return {
    id: dc.uuid,
    removed: dc.deleted,
    phoneNumber: dc.phone_number,
    normalizedPhone: dc.normalized_phone,
    duration: dc.duration,
    type: dc.type,
    callDate: dc.call_date,
    notShow: dc.not_show,
    simPhone: dc.sim_phone,
    simSubscriptionId: dc.sim_subscription_id,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    callerInfo: mapCallerDCtoEntity(dc.caller_info),
    notes: (dc.notes || []).map((note) => mapNoteDCToEntity(note)),
  };
};

export const mapCallEntityToDC = (entity: CallEntity): CallDC => {
  return {
    uuid: entity.id,
    deleted: entity.removed,
    phone_number: entity.phoneNumber,
    normalized_phone: entity.normalizedPhone,
    duration: entity.duration,
    type: entity.type,
    call_date: entity.callDate,
    not_show: entity.notShow,
    sim_phone: entity.simPhone,
    sim_subscription_id: entity.simSubscriptionId,
    created_at: entity.createdAt,
    updated_at: entity.updatedAt,
    caller_info: mapCallerEntityToDC(entity.callerInfo),
    notes: entity.notes.map(mapPartialNoteEntityToDC) as NoteDC[],
  };
};
