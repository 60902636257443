import { injectable, inject } from 'inversify';
import { Observable, from, switchMap, map } from 'rxjs';
import { TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';

import TagLinkEntity from 'src/db/entities/tagLink/TagLinkEntity';

export interface ITagLinksDao {
  getTagLinks(selector): Observable<TagLinkEntity[]>;
}

@injectable()
export default class TagLinksDao implements ITagLinksDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;

  getTagLinks = (selector) => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db['tag-links'].find({ selector }).$;
      }),
      map((docs) => {
        return docs.map((doc) => doc.toMutableJSON() as unknown as TagLinkEntity);
      })
    );
  };
}
