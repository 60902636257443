import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';

const useSidebarViewModel = () => {
  const { currentUser } = useCurrentUser();
  const { subscription } = useCurrentSubscription();

  const { isUserOnFreeTrial, isUserOnFreePlan, isActive } = subscription;
  const { isAdmin } = currentUser;

  return {
    isPlansPageLinkShown: isAdmin && (isUserOnFreeTrial || isUserOnFreePlan || !isActive),
  };
};

export default useSidebarViewModel;
