import { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import { AppState } from 'src/app/store/store';
import useTaskDrawer from '../../TaskDrawer/useTaskDrawer';
import GenericForm, { FormValues } from '../GenericForm/GenericForm';
import { useAppDispatch, useAppStore } from 'src/app/hooks';
import { updateTask, selectTasks } from '../../../../slices/tasksSlice/tasksSlice';
import { selectContactsMap } from '../../../../slices/contactsSlice';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import useReAssignContact from '../useReAssignContact';
import useGetRelatedContactId from '../useGetRelatedContactId';
import CreatedByLabel from '../../../collaboration/components/CreatedByLabel/CreatedByLabel';
import useShouldShowCreatedBy from '../../../collaboration/hooks/useShouldShowCreatedBy';

import styles from './UpdateForm.module.css';
import TaskEntity from 'src/db/entities/task/TaskEntity';

type FormOptions = {
  closeAfterSave?: boolean;
  onFormDataChanged: () => void;
  defaultValues?: Partial<TaskEntity>;
};

export default function UpdateTaskForm(props: FormOptions) {
  const { t } = useTranslation('tasks-page');
  const store = useAppStore();

  const {
    defaultValues: { id },
    closeAfterSave = true,
    onFormDataChanged,
  } = props;

  const task = useMemo(() => {
    const tasks = selectTasks(store.getState());

    return tasks.find((taskItem) => taskItem.id === id);
  }, [id, store]);

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const { close } = useTaskDrawer();

  const shouldShowCreatedBy = useShouldShowCreatedBy(task?.createdBy);

  const reAssignContact = useReAssignContact();
  const getRelatedContactId = useGetRelatedContactId();

  const handleSubmit = async (formValues: FormValues) => {
    const prepareContactId = async () => {
      if (formValues.contactId === null) {
        return null;
      } else {
        const state = store.getState() as AppState;
        const contact = selectContactsMap(state)[formValues.contactId];
        if (!contact) {
          return null;
        }
        if (contact.workspace_id === null) {
          const contactId = await getRelatedContactId(formValues.contactId);

          return contactId;
        } else {
          return formValues.contactId;
        }
      }
    };

    try {
      const contactId = await prepareContactId();

      const updatedTask: TaskEntity = {
        ...task,
        contactId: contactId,
        important: formValues.important,
        title: formValues.title,
        description: formValues.description,
        descriptionUpdatedAt:
          formValues.description !== task.description ? Date.now() : task.descriptionUpdatedAt,
        dueDate: formValues.dueDate ? formValues.dueDate.getTime() : undefined,
        reminder: formValues.reminder
          ? {
              date: formValues.reminder.getTime(),
            }
          : undefined,
        assignedTo: formValues.assignedTo,
        updatedAt: Date.now(),
      };

      await dispatch(updateTask(updatedTask)).unwrap();

      enqueueSnackbar({ message: t('form.notification.updated'), variant: MessageType.Success });

      if (closeAfterSave) {
        close();
      }

      reAssignContact({
        taskAssignedTo: formValues.assignedTo,
        taskRelatedContactId: contactId,
      });
    } catch (error) {
      console.log(error);
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };

  return (
    <div className={styles.wrapper}>
      <GenericForm
        onSubmit={handleSubmit}
        onFormDataChanged={onFormDataChanged}
        defaultValues={{
          title: task.title,
          contactId: task.contactId,
          reminder: task.reminder ? new Date(task.reminder.date) : null,
          dueDate: task.dueDate ? new Date(task.dueDate) : null,
          description: task.description,
          important: task.important,
          assignedTo: task.assignedTo,
        }}
      />
      {shouldShowCreatedBy && (
        <CreatedByLabel
          className={styles.createdBy}
          email={task?.createdBy}
          createdAt={task?.createdAt}
        />
      )}
    </div>
  );
}
