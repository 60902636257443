import { useEffect } from 'react';
import { selectIsCurrentWorkspaceCollaborative } from 'slices/workspacesSlice';
import useCustomerIOViewModel from 'src/analytics/customerIO/ui/useCustomerIOViewModel';
import { AppState } from 'src/app/store/store';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentWorkspace } from 'src/features2/workspaces/ui/hooks/useCurrentWorkspace';
import useAmplitude from 'src/analytics/amplitude/ui/useAmplitude';
//check if you are on the client (browser) or server

const AnalyticsProvider = ({ children, store }) => {
  const amplitude = useAmplitude();
  const { currentUser } = useCurrentUser();
  const { subscription } = useCurrentSubscription();
  const { currentWorkspace } = useCurrentWorkspace();
  const state = store.getState() as AppState;
  const { customerIOInit } = useCustomerIOViewModel();
  const user = state.user;
  const isCurrentWorkspaceCollaborative = selectIsCurrentWorkspaceCollaborative(state);

  useEffect(() => {
    customerIOInit();
  }, []);

  const planType = subscription?.plan;
  const purchaseType = subscription?.purchaseType;
  const role = currentUser?.role;
  useEffect(() => {
    const accountType = isCurrentWorkspaceCollaborative ? 'colaborative' : 'individual';
    amplitude.setUserForTracking({
      email: user?.profile?.email,
      companyWebsite: currentWorkspace?.companyWebsite,
      companyName: currentWorkspace?.name,
      companySize: currentWorkspace?.companySize,
      businessPhone: currentWorkspace?.businessPhone,
      accountType,
      planType,
      purchaseType,
      role,
    });
  }, [
    user.profile,
    isCurrentWorkspaceCollaborative,
    planType,
    purchaseType,
    role,
    currentWorkspace?.companySize,
    currentWorkspace?.businessPhone,
    currentWorkspace?.name,
    currentWorkspace?.companyWebsite,
    amplitude,
  ]);

  return children;
};

export default AnalyticsProvider;
