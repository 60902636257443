import { first } from 'rxjs';
import { setIsAuth } from 'slices/userSlice';
import { useAppDispatch } from 'src/app/hooks';
import {
  forwardToLoginPage,
  forwardToLoginPageWithParams,
} from 'src/services/router/appRouterService';
import { useInjection } from '../../../app/ioc/ioc.react';
import { TYPES } from '../../../features2/user/di/types';
import { IUserUseCases } from '../../../features2/user/domain/abstractions/IUserUseCases';
import { resetStore } from '../../../app/store/store';

export default function useLogout() {
  const useCases = useInjection<IUserUseCases>(TYPES.UserUseCases);
  const dispatch = useAppDispatch();

  return (redirectParams?: { [key: string]: string }) => {
    dispatch(setIsAuth(false));

    if (redirectParams) {
      forwardToLoginPageWithParams(redirectParams);
    } else {
      forwardToLoginPage();
    }

    useCases
      .logout()
      .pipe(first())
      .subscribe(() => {
        resetStore();
      });
  };
}
