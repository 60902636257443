const getDocumentIdByIdAndWorkspaceId = (id: string, workspaceId: string | null) =>
  `${id}|${workspaceId || ''}`;

const DB_IS_WIPING_STATUS_KEY = 'isDbWiping';
export const dbWipeStatusHandlers = {
  isInProgress: () => JSON.parse(localStorage.getItem(DB_IS_WIPING_STATUS_KEY)),
  startDbWipe: () => localStorage.setItem(DB_IS_WIPING_STATUS_KEY, String(true)),
  finishDbWipe: () => localStorage.setItem(DB_IS_WIPING_STATUS_KEY, String(false)),
};
export { getDocumentIdByIdAndWorkspaceId };
