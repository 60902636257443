import { useInjection } from 'src/app/ioc/ioc.react';
import { TYPES } from '../di/types';
import { IFBAnalyticsUseCases } from '../domain/abstractions/IFBAnalyticsUseCases';
import FB_EVENTS from '../domain/enums/FB_EVENTS';

const useFBAnalytics = () => {
  const useCases = useInjection<IFBAnalyticsUseCases>(TYPES.FBAnalyticsUseCases);
  const trackEvent = (eventType: string, eventName: FB_EVENTS, props?: any) =>
    useCases.trackEvent(eventType, eventName, props);

  return {
    trackEvent,
  };
};

export default useFBAnalytics;
