import { useTranslation, Trans } from 'react-i18next';
import { FunctionComponent } from 'react';
import clsx from 'clsx';

import { NotificationModel } from '../../api/notificationsAPI';
import { patchNotification } from 'slices/notificationSlice';
import DeleteIcon from 'icons/delete.svg';
import EditIcon from 'icons/edit.svg';
import ContactIcon from 'icons/contacts.svg';
import BellIcon from 'icons/header/notification.svg';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import styles from './styles.module.scss';
import {
  forwardToCallsPage,
  forwardToContactsPage,
  forwardToLoginPage,
} from 'src/services/router/appRouterService';
import useTaskDrawer from 'src/features/tasks/TaskDrawer/useTaskDrawer';
import { actions } from 'slices/workspacesSlice';
import { useProposalDrawer } from '../proposals/ProposalDrawer';
import { setCurrentContact } from 'slices/contactsSlice';
import { selectNotes } from 'slices/notesSlice';
import { externalOpenUserInfoPopup } from 'components/UI/Headers/Header/components/UserInfo/UserInfo';

export const useNotificationData = (
  notification: NotificationModel,
  onClose: () => void
): { text: string; onClick: () => void; icon: FunctionComponent<SVGElement> } => {
  const { t } = useTranslation('notifications');
  const notes = useAppSelector(selectNotes);

  const dispatch = useAppDispatch();
  const taskContext = useTaskDrawer();
  const proposalContext = useProposalDrawer();
  const handleClick = async () => {
    onClose();
    if (!notification.isRead) {
      await dispatch(
        patchNotification({
          notificationId: notification.id,
          data: { isRead: true, updatedAt: Math.floor(Date.now() / 1000) },
          workspaceId: notification.data.workspaceId,
        })
      );
    }
  };

  const type = notification.type;
  let notificationData;
  switch (type) {
    case 'task_reminder':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.task_reminder'}
            t={t}
            values={{ task_name: notification.data.orderedParams[0] }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.bell)}>
            <BellIcon />
          </div>
        ),
        onClick: () => {
          taskContext.openUpdateForm({ id: notification.data.itemId });
          handleClick();
        },
      };
      break;
    case 'task_due_date':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.task_due_date'}
            t={t}
            values={{ task_name: notification.data.orderedParams[0] }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.bell)}>
            <BellIcon />
          </div>
        ),
        onClick: () => {
          taskContext.openUpdateForm({ id: notification.data.itemId });
          handleClick();
        },
      };
      break;
    case 'workspace_user_invited':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.workspace_user_invited'}
            t={t}
            values={{
              admin_name: notification.data.orderedParams[0],
              company_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: async () => {
          handleClick();
          externalOpenUserInfoPopup();
        },
      };
      break;
    case 'workspace_user_removed':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.workspace_user_removed'}
            t={t}
            values={{
              admin_name: notification.data.orderedParams[0],
              company_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.delete)}>
            <DeleteIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToLoginPage();
          dispatch(actions.chooseWorkspace(null));
          handleClick();
        },
      };
      break;
    case 'contact_assigned':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.contact_assigned'}
            t={t}
            values={{
              teammate_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
            components={[<strong className={styles.notificationLink} key={'contact'} />]}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: () => {
          forwardToContactsPage();
          dispatch(setCurrentContact(notification.data.itemId));
          handleClick();
        },
      };
      break;
    case 'column_removed':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.column_removed'}
            t={t}
            values={{
              column_name: notification.data.orderedParams[0],
              teammate_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.delete)}>
            <DeleteIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToContactsPage();
          handleClick();
        },
      };
      break;
    case 'status_changed':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.status_changed'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              old_name: notification.data.orderedParams[1],
              new_name: notification.data.orderedParams[2],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToCallsPage();
          handleClick();
        },
      };
      break;
    case 'tag_changed':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.tag_changed'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              old_name: notification.data.orderedParams[1],
              new_name: notification.data.orderedParams[2],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToContactsPage();
          handleClick();
        },
      };
      break;
    case 'status_moved':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.status_moved'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
              item_name: notification.data.orderedParams[2],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToCallsPage();
          handleClick();
        },
      };
      break;
    case 'tag_moved':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.tag_moved'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
              item_name: notification.data.orderedParams[2],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: async () => {
          await forwardToContactsPage();
          handleClick();
        },
      };
      break;
    case 'note_created':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.note_created'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
            components={[<strong className={styles.notificationLink} key={'contact_name'} />]}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: () => {
          const relatedContactIdToNote =
            (notes.length &&
              notes.find((note) => note.id === notification.data.itemId)?.links.direct.id) ??
            null;
          forwardToContactsPage();
          dispatch(setCurrentContact(relatedContactIdToNote));
          handleClick();
        },
      };
      break;
    case 'task_created':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.task_created'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
            components={[<strong className={styles.notificationLink} key={'contact_name'} />]}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: () => {
          taskContext.openUpdateForm({ id: notification.data.itemId });
          handleClick();
        },
      };
      break;
    case 'price_proposal_created':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.price_proposal_created'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
            components={[<strong className={styles.notificationLink} key={'contact_name'} />]}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: () => {
          proposalContext.openUpdateForm({
            uuid: notification.data.itemId,
            onSuccess: proposalContext.close,
          });
          handleClick();
        },
      };
      break;
    case 'note_updated':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.note_updated'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: () => {
          const relatedContactIdToNote =
            (notes.length &&
              notes.find((note) => note.id === notification.data.itemId)?.links.direct.id) ??
            null;
          dispatch(setCurrentContact(relatedContactIdToNote));
          handleClick();
        },
      };
      break;
    case 'task_updated':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.task_updated'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: () => {
          taskContext.openUpdateForm({ id: notification.data.itemId });
          handleClick();
        },
      };
      break;
    case 'price_proposal_updated':
      notificationData = {
        text: (
          <Trans
            i18nKey={'types.price_proposal_updated'}
            t={t}
            values={{
              user_name: notification.data.orderedParams[0],
              contact_name: notification.data.orderedParams[1],
            }}
          />
        ),
        icon: (
          <div className={clsx(styles.notificationIcon, styles.edit)}>
            <EditIcon />
          </div>
        ),
        onClick: () => {
          proposalContext.openUpdateForm({
            uuid: notification.data.itemId,
            onSuccess: proposalContext.close,
          });
          handleClick();
        },
      };
      break;
    default:
      notificationData = {
        text: notification.data.title,
        icon: (
          <div className={clsx(styles.notificationIcon, styles.contact)}>
            <ContactIcon />
          </div>
        ),
        onClick: () => {
          handleClick();
        },
      };
  }
  return notificationData;
};
