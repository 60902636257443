import { dbVersion } from '../../constants';

export default {
  title: 'contact schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: {
    key: 'pk_id',
    fields: ['id', 'workspaceId'],
    separator: '|',
  },
  type: 'object',
  required: [
    'pk_id',
    'legacyId',
    'id',
    'anchorContactId',
    'anchorContactIds',
    'workspaceId',
    'assignedTo',
    'phones',
    'emails',
    'urls',
    'addresses',
    'dates',
    'socialProfiles',
    'name',
    'jobTitle',
    'company',
    'isSpammer',
    'isArchived',
    'isPersonal',
    'suggestionName',
    'suggestionJobTitle',
    'suggestionCompany',
    'hidden',
    'thumbnail',
    'createdAt',
    'updatedAt',
    'callersInfo',
  ],
  properties: {
    pk_id: {
      type: 'string',
      maxLength: 100,
    },
    legacyId: {
      type: 'string',
    },
    id: {
      type: 'string',
      maxLength: 100,
    },
    anchorContactId: {
      type: ['string', 'null'],
    },
    anchorContactIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    workspaceId: {
      type: 'string',
    },
    assignedTo: {
      type: ['string', 'null'],
    },
    phones: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'customLabel', 'phone', 'normalizedPhone'],
        properties: {
          type: {
            type: ['string', 'null'],
          },
          customLabel: {
            type: ['string', 'null'],
          },
          phone: {
            type: 'string',
          },
          normalizedPhone: {
            type: ['string', 'null'],
          },
        },
      },
    },
    emails: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'customLabel', 'email'],
        properties: {
          type: {
            type: ['string', 'null'],
          },
          customLabel: {
            type: ['string', 'null'],
          },
          email: {
            type: 'string',
          },
        },
      },
    },
    urls: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'customLabel', 'urls'],
        properties: {
          type: {
            type: ['string', 'null'],
          },
          customLabel: {
            type: ['string', 'null'],
          },
          urls: {
            type: 'string',
          },
        },
      },
    },
    addresses: {
      type: 'array',
      items: {
        type: 'object',
        required: [
          'type',
          'customLabel',
          'street1',
          'street2',
          'city',
          'country',
          'zipCode',
          'fullAddress',
        ],
        properties: {
          type: {
            type: ['string', 'null'],
          },
          customLabel: {
            type: ['string', 'null'],
          },
          street1: {
            type: ['string', 'null'],
          },
          street2: {
            type: ['string', 'null'],
          },
          city: {
            type: ['string', 'null'],
          },
          country: {
            type: ['string', 'null'],
          },
          zipCode: {
            type: ['string', 'null'],
          },
          fullAddress: {
            type: ['string', 'null'],
          },
        },
      },
    },
    dates: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'day', 'month', 'year'],
        properties: {
          type: {
            type: 'string',
          },
          day: {
            type: 'number',
          },
          month: {
            type: 'number',
          },
          year: {
            type: 'number',
          },
        },
      },
    },
    socialProfiles: {
      type: 'array',
      items: {
        type: 'object',
        required: ['type', 'customLabel', 'userId'],
        properties: {
          type: {
            type: 'string',
          },
          customLabel: {
            type: ['string', 'null'],
          },
          userId: {
            type: 'string',
          },
        },
      },
    },
    name: {
      type: ['string', 'null'],
    },
    jobTitle: {
      type: ['string', 'null'],
    },
    company: {
      type: ['string', 'null'],
    },
    isSpammer: {
      type: 'boolean',
    },
    isArchived: {
      type: 'boolean',
    },
    isPersonal: {
      type: 'boolean',
    },
    suggestionName: {
      type: ['string', 'null'],
    },
    suggestionJobTitle: {
      type: ['string', 'null'],
    },
    suggestionCompany: {
      type: ['string', 'null'],
    },
    hidden: {
      type: 'boolean',
    },
    thumbnail: {
      type: ['string', 'null'],
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    callersInfo: {
      type: 'object',
    },
  },
};
