import { appRoutes } from '../utils/constants';
import React from 'react';

const externalInjectablePages = [appRoutes.PRICING_PAGE];

function embedPageHoc(AppComponent) {
  const WrappedApp = (props) => {
    const { Component, router } = props;
    // external pages to inject to main website leader.net via iframe
    const isExternalPage = externalInjectablePages.indexOf(router.pathname) != -1;
    if (isExternalPage) {
      return <Component />;
    }

    return <AppComponent {...props} />;
  };
  return WrappedApp;
}

export default embedPageHoc;
