import ContactDC from '../../db/entities/contact/ContactDC';
import { mapContactDCToEntity } from '../../db/entities/contact/mapper';

import TaskDC from '../../db/entities/task/TaskDC';
import { mapTaskDCtoEntity } from '../../db/entities/task/mapper';

import WorkspaceDC from '../../db/entities/workspace/WorkspaceDC';
import { mapWorkspaceDCtoEntity } from '../../db/entities/workspace/mapper';

import NoteDC from '../../db/entities/note/NoteDC';
import { mapNoteDCToEntity } from '../../db/entities/note/mapper';

import TagDC from '../../db/entities/tag/TagDC';
import { mapTagDCtoEntity } from '../../db/entities/tag/mapper';

import TagLinkDC from '../../db/entities/tagLink/TagLinkDC';
import { mapTagLinkDCtoEntity } from '../../db/entities/tagLink/mapper';

import BoardColumnDC from '../../db/entities/boardColumn/BoardColumnDC';
import { mapBoardColumnDCtoEntity } from '../../db/entities/boardColumn/mapper';

import BoardItemDC from '../../db/entities/boardItem/BoardItemDC';
import { mapBoardItemDCtoEntity } from '../../db/entities/boardItem/mapper';

import ProposalDC from '../../db/entities/proposal/ProposalDC';
import { mapProposalDCToEntity } from '../../db/entities/proposal/mapper';

import CallDC from '../../db/entities/call/CallDC';
import { mapCallDCtoEntity } from '../../db/entities/call/mapper';

import NotificationDC from '../../db/entities/notification/NotificationDC';
import { mapNotificationDCtoEntity } from '../../db/entities/notification/mapper';

import ProposalSettingsDC from '../../db/entities/proposalSettings/ProposalSettingsDC';
import { mapProposalSettingsDСtoEntity } from '../../db/entities/proposalSettings/mapper';

import CustomFieldDC from 'src/db/entities/customField/CustomFieldDC';
import { mapCustomFieldValueDCtoEntity } from 'src/db/entities/customFieldValue/mapper';

import CustomFieldValueDC from 'src/db/entities/customFieldValue/CustomFieldValueDC';
import { mapCustomFieldDCtoEntity } from 'src/db/entities/customField/mapper';

import { DbManager } from '../../db/dbManager';
import { container } from '../ioc/container';
import { TYPES } from '../ioc/types';
import { TYPES as WORKSPACES_MODULE_TYPES } from '../../features2/workspaces/di/types';
import { IWorkspacesStateRepository } from '../../features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import FileDC from '../../db/entities/file/FileDC';
import { mapFileDCtoEntity } from '../../db/entities/file/mapper';
import { getDocumentIdByIdAndWorkspaceId } from '../../db/utils';

const getDispatchDictionaryDelete = async () => {
  const dbManager = container.get<DbManager>(TYPES.DbManager);
  const workspacesRepository = container.get<IWorkspacesStateRepository>(
    WORKSPACES_MODULE_TYPES.WorkspacesStateRepository
  );
  const db = await dbManager.getDb();
  const currentWorkspaceId = workspacesRepository.currentWorkspaceId.value;

  return {
    task: (items: TaskDC[]) => {
      const ids = items
        .map((dc) => mapTaskDCtoEntity(dc, { workspaceId: currentWorkspaceId }))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));
      return db.tasks.bulkRemove(ids);
    },
    workspace: (items: WorkspaceDC[]) => {
      const ids = items.map(mapWorkspaceDCtoEntity).map(({ id }) => id);
      return db.workspaces.bulkRemove(ids);
    },
    note: (items: NoteDC[]) => {
      const ids = items
        .map((dc) => mapNoteDCToEntity(dc, currentWorkspaceId))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));
      return db.notes.bulkRemove(ids);
    },
    call: (items: CallDC[]) => {
      const ids = items.map(mapCallDCtoEntity).map(({ id }) => id);
      return db.calls.bulkRemove(ids);
    },
    price_proposal: (items: ProposalDC[]) => {
      const ids = items
        .map((dc) => mapProposalDCToEntity(dc, currentWorkspaceId))
        .map(({ uuid }) => uuid);
      return db.proposals.bulkRemove(ids);
    },
    price_proposal_settings: (items: ProposalSettingsDC[]) => {
      const ids = items.map(mapProposalSettingsDСtoEntity).map(({ uuid }) => uuid);
      return db['proposal-settings'].bulkRemove(ids);
    },
    notification: (items: NotificationDC[]) => {
      const ids = items.map(mapNotificationDCtoEntity).map(({ id }) => id);
      return db.notifications.bulkRemove(ids);
    },
    contact: (items: ContactDC[]) => {
      const ids = items
        .map(mapContactDCToEntity)
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));

      return db.contacts.bulkRemove(ids);
    },
    board_column: (items: BoardColumnDC[]) => {
      const ids = items
        .map((dc) => mapBoardColumnDCtoEntity(dc, currentWorkspaceId))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));

      return db['board-columns'].bulkRemove(ids);
    },
    board_item: (items: BoardItemDC[]) => {
      const ids = items
        .map((dc) => mapBoardItemDCtoEntity(dc, currentWorkspaceId))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));
      return db['board-items'].bulkRemove(ids);
    },
    tag: (items: TagDC[]) => {
      const ids = items
        .map((dc) => mapTagDCtoEntity(dc, currentWorkspaceId))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));
      return db.tags.bulkRemove(ids);
    },
    tag_link: (items: TagLinkDC[]) => {
      const ids = items
        .map((dc) => mapTagLinkDCtoEntity(dc, currentWorkspaceId))
        .map(({ id, workspaceId }) => getDocumentIdByIdAndWorkspaceId(id, workspaceId));
      return db['tag-links'].bulkRemove(ids);
    },
    file: (items: FileDC[]) => {
      const ids = items.map((dc) => mapFileDCtoEntity(dc)).map(({ uuid }) => uuid);
      return db.files.bulkRemove(ids);
    },
    custom_field: (items: CustomFieldDC[]) => {
      const ids = items
        .map((dc) => mapCustomFieldDCtoEntity(dc, currentWorkspaceId))
        .map(({ uuid }) => uuid);
      return db['custom-fields'].bulkRemove(ids);
    },
    custom_field_value: (items: CustomFieldValueDC[]) => {
      const ids = items
        .map((dc) => mapCustomFieldValueDCtoEntity(dc, currentWorkspaceId))
        .map(({ uuid }) => uuid);
      return db['custom-field-values'].bulkRemove(ids);
    },
  };
};
export default getDispatchDictionaryDelete;
