import clsx from 'clsx';
import React from 'react';

import { NotificationModel } from '../../api/notificationsAPI';
import styles from './styles.module.scss';
import { useCallDate } from '../../app/hooks/useCallDate';
import { useNotificationData } from './useNotificationData';
import Index from '../../features2/localization/ui/DirectedText';

interface IProps {
  onClose: () => void;
  notification: NotificationModel;
}

const Row: React.FC<IProps> = ({ notification, onClose }) => {
  const notificationDate = useCallDate(notification.createdAt);
  const notificationData = useNotificationData(notification, onClose);

  return (
    <div
      onClick={notificationData.onClick}
      role="button"
      onKeyDown={notificationData.onClick}
      tabIndex={0}
      className={clsx({ [styles.rowContainer]: true, [styles.unreadRow]: !notification.isRead })}
    >
      {notificationData.icon}
      <div className={styles.notificationContainer}>
        <span className={styles.notificationTitle}>
          <Index>{notificationData.text}</Index>
        </span>
        <span className={styles.notificationDate}>{notificationDate}</span>
      </div>
    </div>
  );
};

export default Row;
