import { Container } from 'inversify';
import CustomerIORepository from '../data/CustomerIORepository';
import CustomerIOUseCases from '../domain/CustomerIOUseCases';
import { ICustomerIORepository } from '../domain/abstractions/ICustomerIORepository';
import { ICustomerIOUseCases } from '../domain/abstractions/ICustomerIOUseCases';
import { TYPES } from './types';
export const bindCustomerIOModule = (container: Container) => {
  container
    .bind<ICustomerIORepository>(TYPES.CustomerIORepository)
    .to(CustomerIORepository)
    .inSingletonScope();
  container.bind<ICustomerIOUseCases>(TYPES.CustomerIOUseCases).to(CustomerIOUseCases);
};
