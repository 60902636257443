import { injectable, inject } from 'inversify';

import { IMonitoringSystemUseCases } from './abstractions/IMonitoringSystemUseCases';
import { IMonitoringSystemRepository } from './abstractions/IMonitoringSystemRepository';
import { TYPES } from '../di/types';

@injectable()
export default class MonitoringSystemUseCases implements IMonitoringSystemUseCases {
  @inject(TYPES.MonitoringSystemRepository)
  private monitoringSystemRepository: IMonitoringSystemRepository;

  captureException = (e) => {
    return this.monitoringSystemRepository.captureException(e);
  };
}
