import { hideDialog, showDialog } from 'slices/uiSlice';
import DialogTypes from 'src/enums/DialogTypes';
import { TDialogData } from 'types/ui';
import { useAppDispatch } from '../useAppDispatch';

type TUsePreparedDialogs = () => {
  showDialog: (t: TDialogData) => void;
  hideDialog: (t: DialogTypes) => void;
};

const usePreparedDialogs: TUsePreparedDialogs = () => {
  const dispatch = useAppDispatch();

  return {
    showDialog: (data: TDialogData) => {
      dispatch(showDialog(data));
    },
    hideDialog: (type: DialogTypes) => {
      dispatch(hideDialog(type));
    },
  };
};

export default usePreparedDialogs;
