import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import { useDialog } from '../../../../app/hooks';

const StorageLimitationReachedDialog: React.FC = () => {
  const { dialogUI, show, hide } = useDialog();
  const { t } = useTranslation('dialogs');

  useEffect(() => {
    show();

    return hide;
  }, []);

  return dialogUI({
    titleText: t('storage_limit_reached_dialog.title'),
    bodyText: t('storage_limit_reached_dialog.description'),
    positiveButtonText: t('storage_limit_reached_dialog.button_text'),
    negativeButtonText: '',
    onSuccess: hide,
  });
};

export default StorageLimitationReachedDialog;
