import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';
import { TSearchHistoryItem } from 'types/search';
import SearchHistoryItemTypes from 'src/enums/SearchHistoryItemTypes';
import SearchItem from '../SearchItem';
import { addHistoryItem, clearHistory } from 'slices/searchSlice';
import { NoteSearchItem } from '../NotesList';
import { ProposalSearchItem } from '../ProposalsList';
import { CompanyItem } from '../CompaniesList';
import { TaskItem } from '../TasksList';
import { forwardToContactsPage } from 'src/services/router/appRouterService';
import { setCurrentContact } from 'slices/contactsSlice';
import { setCurrentPageName } from 'slices/uiSlice';
import { FilesItem } from '../FilesList';

interface IRecentlySearchProps {
  history: TSearchHistoryItem[];
  closeSearchModal: () => void;
}

const RecentlySearch: React.FC<IRecentlySearchProps> = ({ history, closeSearchModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('search');

  if (!history?.length) return null;

  const onClearHistory = () => dispatch(clearHistory());

  return (
    <div>
      <div className={styles.Title}>{t('title_recently_search')}</div>
      <div className={styles.Wrapper}>
        {history.map(({ type, ...rest }, index) => {
          switch (type) {
            case SearchHistoryItemTypes.CONTACT: {
              const { contactData } = rest;
              const { title, subtitle, uuid, is_archived } = contactData;

              const onClick = async () => {
                await forwardToContactsPage();
                dispatch(setCurrentPageName('duplicates:Contacts_title'));
                dispatch(setCurrentContact(uuid));
                closeSearchModal();
                dispatch(
                  addHistoryItem({
                    type: SearchHistoryItemTypes.CONTACT,
                    id: uuid,
                    contactData: {
                      title,
                      subtitle,
                      uuid,
                    },
                  })
                );
              };

              return (
                <SearchItem
                  name={title}
                  isArchived={is_archived}
                  key={uuid}
                  title={title}
                  subtitle={subtitle}
                  onClick={onClick}
                />
              );
            }

            case SearchHistoryItemTypes.NOTE: {
              const { notesData } = rest;
              const { note, contact } = notesData;

              return (
                <NoteSearchItem
                  key={note?.id || index}
                  note={note}
                  contact={contact}
                  closeSearchModal={closeSearchModal}
                />
              );
            }

            case SearchHistoryItemTypes.PROPOSAL: {
              const { proposalData } = rest;

              return (
                <ProposalSearchItem
                  key={proposalData.uuid}
                  proposal={proposalData}
                  closeSearchModal={closeSearchModal}
                />
              );
            }

            case SearchHistoryItemTypes.COMPANY: {
              const { companyData } = rest;

              return (
                <CompanyItem
                  key={companyData.uuid}
                  contact={companyData}
                  closeSearchModal={closeSearchModal}
                />
              );
            }

            case SearchHistoryItemTypes.TASK: {
              const {
                taskData: { task, contacts },
              } = rest;

              return (
                <TaskItem
                  key={task.id}
                  task={task}
                  contacts={contacts}
                  closeSearchModal={closeSearchModal}
                />
              );
            }

            case SearchHistoryItemTypes.FILE: {
              const { fileData } = rest;

              return (
                <FilesItem
                  key={fileData.uuid}
                  file={fileData}
                  closeSearchModal={closeSearchModal}
                />
              );
            }
          }
        })}
      </div>
      <div onClick={onClearHistory} className={styles.ClearButton}>
        {t('clear_history_button_text')}
      </div>
    </div>
  );
};

export default RecentlySearch;
