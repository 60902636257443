import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FormValues } from '../useSettingsSubForm';
import TextField from '../../../../../../components/UI/Inputs/TextField';
import styles from './styles.module.scss';

export default function ValidityInput() {
  const { t } = useTranslation('proposal-settings');

  const { setValue, control } = useFormContext<FormValues>();

  return (
    <Controller
      name="validity"
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            // wrong type of ControllerRenderProps.onBlur
            (field.onBlur as (event: React.FocusEvent<HTMLInputElement>) => void)(event);

            if (event.target.value === '') {
              setValue('validity', 30, { shouldValidate: true });
            }
          }}
          id="proposal_settings_validity"
          label={t('form.field.validity.label')}
          labelHint={t('form.field.validity.hint')}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          fullWidth
          endAdornment={
            <div className={styles.InputHint}>{t('form.field.validity.input_hint')}</div>
          }
        />
      )}
    />
  );
}
