import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { IconButton } from '@material-ui/core';
import { getYear } from 'date-fns';
import { useRouter } from 'next/router';

import styles from './styles/Header.module.css';
import InputBase from '../InputBase';

const DatePickerHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const { locale } = useRouter();

  const changeMonth = (e, type: string) => {
    e.preventDefault();
    type === 'increase' && increaseMonth();
    type === 'decrease' && decreaseMonth();
  };
  return (
    <div className={styles.container}>
      <div className={styles.headerLeft}>
        <span className={styles.month}>
          {new Date(date).toLocaleDateString(locale, { month: 'long' })}
        </span>
        <InputBase
          value={new Date(date).toLocaleDateString(locale, { year: 'numeric' })}
          className={styles.yearInput}
          type="number"
          defaultValue={getYear(date)}
          onChange={(e) => {
            if (e.target.value.length > 4) {
              changeYear(new Date().getFullYear());
            } else {
              changeYear(Math.abs(parseInt(e.target.value) || new Date().getFullYear()));
            }
          }}
        />
      </div>
      <div className={styles.headerRight}>
        <IconButton onClick={(e) => changeMonth(e, 'decrease')} disabled={prevMonthButtonDisabled}>
          <ChevronLeft />
        </IconButton>
        <IconButton onClick={(e) => changeMonth(e, 'increase')} disabled={nextMonthButtonDisabled}>
          <ChevronRight />
        </IconButton>
      </div>
    </div>
  );
};
export default DatePickerHeader;
