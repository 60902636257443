const typography = {
  fontFamily:
    "Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif",

  h2: {
    fontSize: 15,
  },
  button: {
    color: '#ffffff',
  },
};
export default typography;
