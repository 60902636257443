import { v4 as uuidv4 } from 'uuid';

import { useAppDispatch, useAppStore } from '../../../app/hooks';
import { selectContactsMap, createContact } from '../../../slices/contactsSlice';
import { selectCurrentWorkspaceId } from '../../../slices/workspacesSlice';
import { selectUserProfile } from '../../../slices/userSlice';
import Contact from '../../../../types/contact';

export default function useDuplicateContact() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  return async (contactId: string, overridenData?: Partial<Contact>) => {
    const state = store.getState();
    const contactsMap = selectContactsMap(state);
    const workspaceId = selectCurrentWorkspaceId(state);
    const { email } = selectUserProfile(state);

    const anchor = contactsMap[contactId];

    const creatingTimestamp = Math.floor(Date.now() / 1000);

    const id = uuidv4();

    const duplicated: Contact = {
      ...anchor,
      uuid: id,
      id: undefined,
      is_archived: false,
      assigned_to: email,
      workspace_id: workspaceId,
      created_at: creatingTimestamp,
      updated_at: creatingTimestamp,
      ...overridenData,
    };

    await dispatch(createContact(duplicated)).unwrap();

    return duplicated.uuid;
  };
}
