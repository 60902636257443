import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { isMobileOnly } from 'react-device-detect';

import routes from 'src/utils/constants/appRoutes';

const PAGES_WITHOUT_REDIRECT = [
  routes.ROOT_SETTINGS_PAGE,
  routes.LOGIN_PAGE,
  routes.PLANS_PAGE,
  routes.PAYMENT_DETAILS_PAGE,
  routes.NOT_FOUND,
  routes.AUTHORIZATION_PAGE,
];

const useMobileVersion = (isAuthenticated) => {
  const [isRedirectingOnMobile, setIsRedirectingOnMobile] = useState(isMobileOnly);
  const router = useRouter();

  useEffect(() => {
    if (isMobileOnly) {
      redirectToSettingsOnMobile();
    }
  }, [router.pathname, isAuthenticated]);

  const redirectToSettingsOnMobile = async () => {
    if (localStorage.getItem('redirect_after_login') && isAuthenticated) {
      router.replace(JSON.parse(localStorage.getItem('redirect_after_login')));
    }

    // redirect users to settings pages on mobile web if user isn't already on it or login page
    if (
      !PAGES_WITHOUT_REDIRECT.some((route) => router.pathname.includes(route)) &&
      isAuthenticated
    ) {
      setIsRedirectingOnMobile(true);

      await router.replace(routes.ROOT_SETTINGS_PAGE);
    }
    setIsRedirectingOnMobile(false);
  };

  return {
    isRedirectingOnMobile,
  };
};

export default useMobileVersion;
