import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import InputBase from '../../../../../../../components/UI/Inputs/InputBase';
import InputLabel from '../../../../../../../components/UI/Inputs/InputLabel';
import Checkbox from '../../../../../../../components/UI/Inputs/Checkbox';

import { FormValues } from '../../useSettingsSubForm';
import styles from './CustomerDetailsInput.module.css';

export default function CustomerDetailsInput({
  value,
  onChange,
}: {
  value: FormValues['customerDetails'];
  onChange: (nextValue: FormValues['customerDetails']) => void;
}) {
  const { t } = useTranslation('proposal-settings');

  const anchorRef = useRef<HTMLDivElement>();

  const [popupOpen, setPopupOpen] = useState(false);

  const options = [
    {
      name: 'email',
      checked: value.includes('email'),
    },
    {
      name: 'fax',
      checked: value.includes('fax'),
    },
    {
      name: 'address',
      checked: value.includes('address'),
    },
    {
      name: 'phone',
      checked: value.includes('phone'),
    },
  ];

  const handleChangeCheckbox = (name: string) => {
    const option = options.find((optionItem) => optionItem.name === name);
    const prevValue = option.checked;
    const index = options.indexOf(option);

    const optionsCopy = [...options];
    optionsCopy.splice(index, 1, { name, checked: !prevValue });

    const nextValue = optionsCopy
      .filter((option) => option.checked === true)
      .map(({ name }) => name) as FormValues['customerDetails'];
    onChange(nextValue);
  };

  const inputValue = value.length > 0 ? value.join(', ') : '';

  return (
    <ClickAwayListener onClickAway={() => setPopupOpen(false)}>
      <div ref={anchorRef}>
        <InputLabel htmlFor="proposal_settings_customer_details">
          {t('form.field.customerDetails.label')}
        </InputLabel>
        <InputBase
          className={styles.BaseInput}
          value={inputValue}
          readOnly
          onFocus={() => setPopupOpen(true)}
          fullWidth
          inputProps={{ className: styles.BaseInput, 'data-hj-allow': '' }}
        />
        <Popper
          open={popupOpen}
          anchorEl={anchorRef.current}
          className={styles.Popper}
          style={{ width: anchorRef?.current?.offsetWidth || '100%' }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={styles.optionsWrapper}>
                {options.map(({ name, checked }) => {
                  return (
                    <div key={name}>
                      <FormControlLabel
                        className={styles.FormControlLabel}
                        checked={checked}
                        id={`proposal_form_customer_details_${name}`}
                        onChange={() => {
                          handleChangeCheckbox(name);
                        }}
                        control={<Checkbox />}
                        label={
                          name === 'email'
                            ? t(`duplicates:Email_field`)
                            : t(`form.field.customerDetails.value.${name}`)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
