import { dbVersion } from '../../constants';

export default {
  title: 'tagLink schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: {
    key: 'pk_id',
    fields: ['id', 'workspaceId'],
    separator: '|',
  },
  type: 'object',
  required: [
    'pk_id',
    'id',
    'nextId',
    'type',
    'itemTypeId',
    'colId',
    'createdAt',
    'updatedAt',
    'workspaceId',
  ],
  properties: {
    pk_id: {
      type: 'string',
      maxLength: 100,
    },
    id: {
      type: 'string',
      maxLength: 100,
    },
    nextId: {
      type: ['string', 'null'],
    },
    type: {
      type: 'string',
    },
    itemTypeId: {
      type: 'string',
    },
    colId: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    workspaceId: {
      type: 'string',
    },
  },
};
