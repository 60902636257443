import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useDialog } from '../../../app/hooks/useDialog';
import DrawerWithHeader from 'components/UI/DrawerWithHeader/DrawerWithHeader';
import CreateForm from '../TaskForm/CreateForm';
import UpdateForm from '../TaskForm/UpdateForm/UpdateForm';

import { FormOptions, Mode } from './TaskDrawerProvider';
import styles from './TaskDrawer.module.css';

export default function TaskDrawer({
  mode,
  formOptions,
  onClose,
}: {
  mode: Mode;
  formOptions: FormOptions;
  onClose: () => void;
}) {
  const { t } = useTranslation('tasks-page');

  const [formIsDirty, setFormIsDirty] = useState(false);
  const handleFormDataChanged = useCallback(() => {
    setFormIsDirty(true);
  }, []);

  useEffect(() => {
    setFormIsDirty(false);
  }, [formOptions]);

  const { show, dialogUI } = useDialog();

  const handleClose = () => {
    if (formIsDirty) {
      show();
    } else {
      onClose();
    }
  };

  return (
    <DrawerWithHeader
      title={mode === 'create' ? t('form.create.title') : t('form.update.title')}
      open={!!mode}
      onClose={handleClose}
      anchor="right"
      contentClassName={styles.drawerContent}
    >
      {mode === 'create' && (
        <>
          <CreateForm {...formOptions} onFormDataChanged={handleFormDataChanged} />
          {dialogUI({
            bodyText: t(`form.close_dialog_create.body`),
            positiveButtonText: t('common:action.discard'),
            negativeButtonText: t('duplicates:Cancel_action'),
            onSuccess: onClose,
            titleText: t(`form.close_dialog_create.title`),
            titleClassName: styles.DialogTitle,
            negativeButtonClassName: styles.CancelButton,
            positiveButtonClassName: styles.DiscardButton,
          })}
        </>
      )}
      {mode === 'update' && (
        <>
          <UpdateForm {...formOptions} onFormDataChanged={handleFormDataChanged} />
          {dialogUI({
            bodyText: t(`form.close_dialog_update.body`),
            positiveButtonText: t('common:action.discard'),
            negativeButtonText: t('duplicates:Cancel_action'),
            onSuccess: onClose,
            titleText: t(`form.close_dialog_update.title`),
            titleClassName: styles.DialogTitle,
            negativeButtonClassName: styles.CancelButton,
            positiveButtonClassName: styles.DiscardButton,
          })}
        </>
      )}
    </DrawerWithHeader>
  );
}
