import 'reflect-metadata';

import React, { useEffect } from 'react';
import Head from 'next/head';
import { Provider } from 'react-redux';
import type { AppProps } from 'next/app';
import { ThemeProvider } from '@material-ui/styles';
import { appWithTranslation } from 'next-i18next';
import { StylesProvider } from '@material-ui/core/styles';
import { hotjar } from 'react-hotjar';
import { BrowserView } from 'react-device-detect';

import MuiTheme from '../theming/index';
import SBProvider from '../components/UI/snackbar/providers/SnackBarProvider';
import ProtectedRoute from '../components/ProtectedRoute';
import { MainLayout } from '../components';
import TaskDrawerProvider from '../features/tasks/TaskDrawer/TaskDrawerProvider';
import ImportCSVDrawerProvider from '../features2/importCSV/ui/ImportCSVDrawer/Provider';
import DownloadAppScreen from '../features/downloadAppScreen';
import { appRoutes } from '../utils/constants';
import * as gtag from '../../lib/gtag';
import PagesWrapper from './PagesWrapper';
import { ProposalDrawerProvider } from 'src/features/proposals/ProposalDrawer';
import BillingLimitsProvider from 'src/features2/billing/ui/limitsAndOverlays/limits/BillingLimitsProvider';
import StreamUpdatesProvider from './StreamUpdatesProvider';
import AnalyticsProvider from 'components/AnalyticsProvider';
import envService from 'src/services/app/envService';
import { IocProvider } from '../app/ioc/ioc.react';
import { container } from '../app/ioc/container';
import FilesProvider from '../features2/files/ui/FilesProvider';
import LocalizationProvider from '../features2/localization/ui/Provider';
import embedPageHoc from '../hoc/embedPageHoc';

import 'animate.css';
import useCreateStore from '../app/hooks/useCreateStore';

import '../styles/globals.scss';
import '../styles/variables.css';

const mainRoutes = [
  appRoutes.CALLS_PAGE,
  appRoutes.DASHBOARD,
  appRoutes.CONTACTS_PAGE,
  appRoutes.CONTACT_PAGE,
  appRoutes.TASKS_PAGE,
  appRoutes.SETTINGS_PAGE,
  appRoutes.PLANS_PAGE,
  appRoutes.PAYMENT_DETAILS_PAGE,
];

function MyApp({ Component, pageProps, router }: AppProps) {
  // TODO: Remove store initialization from React component
  const store = useCreateStore();

  useEffect(() => {
    if (envService.isProd() || envService.isPrerelease()) {
      hotjar.initialize(
        parseInt(process.env.NEXT_PUBLIC_HJ_ID),
        parseInt(process.env.NEXT_PUBLIC_HJ_SV)
      );
    }
  }, []);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const isMainRoute = mainRoutes.indexOf(router.pathname) != -1;

  if (!store) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Leader - CRM</title>
      </Head>
      <IocProvider container={container}>
        <Provider store={store}>
          <StreamUpdatesProvider store={store}>
            <AnalyticsProvider store={store}>
              <StylesProvider injectFirst>
                <ThemeProvider theme={MuiTheme}>
                  <ProtectedRoute router={router}>
                    <SBProvider>
                      <TaskDrawerProvider>
                        <BillingLimitsProvider>
                          <ProposalDrawerProvider>
                            <FilesProvider>
                              <ImportCSVDrawerProvider>
                                <LocalizationProvider>
                                  <PagesWrapper>
                                    <BrowserView>
                                      <DownloadAppScreen />
                                    </BrowserView>
                                    {isMainRoute ? (
                                      <MainLayout pathName={router.pathname}>
                                        <Component {...pageProps} />
                                      </MainLayout>
                                    ) : (
                                      <Component {...pageProps} />
                                    )}
                                  </PagesWrapper>
                                </LocalizationProvider>
                              </ImportCSVDrawerProvider>
                            </FilesProvider>
                          </ProposalDrawerProvider>
                        </BillingLimitsProvider>
                      </TaskDrawerProvider>
                    </SBProvider>
                  </ProtectedRoute>
                </ThemeProvider>
              </StylesProvider>
            </AnalyticsProvider>
          </StreamUpdatesProvider>
        </Provider>
      </IocProvider>
    </>
  );
}

export default appWithTranslation(embedPageHoc(MyApp));
