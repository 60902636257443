import { TeamMember } from '../../../../api/workspacesAPI';
import Avatar from '../../../../components/UI/Avatar';
import Menu, { MenuProps } from '../../../../components/UI/Menu/Menu';
import MenuItem from '../../../../components/UI/Menu/MenuItem';
import styles from './AssignToSelect.module.css';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import AddUserIcon from 'icons/add-user-icon.svg';
import appRoutes from 'src/utils/constants/appRoutes';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { useCurrentWorkspace } from 'src/features2/workspaces/ui/hooks/useCurrentWorkspace';
export default function TeammembersMenu({
  className,
  anchorEl,
  anchorReference,
  anchorOrigin,
  value,
  options,
  onSelect,
  onClose,
}: {
  className?: string;
  anchorEl: MenuProps['anchorEl'];
  value: TeamMember['email'];
  options: TeamMember[];
  onSelect: (value: TeamMember['email']) => void;
  onClose?: () => void;
  anchorReference?: MenuProps['anchorReference'];
  anchorOrigin?: MenuProps['anchorOrigin'];
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const { currentUser } = useCurrentUser();
  const { currentWorkspace } = useCurrentWorkspace();
  return (
    <Menu
      className={className}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorReference={anchorReference}
      anchorOrigin={anchorOrigin}
      getContentAnchorEl={null}
    >
      {options.map(({ thumbnail, fullName, email }) => (
        <MenuItem
          key={email}
          selected={email === value}
          onClick={() => {
            if (value !== email) {
              onSelect(email);
            }

            if (onClose) {
              onClose();
            }
          }}
          className={styles.option}
          onContextMenu={(e) => e.stopPropagation()}
        >
          <Avatar className={styles.optionAvatar} size={24} thumbnail={thumbnail} name={fullName} />{' '}
          {fullName}
        </MenuItem>
      ))}
      {(currentUser.isAdmin || currentWorkspace?.isPersonal) && (
        <MenuItem className={styles.option} onClick={() => router.push(appRoutes.TEAMMATES_PAGE)}>
          <div className={styles.optionAddTeammatesIcon}>
            <AddUserIcon />
          </div>
          {t('common:invite_teammates_button_text')}
        </MenuItem>
      )}
    </Menu>
  );
}
