import NotificationEntity from './NotificationEntity';
import NotificationDC from './NotificationDC';

export const mapNotificationDCtoEntity = (dc: NotificationDC) => {
  const prepareData = (dcData: NotificationDC['data']): NotificationEntity['data'] => {
    const data = {
      notificationId: dcData.notification_id,
      workspaceId: dcData.workspace_id,
      title: dcData.title,
      orderedParams: dcData.ordered_params,
    };

    const optionalParams = [
      ['item_type', 'itemType'],
      ['item_uuid', 'itemId'],
      ['admin_name', 'adminName'],
      ['company_name', 'companyName'],
    ];

    for (const param of optionalParams) {
      const [dcField, entityField] = param;

      if (typeof dcData[dcField] !== 'undefined') {
        data[entityField] = dcData[dcField];
      }
    }

    return data;
  };

  return {
    id: dc.uuid,
    type: dc.type,
    isRead: dc.is_read,
    data: prepareData(dc.data),
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
  };
};
