import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { selectBoard } from './slice';
import { selectCurrentWorkspaceId } from '../workspacesSlice';

import { sliceName } from './constants';
import { AppState } from '../../app/store/store';
import API, { ColumnModel } from '../../api/boardAPI';

export const addColumn = createAsyncThunk(
  `${sliceName}/addColumn`,
  async ({ title, color }: { title: string; color: string }, { getState }) => {
    const column = {
      id: uuidv4(),
      title,
      color,
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
      items: [],
    };

    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    return API.updateBoardColumn({ column, workspaceId });
  }
);

export const updateColumn = createAsyncThunk(
  `${sliceName}/updateColumn`,
  async (
    { uuid, title, color }: { uuid: string; title?: string; color?: string },
    { getState }
  ) => {
    const board = selectBoard(getState() as AppState);

    const column = board.columns.find((colItem) => colItem.id === uuid);

    const updatedColumn: ColumnModel = {
      ...column,
      title: title || column.title,
      color: color || column.color,
      updatedAt: Math.floor(Date.now() / 1000),
    };

    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    return API.updateBoardColumn({ column: updatedColumn, workspaceId });
  }
);
