enum FB_EVENTS {
  LOGIN_SUCCESS = 'login_success',
  ADD_QUOTE = 'add_quote',
  ADD_TASK = 'add_task',
  ADD_NOTE = 'add_note',
  COLLABORATION_ACCOUNT = 'collaboration_account',
  ADD_TEAMMATE = 'add_teammate',
  ADD_LABEL = 'add_label',
}
export default FB_EVENTS;
