import React from 'react';
import { Popover } from '@material-ui/core';

import InfoIcon from 'public/images/icons/header/info.svg';
import styles from './styles.module.scss';
import useHelpMenuViewModel from 'components/UI/Headers/Header/components/HelpMenu/useHelpMenuViewModel';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import ExternalLinks from 'src/enums/ExternalLinks';
import { useCurrentWorkspace } from 'src/features2/workspaces/ui/hooks/useCurrentWorkspace';

const HelpMenu: React.FC = () => {
  const { onButtonClick, onClose, anchorEl, links } = useHelpMenuViewModel();
  const { currentUser } = useCurrentUser();
  const { subscription } = useCurrentSubscription();
  const { currentWorkspace } = useCurrentWorkspace();
  const accType = subscription?.paidMembersCount > 1 ? 'collaborative' : 'individual';
  return (
    <>
      <button onClick={onButtonClick} className={styles.Button}>
        <InfoIcon />
      </button>
      <Popover
        anchorEl={anchorEl}
        classes={{ paper: styles.Popover }}
        open={!!anchorEl}
        onClose={onClose}
        anchorOrigin={{
          horizontal: -39,
          vertical: 39,
        }}
      >
        {links.map(({ link, text }, index) => {
          if (link == ExternalLinks.CONTACT_US) {
            return (
              <a
                onClick={onClose}
                key={index}
                href={`${link}?acc_type=${accType}&country=${currentUser?.settings.country}&Plan_type=${subscription?.plan}&fullName=${currentUser?.fullName}&Purchase_type=${subscription?.purchaseType}&numberOfTeammates=${currentWorkspace?.members?.length}`}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {text}
              </a>
            );
          } else {
            return (
              <a
                onClick={onClose}
                key={index}
                href={link}
                target={'_blank'}
                rel={'noopener noreferrer'}
              >
                {text}
              </a>
            );
          }
        })}
      </Popover>
    </>
  );
};

export default HelpMenu;
