import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
const EmptyNotifications = () => {
  const { t } = useTranslation('notifications');
  return (
    <div className={styles.emptyWrapper}>
      <div className={styles.emptyIcon}></div>
      <span className={styles.emptyTitle}>{t('empty')}</span>
    </div>
  );
};
export default EmptyNotifications;
