import FileBC from '../../../../features2/files/domain/FileBC';
import Contact from 'types/contact';

type TFiles = {
  files: FileBC[];
  searchValue: '';
  isAllContentVisible: boolean;
  contacts: Contact[];
  userEmail: string;
};
export const getFilteredFiles = ({
  files,
  searchValue,
  isAllContentVisible,
  contacts,
  userEmail,
}: TFiles) => {
  const allowedFiles = isAllContentVisible
    ? files
    : files.filter((file) => {
        return (
          contacts.find((contact) => contact.uuid === file.itemUuid)?.assigned_to === userEmail
        );
      });
  // let filteredFiles = allowedFiles;
  // let filteredFilesByMembers;
  // if (selectedTeamMembers.length) {
  //   filteredFilesByMembers = [];
  //   selectedTeamMembers.forEach(({ email }) => {
  //     const relatedContacts = teamMembersContactsMap[email];
  //     allowedFiles.forEach((file) => {
  //       relatedContacts.forEach((contact) => {
  //         if (file.itemUuid === contact.uuid) {
  //           filteredFilesByMembers.push(file);
  //         }
  //       });
  //     });
  //   });
  // }
  // let filteredFilesByTags;
  // if (selectedTags.length) {
  //   const allowedTagsItems = getAllowedTagsItems(selectedTags);
  //   filteredFilesByTags = filteredFilesByMembers || filteredFiles;
  //   allowedTagsItems.forEach((contactId) => {
  //     allowedFiles.forEach((file) => {
  //       if (file.itemUuid === contactId) {
  //         allowedFiles.push(file);
  //       }
  //     });
  //   });
  // }

  return allowedFiles.filter(({ name, link }) => {
    return name.toLowerCase().includes(searchValue.toLowerCase());
  });
};
