import { injectable } from 'inversify';
import IUserApiService from '../../domain/abstractions/IUserApiService';
import axios from 'src/app/axios';
import { endpoints } from 'src/utils/constants';
import { from, map } from 'rxjs';
import ProviderInfoDC from 'src/db/entities/providerInfo/ProviderInfoDC';
import { mapProviderInfoDCToEntity } from 'src/db/entities/providerInfo/mapper';

@injectable()
export default class UserApiService implements IUserApiService {
  fetchProviderInfoByTempToken = (provider, token) => {
    const response = axios.post<ProviderInfoDC>(`${endpoints.vendor.connect}${provider}`, {
      code: token,
      redirect_url: window.location.origin + process.env.NEXT_PUBLIC_REDIRECT_URI,
    });
    return from(response).pipe(map((response) => mapProviderInfoDCToEntity(response.data)));
  };
}
