import { useAppStore } from '../../../app/hooks';
import { AppState } from '../../../app/store/store';
import { selectContactsMap } from '../../../slices/contactsSlice';
import useDuplicateContact from '../../../features/contacts/hooks/useDuplicateContact';

// this hook uses for duplication contact if the user create/updates the proposal
// and the related contact is private (field workspace_id === null)
export default function useGetRelatedContactId() {
  const store = useAppStore();
  const duplicateContact = useDuplicateContact();

  return async (selectedContactId: string) => {
    const state = store.getState() as AppState;
    const selectedContact = selectContactsMap(state)[selectedContactId];

    if (selectedContact.workspace_id === null) {
      const id = await duplicateContact(selectedContactId);

      return id;
    } else {
      return selectedContactId;
    }
  };
}
