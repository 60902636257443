import { injectable, inject } from 'inversify';
import { Observable, from, switchMap, map } from 'rxjs';
import { TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';

import ProfileEntity from '../../../db/entities/profile/ProfileEntity';

export interface IProfileDao {
  getProfile(): Observable<ProfileEntity>;
  upsertProfile(profile: ProfileEntity): Observable<ProfileEntity>;
}

@injectable()
export default class ProfileDao implements IProfileDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;

  getProfile = () => {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db.profile.find().$;
        })
      )
      .pipe(
        map((docs) => {
          return docs[0]?.toMutableJSON() as ProfileEntity;
        })
      );
  };

  upsertProfile(profile) {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.profile.bulkUpsert([profile]);
      }),
      map((docs) => {
        return docs[0].toMutableJSON() as ProfileEntity;
      })
    );
  }
}
