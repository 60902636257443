import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from 'icons/close.svg';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import useTutorialDialog from './useTutorialDialog';

const TutorialDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const { needToShowTurorial } = useTutorialDialog();
  return (
    <Dialog
      classes={{ paper: styles.container }}
      onClose={handleClose}
      open={open || needToShowTurorial}
    >
      <IconButton onClick={handleClose} color={'inherit'} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={styles.title}>{t('common:tutorial.dialog.title')}</DialogTitle>
      <DialogContent className={styles.contentWrapper}>
        <div className={styles.description}>
          <span>{t('common:tutorial.dialog.description')}</span>
        </div>
        <div className={styles.videoWrapper}>
          <iframe
            src={
              'https://player.vimeo.com/video/785966943?h=db42d5650f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%'
            }
            frameBorder="0"
            allow={'autoplay; fullscreen; picture-in-picture'}
            allowFullScreen={true}
            title={'Leader Tutorial'}
          ></iframe>
        </div>
        <script src={'https://player.vimeo.com/api/player.js'}></script>
      </DialogContent>
    </Dialog>
  );
};

export default TutorialDialog;
