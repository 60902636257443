import React from 'react';

import styles from './styles.module.scss';
import FilePreviewLoader from '../FilePreviewLoader';

interface IPreviewAreaProps {
  children: React.ReactNode;
  isLoading?: boolean;
  noLoader?: boolean;
}

const PreviewArea: React.FC<IPreviewAreaProps> = ({ children, noLoader, isLoading }) => {
  return (
    <div className={styles.PreviewArea}>
      {isLoading && !noLoader && <FilePreviewLoader />}
      {children}
    </div>
  );
};

export default PreviewArea;
