import CustomFieldDC from './CustomFieldDC';
import CustomFieldEntity from './CustomFieldEntity';

export const mapCustomFieldDCtoEntity = (
  dc: CustomFieldDC,
  workspaceId: string
): CustomFieldEntity => ({
  uuid: dc.uuid,
  name: dc.name,
  fieldType: dc.field_type,
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  workspaceId,
});

export const mapCustomFieldEntityToDC = (entity: CustomFieldEntity): CustomFieldDC => ({
  uuid: entity.uuid,
  name: entity.name,
  field_type: entity.fieldType,
  created_at: entity.createdAt,
  updated_at: entity.updatedAt,
  workspace_id: entity.workspaceId,
});
