import React from 'react';

import PreviewArea from '../shared/PreviewArea';
import useFilePreviewViewModel from '../../useFilePreviewViewModel';
import { IFilePreviewUnitProps } from '../../index';

const FilePreviewHTML: React.FC<IFilePreviewUnitProps> = ({ file, setIsLoading, isLoading }) => {
  const { fileLink } = useFilePreviewViewModel({ file, isFileSupported: true });

  return (
    <PreviewArea isLoading={isLoading}>
      {fileLink && (
        <iframe
          style={{
            height: 'calc(100% - 129px)',
            width: '70%',
            margin: '0 auto',
            display: 'block',
            background: 'var(--color-white)',
            visibility: isLoading ? 'hidden' : 'visible',
          }}
          src={fileLink}
          title={file.name}
          onLoad={() => setIsLoading(false)}
        ></iframe>
      )}
    </PreviewArea>
  );
};

export default FilePreviewHTML;
