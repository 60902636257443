const getStartEnd = (str: string, sub: string) => {
  const lcString = str?.toLowerCase();
  const lcSubstring = sub?.toLowerCase();

  return [lcString?.indexOf(lcSubstring), lcString?.indexOf(lcSubstring) + lcSubstring?.length];
};

export const getHighlightedOverlaps = (string: string, target: string) => {
  if (target) {
    const regex = new RegExp(processSpecialCharactersForRegExp(target), 'ig');
    const coordinates = getStartEnd(string, target);
    const replaceMask = `<span>${string?.slice(coordinates[0], coordinates[1])}</span>`;

    return string?.replace(regex, replaceMask);
  } else {
    return string;
  }
};

const processSpecialCharactersForRegExp = (string: string): string =>
  string.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
