import React, { useEffect, useState } from 'react';
import { uuid4 } from '@sentry/utils';

import FilesContext from '../../domain/context/FilesContext';
import FilesUploadingDialog from '../Files/FilesUploadingDialog';
import { UploadingQueueItem } from '../../data/FileUploadStateRepository.types';
import FileBC from '../../domain/FileBC';
import FilePreview from '../Files/FilePreview';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import { selectCurrentUserMemberData } from 'slices/workspacesSlice';
import { selectRedirectEffect, setRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from '../../../../enums/RedirectEffectTargetType';
import FileUploadingStatus from '../../domain/enum/FileUploadingStatus';
import { useTranslation } from 'next-i18next';
import { useInjection } from '../../../../app/ioc/ioc.react';
import { IFilesUseCases } from '../../domain/abstractions/IFilesUseCases';
import { TYPES } from '../../di/types';

interface IFilesProviderProps {
  children: React.ReactNode;
}

const FilesProvider: React.FC<IFilesProviderProps> = ({ children }) => {
  const { t } = useTranslation('files');
  const { getFiles, updateFileByUuid } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const [uploadingQueue, setUploadingQueue] = useState<UploadingQueueItem[]>([]);
  const [previewFile, setPreviewFile] = useState<FileBC>(null);
  const [commonFilesList, setCommonFilesList] = useState<FileBC[]>([]);
  const [thumbnailsMap, setThumbnailsMap] = useState<Map<string, string>>(new Map());
  const [uploadingDialogTitle, setUploadingDialogTitle] = useState<string>(
    t('uploading_dialog.in_process', { filesCount: uploadingQueue.length })
  );
  const [uploadingStatusMap, setUploadingStatusMap] = useState({});
  const currentContact = useAppSelector(selectCurrentContact);
  const currentUser = useAppSelector(selectCurrentUserMemberData);
  const redirectEffect = useAppSelector(selectRedirectEffect);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getFilesSubscription = getFiles().subscribe((files) => {
      setCommonFilesList(
        files
          .map((item) => {
            if (!item.isOptimistic) {
              const thumbnail = thumbnailsMap.get(item.uuid);

              if (thumbnail) {
                updateFileByUuid(item.uuid, { thumbnail });

                return { ...item, thumbnail };
              }
            }

            return item;
          })
          .sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 0))
      );
    });

    return () => getFilesSubscription.unsubscribe();
  }, [thumbnailsMap]);

  useEffect(() => {
    const values = Object.values(uploadingStatusMap);

    const errors = values.filter((status) => status === FileUploadingStatus.ERROR);
    const isCompleted =
      values.filter((status) => status === FileUploadingStatus.SUCCESS).length === values.length &&
      values.length;

    if (errors.length) {
      setUploadingDialogTitle(t('uploading_dialog.failed', { errorsCount: errors.length }));
    } else if (isCompleted) {
      setUploadingDialogTitle(t('uploading_dialog.complete'));
      // setUploadingStatusMap({});
    } else if (!errors.length && !isCompleted) {
      const uploadingCount = values.filter(
        (value) =>
          value === FileUploadingStatus.UPLOADING ||
          value === FileUploadingStatus.UPLOADED_TO_STORAGE
      ).length;

      setUploadingDialogTitle(t('uploading_dialog.in_process', { filesCount: uploadingCount }));
    }
  }, [uploadingStatusMap]);

  useEffect(() => {
    if (redirectEffect?.targetType === RedirectEffectTargetType.FILE) {
      const targetFile = commonFilesList.find(
        (listItem) => listItem.uuid === redirectEffect.targetId
      );

      if (targetFile) {
        setPreviewFile(targetFile);
      }
    }
  }, [redirectEffect]);

  const addItemToQueue = (files: File[]) => {
    const uploaded = [...uploadingQueue];

    files.some((file) => {
      const queueData: UploadingQueueItem = {
        itemUuid: currentContact.uuid,
        creatorEmail: currentUser.email,
        id: uuid4(),
        file: file,
        progress: 0,
      };

      uploaded.push(queueData);
    });

    setUploadingQueue(uploaded);
  };

  const removeItemFromQueue = (fileId: UploadingQueueItem['id']) => {
    const filteredQueue = uploadingQueue.filter((item) => item.id !== fileId);

    setUploadingQueue(filteredQueue);
  };

  const onPreviewClose = () => {
    setPreviewFile(null);

    if (redirectEffect?.targetType === RedirectEffectTargetType.FILE) {
      dispatch(setRedirectEffect(null));
    }
  };

  const onUploadingDialogClose = () => {
    setUploadingQueue([]);
    setUploadingStatusMap({});
  };

  const setCommonFilesListWrapper = (list: FileBC[]) => setCommonFilesList(list);
  const setThumbnailsMapWrapper = (map: Map<string, string>) => setThumbnailsMap(map);
  const setUploadingStatusMapWrapper = (map: { [key: string]: string }) =>
    setUploadingStatusMap(map);

  return (
    <FilesContext.Provider
      value={{
        uploadingQueue,
        addItemToQueue,
        setPreviewFile,
        removeItemFromQueue,
        setCommonFilesList: setCommonFilesListWrapper,
        setThumbnailsMap: setThumbnailsMapWrapper,
        setUploadingStatusMap: setUploadingStatusMapWrapper,
        uploadingStatusMap,
        thumbnailsMap,
        uploadingDialogTitle,
        commonFilesList,
      }}
    >
      {children}
      <FilesUploadingDialog uploadingQueue={uploadingQueue} onClose={onUploadingDialogClose} />
      {previewFile && <FilePreview file={previewFile} onClose={onPreviewClose} />}
    </FilesContext.Provider>
  );
};

export default FilesProvider;
