import { useState } from 'react';
import ImportCSVContext from '../../ImportCSVContext';
import DrawerWrapper from '../Wrapper';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export type FormOptions = { closeAfterSave?: boolean; defaultValues?: Partial<TaskEntity> };

export default function ImportCSVDrawerProvider({ children }: { children: React.ReactNode }) {
  const [file, setFile] = useState<File>();
  const [columnId, setColumnId] = useState<string>();
  const [tagName, setTagName] = useState<string>();

  const handleLoadFile = ({ file, tagName, columnId }) => {
    setTagName(tagName);
    setColumnId(columnId);
    setFile(file);
  };

  const handleClose = () => {
    setFile(undefined);
    setColumnId(undefined);
    setTagName(undefined);
  };
  return (
    <ImportCSVContext.Provider value={{ handleLoadFile }}>
      {children}
      <DrawerWrapper
        onClose={handleClose}
        isOpen={!!file}
        file={file}
        columnId={columnId}
        tagName={tagName}
      />
    </ImportCSVContext.Provider>
  );
}
