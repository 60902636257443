import { Observable } from 'rxjs';

import ProfileEntity from '../../../db/entities/profile/ProfileEntity';
import { inject, injectable } from 'inversify';
import { TYPES } from '../di/types';
import { IProfileDao } from './ProfileDao';

export interface IProfileDataRepository {
  getProfile(): Observable<ProfileEntity>;
}

@injectable()
export default class ProfileDataRepository implements IProfileDataRepository {
  @inject(TYPES.ProfileDao) private dao: IProfileDao;

  getProfile() {
    return this.dao.getProfile();
  }
}
