import axios from '../axios';
import endpoints from '../../utils/constants/endpoints';
import RequestHeaders from '../../enums/RequestHeaders';

const getStreamUpdates = async (updateToken = '', workspaceId?: string) => {
  const params = workspaceId && { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } };

  const { data } = await axios.get(
    `${endpoints.stream.updates}?update_token=${updateToken}`,
    params
  );
  return data;
};
export default getStreamUpdates;
