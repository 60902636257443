import { Container } from 'inversify';
import { DbManager } from '../../db/dbManager';
import { LocalStorageRepository, IPersistentRepository } from '../../services/PersistentRepository';

import { bindWorkspacesModule } from '../../features2/workspaces/di/workspacesIOC';
import { bindUserModule } from '../../features2/user/di/userIOC';
import { bindSyncModule } from '../../features2/sync/di/syncIOC';
import { bindImportCSVModule } from '../../features2/importCSV/di/ImportCSVIOC';
import { bindBillingModule } from '../../features2/billing/di/billingIOC';
import { bindProfileModule } from '../../features2/profile/di/profileIOC';
import { bindFilesModule } from '../../features2/files/di/filesIOC';
import { bindLoginModule } from '../../features2/authorization/login/di/loginIOC';
import { bindOnboardingModule } from '../../features2/authorization/onboarding/di/onboardingIOC';
import { bindContactsModule } from '../../features2/contacts/di/contactsIOC';
import { bindCustomerIOModule } from 'src/analytics/customerIO/di/CustomerIOIOC';
import { bindFBAnalyticsModule } from 'src/analytics/FB/di/FBAnalyticsIOC';
import { bindTagLinksModule } from '../../features2/tagLinks/di/tagLinksIOC';
import { bindMonitoringSystemModule } from '../../features2/monitoringSystem/di/monitoringSystemIOC';

import AmplitudeManager, { IAmplitudeManager } from 'src/analytics/amplitude';
import { getThirdPartyDependencies } from './thirdPartyContainers';

import { TYPES } from './types';
import { bindCustomFieldsModule } from 'src/features2/common/customField/di/customFieldsIOC';
import { bindCustomFieldValueModule } from 'src/features2/common/customFieldValue/di/customFieldValueIOC';

const container = new Container();

container.load(getThirdPartyDependencies);
container.bind<DbManager>(TYPES.DbManager).to(DbManager).inSingletonScope();
container
  .bind<IPersistentRepository>(TYPES.PersistentRepository)
  .to(LocalStorageRepository)
  .inSingletonScope();
container.bind<IAmplitudeManager>(TYPES.AmplitudeManager).to(AmplitudeManager).inSingletonScope();
bindWorkspacesModule(container);
bindFilesModule(container);
bindLoginModule(container);
bindUserModule(container);
bindSyncModule(container);
bindImportCSVModule(container);
bindBillingModule(container);
bindProfileModule(container);
bindOnboardingModule(container);
bindContactsModule(container);
bindCustomerIOModule(container);
bindFBAnalyticsModule(container);
bindTagLinksModule(container);
bindMonitoringSystemModule(container);
bindCustomFieldsModule(container);
bindCustomFieldValueModule(container);

export { container };
