import CallerDC from './CallerDC';
import CallerEntity from './CallerEntity';

export const mapCallerDCtoEntity = (dc: CallerDC): CallerEntity => ({
  phone: dc.phone,
  name: dc.name,
  jobTitle: dc.job_title,
  geospace:
    dc.geospace === null
      ? null
      : {
          latitude: dc.geospace.latitude,
          longitude: dc.geospace.longitude,
          country: dc.geospace.country,
          countryCode: dc.geospace.country_code,
          region: dc.geospace.region,
        },
  thumbnail: dc.thumbnail,
  business: dc.business || false,
  spam: dc.spam,
  bigSpammer: dc.big_spammer || false,
});

export const mapCallerEntityToDC = (entity: CallerEntity): CallerDC => {
  return {
    phone: entity.phone,
    name: entity.name,
    job_title: entity.jobTitle,
    geospace:
      entity.geospace === null
        ? null
        : {
            latitude: entity.geospace.latitude,
            longitude: entity.geospace.longitude,
            country: entity.geospace.country,
            country_code: entity.geospace.countryCode,
            region: entity.geospace.region,
          },
    thumbnail: entity.thumbnail,
    business: entity.business,
    spam: entity.spam,
    big_spammer: entity.bigSpammer,
  };
};
