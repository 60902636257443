import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import isToday from 'date-fns/isToday';
import getHours from 'date-fns/getHours';
import getMinutes from 'date-fns/getMinutes';

import { useFormat } from '../../../../lib/date-fns';

import ClockIcon from 'public/images/icons/clock-outline.svg';
import styles from './TimeBadge.module.css';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export default function DueBadge({
  timestamp,
  completed,
  repeat,
  className,
}: {
  timestamp: number;
  completed: boolean;
  repeat: TaskEntity['repeat'];
  className?: string;
}) {
  const { t } = useTranslation('tasks-page');
  const format = useFormat();

  const date = new Date(timestamp);
  const now = Date.now();

  const overDue = timestamp - now < 0 && !completed;

  const formattedDate = format(date, 'iii d MMM');
  const formattedTime =
    getHours(date) === 0 && getMinutes(date) === 0 ? null : format(date, 'HH:mm');

  const getTitleMessage = () => {
    let title = formattedDate;

    if (repeat === 'daily') {
      title = title + ', ' + t('grid.cell.repeat.value.daily');
    } else if (repeat === 'weekly') {
      title = title + ', ' + t('grid.cell.repeat.value.weekly');
    } else if (repeat === 'monthly') {
      title = title + ', ' + t('grid.cell.repeat.value.monthly');
    }

    return title;
  };

  return (
    <div
      className={clsx(
        className,
        styles.wrapper,
        completed && styles.completed,
        overDue && styles.overdue
      )}
      title={getTitleMessage()}
    >
      <ClockIcon className={styles.icon} />
      {overDue && <>{t('grid.cell.due.over_due')} - </>}
      {isToday(date) ? <span>{t('common:day.today')} </span> : formattedDate} {formattedTime}
      {!!repeat && (
        <span>
          {', '}
          {repeat === 'daily' && t('grid.cell.repeat.value.daily')}
          {repeat === 'weekly' && t('grid.cell.repeat.value.weekly')}
          {repeat === 'monthly' && t('grid.cell.repeat.value.monthly')}
        </span>
      )}
    </div>
  );
}
