import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import ProposalEntity from '../../../../db/entities/proposal/ProposalEntity';
import { ColumnModel } from '../../../../api/boardAPI';

export const getAllowedTagsItems = (selectedTags: TagBoardColumn[]) => {
  const allowedTagsItemsMap = {};
  const allowedTagsItems = [];

  selectedTags.forEach(({ items }) => {
    items.forEach((item) => {
      allowedTagsItemsMap[item.itemTypeId] = allowedTagsItemsMap[item.itemTypeId] + 1 || 1;
    });
  });

  for (const [key, value] of Object.entries(allowedTagsItemsMap)) {
    if (value === selectedTags.length) {
      allowedTagsItems.push(key);
    }
  }

  return allowedTagsItems;
};

export const getFakeQuotesStatus = (proposals: ProposalEntity[]): ColumnModel => {
  const now = Date.now();

  return {
    id: '',
    title: 'proposals:sectionName',
    color: '#ff6e39',
    createdAt: now,
    updatedAt: now,
    items: proposals.map((element) => ({
      uuid: element.uuid,
      type: 'contact',
      typeUuid: element.contactId,
      columnUuid: '',
      createdAt: now,
      updatedAt: now,
    })),
  };
};
