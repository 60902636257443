import BoardItemDC from './BoardItemDC';
import BoardItemEntity from './BoardItemEntity';

export const mapBoardItemDCtoEntity = (dc: BoardItemDC, workspaceId: string): BoardItemEntity => {
  return {
    type: dc.item_type,
    typeUuid: dc.item_uuid,
    id: dc.uuid,
    nextId: dc.next_uuid,
    colId: dc.column_uuid,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    workspaceId: workspaceId,
  };
};
