import { createContext } from 'react';
export interface IImportCSVContext {
  handleLoadFile: ({
    file,
    tagName,
    columnId,
  }: {
    file: File;
    tagName?: string;
    columnId?: string;
  }) => void;
}

const ImportCSVContext = createContext({
  handleLoadFile: undefined,
});

export default ImportCSVContext;
