import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks/';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import styles from './styles.module.scss';
import EmptyNotifications from './EmptyNotifications';
import { deleteNotifications, selectNotifications } from 'slices/notificationSlice';
import { useTranslation } from 'next-i18next';
import Spinner from 'components/UI/Spinner';
import { useSnackbar } from 'notistack';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import DeleteDialog from '../../components/UI/Dialogs/DeleteDialog';

import Row from './Row';
type NotificationsProps = {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  className?: string;
};
const Notifications = ({ anchorEl, onClose, className }: NotificationsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(selectNotifications);
  const { t } = useTranslation(['notifications', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const open = !!anchorEl;
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleClearAll = async () => {
    setLoading(true);
    try {
      await dispatch(deleteNotifications([])).unwrap();
    } catch (err) {
      const message = !err.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');
      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
    setLoading(false);
  };
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={onClose}
      className={clsx(className, styles.wrapper)}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{t('duplicates:Notifications_title')}</h3>
          {!!notifications.length && (
            <button onClick={handleOpenDialog} className={styles.clearAll}>
              {t('common:clearAll')}
            </button>
          )}
        </div>
        {notifications.length ? (
          loading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner size={60} />
            </div>
          ) : (
            notifications.map((notification) => (
              <Row onClose={onClose} notification={notification} key={notification.id} />
            ))
          )
        ) : (
          <EmptyNotifications />
        )}
      </div>
      <DeleteDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onSuccess={handleClearAll}
        title={t('notifications:clearDialogTitle')}
        description={t('notifications:clearDialogDescription')}
        deleteButtonText={t('duplicates:Yes_label')}
      />
    </Popover>
  );
};
export default Notifications;
