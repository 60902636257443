import { useState } from 'react';
import { catchError, throwError, firstValueFrom } from 'rxjs';

import { useInjection } from 'src/app/ioc/ioc.react';
import { useSubscription, useObservable, useBehaviorSubject } from 'src/app/hooks/rx';

import { IBillingUseCases } from '../domain/abstractions/IBillingUseCases';
import BillingDetailsEntity from '../domain/entities/BillingDetailsEntity';

import { TYPES } from '../di/types';

export const useCurrentSubscription = () => {
  const useCases = useInjection<IBillingUseCases>(TYPES.BillingUseCases);
  const [subscription] = useBehaviorSubject(useCases.getSubscription());

  const purchaseSubscription = (workspace, plan, promoCodeId, isRenewProcess) => {
    return firstValueFrom(
      useCases.purchaseSubscription(workspace, plan, promoCodeId, isRenewProcess)
    );
  };

  const cancelSubscription = () => {
    return firstValueFrom(useCases.cancelSubscription(subscription.workspaceId));
  };

  return {
    subscription,
    purchaseSubscription,
    cancelSubscription,
  };
};

export const usePaymentProvider = () => {
  const useCases = useInjection<IBillingUseCases>(TYPES.BillingUseCases);
  const [pfToken, setPFToken] = useState<string>(null);
  const [fetchFailed, setFetchFailed] = useState<boolean>(false);

  const pfTokenObservable = useObservable(
    () =>
      useCases.getPFToken().pipe(
        catchError((err) => {
          setFetchFailed(true);

          return throwError(err);
        })
      ),
    []
  );

  useSubscription(pfTokenObservable, (token) => {
    setPFToken(token);
  });

  const bindPFToken = async (token, last4Digits) => {
    return firstValueFrom(useCases.bindPFTokenToUser(token, last4Digits));
  };

  const updatePFToken = async () => {
    useCases.updatePFToken();
  };

  return {
    pfToken,
    fetchFailed,
    bindPFToken,
    updatePFToken,
  };
};

export type BillingDetailsData = {
  billingDetails: BillingDetailsEntity;
  updateBillingDetails: (billingDetails: BillingDetailsEntity, cb?: () => void) => void;
  isProcessing: boolean;
  error?: any; // TODO: define errors and replace any
};

export const useBillingDetails = (): BillingDetailsData => {
  const useCases = useInjection<IBillingUseCases>(TYPES.BillingUseCases);
  const [billingDetails] = useBehaviorSubject(useCases.getBillingDetails());

  // update billing details
  const [isProcessing, setIsProcessing] = useState(false);
  const [error /*, setError */] = useState(null);

  const updateBillingDetails = (billingDetails) => {
    setIsProcessing(true);
    return firstValueFrom(useCases.updateBillingDetails(billingDetails)).finally(() => {
      setIsProcessing(false);
    });
  };

  return {
    billingDetails,
    updateBillingDetails,
    isProcessing,
    error,
  };
};
