export const CRM_MAIN_URL = 'https://leader.net';
export const CRM_APP_URL = 'https://app.leader.net/';
export const TERMS_OF_SERVICE_URL = 'https://leader.net/terms-of-service/';
export const PRIVACY_LINK_URL = 'https://leader.net/privacy-policy/';

// external urls
// TODO: move other here from src/enums/ExternalLinks.ts (it shouldn't be enum, just plain constants)
export const CONTACT_SUPPORT_EXTERNAL_URL = 'https://leader.zendesk.com/hc/en-us/requests/new';
export const ANDROID_APP_LINK = process.env.NEXT_PUBLIC_APP_ANDROID_APP_LINK_TO_DOWNLOAD;
export const IOS_APP_LINK = process.env.NEXT_PUBLIC_APP_IOS_APP_LINK_TO_DOWNLOAD;
