import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';
import { useAppStore, useAppDispatch } from '../../../app/hooks';
import { selectProposalSettings, updateSettings } from '../../../slices/proposalsSlice';
import { selectCurrentWorkspaceId } from '../../../slices/workspacesSlice';

import DollarSymbol from '../../../../public/images/icons/dollar-symbol.svg';

import { MessageType } from '../../../components/UI/snackbar/providers/SnackBarProvider';
import { FormValues as SettingsFormValues } from './subForms/SettingsSubForm/useSettingsSubForm';
import ProposalSettingsEntity, {
  CustomersDetailsNames,
} from 'src/db/entities/proposalSettings/ProposalSettingsEntity';

export default function useSaveSettings() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { enqueueSnackbar } = useSnackbar();

  return async (settingsValues: SettingsFormValues, onUpdate: () => void | undefined) => {
    const prevSettings = selectProposalSettings(store.getState());
    const currentWorkspaceId = selectCurrentWorkspaceId(store.getState());

    const data: ProposalSettingsEntity = {
      uuid: prevSettings.uuid,
      businessDetails: {
        uuid: prevSettings.businessDetails.uuid,
        address: settingsValues.address,
        description: settingsValues.description,
        email: settingsValues.email,
        fax: settingsValues.fax,
        imageUrl: settingsValues.logoUrl,
        moreDetails: settingsValues.moreDetails,
        name: settingsValues.businessName,
        phone: settingsValues.phoneNumber,
        website: settingsValues.website,
      },
      customerDetails: [
        {
          name: CustomersDetailsNames.EMAIL,
          enabled: settingsValues.customerDetails.includes('email'),
        },
        {
          name: CustomersDetailsNames.FAX,
          enabled: settingsValues.customerDetails.includes('fax'),
        },
        {
          name: CustomersDetailsNames.ADDRESS,
          enabled: settingsValues.customerDetails.includes('address'),
        },
        {
          name: CustomersDetailsNames.PHONE,
          enabled: settingsValues.customerDetails.includes('phone'),
        },
      ],
      currency: settingsValues.currency,
      enteringPriceIncludesTax: prevSettings.enteringPriceIncludesTax,
      footerText: settingsValues.footerText,
      includeTotalPrice: settingsValues.includeTotalPrice,
      startPrice: settingsValues.startNumber,
      taxRate: settingsValues.taxRate,
      validity: settingsValues.validity,
      includesTax: prevSettings.includesTax,
      lang: window.navigator.language,
      workspaceId: currentWorkspaceId,
      updatedAt: Date.now(),
      createdAt: prevSettings.createdAt,
    };

    try {
      await dispatch(updateSettings(data)).unwrap();

      enqueueSnackbar({
        message: t('proposal-settings:form.notification.updated'),
        variant: MessageType.Success,
        icon: <DollarSymbol />,
      });

      if (typeof onUpdate === 'function') {
        onUpdate();
      }
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
  };
}
