import { injectable } from 'inversify';

import FilesUploadingError from '../domain/enum/FilesUploadingError';
import FilesUploadingErrorActions from '../domain/enum/FilesUploadingErrorActions';
import { IFileUploadingErrorHandlingRepository } from '../domain/abstractions/IFileUploadErrorHandlingRepository';

export type TFilesErrorData = {
  type: FilesUploadingError;
  message?: string;
  action?: FilesUploadingErrorActions;
  canRetry?: boolean;
};

@injectable()
export default class FileUploadErrorHandlingRepository
  implements IFileUploadingErrorHandlingRepository
{
  getHandleData: IFileUploadingErrorHandlingRepository['getHandleData'] = (error) => {
    switch (error) {
      case FilesUploadingError.STORAGE_LIMIT_EXCEEDED:
        return {
          type: FilesUploadingError.STORAGE_LIMIT_EXCEEDED,
          action: FilesUploadingErrorActions.STORAGE_LIMITATION_REACHED_DIALOG,
          canRetry: false,
        };

      case FilesUploadingError.ENTITY_TOO_LARGE:
        return {
          type: FilesUploadingError.ENTITY_TOO_LARGE,
          action: FilesUploadingErrorActions.FILE_SIZE_NOT_SUPPORTED_DIALOG_LARGE,
          canRetry: false,
        };

      case FilesUploadingError.ENTITY_TOO_SMALL:
        return {
          type: FilesUploadingError.ENTITY_TOO_SMALL,
          action: FilesUploadingErrorActions.FILE_SIZE_NOT_SUPPORTED_DIALOG_SMALL,
          canRetry: false,
        };

      case FilesUploadingError.COMMON:
        return {
          type: FilesUploadingError.COMMON,
          canRetry: false,
          message: 'files:error_message.upload_failed',
        };

      default:
        return {
          type: FilesUploadingError.COMMON,
          canRetry: true,
          message: 'files:error_message.upload_failed',
        };
    }
  };
}
