import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';
import { v4 as uuidv4 } from 'uuid';

import { createProposal } from '../../../../slices/proposalsSlice';
import { FormValues } from '../subForms/MainSubForm/useMainSubForm';

import { useAppStore } from '../../../../app/hooks';
import { selectProposalSettings } from '../../../../slices/proposalsSlice';
import { selectBoardContactsMap, actions as boardActions } from '../../../../slices/boardSlice';

import { selectContactsMap, patchContactOptimistic } from '../../../../slices/contactsSlice';
import {
  selectCurrentWorkspaceId,
  selectCurrentUserMemberData,
} from '../../../../slices/workspacesSlice';

import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';
import { useAppDispatch } from '../../../../app/hooks';
import DollarSymbol from '../../../../../public/images/icons/dollar-symbol.svg';
import useGetRelatedContactId from '../useGetRelatedContactId';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

export default function useCreateProposal() {
  const { t } = useTranslation('proposal-form');
  const store = useAppStore();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const getRelatedContactId = useGetRelatedContactId();

  const create = async (
    proposalValues: FormValues,
    onSuccess?: (data?: ProposalEntity) => void | Promise<void>
  ) => {
    try {
      const state = store.getState();

      const relatedContactId = await getRelatedContactId(proposalValues.contactId);

      const contact = selectContactsMap(state)[relatedContactId];

      if (contact) {
        const boardItem = selectBoardContactsMap(state)[relatedContactId];

        if (boardItem) {
          dispatch(
            boardActions.deleteItems({
              columnUuid: boardItem.columnUuid,
              itemsUuids: [boardItem.uuid],
            })
          );
        }
      }

      if (contact?.is_archived) {
        dispatch(
          patchContactOptimistic({
            contactId: contact.uuid,
            data: { is_archived: false, updated_at: Math.floor(Date.now() / 1000) },
          })
        ).unwrap();
      }

      const settings = selectProposalSettings(state);
      const { email } = selectCurrentUserMemberData(state);

      const data: ProposalEntity = {
        uuid: uuidv4(),
        number: 0,
        contactId: relatedContactId,
        client: proposalValues.client,
        title: proposalValues.title,
        items: proposalValues.items,
        description: proposalValues.comment,
        discountAmount: proposalValues.discountAmount,
        discountType: proposalValues.discountType,
        isDraft: true,
        visible: true,
        sentAt: null,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        workspaceId: selectCurrentWorkspaceId(store.getState()),
        createdBy: email,
        updatedBy: email,
        settings: {
          ...settings,
          currency: proposalValues.currency,
          includesTax: proposalValues.includesTax,
        },
      };

      await dispatch(createProposal(data)).unwrap();

      enqueueSnackbar({
        message: t('form.notification.saved'),
        variant: MessageType.Success,
        icon: <DollarSymbol />,
      });

      if (onSuccess) {
        await onSuccess(data);
      }
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
  };

  return create;
}
