import { useTranslation } from 'react-i18next';
import ResponseColumnsDC from 'src/features2/importCSV/domain/entities/ResponseDC';
import SelectFields from 'src/features2/importCSV/domain/enums/SelectFields';
import BaseInput from 'components/UI/Inputs/InputBase';
import Select from 'components/UI/Inputs/Select';
import ArrowIcon from 'icons/arrow.svg';
import ArrowDownIcon from 'icons/arrow-down.svg';
import styles from './styles.module.scss';
import { useState, useEffect } from 'react';
import { useInjection } from 'src/app/ioc/ioc.react';
import { ICustomFieldUseCase } from 'src/features2/common/customField/domain/abstractions/ICustomFieldUseCase';
import { CUSTOM_FIELDS_TYPES } from 'src/features2/common/customField/di/types';
import { useObservable, useObservableState } from 'src/app/hooks/rx';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
const ParserRow = ({
  rowData,
  onChange,
  index,
  error,
}: {
  rowData: ResponseColumnsDC;
  onChange: (index, value) => void;
  index: number;
  error: boolean;
}) => {
  const { t } = useTranslation('import-csv');
  const [parsedRowData, setParsedRowData] = useState<ResponseColumnsDC>();
  const customFieldUseCase = useInjection<ICustomFieldUseCase>(
    CUSTOM_FIELDS_TYPES.CustomFieldUseCase
  );
  const customFields = useObservableState<CustomFieldEntity[]>(
    useObservable(() => customFieldUseCase.getAll(), []),
    []
  );
  const customFieldsNames = customFields.map((field) => ({
    value: field.name,
    text: field.name,
    isCustomField: true,
  }));

  const SelectOptions = [
    ...SelectFields.reduce((options, key, index) => {
      options[index] = { value: key, text: t(`SelectFields.${key}`) };
      return options;
    }, []),
    ...customFieldsNames,
  ];

  useEffect(() => {
    setParsedRowData(rowData);
  }, [rowData]);

  if (!parsedRowData) {
    return;
  }
  return (
    <div className={styles.rowWrapper}>
      <span className={styles.rowTitle}>{parsedRowData.name}</span>
      <div className={styles.rowContainer}>
        <BaseInput disabled value={parsedRowData.example} className={styles.exampleValue} />
        <ArrowIcon />
        <Select
          menuItemClassName={styles.selectItem}
          className={styles.select}
          classes={{
            selectMenu: styles.selectMenu,
          }}
          menuProps={{
            classes: {
              paper: styles.selectMenuList,
            },
          }}
          renderVal={
            parsedRowData.attribute ? (
              customFieldsNames.find((el) => el.text === parsedRowData.attribute) ? (
                parsedRowData.attribute
              ) : (
                t(`SelectFields.${parsedRowData.attribute}`)
              )
            ) : (
              <span className={styles.placeholder}>{t('SelectFields.placeholder')}</span>
            )
          }
          options={SelectOptions}
          value={parsedRowData.attribute || 'none'}
          onChange={(e) => onChange(index, e.target.value)}
          IconComponent={() => (
            <div className={styles.selectArrow}>
              <ArrowDownIcon />
            </div>
          )}
        />
        {error && (
          <span className={styles.errorLabel}>{t('ErrorCases.WrongColumnType.label')}</span>
        )}
      </div>
    </div>
  );
};
export default ParserRow;
