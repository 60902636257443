import appRoutes from '../../../../utils/constants/appRoutes';

import CallIcon from 'icons/sidebar/calls-icon.svg';
import ContactsIcon from 'icons/sidebar/contacts-icon.svg';
import TasksIcon from 'icons/sidebar/tasks-icon.svg';
import SettingsIcon from 'icons/sidebar/settings-icon.svg';
import PlansIcon from 'icons/sidebar/plans-icon.svg';

enum SidebarButtonType {
  CALLS,
  CONTACTS,
  TASKS,
  SETTINGS,
  PLANS,
}

const sidebarData = [
  {
    icon: <CallIcon />,
    title: 'common:sidebar.calls_button',
    tooltipTitle: 'common:tooltips.sidebar_calls',
    link: appRoutes.CALLS_PAGE,
    type: SidebarButtonType.CALLS,
  },
  {
    icon: <ContactsIcon /*className={styles.contactIcon}*/ />,
    title: 'duplicates:Contacts_title',
    tooltipTitle: 'duplicates:Contacts_title',
    link: appRoutes.CONTACTS_PAGE,
    type: SidebarButtonType.CONTACTS,
  },
  {
    icon: <TasksIcon />,
    title: 'duplicates:Tasks_title',
    tooltipTitle: 'duplicates:Tasks_title',
    link: appRoutes.TASKS_PAGE,
    type: SidebarButtonType.TASKS,
  },
  {
    icon: <SettingsIcon />,
    title: 'duplicates:Settings_title',
    tooltipTitle: 'duplicates:Settings_title',
    link: appRoutes.SETTINGS_PAGE,
    type: SidebarButtonType.SETTINGS,
  },
  {
    icon: <PlansIcon />,
    title: 'common:sidebar.plans_button',
    tooltipTitle: 'common:tooltips.sidebar_plans',
    link: appRoutes.PLANS_PAGE,
    type: SidebarButtonType.PLANS,
  },
];

export { SidebarButtonType, sidebarData };
