import React from 'react';

import PreviewArea from '../shared/PreviewArea';
import styles from './styles.module.scss';
import { IFilePreviewUnitProps } from '../../index';
import CircularProgress from '@material-ui/core/CircularProgress';
import useFilePreviewTextViewModel from './useFilePreviewTextViewModel';

const FilePreviewText: React.FC<IFilePreviewUnitProps> = ({ file }) => {
  const { onScroll, isLoading, chunks } = useFilePreviewTextViewModel(file);

  return (
    <PreviewArea noLoader>
      <div className={styles.TextWrapper} onScroll={onScroll}>
        {isLoading ? (
          <CircularProgress size={15} />
        ) : (
          <div className={styles.Text}>
            {chunks.map(({ id, content }) => (
              <span key={id}>{content}</span>
            ))}
          </div>
        )}
      </div>
    </PreviewArea>
  );
};

export default FilePreviewText;
