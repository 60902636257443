import MUICircularProgress, {
  CircularProgressProps as MUICircularProgressProps,
} from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import styles from '../../styles/components/Spinner.module.css';

export default function Spinner(props: MUICircularProgressProps) {
  const { className, ...restProps } = props;

  return <MUICircularProgress {...restProps} className={clsx(styles.Spinner, className)} />;
}
