import { inject, injectable } from 'inversify';
import { map } from 'rxjs';

import FileBC from '../domain/FileBC';
import { TYPES } from '../di/types';
import { downloadFileByLink } from '../../../utils/files';
import { mapFileBCtoFileDC } from '../../../db/entities/file/mapper';
import { IFileInteractionRepository } from '../domain/abstractions/IFileInteractionRepository';
import { IFilesApiService } from '../domain/abstractions/IFilesApiService';

const MAX_THUMBNAIL_LENGTH = 65535;

@injectable()
export default class FileInteractionRepository implements IFileInteractionRepository {
  @inject(TYPES.FilesApiService) private api: IFilesApiService;

  deleteFile: IFileInteractionRepository['deleteFile'] = (fileId) => {
    return this.api.deleteFile(fileId);
  };

  downloadFile: IFileInteractionRepository['downloadFile'] = (file) => {
    this.api
      .getDownloadLink(file.uuid)
      .pipe(map((response) => response))
      .subscribe((response) => {
        downloadFileByLink(file.name, response[0].file_presigned_link);
      });
  };

  renameFile: IFileInteractionRepository['renameFile'] = (newFileName, updatedBy, file) => {
    const { thumbnail = '', ...restFile } = file;
    const updObj = {
      ...restFile,
      name: newFileName,
      updatedAt: new Date().toISOString(),
      updatedBy,
    } as FileBC;

    // TODO: RS: Refactor after thumbnail S3 upload support
    if (thumbnail && thumbnail?.length < MAX_THUMBNAIL_LENGTH) {
      updObj.thumbnail = file.thumbnail;
    }

    const updatedFile = [new FileBC(updObj)].map(mapFileBCtoFileDC)[0];

    return this.api.updateFile(updatedFile);
  };
}
