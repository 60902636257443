import { injectable } from 'inversify';
import { from, map } from 'rxjs';

import axios from '../../../../app/axios';
import WorkspaceDC from '../../../../db/entities/workspace/WorkspaceDC';
import { mapWorkspaceDCtoEntity } from '../../../../db/entities/workspace/mapper';
import { IWorkspacesApiService } from '../../domain/abstractions/IWorkspacesApiService';

const routes = {
  base: '/api/v1/workspaces',
  members: '/api/v1/workspaces/{workspace_uuid}/members',
};

@injectable()
export default class WorkspacesApiService implements IWorkspacesApiService {
  get = () => {
    const request = axios.get<WorkspaceDC[]>(routes.base);

    return from(request).pipe(map((response) => response.data));
  };

  deleteMembers = (data, workspaceId) => {
    const request = axios.delete(routes.members.replace('{workspace_uuid}', workspaceId), {
      data,
    });

    return from(request).pipe(map((response) => response.data));
  };

  patchWorkspace = (data) => {
    const request = axios.patch(routes.base, data);

    return from(request).pipe(map((response) => response.data));
  };
  patch = (data) => {
    const response = axios.patch<WorkspaceDC>(routes.base, data);
    return from(response).pipe(map((response) => mapWorkspaceDCtoEntity(response.data)));
  };
}
