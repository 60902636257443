import { dbVersion } from '../../constants';

export default {
  title: 'profile schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'id',
  type: 'object',
  required: [
    'id',
    'email',
    'fullName',
    'blocked',
    'createdAt',
    'updatedAt',
    'picture',
    'hasFeed',
    'hasInvitedTeammates',
    'settings',
    'onboardingInfo',
    'platforms',
    'personalWorkspaceId',
  ],
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    email: {
      type: 'string',
    },
    fullName: {
      type: 'string',
    },
    blocked: {
      type: 'boolean',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    picture: {
      type: ['string', 'null'],
    },
    hasFeed: {
      type: 'boolean',
    },
    hasInvitedTeammates: {
      type: 'boolean',
    },
    settings: {
      type: 'object',
      required: [
        'incomingCallAvailable',
        'afterCallAvailable',
        'assistantAvailable',
        'businessCardAvailable',
        'lang',
        'country',
        'assistantAvailableCategory',
        'assistantDisableCaller',
        'afterFirstCallAnsweredEnabled',
        'afterFirstCallAnsweredLinkOption',
        'afterFirstCallMissedEnabled',
        'afterFirstCallMissedMessageText',
        'afterFirstCallMissedLinkOption',
        'afterMissedCallMessage',
        'afterfirstCallMessage',
        'lastProposalNumber',
        'accountForExport',
      ],
      properties: {
        incomingCallAvailable: {
          type: ['boolean', 'null'],
        },
        afterCallAvailable: {
          type: ['boolean', 'null'],
        },
        assistantAvailable: {
          type: ['boolean', 'null'],
        },
        businessCardAvailable: {
          type: ['boolean', 'null'],
        },
        lang: {
          type: ['string', 'null'],
        },
        country: {
          type: ['string', 'null'],
        },
        assistantAvailableCategory: {
          type: ['string', 'null'],
        },
        assistantDisableCaller: {
          type: ['array', 'null'],
        },
        afterFirstCallAnsweredEnabled: {
          type: ['boolean', 'null'],
        },
        afterFirstCallAnsweredLinkOption: {
          type: ['boolean', 'null'],
        },
        afterFirstCallMissedEnabled: {
          type: ['boolean', 'null'],
        },
        afterFirstCallMissedMessageText: {
          type: ['string', 'null'],
        },
        afterFirstCallMissedLinkOption: {
          type: ['string', 'null'],
        },
        afterMissedCallMessage: {
          type: ['string', 'null'],
        },
        afterFirstCallMessage: {
          type: ['string', 'null'],
        },
        lastProposalNumber: {
          type: ['number', 'null'],
        },
        accountForExport: {
          type: ['string', 'null'],
        },
      },
    },
    onboardingInfo: {
      type: ['object', 'null'],
      required: [
        'companyName',
        'industry',
        'stuffCount',
        'country',
        'jobField',
        'jobRole',
        'lang',
        'userExperience',
        'skipInviteTeammates',
      ],
      properties: {
        companyName: {
          type: 'string',
        },
        industry: {
          type: 'string',
        },
        stuffCount: {
          type: 'string',
        },
        country: {
          type: 'string',
        },
        jobField: {
          type: 'string',
        },
        jobRole: {
          type: 'string',
        },
        lang: {
          type: 'string',
        },
        userExperience: {
          type: 'string',
        },
        skipInviteTeammates: {
          type: ['boolean', 'null'],
        },
      },
    },
    platforms: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    personalWorkspaceId: {
      type: ['string', 'null'],
    },
  },
};
