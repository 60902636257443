import { useMemo } from 'react';
import { selectUserOnboardingInfo } from 'slices/userSlice';
import { formatPhoneNumber } from 'src/utils/PhoneHelper';
import { useAppSelector } from '../useAppSelector';

type TUseFormatNumber = () => {
  formatNumber: (phoneNumber: string, normalizedPhoneNumber: string) => string;
};

const useFormatNumber: TUseFormatNumber = () => {
  const userOnboardingInfo = useAppSelector(selectUserOnboardingInfo);
  const formatNumber = useMemo(
    () => (phoneNumber, normalizedPhoneNumber) =>
      formatPhoneNumber({
        phoneNumber,
        normalizedPhoneNumber,
        userRegion: userOnboardingInfo?.country,
      }),
    [userOnboardingInfo?.country]
  );
  return {
    formatNumber,
  };
};

export default useFormatNumber;
