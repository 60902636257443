import ResponseColumnDTO from './ResponseDTO';

export default class ResponseColumnDC {
  attribute: string;
  example: string;
  index: number;
  name: string;

  constructor(responseColumnDTO: ResponseColumnDTO) {
    this.name = responseColumnDTO.name;
    this.attribute = responseColumnDTO.contact_attr;
    this.index = responseColumnDTO.index;
    this.example = responseColumnDTO.example_value;
  }
}
