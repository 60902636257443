import { Observable, from, map, catchError, of } from 'rxjs';
import ICustomFieldApiService from '../../domain/abstractions/ICustomFieldApiService';
import { injectable, inject } from 'inversify';
import axios from '../../../../../app/axios';
import { TYPES } from 'src/app/ioc/types';
import type { IPersistentRepository } from '../../../../../services/PersistentRepository';
import RequestHeaders from 'src/enums/RequestHeaders';
import { AxiosError } from 'axios';
import CustomFieldDC from 'src/db/entities/customField/CustomFieldDC';

const routes = {
  CUSTOM_FIELDS: '/api/v1/custom-fields',
};

@injectable()
export default class CustomFieldApiService implements ICustomFieldApiService {
  @inject(TYPES.PersistentRepository) private localStorageRepository: IPersistentRepository;

  createField(data: { uuid: string; name: string }): Observable<string> {
    const request = axios.post(
      routes.CUSTOM_FIELDS,
      { field_type: 'text', ...data },
      {
        headers: {
          [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
        },
      }
    );

    return from(request).pipe(
      map((response) => response.data),
      catchError((error: AxiosError) => {
        return of(error.response.status);
      })
    );
  }

  deleteField(uuid: string): Observable<boolean> {
    const request = axios.delete(routes.CUSTOM_FIELDS, {
      data: uuid,
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(
      map((response) => response.data),
      catchError((error: AxiosError) => {
        return of(error.response.status);
      })
    );
  }

  fetchCustomFields(): Observable<CustomFieldDC[]> {
    const request = axios.get(routes.CUSTOM_FIELDS, {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(map((response) => response.data));
  }
}
