import { Subscription, skip, debounceTime } from 'rxjs';
import groupBy from 'lodash/groupBy';
import { batch } from 'react-redux';

import { actions as workspacesActions } from '../../slices/workspacesSlice';
import { setTasks } from 'slices/tasksSlice/tasksSlice';
import { setUserProfile } from 'slices/userSlice';
import { actions as callsActions } from '../../slices/callsSlice';
import { setNotes } from 'slices/notesSlice';
import { actions as proposalActions } from '../../slices/proposalsSlice';
import { setNotifications } from 'slices/notificationSlice';
import { setContacts } from 'slices/contactsSlice';
import { setColumns } from 'slices/boardSlice';
import { setTags } from 'slices/tagsBoardSlice';

import Contact from '../../../types/contact';

import { NotificationModel } from '../../api/notificationsAPI';
import { ColumnModel } from '../../api/boardAPI';

import { TagBoardColumn } from '../../api/tagsBoardAPI';

import { Database } from '../../db/dbManager';
import { mapContactEntityToDC } from '../../db/entities/contact/mapper';

import sortLinkedList from '../../utils/sortLinkedList';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';

import { AppStore } from './store';

export class RxDbConnector {
  private db: Database;
  private store: AppStore;
  private workspacesRepository: IWorkspacesStateRepository;
  private unsubscribe: () => void | null;

  constructor(store: AppStore, db: Database, workspacesRepository: IWorkspacesStateRepository) {
    this.store = store;
    this.db = db;
    this.workspacesRepository = workspacesRepository;
  }

  connect = async () => {
    await this.prefillStore();
    this.unsubscribe = this.subscribeForUpdates();

    this.workspacesRepository.currentWorkspaceId.pipe(skip(1)).subscribe(() => {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      this.unsubscribe = this.subscribeForUpdates();
    });
  };

  prefillStore = async () => {
    const workspaceId = this.workspacesRepository.currentWorkspaceId.value;

    const workspaceIsChosen = !!workspaceId;

    const [profileDocument] = await this.db.profile.find().exec();

    const workspaceDocuments = await this.db.workspaces.find().exec();
    const workspaces = workspaceDocuments.map((document) => document.toMutableJSON());

    if (workspaceId) {
      const workspace = workspaces.find(({ id }) => id === workspaceId);
      if (workspace) {
        this.store.dispatch(workspacesActions.chooseWorkspace(workspaceId));
      }
    }

    this.workspacesRepository.currentWorkspaceId.subscribe((value) => {
      this.store.dispatch(workspacesActions.chooseWorkspace(value || null));
    });

    const callDocuments = await this.db.calls.find().exec();
    const calls = callDocuments.map((document) => document.toMutableJSON());

    const tasksSelector = workspaceIsChosen ? { workspaceId: { $eq: workspaceId } } : {};
    const tasksDocuments = await this.db.tasks.find({ selector: tasksSelector }).exec();
    const tasks = tasksDocuments.map((document) => document.toMutableJSON());

    const notesSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const noteDocuments = await this.db.notes.find({ selector: notesSelector }).exec();
    const notes = noteDocuments.map((document) => document.toMutableJSON());

    const proposalsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const proposalDocuments = await this.db.proposals.find({ selector: proposalsSelector }).exec();
    const proposals = proposalDocuments.map((document) => document.toMutableJSON());

    const proposalSettingsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const proposalSettingsDocuments = await this.db['proposal-settings']
      .find({ selector: proposalSettingsSelector })
      .exec();
    const [proposalSettings] = proposalSettingsDocuments.map((document) =>
      document.toMutableJSON()
    );

    const notificationsSelector = workspaceId
      ? {
          $or: [
            { 'data.workspaceId': { $eq: workspaceId } },
            { 'data.workspaceId': { $eq: null } },
          ],
        }
      : {};
    const notificationDocuments = await this.db.notifications
      .find({ selector: notificationsSelector })
      .exec();

    const notifications = notificationDocuments.map((document) =>
      document.toMutableJSON()
    ) as NotificationModel[];

    const contactsSelector = workspaceId
      ? { $or: [{ workspaceId: { $eq: workspaceId } }, { workspaceId: { $eq: null } }] }
      : {};
    const contactDocuments = await this.db.contacts.find({ selector: contactsSelector }).exec();
    const contacts = contactDocuments.map((document) =>
      mapContactEntityToDC(document.toMutableJSON())
    ) as Contact[];

    const boardColumnsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const boardColumnDocuments = await this.db['board-columns']
      .find({ selector: boardColumnsSelector })
      .exec();

    const boardColumns = boardColumnDocuments.map((document) => document.toMutableJSON());

    const boardItemsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const boardItemDocuments = await this.db['board-items']
      .find({ selector: boardItemsSelector })
      .exec();
    const boardItems = boardItemDocuments.map((document) => document.toMutableJSON());

    const tagsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const tagDocuments = await this.db.tags.find({ selector: tagsSelector }).exec();
    const tags = tagDocuments.map((document) => document.toMutableJSON());

    const tagLinksSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
    const tagLinkDocuments = await this.db['tag-links'].find({ selector: tagLinksSelector }).exec();
    const tagLinks = tagLinkDocuments.map((document) => document.toMutableJSON());

    batch(() => {
      if (profileDocument) {
        this.store.dispatch(setUserProfile(profileDocument.toMutableJSON()));
      }
      this.store.dispatch(workspacesActions.setWorkspaces(workspaces));
      this.store.dispatch(callsActions.setCalls(calls));
      this.store.dispatch(setTasks(tasks));
      this.store.dispatch(setNotes(notes));
      this.store.dispatch(proposalActions.setProposals(proposals));
      this.store.dispatch(proposalActions.setSettings(proposalSettings));

      this.store.dispatch(setNotifications(notifications));

      this.store.dispatch(setContacts(contacts));

      if (boardColumns.length > 0) {
        const board = prepareBoard(boardColumns, boardItems).map((col) => ({
          ...col,
          items: col.items.map((item) => ({ uuid: item.id, columnUuid: item.colId, ...item })),
        })) as ColumnModel[];

        this.store.dispatch(setColumns(board));
      }

      if (tags.length > 0) {
        const tagBoard = prepareBoard(tags, tagLinks).map((tag) => {
          return {
            ...tag,
            items: tag.items.map((item) => ({ tagId: item.colId, ...item })),
          };
        }) as TagBoardColumn[];

        this.store.dispatch(setTags(tagBoard));
      }
    });
  };

  subscribeForUpdates = () => {
    const subscriptions: Subscription[] = [];

    const workspaceId = this.workspacesRepository.currentWorkspaceId.value;
    const workspaceIsChosen = !!workspaceId;

    const profileSubscription = this.db.profile.find().$.subscribe((documents) => {
      const [profileDocument] = documents;

      if (profileDocument) {
        this.store.dispatch(setUserProfile(profileDocument.toMutableJSON()));
      }
    });
    subscriptions.push(profileSubscription);

    const workspacesSubscription = this.db.workspaces.find().$.subscribe((documents) => {
      this.store.dispatch(
        workspacesActions.setWorkspaces(documents.map((doc) => doc.toMutableJSON()))
      );
    });
    subscriptions.push(workspacesSubscription);

    const contactsSelector = workspaceId
      ? { $or: [{ workspaceId: { $eq: workspaceId } }, { workspaceId: { $eq: null } }] }
      : {};
    const contactSubscription = this.db.contacts
      .find({ selector: contactsSelector })
      .$.subscribe((documents) => {
        const contacts = documents.map((document) =>
          mapContactEntityToDC(document.toMutableJSON())
        ) as Contact[];

        this.store.dispatch(setContacts(contacts));
      });
    subscriptions.push(contactSubscription);

    const callsSubscription = this.db.calls.find().$.subscribe((documents) => {
      const calls = documents.map((document) => document.toMutableJSON());
      this.store.dispatch(callsActions.setCalls(calls));
    });
    subscriptions.push(callsSubscription);

    if (workspaceIsChosen) {
      const tasksSelector = { workspaceId: { $eq: workspaceId } };
      const tasksSubscription = this.db.tasks
        .find({ selector: tasksSelector })
        .$.subscribe((documents) => {
          this.store.dispatch(setTasks(documents.map((doc) => doc.toMutableJSON())));
        });
      subscriptions.push(tasksSubscription);

      const notesSelector = { workspaceId: { $eq: workspaceId } };
      const notesSubscription = this.db.notes
        .find({ selector: notesSelector })
        .$.subscribe((documents) => {
          const notes = documents.map((document) => document.toMutableJSON());
          this.store.dispatch(setNotes(notes));
        });
      subscriptions.push(notesSubscription);

      const proposalsSubscription = this.db.proposals
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.subscribe((documents) => {
          const proposals = documents.map((document) => document.toMutableJSON());
          this.store.dispatch(proposalActions.setProposals(proposals));
        });
      subscriptions.push(proposalsSubscription);

      const proposalSettingsSubscription = this.db['proposal-settings']
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.subscribe((documents) => {
          const [proposalSettings] = documents.map((document) => document.toMutableJSON());

          this.store.dispatch(proposalActions.setSettings(proposalSettings));
        });
      subscriptions.push(proposalSettingsSubscription);

      const notificationSubscription = this.db.notifications
        .find({
          selector: {
            $or: [
              { 'data.workspaceId': { $eq: workspaceId } },
              { 'data.workspaceId': { $eq: null } },
            ],
          },
        })
        .$.subscribe((documents) => {
          const notifications = documents.map((document) =>
            document.toMutableJSON()
          ) as NotificationModel[];

          this.store.dispatch(setNotifications(notifications));
        });
      subscriptions.push(notificationSubscription);

      const boardColumnsSubscription = this.db['board-columns']
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.pipe(debounceTime(500))
        .subscribe(async (documents) => {
          const boardColumns = documents.map((document) => document.toMutableJSON());

          const boardItemsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
          const boardItemDocuments = await this.db['board-items']
            .find({ selector: boardItemsSelector })
            .exec();
          const boardItems = boardItemDocuments.map((document) => document.toMutableJSON());

          if (boardColumns.length > 0) {
            const board = prepareBoard(boardColumns, boardItems).map((col) => ({
              ...col,
              items: col.items.map((item) => ({
                uuid: item.id,
                columnUuid: item.colId,
                ...item,
              })),
            })) as ColumnModel[];
            this.store.dispatch(setColumns(board));
          }
        });
      subscriptions.push(boardColumnsSubscription);

      const boardItemsSubscription = this.db['board-items']
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.subscribe(async (documents) => {
          const boardItems = documents.map((document) => document.toMutableJSON());

          const boardColumnsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
          const boardColumnDocuments = await this.db['board-columns']
            .find({ selector: boardColumnsSelector })
            .exec();
          const boardColumns = boardColumnDocuments.map((document) => document.toMutableJSON());

          if (boardColumns.length > 0) {
            const board = prepareBoard(boardColumns, boardItems).map((col) => ({
              ...col,
              items: col.items.map((item) => ({ uuid: item.id, columnUuid: item.colId, ...item })),
            })) as ColumnModel[];
            this.store.dispatch(setColumns(board));
          }
        });
      subscriptions.push(boardItemsSubscription);

      const tagsSubscription = this.db.tags
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.pipe(debounceTime(500))
        .subscribe(async (documents) => {
          const tags = documents.map((document) => document.toMutableJSON());

          const tagLinkDocuments = await this.db['tag-links']
            .find({ selector: { workspaceId: { $eq: workspaceId } } })
            .exec();
          const tagLinks = tagLinkDocuments.map((document) => document.toMutableJSON());

          if (tags.length > 0) {
            const tagBoard = prepareBoard(tags, tagLinks).map((tag) => {
              return {
                ...tag,
                items: tag.items.map((item) => ({ tagId: item.colId, ...item })),
              };
            }) as TagBoardColumn[];

            this.store.dispatch(setTags(tagBoard));
          }
        });
      subscriptions.push(tagsSubscription);

      const tagLinksSubscription = this.db['tag-links']
        .find({ selector: { workspaceId: { $eq: workspaceId } } })
        .$.pipe(debounceTime(500))
        .subscribe(async (documents) => {
          const tagLinks = documents.map((document) => document.toMutableJSON());
          const tagsSelector = workspaceId ? { workspaceId: { $eq: workspaceId } } : {};
          const tagDocuments = await this.db.tags.find({ selector: tagsSelector }).exec();
          const tags = tagDocuments.map((document) => document.toMutableJSON());

          if (tags.length > 0) {
            const tagBoard = prepareBoard(tags, tagLinks).map((tag) => {
              return {
                ...tag,
                items: tag.items.map((item) => ({ tagId: item.colId, ...item })),
              };
            }) as TagBoardColumn[];
            this.store.dispatch(setTags(tagBoard));
          }
        });
      subscriptions.push(tagLinksSubscription);
    }

    return () => {
      subscriptions.forEach((subscriptionItem) => {
        subscriptionItem.unsubscribe();
      });
    };
  };
}

function prepareBoard<
  T extends { id: string; nextId: string },
  K extends { id: string; nextId: string; colId: string }
>(cols: T[], items: K[]) {
  const orderedCols = sortLinkedList(cols, 'nextId', 'id') as T[];

  const grouppedItems = groupBy(items, 'colId');

  return orderedCols.map((col) => {
    const orderedItems = grouppedItems[col.id]
      ? (sortLinkedList(grouppedItems[col.id], 'nextId', 'id') as K[])
      : [];

    return {
      ...col,
      items: orderedItems,
    };
  });
}
