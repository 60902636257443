import Contact from 'types/contact';

type TGetFilteredCompaniesArgs = {
  contactsWithCompanies: Contact[];
  searchValue: string;
};
type TGetFilteredCompanies = (args: TGetFilteredCompaniesArgs) => Contact[];

export const getFilteredCompanies: TGetFilteredCompanies = ({
  contactsWithCompanies,
  searchValue,
}) => {
  return contactsWithCompanies.filter(({ company, suggestion_company }) => {
    return (
      suggestion_company?.toLowerCase().includes(searchValue.toLowerCase()) ||
      company?.toLowerCase().includes(searchValue.toLowerCase())
    );
  });
};
