import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserLocalStorage, setDataToLocalStorage } from '../utils';
import { fetchUserByTempToken, updateOnboardingInfo, fetchUserAPI } from '../api/userAPI';
import { AppState } from '../app/store/store';
import { getDirectTokenLocalStorage } from 'src/utils/helpers';
import ProfileEntity from '../db/entities/profile/ProfileEntity';
import { DbManager } from '../db/dbManager';
import { container } from '../app/ioc/container';
import { TYPES } from '../app/ioc/types';

export interface UserLocalStorage {
  id_token: string;
  [key: string]: any;
  access_token: string;
  refresh_token: string;
  token_type: string;
  profile: ProfileEntity;
  connection_state: string;
}

interface InitialState {
  profile: null | ProfileEntity;
  userLocalStorage: UserLocalStorage;
  isNewUser: boolean;
  isAuth: boolean;
  openMenu: boolean;
  isSignupFinished: boolean;
  isTeamMembersAdded: boolean;
  userUpdateToken: string;
}

const getInitialState = (): InitialState => {
  const userLocalStorage = getUserLocalStorage();

  return {
    userLocalStorage,
    openMenu: false,
    profile: null,
    isNewUser: false,
    isAuth: Object.keys(userLocalStorage).length !== 0,
    isSignupFinished: false,
    isTeamMembersAdded: false,
    userUpdateToken: '',
  };
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const data = await fetchUserAPI();
  const directToken = getDirectTokenLocalStorage();

  if (directToken) {
    setDataToLocalStorage('user', data);
  }

  return data;
});

export const userAsync = createAsyncThunk(
  'users/fetchUserByTempToken',
  async (payload: { provider: string; token: string }) => {
    try {
      const data = await fetchUserByTempToken(payload);

      setDataToLocalStorage('user', data);

      const dbManager = container.get<DbManager>(TYPES.DbManager);
      const db = await dbManager.getDb();

      db.profile.upsert(data.profile);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const sendUserProfileDataAsync = createAsyncThunk<any, void, { state: AppState }>(
  'user/fetchFromUserSingUp',
  async (_, { getState }) => {
    const userProfileData = getState().user.profile;

    try {
      await updateOnboardingInfo(userProfileData.onboardingInfo);
    } catch (error) {
      throw new Error(error);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState,
  reducers: {
    setUpdateToken(state, action) {
      state.userUpdateToken = action.payload;
    },
    setOpenMenu(state, action) {
      state.openMenu = action.payload;
    },
    setOnboardingInfo(state, action: PayloadAction<Partial<ProfileEntity['onboardingInfo']>>) {
      const prevState = state.profile.onboardingInfo || ({} as ProfileEntity['onboardingInfo']);

      state.profile.onboardingInfo = {
        ...prevState,
        ...action.payload,
      };
    },
    setIsTeamMembersAdded(state) {
      state.isTeamMembersAdded = true;
    },
    setIsAuth(state, { payload = true }) {
      state.isAuth = payload;
    },
    setUserLanguage(state, { payload }) {
      state.userLocalStorage.profile.settings.lang = payload;
    },
    setUserProfile(state, action: PayloadAction<ProfileEntity>) {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userAsync.fulfilled, (state, action) => {
      const userLocalStorage = getUserLocalStorage();
      state.userLocalStorage = { ...userLocalStorage, ...action.payload };
      state.isAuth = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.profile = { ...state.profile, ...action.payload };
    });
  },
});

const { reducer, actions } = userSlice;
export const selectUpdateToken = (state: AppState) => state.user.userUpdateToken;
export const selectUserIndustry = (state: AppState) => state.user.profile?.onboardingInfo?.industry;

export const selectUserHasOnboardingInfo = (state: AppState) =>
  !!state.user.profile?.onboardingInfo;

export const selectOnboardingInfoFilled = (state: AppState) => {
  if (!state.user.profile?.onboardingInfo) {
    return false;
  }

  const fieldsList = [
    'companyName',
    'country',
    'industry',
    'jobField',
    'jobRole',
    'language',
    'stuffCount',
    'userExperience',
  ];

  return !fieldsList.some((name) => {
    const value = state.user.profile?.onboardingInfo[name];

    return value === null || typeof value === 'undefined';
  });
};

export const selectUserOnboardingInfo = (state: AppState) =>
  state.user.userLocalStorage?.profile?.onboardingInfo;

export const selectHasInvitedTeammates = (state: AppState) => {
  return (
    state.user.userLocalStorage?.profile?.hasInvitedTeammates ||
    state.user.profile?.hasInvitedTeammates
  );
};
export const selectUserConnectionState = (state: AppState) => {
  return state.user.userLocalStorage?.connection_state;
};
export const selectSkippedAddingTeammates = (state: AppState) => {
  return (
    !!state.user.userLocalStorage?.profile?.onboardingInfo?.skipInviteTeammates ||
    state.user.profile?.onboardingInfo?.skipInviteTeammates
  );
};
export const selectOpenMenu = (state: AppState) => state?.user?.openMenu;
export const selectIsAuth = (state: AppState) => state?.user?.isAuth;
export const selectIsNewUser = (state: AppState) => state?.user?.isNewUser;

export const selectUserProfile = (state: AppState) => state?.user?.profile;
export const selectUserLocalStorage = (state: AppState) => state?.user?.userLocalStorage;

export const selectUserLanguage = (state: AppState) =>
  state?.user?.userLocalStorage?.profile?.settings?.lang || state?.user?.profile?.settings?.lang;

export const selectUserHasApp = (state: AppState) =>
  (state?.user?.userLocalStorage?.profile || state?.user?.profile)?.platforms?.find(
    (platformItem) => platformItem === 'ios' || platformItem === 'android'
  );

export const selectIsSignupFinished = (state: AppState) => {
  if (!state?.user?.profile?.onboardingInfo) {
    return false;
  }
  const { stuffCount, jobField, jobRole, userExperience } = state?.user?.profile?.onboardingInfo;

  return !!stuffCount && !!jobField && !!jobRole && !!userExperience;
};

export const selectIsSecondStepFinished = (state: AppState) => {
  if (!state.user?.profile?.onboardingInfo) {
    return false;
  }

  const { country, lang, industry, companyName } = state.user.profile.onboardingInfo;

  return !!country && !!lang && !!industry && !!companyName;
};

export const {
  setIsTeamMembersAdded,
  setOnboardingInfo,
  setIsAuth,
  setUpdateToken,
  setOpenMenu,
  setUserLanguage,
  setUserProfile,
} = actions;
export default reducer;
