import { patchContactOptimistic } from 'slices/contactsSlice';
import { withErrorSnackbar } from '../api/withErrorSnackbar';
import { TSetDontShowInLeader } from './types';

export const setDontShowInLeader: TSetDontShowInLeader = async ({
  contact,
  callback,
  dispatch,
  enqueueSnackbar,
  notShowValue,
}) => {
  withErrorSnackbar({
    onTry: async () => {
      if (contact) {
        if (contact.not_show !== notShowValue) {
          if (callback) {
            callback();
          }

          await dispatch(
            patchContactOptimistic({
              contactId: contact.uuid,
              data: {
                not_show: notShowValue,
                updated_at: Math.floor(Date.now() / 1000),
                workspace_id: contact.workspace_id || '',
              },
            })
          ).unwrap();
        }
      }
    },
    enqueueSnackbar,
  });
};
