import ContactDC from './ContactDC';
import ContactEntity from './ContactEntity';
import { mapCallerDCtoEntity, mapCallerEntityToDC } from '../caller/mapper';

export const mapContactDCToEntity = (dc: ContactDC): ContactEntity => {
  const getCallersInfo = (): ContactEntity['callersInfo'] => {
    const entities = Object.entries(dc.callers_info);

    const callersInfo = {};

    for (const entity of entities) {
      const [phone, info] = entity;
      callersInfo[phone] = mapCallerDCtoEntity(info);
    }

    return callersInfo;
  };

  return {
    legacyId: dc.id,
    id: dc.uuid,
    anchorContactId: dc.anchor_contact_id,
    anchorContactIds: dc.anchor_contact_ids || [],
    assignedTo: dc.assigned_to,
    workspaceId: dc.workspace_id,
    phones: (dc.phones || []).map((phoneItem) => ({
      type: phoneItem.type,
      customLabel: phoneItem.custom_label,
      phone: phoneItem.phone,
      normalizedPhone: phoneItem.normalized_phone,
    })),
    emails: (dc.emails || []).map((emailItem) => ({
      type: emailItem.type,
      customLabel: emailItem.custom_label,
      email: emailItem.email,
    })),
    urls: (dc.urls || []).map((urlItem) => ({
      type: urlItem.type,
      customLabel: urlItem.custom_label,
      url: urlItem.url,
    })),
    addresses: (dc.addresses || []).map((addressItem) => ({
      type: addressItem.type,
      customLabel: addressItem.custom_label,
      street1: addressItem.street1,
      street2: addressItem.street2,
      city: addressItem.city,
      country: addressItem.country,
      zipCode: addressItem.zip_code,
      fullAddress: addressItem.full_address,
    })),
    dates: (dc.dates || []).map((dateItem) => ({
      type: dateItem.type,
      day: dateItem.day,
      month: dateItem.month,
      year: dateItem.year,
    })),
    socialProfiles: (dc.social_profiles || []).map((socProfileItem) => ({
      type: socProfileItem.type,
      customLabel: socProfileItem.custom_label,
      userId: socProfileItem.user_id,
    })),

    name: dc.name,
    jobTitle: dc.job_title,
    company: dc.company,
    isSpammer: dc.is_spammer || false,
    isArchived: dc.is_archived || false,
    isPersonal: dc.is_personal || false,
    suggestionName: dc.suggestion_name,
    suggestionJobTitle: dc.suggestion_job_title,
    suggestionCompany: dc.suggestion_company,
    hidden: dc.not_show || false,
    thumbnail: dc.thumbnail,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    callersInfo: getCallersInfo(),
  };
};

export const mapContactEntityToDC = (entity: ContactEntity): ContactDC => {
  const getCallersInfo = (): ContactDC['callers_info'] => {
    const entities = Object.entries(entity.callersInfo);

    const callersInfo = {};

    for (const entity of entities) {
      const [phone, info] = entity;
      callersInfo[phone] = mapCallerEntityToDC(info);
    }

    return callersInfo;
  };

  return {
    id: entity.legacyId,
    uuid: entity.id,
    anchor_contact_id: entity.anchorContactId,
    anchor_contact_ids: entity.anchorContactIds,
    assigned_to: entity.assignedTo,
    workspace_id: entity.workspaceId,
    phones: entity.phones.map((phoneItem) => ({
      type: phoneItem.type,
      custom_label: phoneItem.customLabel,
      phone: phoneItem.phone,
      normalized_phone: phoneItem.normalizedPhone,
    })),
    emails: entity.emails.map((emailItem) => ({
      type: emailItem.type,
      custom_label: emailItem.customLabel,
      email: emailItem.email,
    })),
    urls: entity.urls.map((urlItem) => ({
      type: urlItem.type,
      custom_label: urlItem.customLabel,
      url: urlItem.url,
    })),
    addresses: entity.addresses.map((addressItem) => ({
      type: addressItem.type,
      custom_label: addressItem.customLabel,
      street1: addressItem.street1,
      street2: addressItem.street2,
      city: addressItem.city,
      country: addressItem.country,
      zip_code: addressItem.zipCode,
      full_address: addressItem.fullAddress,
      geolocation: null,
    })),
    dates: entity.dates.map((dateItem) => ({
      type: dateItem.type,
      day: dateItem.day,
      month: dateItem.month,
      year: dateItem.year,
    })),
    social_profiles: entity.socialProfiles.map((socProfileItem) => ({
      type: socProfileItem.type,
      custom_label: socProfileItem.customLabel,
      user_id: socProfileItem.userId,
    })),
    name: entity.name,
    job_title: entity.jobTitle,
    company: entity.company,
    is_spammer: entity.isSpammer,

    is_archived: entity.isArchived,
    is_personal: entity.isPersonal,
    suggestion_name: entity.suggestionName,

    suggestion_job_title: entity.suggestionJobTitle,
    suggestion_company: entity.suggestionCompany,
    not_show: entity.hidden,
    thumbnail: entity.thumbnail,
    created_at: entity.createdAt,
    updated_at: entity.updatedAt,
    callers_info: getCallersInfo(),
    tags: [],
    notes: [],
    tasks: [],
  };
};
