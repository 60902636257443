import callerSchema from '../caller/callerSchema';
import noteSchema from '../note/noteSchema';
import { dbVersion } from '../../constants';

export default {
  title: 'call schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'id',
  type: 'object',
  required: [
    'id',
    'removed',
    'phoneNumber',
    'normalizedPhone',
    'duration',
    'type',
    'callDate',
    'notShow',
    'simPhone',
    'simSubscriptionId',
    'createdAt',
    'updatedAt',
    'callerInfo',
    'notes',
  ],
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    removed: {
      type: 'boolean',
    },
    phoneNumber: {
      type: 'string',
    },
    normalizedPhone: {
      type: 'string',
    },
    duration: {
      type: 'number',
    },
    type: {
      type: 'string',
    },
    callDate: {
      type: 'number',
    },
    notShow: {
      type: 'boolean',
    },
    simPhone: {
      type: ['string', 'null'],
    },
    simSubscriptionId: {
      type: ['number', 'null'],
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    notes: {
      type: 'array',
      items: noteSchema,
    },
    callerInfo: callerSchema,
  },
};
