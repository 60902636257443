import React from 'react';

import PreviewArea from '../shared/PreviewArea';
import useFilePreviewViewModel from '../../useFilePreviewViewModel';
import { IFilePreviewUnitProps } from '../../index';
import useFilePreviewMediaViewModel from '../shared/useFilePreviewMediaViewModel';
import FilePreviewProblem from '../../../FilePreviewProblem';

const FilePreviewAudio: React.FC<IFilePreviewUnitProps> = ({ file, isLoading, setIsLoading }) => {
  const { isSupported } = useFilePreviewMediaViewModel(file, setIsLoading, true);
  const { fileLink } = useFilePreviewViewModel({ file, isFileSupported: isSupported });

  return (
    <PreviewArea isLoading={isLoading}>
      {isSupported && fileLink ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio
          style={{ margin: '5% auto 0', display: 'block' }}
          controls
          src={fileLink}
          onLoadedData={() => setIsLoading(false)}
        />
      ) : (
        <FilePreviewProblem file={file} />
      )}
    </PreviewArea>
  );
};

export default FilePreviewAudio;
