import ResponseColumnDC from './ResponseDC';

export default class ResponseColumnDTO {
  contact_attr: string;
  example_value: string;
  index: number;
  name: string;

  constructor(responseColumnDC: ResponseColumnDC) {
    this.name = responseColumnDC.name;
    this.contact_attr = responseColumnDC.attribute;
    this.index = responseColumnDC.index;
    this.example_value = responseColumnDC.example;
  }
}
