import { Observable } from 'rxjs';
import { ICustomFieldUseCase } from './abstractions/ICustomFieldUseCase';
import { injectable, inject } from 'inversify';
import { CUSTOM_FIELDS_TYPES } from '../di/types';
import type { ICustomFieldRepository } from './abstractions/ICustomFieldRepository';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';

@injectable()
export default class CustomFieldUseCase implements ICustomFieldUseCase {
  @inject(CUSTOM_FIELDS_TYPES.CustomFieldRepository) private repository: ICustomFieldRepository;

  createField(data: Pick<CustomFieldEntity, 'uuid' | 'name'>): Observable<CustomFieldEntity> {
    return this.repository.createField(data);
  }

  deleteField(uuid: string): Observable<boolean> {
    return this.repository.deleteField(uuid);
  }

  getAll(): Observable<CustomFieldEntity[]> {
    return this.repository.getAll();
  }

  fetchAndSaveCustomFields(): Observable<void> {
    return this.repository.fetchAndSaveCustomFields();
  }
}
