import { useTranslation } from 'react-i18next';

import Button from 'components/UI/Buttons/BaseButton';
import styles from './styles.module.scss';
import ImportDoneIcon from 'icons/import-done.svg';

interface IImportDone {
  numberContacts: number;
  handleClose: () => void;
}
const ImportDone = ({ handleClose, numberContacts }: IImportDone) => {
  const { t } = useTranslation(['import-csv', 'common']);
  return (
    <div className={styles.container}>
      <ImportDoneIcon />
      <h3 className={styles.title}>{t('import-csv:ImportDone.title')}</h3>
      <span className={styles.description}>
        {t('import-csv:ImportDone.description', { count: numberContacts })}
      </span>
      <Button onClick={handleClose}>{t('common:action.done')}</Button>
    </div>
  );
};
export default ImportDone;
