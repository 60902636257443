import { useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import BaseButton from '../BaseButton';
import TutorialDialog from 'components/UI/Dialogs/TutorialDialog';
import PlayIcon from 'icons/play_icon.svg';
const TutorialButton = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleClick = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };
  return (
    <>
      <BaseButton startIcon={<PlayIcon />} onClick={handleClick} className={styles.button}>
        {t('common:action:watch_tutorial')}
      </BaseButton>
      <TutorialDialog open={dialogOpen} handleClose={handleClose} />
    </>
  );
};

export default TutorialButton;
