import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import { useDialog } from '../../../../app/hooks';

export interface IFileRemovingDialogProps {
  onDelete(): void;
  onClose(): void;
  fileName: string;
}

const FileRemovingDialog: React.FC<IFileRemovingDialogProps> = ({
  onDelete,
  fileName,
  onClose,
}) => {
  const { dialogUI, show, hide } = useDialog();
  const { t } = useTranslation('dialogs');

  useEffect(() => {
    show();

    return () => {
      onClose();
      hide();
    };
  }, []);

  return dialogUI({
    titleText: t('file_removing_dialog.title'),
    bodyText: t('file_removing_dialog.description', { fileName }),
    positiveButtonText: t('file_removing_dialog.delete_button_text'),
    negativeButtonText: t('file_removing_dialog.cancel_button_text'),
    onSuccess: onDelete,
  });
};

export default FileRemovingDialog;
