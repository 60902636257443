import { inject, injectable } from 'inversify';
import { IImportCSVApiService } from '../../domain/abstractions/IImportApiService';
import { catchError, map, throwError } from 'rxjs';
import { TYPES } from '../../di/types';
import { TYPES as ThirdPartyTypes } from 'src/app/ioc/thirdPartyContainers/types';
import { IImportCSVUploadStateRepository } from '../../domain/abstractions/IImportCSVUploadStateRepository';
import { AxiosError } from 'axios';
import CSVConfirmingErrors from '../../domain/enums/CSVConfirmingErrors';
import ImportCSVUploadConfirmError from '../ImportCSVUploadConfirmError';
import CSVUploadingErrors from '../../domain/enums/CSVUploadingErrors';
import { TYPES as WORKSPACE_TYPES } from 'src/features2/workspaces/di/types';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
@injectable()
export default class ImportCSVUploadStateRepository implements IImportCSVUploadStateRepository {
  @inject(TYPES.ImportCSVApiService) private api: IImportCSVApiService;
  @inject(ThirdPartyTypes.AbortControllerFactory)
  private AbortControllerFactory: () => AbortController;
  @inject(WORKSPACE_TYPES.WorkspacesStateRepository)
  private workspacesStateRepository: IWorkspacesStateRepository;
  uploadedFileId: string;
  abortController: AbortController;
  cancelUpload = () => {
    this.abortController?.abort();
  };
  private mapResponseErrorToHandledError(error: AxiosError) {
    const errorResponse = error.response;

    let handledError: CSVUploadingErrors;
    //@ts-ignore
    switch (errorResponse?.data?.errors[0].message) {
      case CSVUploadingErrors.CSV_FORMAT_ERROR:
        handledError = CSVUploadingErrors.CSV_FORMAT_ERROR;
        break;
      case CSVUploadingErrors.CSV_FILE_SIZE_ERROR:
        handledError = CSVUploadingErrors.CSV_FILE_SIZE_ERROR_ROW;
        break;
      default:
        handledError = CSVUploadingErrors.COMMON;
    }

    return handledError;
  }
  confirmCSV = (columns, tag_name, board_column_id) => {
    const workspaceId = this.workspacesStateRepository.currentWorkspaceId.value;
    this.abortController = this.AbortControllerFactory();
    return (
      this.api
        .confirmUploadCSVToBackend(
          columns,
          tag_name,
          board_column_id,
          this.uploadedFileId,
          this.abortController,
          workspaceId
        )
        //@ts-ignore
        .pipe(
          //@ts-ignore
          map((response) => response.data),
          catchError((err: AxiosError) => {
            if (err?.code === 'ERR_CANCELED') {
              return throwError(
                () => new ImportCSVUploadConfirmError(CSVConfirmingErrors.CANCELED)
              );
            }
            if (err.response.status === 422 && err.code === 'ERR_BAD_REQUEST') {
              //return errors array with indexes of row where we have incorrect value (2 is index inside loc from response.data.detail);
              return throwError(
                () =>
                  new ImportCSVUploadConfirmError(
                    CSVConfirmingErrors.WRONG_COLUMN_TYPE,
                    //@ts-ignore
                    err.response.data.detail.map((row) => row.loc[2])
                  )
              );
            } else {
              return throwError(() => new ImportCSVUploadConfirmError(CSVConfirmingErrors.COMMON));
            }
          })
        )
    );
  };

  upload = (file, onProgress) => {
    const workspaceId = this.workspacesStateRepository.currentWorkspaceId.value;
    const postData = new FormData();
    postData.append('file', file);
    this.abortController = this.AbortControllerFactory();
    return this.api
      .uploadCSVToBackend(postData, onProgress, this.abortController, workspaceId)
      .pipe(
        map((response) => {
          //@ts-ignore
          this.uploadedFileId = response.data.file_id;
          //@ts-ignore
          return response.data.columns;
        }),
        catchError((err: AxiosError) => {
          return throwError(() => this.mapResponseErrorToHandledError(err));
        })
      );
  };
}
