import { Container } from 'inversify';

import LoginUseCases from '../domain/LoginUseCases';
import ILoginUseCases from '../domain/abstractions/ILoginUseCases';

import LoginRepository from '../data/LoginRepository';
import ILoginRepository from '../domain/abstractions/ILoginRepository';

import { TYPES } from './types';

export const bindLoginModule = (container: Container) => {
  container.bind<ILoginRepository>(TYPES.LoginRepository).to(LoginRepository).inSingletonScope();
  container.bind<ILoginUseCases>(TYPES.LoginUseCases).to(LoginUseCases);
};
