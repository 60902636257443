export const TYPES = {
  ProfileDao: Symbol.for('ProfileDao'),
  ProfileApiService: Symbol.for('ProfileApiService'),
  ProfileRepository: Symbol.for('ProfileRepository'),
  ProfileSettingsRepository: Symbol.for('ProfileSettingsRepository'),
  ProfileUseCases: Symbol.for('ProfileUseCases'),
  ProfileDeleteRepository: Symbol.for('ProfileDeleteRepository'),
  ProfileExportRepository: Symbol.for('ProfileExportRepository'),
  ProfileDataRepository: Symbol.for('ProfileDataRepository'),
};
