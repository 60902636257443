import { useContext } from 'react';

import BillingLimitsContext from './BillingLimitsContext';
import { LIMITS_TYPES, BLOCKED_FEATURED_TYPES } from './types';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { CreditsTypes } from 'src/features2/billing/domain/entities/SubscriptionEntity';
import { useTagLinks } from 'src/features2/tagLinks/ui/hooks';
import useAmplitude, { ANALYTICS_EVENTS } from 'src/analytics/amplitude/ui/useAmplitude';

const useLimitsByType = (type: CreditsTypes) => {
  const { trackEvent: trackAmplitude } = useAmplitude();

  const currentUnixTimestamp = new Date().getTime() / 1000;

  const context = useContext(BillingLimitsContext);
  const { subscription } = useCurrentSubscription();

  // for Proposal Drawer case since it's rendered on top of app, even on login page, where we don't have subscription state
  // TODO: ProposalProvider should be separated from Proposal Component and component should be rendered on logged-in state only
  if (!subscription) {
    return {
      checkLimits: () => {},
    };
  }

  const { credits, isUserOnFreePlan, isActive } = subscription;
  const credit = credits?.find((credit) => credit?.creditType === type);

  const isFeatureAllowed =
    (!isUserOnFreePlan && isActive) ||
    (isUserOnFreePlan &&
      (credit ? credit?.used < credit?.limit || currentUnixTimestamp >= credit?.expiresAt : true));

  return {
    credit,
    isAllowed: isFeatureAllowed,
    checkLimits: () => {
      if (!isFeatureAllowed) {
        context?.open({
          limitsType: LIMITS_TYPES.LIMITED,
        });
        type !== CreditsTypes.TAG_LINK &&
          trackAmplitude(ANALYTICS_EVENTS.FREE_USER_REACH_MONTHLY_LIMIT, { type });
      }
      return isFeatureAllowed;
    },
  };
};

export const useLabelsLimits = () => {
  const { trackEvent: trackAmplitude } = useAmplitude();

  const context = useContext(BillingLimitsContext);
  const { isAllowed, credit } = useLimitsByType(CreditsTypes.TAG_LINK);

  const { contactsWithUpdatedLabels } = useTagLinks({ updatedAfter: credit?.createdAt });

  return {
    checkLimits: (contactId) => {
      const isContactUsed = contactsWithUpdatedLabels?.indexOf(contactId) !== -1;
      const isFeatureAllowed = isAllowed || isContactUsed;

      if (!isFeatureAllowed) {
        context.open({
          limitsType: LIMITS_TYPES.LIMITED,
        });
        trackAmplitude(ANALYTICS_EVENTS.FREE_USER_REACH_MONTHLY_LIMIT, {
          type: CreditsTypes.TAG_LINK,
        });
      }
      return isFeatureAllowed;
    },
  };
};

const useBlockedFeatureLimits = (type: BLOCKED_FEATURED_TYPES) => {
  const { trackEvent: trackAmplitude } = useAmplitude();

  const context = useContext(BillingLimitsContext);
  const { subscription } = useCurrentSubscription();
  const { isUserOnFreePlan, isActive } = subscription;

  const isFeatureAllowed =
    !isUserOnFreePlan &&
    isActive &&
    !(subscription.isIndividual && type === BLOCKED_FEATURED_TYPES.INVITE_TEAMMATE);

  return {
    isAllowed: isFeatureAllowed,
    checkLimits: () => {
      if (!isFeatureAllowed) {
        context.open({
          limitsType: LIMITS_TYPES.BLOCKED_FEATURE,
          featureType: type,
        });
        trackAmplitude(ANALYTICS_EVENTS.FREE_USER_GET_BLOCKED_PREMIUM_FEATURE, {
          type,
        });
      }
      return isFeatureAllowed;
    },
  };
};

export const useNotesLimits = () => useLimitsByType(CreditsTypes.NOTE);
export const useQuotesLimits = () => useLimitsByType(CreditsTypes.PRICE_PROPOSAL);
export const useTasksLimits = () => useLimitsByType(CreditsTypes.TASK);

export const useFilesLimits = () => useBlockedFeatureLimits(BLOCKED_FEATURED_TYPES.FILES);
export const useAddTeammatesLimits = () =>
  useBlockedFeatureLimits(BLOCKED_FEATURED_TYPES.INVITE_TEAMMATE);
export const useInsightsLimits = () => useBlockedFeatureLimits(BLOCKED_FEATURED_TYPES.INSIGHTS);
export const useImportContactLimits = () =>
  useBlockedFeatureLimits(BLOCKED_FEATURED_TYPES.IMPORT_CONTACT);
export const useIntegrationsLimits = () =>
  useBlockedFeatureLimits(BLOCKED_FEATURED_TYPES.INTEGRATIONS);
