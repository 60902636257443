import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { appRoutes } from 'src/utils/constants';
import usePlansAndPurchasePagesDetection from '../helpers';
import commonDataProviderHoc from 'src/features2/common/provider/commonDataProviderHoc';

import styles from './styles.module.scss';

const HeaderPromo = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'promos' });

  const { subscription } = useCurrentSubscription();
  const { currentUser } = useCurrentUser();
  const { isPlansOrPurchasesPage } = usePlansAndPurchasePagesDetection();

  if (isPlansOrPurchasesPage) return null;

  const {
    isUserOnFreePlan,
    isUserOnFreeTrial,
    isActive,
    daysLeft,
    isPaymentFailed,
    isCanceledAndExpired,
  } = subscription;
  const { isAdmin } = currentUser;

  let text = null;

  const updatedNowLinkBasedOnPermissions = isAdmin ? (
    <Link href={appRoutes.PLANS_PAGE}>{t('upgradeNow')}</Link>
  ) : (
    <>{t('short.contactAdmin')}</>
  );

  const fixNowLinkBasedOnPermissions = isAdmin ? (
    <Link href={appRoutes.SUBSCRIPTION_PAGE}>{t('fixNow')}</Link>
  ) : (
    <>{t('short.contactAdmin')}</>
  );

  if (!isActive) {
    text = (
      <>
        {t('short.subExpired')} <br />
        {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isUserOnFreeTrial) {
    if (isActive) {
      if (daysLeft > 1) {
        text = (
          <>
            {t('short.freeTrialDaysLeft', { daysLeft })} <br /> {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
      if (daysLeft === 1) {
        text = (
          <>
            {t('short.freeTrialDayLeft')} <br /> {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
    } else {
      text = (
        <>
          {t('short.freeTrialIsEnded')} <br /> {updatedNowLinkBasedOnPermissions}
        </>
      );
    }
  }

  if (isUserOnFreePlan) {
    text = (
      <>
        {t('short.onFreePlan')} <br /> {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isPaymentFailed) {
    text = (
      <>
        {t('short.issueWithLastPayment')} <br />
        {fixNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isCanceledAndExpired) {
    text = (
      <>
        {t('short.subCanceledAndExpired')} <br />
        {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (!text) return null; // play it safe

  return (
    <div className={styles.wrapper}>
      <span>{text}</span>
    </div>
  );
};

export default commonDataProviderHoc(HeaderPromo);
