import { Container } from 'inversify';
import { TYPES } from './types';
import { IUserUseCases } from '../domain/abstractions/IUserUseCases';
import UserUseCases from '../domain/UserUseCases';
import { IUserStateRepository } from '../domain/abstractions/IUserStateRepository';
import UserStateRepository from '../data/UserStateRepository';
import UserDao, { IUserDao } from '../data/UserDao';
import { UserRouter, IUserRouter } from '../router';
import IUserApiService from '../domain/abstractions/IUserApiService';
import UserApiService from '../data/network/UserApiService';

export const bindUserModule = (container: Container) => {
  container.bind<IUserUseCases>(TYPES.UserUseCases).to(UserUseCases);
  container.bind<IUserApiService>(TYPES.UserAPIService).to(UserApiService);
  container.bind<IUserRouter>(TYPES.UserRouter).to(UserRouter);
  container.bind<IUserDao>(TYPES.UserDao).to(UserDao);
  container
    .bind<IUserStateRepository>(TYPES.UserStateRepository)
    //@ts-ignore
    .to(UserStateRepository)
    .inSingletonScope();
};
