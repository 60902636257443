import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import { TeamMember } from 'src/api/workspacesAPI';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import Contact from 'types/contact';
import { getAllowedTagsItems } from './filterHelpers';

type TGetFilteredProposalsArgs = {
  contacts: Contact[];
  selectedTeamMembers: TeamMember[];
  isAllContentVisible: boolean;
  userEmail: string;
  selectedTags: TagBoardColumn[];
  proposals: ProposalEntity[];
  searchValue: string;
  teamMembersContactsMap: Record<string, Contact[]>;
};
type TGetFilteredProposals = (args: TGetFilteredProposalsArgs) => ProposalEntity[];

export const getFilteredProposalsList: TGetFilteredProposals = (args) => {
  const {
    searchValue,
    proposals,
    contacts,
    selectedTeamMembers,
    userEmail,
    isAllContentVisible,
    selectedTags,
    teamMembersContactsMap,
  } = args;
  const searchValueLower = searchValue.toLowerCase();
  if (searchValueLower) {
    let filteredProposals = proposals;

    if (selectedTeamMembers.length) {
      filteredProposals = [];

      selectedTeamMembers.forEach(({ email }) => {
        const relatedContacts = teamMembersContactsMap[email];

        proposals.forEach((proposal) => {
          relatedContacts.forEach((contact) => {
            if (proposal.contactId === contact.uuid) {
              filteredProposals.push(proposal);
            }
          });
        });
      });
    }

    if (selectedTags.length) {
      const allowedTagsItems = getAllowedTagsItems(selectedTags);
      const allowedProposals = [];

      allowedTagsItems.forEach((contactId) => {
        proposals.forEach((proposal) => {
          if (proposal.contactId === contactId) {
            allowedProposals.push(proposal);
          }
        });
      });

      filteredProposals = allowedProposals;
    }

    filteredProposals = filteredProposals.filter(({ contactId, title, description, number }) => {
      const { name, assigned_to } = contacts.find((contact) => contact.uuid === contactId);
      if (!isAllContentVisible && assigned_to !== userEmail) return false;
      return (
        name?.toLowerCase().includes(searchValueLower) ||
        title?.toLowerCase().includes(searchValueLower) ||
        description?.toLowerCase().includes(searchValueLower) ||
        String(number).toLowerCase().includes(searchValueLower)
      );
    });

    return filteredProposals;
  }
  return [];
};
