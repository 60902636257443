import { dbVersion } from '../../constants';

export default {
  title: 'note schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: {
    key: 'pk_id',
    fields: ['id', 'workspaceId'],
    separator: '|',
  },
  type: 'object',
  required: [
    'pk_id',
    'legacyId',
    'id',
    'subject',
    'preview',
    'content',
    'links',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'workspaceId',
    'notes',
  ],
  properties: {
    pk_id: {
      type: 'string',
      maxLength: 100,
    },
    legacyId: {
      type: ['string', 'null'],
    },
    id: {
      type: 'string',
      maxLength: 100,
    },
    subject: {
      type: 'string',
    },
    preview: {
      type: ['string', 'null'],
    },
    content: {
      type: 'string',
    },
    links: {
      type: 'object',
      required: ['direct'],
      properties: {
        direct: {
          type: 'object',
          required: ['id', 'type'],
          properties: {
            id: {
              type: ['string', 'null'],
            },
            type: {
              type: ['string', 'null'],
            },
          },
        },
      },
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    createdBy: {
      type: ['string', 'null'],
    },
    updatedBy: {
      type: ['string', 'null'],
    },
    workspaceId: {
      type: 'string',
      maxLength: 36,
    },
  },
  indexes: ['workspaceId'],
};
