import React from 'react';
import Image from 'next/image';

import FileMimeType from './FileMimeType';

interface IFileIconProps {
  fileType: File['type'];
  width?: number;
  height?: number;
  className?: string;
}

const FileIcon: React.FC<IFileIconProps> = ({ fileType, width = 12, height = 16, className }) => {
  const renderIcon = (fileName: string) => (
    <Image
      className={className}
      src={`/images/icons/files/${fileName}`}
      width={width}
      height={height}
    />
  );

  switch (fileType) {
    case FileMimeType.JPEG:
    case FileMimeType.GIF:
    case FileMimeType.PNG:
    case FileMimeType.TIFF:
    case FileMimeType.HEIC:
    case FileMimeType.HEIF: {
      return renderIcon('image.png');
    }

    case FileMimeType.VIDEO_MP4:
    case FileMimeType.AVI_MSVIDEO:
    case FileMimeType.AVI:
    case FileMimeType.MOV:
    case FileMimeType.FLV: {
      return renderIcon('video.png');
    }

    case FileMimeType.MP3:
    case FileMimeType.WAV:
    case FileMimeType.X_WAV:
    case FileMimeType.M4A:
    case FileMimeType.X_M4A:
    case FileMimeType.AUDIO_MP4: {
      return renderIcon('audio.png');
    }

    case FileMimeType.DOC:
    case FileMimeType.DOCX: {
      return renderIcon('doc.png');
    }

    case FileMimeType.PPT:
    case FileMimeType.PPTX: {
      return renderIcon('ppt.png');
    }

    case FileMimeType.XLS:
    case FileMimeType.XLSX: {
      return renderIcon('xls.png');
    }

    case FileMimeType.ZIP:
    case FileMimeType.RAR: {
      return renderIcon('zip.png');
    }

    case FileMimeType.SVG: {
      return renderIcon('svg.png');
    }

    case FileMimeType.CSV: {
      return renderIcon('csv.png');
    }

    case FileMimeType.HTML: {
      return renderIcon('html.png');
    }

    case FileMimeType.ODP: {
      return renderIcon('odf.png');
    }

    case FileMimeType.PDF: {
      return renderIcon('pdf.png');
    }

    case FileMimeType.RTF_TEXT:
    case FileMimeType.RTF_APPLICATION: {
      return renderIcon('rtf.png');
    }

    case FileMimeType.TXT: {
      return renderIcon('txt.png');
    }

    default: {
      return renderIcon('common.png');
    }
  }
};

export default FileIcon;
