import MUIInputLabel, { InputLabelProps as MUIInputLabelProps } from '@material-ui/core/InputLabel';
import clsx from 'clsx';
import styles from '../../../styles/components/inputs/InputLabel.module.css';
import InfoIcon from '../../../../public/images/icons/info.svg';
import Tooltip from '@material-ui/core/Tooltip';

export default function InputLabel(
  props: Omit<
    MUIInputLabelProps,
    'color' | 'disableAnimation' | 'focused' | 'margin' | 'shrink' | 'variant'
  > & { hint?: React.ReactNode }
) {
  const { hint, className, ...restProps } = props;

  return (
    <MUIInputLabel
      {...restProps}
      className={clsx(styles.Label, className)}
      disableAnimation
      shrink={true}
    >
      {props.children}
      {!!hint && (
        <Tooltip
          classes={{
            tooltip: styles.Tooltip,
          }}
          title={hint}
          arrow
        >
          <div className={styles.hint}>
            <InfoIcon className={styles.InfoIcon} />
          </div>
        </Tooltip>
      )}
    </MUIInputLabel>
  );
}
