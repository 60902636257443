import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { hideDialog } from 'slices/uiSlice';
import { trackEvent } from 'src/analytics/amplitude';
import { useAppDispatch, useDialog } from 'src/app/hooks';
import useSetContactHidden from 'src/app/hooks/contact/useSetContactHidden';
import DialogTypes from 'src/enums/DialogTypes';
import appRoutes from 'src/utils/constants/appRoutes';
import Contact from 'types/contact';
import styles from './styles.module.scss';

export interface IDontShowInLeaderDialogProps {
  contactId: Contact['uuid'];
  callback: () => void;
}

const DontShowInLeaderDialog: React.FC<IDontShowInLeaderDialogProps> = ({
  contactId,
  callback,
}) => {
  const { dialogUI, show, hide } = useDialog();
  const { t } = useTranslation('dialogs');
  const dispatch = useAppDispatch();
  const hideContact = useSetContactHidden();
  const router = useRouter();

  useEffect(() => {
    show();

    return hide;
  }, [show, hide]);

  return dialogUI({
    titleText: t('dialogs:dont_show_dialog.title'),
    bodyText: t('dialogs:dont_show_dialog.description'),
    negativeButtonText: t('duplicates:Settings_title'),
    positiveButtonText: t('duplicates:Continue_action'),
    handleSuccessWithoutClose: true,
    titleClassName: styles.Text,
    negativeButtonClassName: styles.Button,
    onSuccess: async () => {
      await hideContact({
        value: true,
        contactId,
        callback,
      });
      trackEvent('Dont_show_in_leader');
      dispatch(hideDialog(DialogTypes.DONT_SHOW_IN_LEADER_DIALOG));
    },
    onNegative: async () => {
      await hideContact({
        contactId,
        value: true,
        callback,
      });
      trackEvent('Dont_show_in_leader');
      dispatch(hideDialog(DialogTypes.DONT_SHOW_IN_LEADER_DIALOG));
      router.push(appRoutes.DONT_SHOW_IN_LEADER_PAGE);
    },
  });
};

export default DontShowInLeaderDialog;
