import { Container } from 'inversify';

import { TYPES } from './types';
import TagLinksDao, { ITagLinksDao } from '../data/TagLinksDao';
import TagLinksRepository, { ITagLinksRepository } from '../data/TagLinksRepository';

export const bindTagLinksModule = (container: Container) => {
  container.bind<ITagLinksDao>(TYPES.TagLinksDao).to(TagLinksDao);
  container.bind<ITagLinksRepository>(TYPES.TagLinksRepository).to(TagLinksRepository);
};
