import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import * as Sentry from '@sentry/react';

import { useAppDispatch } from '../../../app/hooks';
import Button from '../../../components/UI/Buttons/BaseButton';
import { streamUpdatesManagerContext } from '../../../app/streamUpdate/streamUpdatesManager';

import { actions } from '../../../slices/boardSlice';
import styles from '../../../styles/calls/index.module.css';

export default function ErrorState() {
  const { t } = useTranslation('calls-page');
  const dispatch = useAppDispatch();

  const [reloading, setReloading] = useState(false);

  const retry = async () => {
    setReloading(true);

    try {
      dispatch(actions.setSyncFailed(false));
      await streamUpdatesManagerContext.instance.fullResync();
    } catch (error) {
      Sentry.captureException(error);

      dispatch(actions.setSyncFailed(true));
    } finally {
      setReloading(false);
    }
  };

  return (
    <div className={styles.errorState}>
      <div className={styles.errorStateContent}>
        <div className={styles.errorStateIcon}>
          <ErrorOutline fontSize="large" />
        </div>
        <div className={styles.errorStateText}>{t('common:error.syncFailed')}</div>
        <div className={styles.errorStateActions}>
          <Button onClick={retry} disabled={reloading}>
            {t('common:action.reload')}
          </Button>
        </div>
      </div>
    </div>
  );
}
