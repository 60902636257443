import { Container } from 'inversify';
import ImportCSVUploadStateRepository from '../data/repositories/ImportCSVUploadStateRepository';
import ImportCSVApiService from '../data/services/ImportCSVApiService/ImportCSVApiService';
import { IImportCSVApiService } from '../domain/abstractions/IImportApiService';
import { IImportCSVUploadStateRepository } from '../domain/abstractions/IImportCSVUploadStateRepository';
import { IImportCSVUseCases } from '../domain/abstractions/IImportCSVUseCases';
import ImportCSVUseCases from '../domain/useCases/ImportCSVUseCases';
import { TYPES } from './types';
export const bindImportCSVModule = (container: Container) => {
  container.bind<IImportCSVApiService>(TYPES.ImportCSVApiService).to(ImportCSVApiService);
  container
    .bind<IImportCSVUploadStateRepository>(TYPES.ImportCSVUploadRepository)
    .to(ImportCSVUploadStateRepository);
  container.bind<IImportCSVUseCases>(TYPES.ImportCSVUseCases).to(ImportCSVUseCases);
};
