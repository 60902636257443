import { useTranslation } from 'next-i18next';
import { MouseEventHandler, useState } from 'react';

import ExternalLinks from '../../../../../../enums/ExternalLinks';

const useHelpMenuViewModel = () => {
  const { t } = useTranslation('dialogs');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);

  const onButtonClick: MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onClose = () => setAnchorEl(null);

  const links = [
    {
      link: ExternalLinks.FAQ_GENERAL,
      text: t('help_menu_dialog.help_center_button'),
    },
    {
      link: ExternalLinks.BOOK_A_DEMO,
      text: t('help_menu_dialog.book_demo_button'),
    },
    {
      link: ExternalLinks.CONTACT_US,
      text: t('help_menu_dialog.contact_us_button'),
    },
  ];

  return {
    onButtonClick,
    onClose,
    anchorEl,
    links,
  };
};

export default useHelpMenuViewModel;
