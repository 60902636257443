export default [
  '#0312A0',
  '#6C20E4',
  '#A363FC',
  '#071BCF',
  '#6222EE',
  '#C38FFD',
  '#3B1EDD',
  '#893DFC',
  '#E3B8FE',
];
