import ResponseColumnsDC from 'src/features2/importCSV/domain/entities/ResponseDC';
import styles from './styles.module.scss';
import Row from './Row.tsx';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import BaseButton from 'components/UI/Buttons/BaseButton';
const CSVColumnParser = ({
  columns,
  fileName,
  onSubmit,
  errorColumns,
}: {
  columns: ResponseColumnsDC[];
  fileName: string;
  onSubmit: (columns: ResponseColumnsDC[]) => void;
  errorColumns?: number[];
}) => {
  const { t } = useTranslation('import-csv');
  const [parsedColumns, setParsedColumns] = useState<ResponseColumnsDC[]>([]);
  const [errorIndexesArray, setErrorIndexesArray] = useState<number[]>([]);

  const handleChangeSelect = (index, value) => {
    setErrorIndexesArray((prevErrArray) => prevErrArray.filter((item) => item != index));
    const newColumns = [...parsedColumns];
    newColumns[index].attribute = value;
    setParsedColumns(newColumns);
  };

  useEffect(() => {
    errorColumns && setErrorIndexesArray(errorColumns);
  }, [errorColumns]);

  useEffect(() => {
    setParsedColumns(columns);
  }, [columns]);
  return (
    <div className={styles.container}>
      <span className={styles.subTitle}>
        {t('parsingCSV.title', { fileName: fileName.toUpperCase() })}
      </span>
      <div className={styles.rowContainer}>
        {parsedColumns.map((column, index) => (
          <Row
            error={errorIndexesArray?.some((indexErrorColumn) => indexErrorColumn === index)}
            rowData={column}
            key={index}
            onChange={handleChangeSelect}
            index={index}
          />
        ))}
      </div>
      <BaseButton
        className={styles.confirmUploadButton}
        onClick={() => onSubmit(columns)}
        disabled={parsedColumns.some(({ attribute }) => !attribute) || !!errorIndexesArray?.length}
      >
        {t('duplicates:Import_contacts_action')}
      </BaseButton>
    </div>
  );
};
export default CSVColumnParser;
