import omit from 'lodash/omit';

import axios from '../../app/axios';
import { endpoints } from '../../utils/constants';

import ProposalEntity from '../../db/entities/proposal/ProposalEntity';
import ProposalDC from '../../db/entities/proposal/ProposalDC';
import { mapProposalEntityToDC } from '../../db/entities/proposal/mapper';

import ProposalSettingsEntity from '../../db/entities/proposalSettings/ProposalSettingsEntity';
import { mapProposalSettingsEntityToDC } from '../../db/entities/proposalSettings/mapper';
import RequestHeaders from '../../enums/RequestHeaders';
import ContentTypes from '../../enums/ContentTypes';

async function createProposal(data: ProposalEntity, workspaceId: string): Promise<number> {
  const params = mapProposalEntityToDC(data);

  const response = await axios.post<ProposalDC>(endpoints.proposals.post, omit(params, 'number'), {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  return response.data.number;
}

async function updateProposal(data: ProposalEntity, workspaceId: string): Promise<void> {
  const params = mapProposalEntityToDC(data);

  await axios.patch<ProposalDC>(endpoints.proposals.post, params, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function hideProposal(uuid: string, workspaceId: string): Promise<void> {
  await axios.patch<ProposalDC>(
    endpoints.proposals.patch,
    {
      uuid,
      not_show: true,
      updated_at: Math.floor(Date.now() / 1000),
    },
    {
      headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
    }
  );
}

async function updateSettings(
  settings: ProposalSettingsEntity,
  workspaceId: string
): Promise<void> {
  const params = mapProposalSettingsEntityToDC(settings);

  await axios.patch(endpoints['proposal-settings'].post, params, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function deleteProposal(uuid: string, workspaceId: string): Promise<void> {
  await axios.delete<true>(endpoints.proposals.delete.replace('{uuid}', uuid), {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

type PdfSignature = string;
type TempPdfUrl = string;

const pdfCache: Record<PdfSignature, TempPdfUrl> = {};

async function generatePdf(data: ProposalEntity): Promise<TempPdfUrl> {
  const params = mapProposalEntityToDC(data);

  const signature = JSON.stringify(params);

  if (pdfCache[signature]) {
    return pdfCache[signature];
  }

  const response = await axios.post(endpoints.proposals.pdf, params, {
    headers: { [RequestHeaders.CONTENT_TYPE]: ContentTypes.APPLICATION_JSON },
    responseType: 'arraybuffer',
  });

  const blob = new Blob([response.data], { type: ContentTypes.APPLICATION_PDF });
  const tempUrl = URL.createObjectURL(blob);

  pdfCache[signature] = tempUrl;

  return tempUrl;
}

export {
  createProposal,
  updateProposal,
  generatePdf,
  deleteProposal,
  hideProposal,
  updateSettings,
};
