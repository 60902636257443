import axiosRetry from 'axios-retry';
import axios from 'axios';
import { first } from 'rxjs';

import envService from 'src/services/app/envService';
import { getDirectTokenLocalStorage } from 'src/utils/helpers';
import { getUserLocalStorage } from '../utils';
import { streamUpdatesManagerContext } from './streamUpdate/streamUpdatesManager';
import { container } from './ioc/container';
import { IUserUseCases } from '../features2/user/domain/abstractions/IUserUseCases';
import { TYPES } from '../features2/user/di/types';
import RequestHeaders from '../enums/RequestHeaders';
import ContentTypes from '../enums/ContentTypes';

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});
const userUseCases = container.get<IUserUseCases>(TYPES.UserUseCases);
axiosRetry(instance, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    const statusesToRetry = [500, 502, 504];

    return statusesToRetry.includes(error?.response?.status);
  },
});

instance.defaults.headers.post[RequestHeaders.CONTENT_TYPE] = ContentTypes.APPLICATION_JSON;
instance.defaults.headers.common[RequestHeaders.APP_ID] = process.env.NEXT_PUBLIC_APP_ID;
instance.defaults.headers.common[RequestHeaders.APP_VERSION] = process.env.NEXT_PUBLIC_APP_VERSION;
instance.defaults.headers.common[RequestHeaders.APP_UUID] = process.env.NEXT_PUBLIC_APP_UUID;
instance.defaults.headers.common[RequestHeaders.APP_PLATFORM] =
  process.env.NEXT_PUBLIC_APP_PLATFORM;

instance.interceptors.request.use((config) => {
  const directToken = getDirectTokenLocalStorage();
  const isAllowedEnv = envService.isPrerelease() || envService.isStage();

  if (directToken?.length && isAllowedEnv) {
    config.headers.Authorization = directToken;
  } else {
    const user = getUserLocalStorage();

    config.headers.Authorization = user?.idToken ?? '';
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      userUseCases
        .logout()
        .pipe(first())
        .subscribe(() => {
          // fast workaround to forward login page, reset state, and reinit db
          window.location.reload();
        });
      return Promise.reject(error);
    } else if (
      (error.response?.status === 400 ||
        error.response?.status === 404 ||
        error.response?.status === 421) &&
      !!streamUpdatesManagerContext.instance
    ) {
      streamUpdatesManagerContext.instance.forceGetStreamUpdates();
      return Promise.reject(error);
    } else if (error.response?.status === 410) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
