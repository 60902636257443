import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import Contact from 'types/contact';
import styles from './styles.module.scss';
import DomainIcon from 'icons/domain.svg';
import SearchIcon from 'icons/search.svg';
import { getHighlightedOverlaps } from 'src/utils/searchUtils';
import { addHistoryItem } from 'slices/searchSlice';
import SearchHistoryItemTypes from 'src/enums/SearchHistoryItemTypes';
import { useTranslation } from 'next-i18next';
import { forwardToContactsPage } from 'src/services/router/appRouterService';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { useInsightsLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

interface ICompaniesListProps {
  companiesList: Contact[];
  searchValue: string;
  closeSearchModal: () => void;
}

const CompaniesList: React.FC<ICompaniesListProps> = ({
  companiesList,
  searchValue,
  closeSearchModal,
}) => {
  const { t } = useTranslation('search');
  const [isExpanded, setIsExpanded] = useState(false);

  if (!companiesList?.length || !searchValue) return null;

  const isLongList = companiesList.length > 3;

  const onSeeAll = () => setIsExpanded(!isExpanded);

  return (
    <div className={styles.Wrapper}>
      <div>{t('title_company')}</div>
      <div className={clsx(styles.List, isExpanded && styles.Expanded)}>
        {companiesList.map((contact) => {
          return (
            <CompanyItem
              key={contact.uuid}
              closeSearchModal={closeSearchModal}
              searchValue={searchValue}
              contact={contact}
            />
          );
        })}
      </div>
      {isLongList && (
        <button onClick={onSeeAll} className={'leader-search-see-more'}>
          <SearchIcon /> {isExpanded ? t('button_see_less') : t('button_see_all_companies')}
        </button>
      )}
    </div>
  );
};

interface ICompanyItemProps {
  contact: Contact;
  searchValue?: string;
  closeSearchModal: () => void;
}

export const CompanyItem: React.FC<ICompanyItemProps> = ({
  contact,
  searchValue,
  closeSearchModal,
}) => {
  const { checkLimits } = useInsightsLimits();
  const dispatch = useDispatch();
  const { uuid, company, suggestion_company } = contact;
  const resultCompany = suggestion_company || company;

  const onClick = async () => {
    if (!checkLimits()) {
      closeSearchModal();
      return;
    }
    await forwardToContactsPage();
    dispatch(setCurrentPageName('duplicates:Contacts_title'));
    dispatch(
      setRedirectEffect({
        contactId: uuid,
        targetType: RedirectEffectTargetType.COMPANY,
        targetId: resultCompany,
      })
    );
    closeSearchModal();
    dispatch(
      addHistoryItem({
        type: SearchHistoryItemTypes.COMPANY,
        id: uuid,
        companyData: contact,
      })
    );
  };

  return (
    <button className={styles.Item} onClick={onClick}>
      <div>
        <div className={styles.Icon}>
          <DomainIcon />
        </div>
      </div>
      <div className={styles.TitleWrapper}>
        <div
          className={styles.Title}
          dangerouslySetInnerHTML={{
            __html: getHighlightedOverlaps(resultCompany, searchValue),
          }}
        />
      </div>
    </button>
  );
};

export default CompaniesList;
