import React from 'react';
import clsx from 'clsx';

import Avatar from 'components/UI/Avatar';
import styles from './styles.module.scss';
import ArchiveTag from 'src/features/settings/pages/Archive/components/ArchiveTag';

interface IContactItemProps {
  name?: string;
  onClick?: () => void;
  thumbnail?: string;
  title?: string;
  subtitle?: string;
  isInnerItem?: boolean;
  isArchived?: boolean;
  nameClassName?: string;
}

const SearchItem: React.FC<IContactItemProps> = ({
  onClick,
  thumbnail,
  title,
  subtitle,
  isInnerItem,
  isArchived,
  name,
  nameClassName,
}) => {
  const ItemComponent = ({ children, ...rest }) =>
    isInnerItem ? <div {...rest}>{children}</div> : <button {...rest}>{children}</button>;

  return (
    <ItemComponent className={styles.Item} onClick={onClick}>
      <Avatar className={styles.Avatar} name={name} thumbnail={thumbnail} />
      <div className={styles.TextWrapper}>
        <div className={styles.NameWrapper}>
          <div
            className={clsx(styles.Name, nameClassName)}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {isArchived && <ArchiveTag className={styles.Tag} />}
        </div>
        <div className={styles.JobTitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
      </div>
    </ItemComponent>
  );
};

export default SearchItem;
