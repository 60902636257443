import { dbVersion } from '../../constants';

export default {
  title: 'file schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'uuid',
  type: 'object',
  required: [
    'uuid',
    'link',
    'thumbnail',
    'name',
    'mimeType',
    'fileSizeBytes',
    'itemType',
    'itemUuid',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
  ],
  properties: {
    uuid: {
      type: 'string',
      maxLength: 100,
    },
    link: {
      type: ['string'],
    },
    thumbnail: {
      type: ['string', 'null'],
    },
    mimeType: {
      type: ['string'],
    },
    fileSizeBytes: {
      type: ['number'],
    },
    itemType: {
      type: ['string'],
    },
    itemUuid: {
      type: 'string',
      maxLength: 100,
    },
    createdAt: {
      type: ['string'],
    },
    updatedAt: {
      type: ['string'],
    },
    createdBy: {
      type: ['string'],
    },
    updatedBy: {
      type: ['string'],
    },
  },
};
