import React, { useContext } from 'react';

import LocalizationContext from '../LocalizationContext';
import styles from './styles.module.scss';
import clsx from 'clsx';

interface IProps {
  children: ((i: boolean) => React.ReactNode) | React.ReactNode;
  style?: React.CSSProperties;
}

const Index: React.FC<IProps> = ({ children, style }) => {
  const { isRTL } = useContext(LocalizationContext);
  const childrenIsFunc = typeof children === 'function';

  return (
    <span style={style} className={clsx(styles.DirectedText, isRTL && styles.RTL)}>
      {/*@ts-ignore*/}
      {childrenIsFunc ? children(isRTL) : children}
    </span>
  );
};

export default Index;
