import React from 'react';
import clsx from 'clsx';

import Avatar from '../../Avatar';
import styles from './ContactsDialog.module.css';
import Contact from '../../../../../types/contact';
import contactService from '../../../../services/contacts/contactService';

interface IProps {
  contact: Contact;
  disabled?: boolean;
}

const ContactsDialogLabel: React.FC<IProps> = ({ contact, disabled = false }) => {
  const contactName = contactService.getContactName(contact);

  return (
    <div className={clsx(styles.item, disabled && styles.disabledItem)}>
      <div className={styles.avatar}>
        <Avatar size={24} thumbnail={contactService.getContactImage(contact)} name={contactName} />
      </div>
      <span className={styles.name}>{contactName}</span>
    </div>
  );
};
export default ContactsDialogLabel;
