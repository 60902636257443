import TagDC from './TagDC';
import TagEntity from './TagEntity';
import { mapTagLinkDCtoEntity } from '../tagLink/mapper';

export const mapTagDCtoEntity = (dc: TagDC, workspaceId: string): TagEntity => {
  return {
    id: dc.uuid,
    nextId: dc.next_uuid,
    title: dc.title,
    color: dc.color,
    items: (dc.items || []).map((item) => mapTagLinkDCtoEntity(item, workspaceId)),
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    workspaceId: workspaceId,
  };
};
