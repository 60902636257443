import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { sliceName } from './constants';
import { AppState } from '../../app/store/store';
import { ItemModel } from '../../api/boardAPI';
import { BoardState } from './types';
import { addColumn, updateColumn } from './asyncThunks';
import { reorder } from '../../utils/array';
import { selectContactsMap } from 'slices/contactsSlice';

const initialState: BoardState = {
  shouldSync: false,
  syncFailed: false,
  board: { columns: [] },
};

const boardSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setShouldSync(state, action: PayloadAction<boolean>) {
      state.shouldSync = action.payload;
    },
    setColumns(state, action: PayloadAction<BoardState['board']['columns']>) {
      state.board.columns = action.payload;
    },
    setSyncFailed(state, action: PayloadAction<boolean>) {
      state.syncFailed = action.payload;
    },
    moveColumn(state, action: PayloadAction<{ uuid: string; destIndex: number }>) {
      const { uuid, destIndex } = action.payload;

      const prevIndex = state.board.columns.findIndex((colItem) => colItem.id === uuid);
      const reorderedColumns = reorder(state.board.columns, prevIndex, destIndex);

      state.board.columns = reorderedColumns;
    },
    updateColumnsOrdering(state, action: PayloadAction<string[]>) {
      const ordering = action.payload;

      state.board.columns = ordering.map((uuid) =>
        state.board.columns.find((colItem) => colItem.id === uuid)
      );
    },
    deleteColumn(state, action: PayloadAction<string>) {
      const uuid = action.payload;

      const index = state.board.columns.findIndex((colItem) => colItem.id === uuid);

      state.board.columns.splice(index, 1);
    },
    addItem(state, action: PayloadAction<{ columnUuid: string; item: ItemModel }>) {
      const { columnUuid, item } = action.payload;

      const сolumnWithSameItem = state.board.columns.find(({ items }) =>
        items.find(({ typeUuid }) => typeUuid === item.typeUuid)
      );

      if (сolumnWithSameItem) {
        const index = сolumnWithSameItem.items.findIndex(
          ({ typeUuid }) => typeUuid === item.typeUuid
        );

        сolumnWithSameItem.items.splice(index, 1);
      }

      const column = state.board.columns.find((colItem) => colItem.id === columnUuid);

      const itemsArray = [item, ...column.items];

      column.items = itemsArray;
    },
    moveItem(
      state,
      action: PayloadAction<{
        itemUuid: string;
        sourceColumnUuid: string;
        destColumnUuid: string;
      }>
    ) {
      const { itemUuid, sourceColumnUuid, destColumnUuid } = action.payload;

      const sourceColumn = state.board.columns.find((colItem) => colItem.id === sourceColumnUuid);
      const oldItem = sourceColumn.items.find((item) => item.uuid === itemUuid);

      const updatedItem: ItemModel = {
        ...oldItem,
        columnUuid: destColumnUuid,
        updatedAt: Math.floor(Date.now() / 1000),
      };

      const indexInSourceColumn = sourceColumn.items.indexOf(oldItem);

      sourceColumn.items.splice(indexInSourceColumn, 1);

      const destColumn = state.board.columns.find((colItem) => colItem.id === destColumnUuid);

      destColumn.items.splice(destColumn.items.length, 0, updatedItem);
    },
    changeItemOrder(
      state,
      action: PayloadAction<{
        columnUuid: string;
        itemUuid: string;
        index: number;
      }>
    ) {
      const { columnUuid, itemUuid, index } = action.payload;

      const column = state.board.columns.find((colItem) => colItem.id === columnUuid);
      const prevItemIndex = column.items.findIndex((item) => item.uuid === itemUuid);

      const reorderedItems = reorder(column.items, prevItemIndex, index);

      column.items = reorderedItems;
    },
    deleteItems(
      state,
      action: PayloadAction<{
        columnUuid: string;
        itemsUuids: string[];
      }>
    ) {
      const { columnUuid, itemsUuids } = action.payload;

      const column = state.board.columns.find(({ id }) => id === columnUuid);

      const filteredItems = column.items.filter(({ uuid }) => !itemsUuids.includes(uuid));

      column.items = filteredItems;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addColumn.fulfilled, (state, action) => {
      state.board.columns.push(action.payload);
    });
    builder.addCase(updateColumn.fulfilled, (state, action) => {
      const updatedColumn = action.payload;

      const index = state.board.columns.findIndex((colItem) => colItem.id === updatedColumn.id);

      state.board.columns.splice(index, 1, updatedColumn);
    });
  },
});

export const { actions } = boardSlice;

export const { deleteItems, setColumns } = actions;

export const createSelectorColumnByUuid = () =>
  createSelector(
    (state: AppState) => state.board.board,
    (_, id: string) => id,
    (board, id) => {
      return board.columns.find((col) => col.id === id);
    }
  );

export const selectBoard = (state: AppState) => state.board.board;
export const selectBoardColumns = (state: AppState) => state.board.board.columns;

export const selectSyncFailed = (state: AppState) => {
  return state.board.syncFailed;
};
export const selectShouldSync = (state: AppState) => state.board.shouldSync;

export const makeSelectBoardColumnsWithVisibleContacts = createSelector(
  selectBoardColumns,
  selectContactsMap,
  (columns, contactsMap) =>
    columns.map((col) => ({
      ...col,
      items: col.items.filter((item) => !contactsMap[item.typeUuid]?.not_show),
    }))
);

export const selectBoardContactsMap = createSelector(selectBoardColumns, (columns) => {
  return columns.reduce((acc: Record<string, ItemModel>, column) => {
    for (const item of column.items) {
      acc[item.typeUuid] = item;
    }

    return acc;
  }, {});
});

export default boardSlice.reducer;
