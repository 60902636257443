import { createContext } from 'react';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

export interface ProposalDrawerContext {
  isOpen: boolean;
  openCreateForm: ({
    defaultValues,
    onSuccess,
    shouldPinContact,
    type,
  }: {
    defaultValues?: Partial<ProposalEntity>;
    onSuccess?: () => void;
    shouldPinContact?: boolean;
    type?: string;
  }) => void;
  openUpdateForm: ({
    uuid,
    onSuccess,
  }: {
    uuid: ProposalEntity['uuid'];
    onSuccess?: () => void;
  }) => void;
  openDuplicateForm: ({
    uuid,
    onSuccess,
    shouldPinContact,
  }: {
    uuid: ProposalEntity['uuid'];
    onSuccess?: () => void;
    shouldPinContact?: boolean;
  }) => void;
  close: () => void;
}

const ProposalDrawerContext = createContext<ProposalDrawerContext>({
  isOpen: false,
  openCreateForm: undefined,
  openUpdateForm: undefined,
  openDuplicateForm: undefined,
  close: undefined,
});

export default ProposalDrawerContext;
