import MUIFormHelperText, {
  FormHelperTextProps as MUIFormHelperTextProps,
} from '@material-ui/core/FormHelperText';
import clsx from 'clsx';
import styles from '../../../styles/components/inputs/FormHelperText.module.css';

export default function FormHelperText(
  props: Omit<
    MUIFormHelperTextProps,
    'disabled' | 'filled' | 'focused' | 'margin' | 'required' | 'variant'
  >
) {
  return (
    <MUIFormHelperText {...props} className={clsx(styles.FormHelperText, props.className)}>
      {props.children}
    </MUIFormHelperText>
  );
}
