import NoteDC from './NoteDC';
import NoteEntity from './NoteEntity';

export const mapNoteDCToEntity = (dc: NoteDC, workspaceId?: string): NoteEntity => {
  return {
    legacyId: dc.id,
    id: dc.uuid,
    subject: dc.subject,
    preview: dc.preview,
    content: dc.content,
    links: {
      direct: {
        id: dc.links.direct.id,
        type: dc.links.direct.type,
      },
    },
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    createdBy: dc.created_by,
    updatedBy: dc.updated_by,
    workspaceId,
  };
};

export const mapPartialNoteEntityToDC = (entity: Partial<NoteEntity>): Partial<NoteDC> => ({
  id: entity?.legacyId,
  uuid: entity?.id,
  subject: entity?.subject,
  preview: entity?.preview,
  content: entity?.content,
  links: entity?.links,
  created_at: entity?.createdAt,
  updated_at: entity?.updatedAt,
  created_by: entity?.createdBy,
  updated_by: entity?.updatedBy,
});
