import AutocompleteMUI, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import Popper from '../Popper';
const useStyles = makeStyles({
  autocompleteWrapper: {
    '& + .MuiAutocomplete-popper': {
      marginTop: -25,
      '& .MuiAutocomplete-paper': {
        borderRadius: 12,
        fontSize: 14,
        color: '#001847',
        boxShadow: '10px 10px 40px 0 #d8dfea',
      },
      '& .MuiAutocomplete-listbox': {
        maxHeight: '230px',
      },
      '& .MuiAutocomplete-option': {
        padding: '8px 8px 8px 17px',
        '&[aria-selected="true"]': {
          background: 'inherit',
        },
        '&[data-focus="true"]': {
          background: '#efecfd',
        },
      },
    },
  },
});
//@ts-ignore
const Autocomplete = ({
  limitTags = 2,
  ...props
}: AutocompleteProps<any, boolean, boolean, boolean>) => {
  const classes = useStyles();
  return (
    <AutocompleteMUI
      limitTags={limitTags}
      disablePortal
      fullWidth
      freeSolo
      disableClearable
      PopperComponent={Popper}
      filterSelectedOptions
      {...props}
      className={clsx(classes.autocompleteWrapper, props.className)}
    />
  );
};

export default Autocomplete;
