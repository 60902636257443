import MUIButton, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import React from 'react';
import Spinner from 'components/UI/Spinner';

import styles from './BaseButton.module.css';

export default function BaseButton(
  props: Omit<MUIButtonProps, 'variant' | 'color'> & {
    variant?: 'text' | 'contained' | 'outlined';
    textSize?: 'small' | 'normal';
    color?: 'primary' | 'secondary' | 'error';
    isProcessing?: boolean;
    target?: string;
  }
) {
  const {
    className,
    variant = 'contained',
    textSize = 'normal',
    color = 'primary',
    isProcessing = false,
    ...restProps
  } = props;

  return (
    <MUIButton
      variant={variant}
      className={clsx({
        [styles.BaseButton]: true,
        [className]: !!className,
        [styles[color]]: color === 'primary',
        [styles.secondary]: color === 'secondary',
        [styles.error]: color === 'error',
        [styles.smallText]: textSize === 'small',
      })}
      {...restProps}
      disabled={restProps.disabled || isProcessing}
    >
      {isProcessing ? <Spinner size={20} /> : props.children}
    </MUIButton>
  );
}
