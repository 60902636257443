import InvoiceDC from './InvoiceDC';
import InvoiceEntity from '../../../domain/entities/InvoiceEntity';

export const mapInvoiceDcToEntity = (dc: InvoiceDC): InvoiceEntity => ({
  transactionId: dc.transaction_id,
  invoiceId: dc.invoice_id,
  amount: dc.amount,
  currency: dc.currency,
  description: dc.description,
  url: dc.url,
  date: dc.date,
});
