import { injectable, inject } from 'inversify';
import { BehaviorSubject, switchMap, Observable, map } from 'rxjs';

import { IWorkspacesDao } from './WorkspacesDao';
import { IWorkspacesStateRepository } from '../domain/abstractions/IWorkspacesStateRepository';
import { TYPES as WORKSPACES_MODULE_TYPES } from '../di/types';
import { TYPES } from '../../../app/ioc/types';
import { IPersistentRepository } from '../../../services/PersistentRepository';
import { IWorkspacesApiService } from '../domain/abstractions/IWorkspacesApiService';
import { mapWorkspaceDCtoEntity } from 'src/db/entities/workspace/mapper';
import WorkspaceDC from 'src/db/entities/workspace/WorkspaceDC';
import Workspace from '../domain/entities/Workspace';

@injectable()
//@ts-ignore
export default class WorkspacesStateRepository implements IWorkspacesStateRepository {
  @inject(WORKSPACES_MODULE_TYPES.WorkspacesDao) private dao: IWorkspacesDao;
  @inject(WORKSPACES_MODULE_TYPES.WorkspacesApiService) private api: IWorkspacesApiService;

  private persistentRepository: IPersistentRepository;
  public currentWorkspaceId: BehaviorSubject<string | undefined>;
  public workspaces: Workspace[];
  public constructor(
    @inject(TYPES.PersistentRepository) persistentRepository: IPersistentRepository
  ) {
    this.persistentRepository = persistentRepository;

    this.currentWorkspaceId = new BehaviorSubject(
      this.persistentRepository.getCurrentWorkspaceId()
    );
  }
  updateWorkspaceFromOnboarding = (data) => {
    const preparedData = {
      uuid: this.currentWorkspaceId.value,
      company_size: data.companySize,
      company_website: data.companyWebsite,
      business_phone: data.businessPhone,
      name: data.companyName,
    };
    return this.api
      .patch(preparedData)
      .pipe(switchMap((workspace) => this.dao.upsertWorkspace(workspace)))
      .subscribe();
  };
  getWorkspaces = () => {
    return this.dao.getWorkspaces();
  };

  getCurrentWorkspace = () => {
    return this.currentWorkspaceId.pipe(
      switchMap((workspaceId) =>
        this.dao
          .getWorkspaces()
          .pipe(map((workspaces) => workspaces.find((workspace) => workspace.id === workspaceId)))
      )
    );
  };

  fetchWorkspaces = () => {
    return this.api
      .get()
      .pipe(
        switchMap((workspaces) => this.dao.upsertWorkspaces(workspaces.map(mapWorkspaceDCtoEntity)))
      );
  };
  putWorkspaces = (workspaces) => {
    return this.dao
      .upsertWorkspaces(workspaces)
      .subscribe((workspaces) => (this.workspaces = workspaces));
  };

  setWorkspace = (workspace: WorkspaceDC) => {
    //@ts-ignore
    return this.dao.upsertWorkspaces([mapWorkspaceDCtoEntity(workspace)]);
  };

  changeWorkspace(next: string | undefined): Observable<true> {
    this.currentWorkspaceId.next(next);
    // TODO: SHOULD BE MOVED TO USE_CASES?
    this.persistentRepository.setCurrentWorkspaceId(next);

    const savedUpdateTokens = this.persistentRepository.getUpdateTokens();

    if (savedUpdateTokens) {
      this.persistentRepository.setUpdateTokens({ ...savedUpdateTokens });
    }
    this.persistentRepository.setWorkspaceRestored(next, false);
    //

    return new Observable((subscriber) => subscriber.next(true));
  }

  deleteMembers = (data) => {
    return this.api.deleteMembers(data, this.currentWorkspaceId.value);
  };
}
