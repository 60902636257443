import {
  convertDateTime,
  getDataFromLocalStorage,
  getUserLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from './helpers';
import { trackEvent } from 'src/analytics/amplitude';
import * as process from 'process';
import { container } from '../app/ioc/container';
import { TYPES as FB_ANALYTICS_TYPES } from 'src/analytics/FB/di/types';
import { IFBAnalyticsUseCases } from '../analytics/FB/domain/abstractions/IFBAnalyticsUseCases';
import FB_EVENTS from '../analytics/FB/domain/enums/FB_EVENTS';

export const authorizeWith = {
  google: (callback): any => {
    const { trackEvent: trackEventFB } = container.get<IFBAnalyticsUseCases>(
      FB_ANALYTICS_TYPES.FBAnalyticsUseCases
    );

    const authConfigGoogle = {
      clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      authority: process.env.NEXT_PUBLIC_GOOGLE_AUTHORITY,
      redirectUri: window.location.origin + process.env.NEXT_PUBLIC_REDIRECT_URI,
    };
    const GOOGLE_SCOPE = ['https://www.googleapis.com/auth/userinfo.profile'];
    const GOOGLE_AUTH_URI = `${authConfigGoogle.authority}/o/oauth2/v2/auth?client_id=${
      authConfigGoogle.clientId
    }&prompt=select_account&response_type=code&redirect_uri=${
      authConfigGoogle.redirectUri
    }&scope=email ${GOOGLE_SCOPE.join(' ')}`;
    trackEvent('Login_start', { provider: 'Google' });
    const popUpWindow = window.open(GOOGLE_AUTH_URI, '', 'width=600,height=600,left=300,top=00');
    const google = setInterval(() => {
      if (!popUpWindow) {
        clearInterval(google);
      }
      try {
        if (popUpWindow.location.href.indexOf('error=') != -1) {
          trackEvent('Login_failed', { provider: 'Google' });
          clearInterval(google);
          popUpWindow.close();
        }
        const m = popUpWindow.location.href.match(/.*code=([^&]+).*/);
        if (m !== null) {
          clearInterval(google);
          trackEventFB('trackCustom', FB_EVENTS.LOGIN_SUCCESS, { provider: 'Google' });

          trackEvent('Login_success', { provider: 'Google' });
          callback('google_com', decodeURIComponent(m[1]), true);

          popUpWindow.close();
        }
      } catch (e) {
        // console.log(e);
        // clearInterval(google);
        // popUpWindow.close();
      }
    }, 100);
  },
  apple: (callback): any => {
    const { trackEvent: trackEventFB } = container.get<IFBAnalyticsUseCases>(
      FB_ANALYTICS_TYPES.FBAnalyticsUseCases
    );
    // @ts-ignore
    AppleID.auth.init({
      clientId: process.env.NEXT_PUBLIC_APPLE_CLIENT_ID,
      scope: 'name email',
      redirectURI: window.location.origin + process.env.NEXT_PUBLIC_REDIRECT_URI,
      state: 'Initial user authentication request',
      usePopup: true,
    });
    document.addEventListener('AppleIDSignInOnSuccess', (data) => {
      trackEvent('Login_success', { provider: 'Apple' });
      trackEventFB('trackCustom', FB_EVENTS.LOGIN_SUCCESS, { provider: 'Apple' });
      // @ts-ignore
      callback('apple', data.detail.authorization.code, true);
    });
    document.addEventListener('AppleIDSignInOnFailure', (err) => {
      trackEvent('Login_failed', { provider: 'Apple', err });
    });
    trackEvent('Login_start', { provider: 'Apple' });

    // @ts-ignore
    AppleID.auth.signIn();
  },
  office: (callback): any => {
    const { trackEvent: trackEventFB } = container.get<IFBAnalyticsUseCases>(
      FB_ANALYTICS_TYPES.FBAnalyticsUseCases
    );
    trackEvent('Login_start', { provider: 'Microsoft' });
    const authConfigMicrosoft = {
      clientId: process.env.NEXT_PUBLIC_MICROSOFT_CLIENT_ID,
      authority: process.env.NEXT_PUBLIC_MICROSOFT_AUTHORITY,
      redirectUri: window.location.origin + process.env.NEXT_PUBLIC_REDIRECT_URI,
    };

    const MICROSOFT_AUTH_URI = `${authConfigMicrosoft.authority}/oauth2/v2.0/authorize?client_id=${authConfigMicrosoft.clientId}&response_type=code&redirect_uri=${authConfigMicrosoft.redirectUri}&response_mode=query&scope=openid%20offline_access%20User.read%20profile%20email%20User.Read.All&state=12345`;

    const popUpWindow = window.open(MICROSOFT_AUTH_URI, '', 'width=600,height=600,left=300,top=00');
    const office = setInterval(() => {
      try {
        if (popUpWindow.location.href.indexOf('error=') != -1) {
          trackEvent('Login_failed', { provider: 'Microsoft' });

          clearInterval(office);
          popUpWindow.close();
        }
        const m = popUpWindow.location.href.match(/.*code=([^&]+).*/);
        if (m !== null) {
          trackEvent('Login_success', { provider: 'Microsoft' });
          trackEventFB('trackCustom', FB_EVENTS.LOGIN_SUCCESS, { provider: 'Mircrosoft' });

          clearInterval(office);
          callback('microsoft', decodeURIComponent(m[1]), true);
          popUpWindow.close();
        }
      } catch (e) {
        console.log(e);
      }
    }, 100);
  },
};

export {
  getUserLocalStorage,
  setDataToLocalStorage,
  getDataFromLocalStorage,
  convertDateTime,
  removeDataFromLocalStorage,
};
