import { useBehaviorSubject } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';

import { IUserUseCases } from '../domain/abstractions/IUserUseCases';
import { TYPES } from '../di/types';

export const useCurrentUser = () => {
  const useCases = useInjection<IUserUseCases>(TYPES.UserUseCases);
  const [currentUser] = useBehaviorSubject(useCases.getCurrentUser());

  return {
    currentUser,
  };
};
