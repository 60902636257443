import React, { useState, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'next-i18next';

import FileBC from '../../../../features2/files/domain/FileBC';
import FileInfo from '../../../../features2/files/ui/Files/FileListItem/components/FileInfo';
import { getHighlightedOverlaps } from '../../../../utils/searchUtils';
import { useAppDispatch } from '../../../../app/hooks';
import { addHistoryItem } from 'slices/searchSlice';
import SearchHistoryItemTypes from '../../../../enums/SearchHistoryItemTypes';
import { forwardToContactsPage } from '../../../../services/router/appRouterService';
import { setCurrentContact } from 'slices/contactsSlice';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import styles from './styles.module.scss';
import SearchIcon from 'icons/search.svg';
import clsx from 'clsx';
import RedirectEffectTargetType from '../../../../enums/RedirectEffectTargetType';

interface IFilesListProps {
  data: FileBC[];
  searchValue: string;
  closeSearchModal: () => void;
}

const FilesList: React.FC<IFilesListProps> = ({ data, searchValue, closeSearchModal }) => {
  const { t } = useTranslation('search');
  const [isExpanded, setIsExpanded] = useState(false);
  const [listMaxHeight, setListMaxHeight] = useState(0);
  const listRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (listRef.current?.children) {
      const first3Children = Array.from(listRef.current.children).slice(0, 3);
      const sumHeight = first3Children.reduce((acc, val) => acc + val.clientHeight, 0);

      setListMaxHeight(sumHeight);
    }
  }, [searchValue, data]);

  if (!data.length || !searchValue) return null;

  const isLongList = data.length > 3;

  const onSeeAllLess = () => setIsExpanded(!isExpanded);

  return (
    <div className={styles.Wrapper}>
      <div>{t('title_files', { count: data.length })}</div>
      <div
        ref={listRef}
        style={{ maxHeight: listMaxHeight }}
        className={clsx(styles.List, isExpanded && styles.Expanded)}
      >
        {data.map((file) => {
          return (
            <FilesItem
              key={file.uuid}
              file={file}
              searchValue={searchValue}
              closeSearchModal={closeSearchModal}
            />
          );
        })}
      </div>
      {isLongList && (
        <button onClick={onSeeAllLess} className={'leader-search-see-more'}>
          <SearchIcon /> {isExpanded ? t('button_see_less') : t('button_see_all_files')}
        </button>
      )}
    </div>
  );
};

interface IFilesItemProps {
  file: FileBC;
  searchValue?: string;
  closeSearchModal: () => void;
}

export const FilesItem: React.FC<IFilesItemProps> = ({ file, closeSearchModal, searchValue }) => {
  const dispatch = useAppDispatch();

  const onFileClick = async () => {
    await forwardToContactsPage();

    closeSearchModal();

    dispatch(setCurrentPageName('duplicates:Contacts_title'));
    dispatch(setCurrentContact(file.itemUuid));

    dispatch(
      setRedirectEffect({
        targetType: RedirectEffectTargetType.FILE,
        targetId: file.uuid,
      })
    );

    dispatch(
      addHistoryItem({
        type: SearchHistoryItemTypes.FILE,
        id: file.uuid,
        fileData: file,
      })
    );
  };

  return (
    <div key={file.uuid}>
      <FileInfo
        isSearch
        file={file}
        name={getHighlightedOverlaps(file.name, searchValue)}
        onFileClick={onFileClick}
      />
    </div>
  );
};

export default FilesList;
