import { useState, useRef, useEffect } from 'react';

import Image from 'next/image';
import clsx from 'clsx';
import styles from './UserInfo.module.scss';
import { useAppSelector } from 'src/app/hooks/useAppSelector';
import UserActionsPopup, { UserActionsPopupProps } from './UserActionsPopup';
import { selectNotifications } from 'slices/notificationSlice';
import useShowSwitchWorkspace from '../../../../../../app/hooks/ui/useShowSwitchWorkspace';
import { useProfile } from '../../../../../../features2/profile/ui/hooks';

export let externalOpenUserInfoPopup;

const UserInfo = () => {
  const { profile } = useProfile();

  const notifications = useAppSelector(selectNotifications);
  const notificationWorkspace = notifications.find(
    (notification) => notification.type === 'workspace_user_invited'
  );
  const isUserSawNotification = notificationWorkspace?.isRead ?? true;
  const [userActionsPopupAnchor, setUserActionsPopupAnchor] =
    useState<UserActionsPopupProps['anchorEl']>(null);

  const userPicButtonRef = useRef<HTMLButtonElement>();

  const showPopup = () => setUserActionsPopupAnchor(userPicButtonRef.current);

  const toggleUserActionsPopup = () => {
    if (!userActionsPopupAnchor) {
      showPopup();
    } else {
      setUserActionsPopupAnchor(null);
    }
  };

  const { showSwitchWorkspace, clearPath } = useShowSwitchWorkspace();

  useEffect(() => {
    if (showSwitchWorkspace) {
      clearPath();

      showPopup();
    }
  }, [showSwitchWorkspace]);

  externalOpenUserInfoPopup = () => showPopup();
  if (!profile) return null;
  const resultPicture = profile.picture;

  return (
    <>
      <button
        className={clsx(styles.userPicButton, !isUserSawNotification && styles.userPicButtonRedDot)}
        onClick={toggleUserActionsPopup}
        ref={userPicButtonRef}
      >
        {resultPicture ? (
          <Image className={styles.picture} src={resultPicture} width={36} height={36} />
        ) : (
          <span className={styles.pictureAlt}>{profile.fullName?.[0] || profile.email?.[0]}</span>
        )}
      </button>
      <UserActionsPopup
        anchorEl={userActionsPopupAnchor}
        onClose={() => setUserActionsPopupAnchor(null)}
      />
    </>
  );
};

export default UserInfo;
