import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';

const palette = {
  primary: {
    light: grey[700],
    main: '#001847',
    dark: grey[900],
  },
  secondary: {
    light: green[300],
    main: red[500],
    dark: green[700],
  },
};

export default palette;
