import { Observable, from, map } from 'rxjs';
import { injectable } from 'inversify';

import axios from 'src/app/axios';
import BillingDetailsDC from '../mappers/billingDetails/BillingDetailsDC';
import BillingDetailsEntity from '../../domain/entities/BillingDetailsEntity';
import {
  mapBillingDetailsDCtoEntity,
  mapBillingDetailsEntityToDC,
} from '../mappers/billingDetails/mapper';

export interface IBillingApiService {
  getBillingDetails: () => Observable<BillingDetailsEntity>;
  updateBillingDetails: (billingDetails: BillingDetailsEntity) => Observable<BillingDetailsEntity>;
}

const baseURL = '/api/v1/billing-service';

// TODO: check server error handlers works correctly
// TODO: think, to do mapping inside the repository instead of Api service

@injectable()
export default class BillingDetailsApiService implements IBillingApiService {
  getBillingDetails() {
    const response = axios.get<BillingDetailsDC>(`${baseURL}/accounts/my`);

    return from(response).pipe(map((response) => mapBillingDetailsDCtoEntity(response.data)));
  }

  updateBillingDetails(billingDetails) {
    const billingDetailsDC = mapBillingDetailsEntityToDC(billingDetails);
    const response = axios.post<BillingDetailsDC>(
      `${baseURL}/bluesnap/billing-info`,
      billingDetailsDC
    );

    return from(response).pipe(map((response) => mapBillingDetailsDCtoEntity(response.data)));
  }
}
