import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import useTaskDrawer from '../TaskDrawer/useTaskDrawer';
import GenericForm, { FormValues } from './GenericForm/GenericForm';
import { useAppDispatch, useAppStore } from 'src/app/hooks';
import { AppState } from 'src/app/store/store';
import { createTask } from '../../../slices/tasksSlice/tasksSlice';
import { selectContactsMap } from '../../../slices/contactsSlice';
import {
  selectCurrentUserMemberData,
  selectCurrentWorkspaceId,
} from '../../../slices/workspacesSlice';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import useReAssignContact from './useReAssignContact';
import useGetRelatedContactId from './useGetRelatedContactId';
import { trackEvent } from 'src/analytics/amplitude';
import TaskEntity from 'src/db/entities/task/TaskEntity';
import useFBAnalytics from 'src/analytics/FB/ui/useFBAnalytics';
import FB_EVENTS from 'src/analytics/FB/domain/enums/FB_EVENTS';

type FormOptions = {
  closeAfterSave?: boolean;
  onFormDataChanged: () => void;
  defaultValues?: Partial<TaskEntity>;
};

export default function CreateTaskForm(props: FormOptions) {
  const { t } = useTranslation('tasks-page');

  const { defaultValues, closeAfterSave = true, onFormDataChanged } = props;
  const { trackEvent: FBTrackEvent } = useFBAnalytics();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const store = useAppStore();

  const { close } = useTaskDrawer();

  const reAssignContact = useReAssignContact();
  const getRelatedContactId = useGetRelatedContactId();

  const handleSubmit = async (formValues: FormValues) => {
    const prepareContactId = async () => {
      if (formValues.contactId === null) {
        return null;
      } else {
        const state = store.getState() as AppState;
        const contact = selectContactsMap(state)[formValues.contactId];

        if (contact.workspace_id === null) {
          const contactId = await getRelatedContactId(formValues.contactId);

          return contactId;
        } else {
          return formValues.contactId;
        }
      }
    };

    try {
      const contactId = await prepareContactId();

      const state = store.getState() as AppState;
      const currentWpId = selectCurrentWorkspaceId(state);

      const task: TaskEntity = {
        id: uuidv4(),
        contactId: contactId,
        important: formValues.important,
        title: formValues.title,
        type: 'regular',
        description: formValues.description,
        descriptionUpdatedAt: formValues.description ? Date.now() : undefined,
        status: 'new',
        dueDate: formValues.dueDate?.getTime(),
        reminder: formValues.reminder
          ? {
              date: formValues.reminder.getTime(),
            }
          : undefined,
        assignedTo: formValues.assignedTo,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        workspaceId: currentWpId,
      };

      await dispatch(createTask(task)).unwrap();
      FBTrackEvent('trackCustom', FB_EVENTS.ADD_TASK);
      trackEvent('Add_task', {
        type: 'Tasks tab',
        'Related to contact': !!contactId,
        Reminder: !!formValues.reminder,
        'Due date': !!formValues.dueDate?.getTime(),
      });
      enqueueSnackbar({ message: t('form.notification.saved'), variant: MessageType.Success });

      if (closeAfterSave) {
        close();
      }

      reAssignContact({
        taskAssignedTo: formValues.assignedTo,
        taskRelatedContactId: contactId,
      });
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({ message, variant: MessageType.Error });
    }
  };

  const getDefaultAssignedTo = () => {
    const state = store.getState();
    const { email } = selectCurrentUserMemberData(state);

    if (defaultValues?.contactId) {
      const contactsMap = selectContactsMap(store.getState());
      return contactsMap[defaultValues?.contactId].assigned_to || email;
    } else {
      return email;
    }
  };

  return (
    <GenericForm
      onSubmit={handleSubmit}
      onFormDataChanged={onFormDataChanged}
      defaultValues={{
        title: defaultValues?.title,
        contactId: defaultValues?.contactId,
        assignedTo: getDefaultAssignedTo(),
        reminder: defaultValues?.reminder?.date > 0 ? new Date(defaultValues.reminder.date) : null,
        dueDate: defaultValues?.dueDate ? new Date(defaultValues?.dueDate) : null,
        description: defaultValues?.description,
        important: defaultValues?.important,
      }}
    />
  );
}
