import { inject, injectable } from 'inversify';
import IOnboardingRepository from '../domain/abstractions/IOnboardingRepository';
import {
  getDataFromLocalStorage,
  removeDataFromLocalStorage,
  setDataToLocalStorage,
} from 'src/utils';

import { TYPES } from 'src/features2/workspaces/di/types';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
@injectable()
export default class OnboardingRepository implements IOnboardingRepository {
  @inject(TYPES.WorkspacesStateRepository) private workspacesRepository: IWorkspacesStateRepository;

  getOnboardingInfoTemporary() {
    const getOnboardingInfoTemporary = getDataFromLocalStorage('onboarding_info_temporary');
    return getOnboardingInfoTemporary;
  }
  setOnboardingInfoTemporary(data) {
    setDataToLocalStorage('onboarding_info_temporary', data);
  }
  removeOnboardingInfoTemporary() {
    removeDataFromLocalStorage('onboarding_info_temporary');
  }

  updateOnboardingInfo(data) {
    this.workspacesRepository.updateWorkspaceFromOnboarding(data);
  }
}
