import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import Select from 'components/UI/Inputs/Select';
import InputBase from 'components/UI/Inputs/InputBase';
import { DISCOUNT_TYPES } from 'src/db/entities/proposal/ProposalEntity';
import getSymbolFromCurrency from 'currency-symbol-map';

import styles from './discountSelect.module.scss';

const DiscountSelect = ({ control, currency, amountError }) => {
  const options = [
    {
      value: DISCOUNT_TYPES.VALUE,
      text: getSymbolFromCurrency(currency) || currency,
    },
    {
      value: DISCOUNT_TYPES.PERCENT,
      text: '%',
    },
  ];

  return (
    <div className={styles.wrapper}>
      {amountError && <div className={styles.errorMessage}>{amountError}</div>}
      <div className={clsx(styles.discountSelect, amountError && styles.selectErrorState)}>
        <Controller
          name="discountType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              className={styles.discountTypeSelect}
              options={options}
              fullWidth
              defaultValue={DISCOUNT_TYPES.VALUE}
            />
          )}
        />
        <Controller
          name="discountAmount"
          control={control}
          render={({ field, fieldState }) => (
            <InputBase
              {...field}
              className={styles.discountValueInput}
              type="number"
              defaultValue={0}
              error={!!fieldState.error}
              placeholder="Value"
              fullWidth
            />
          )}
        />
      </div>
    </div>
  );
};

export default DiscountSelect;
