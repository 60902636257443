import { TGetContactImage } from './types';
import Contact from '../../../types/contact';
import initialsBackgroundPalette from '../../utils/constants/initialsBackgroundPalette';

const getContactImage: TGetContactImage = (contact: Contact) => {
  if (contact) {
    if (contact.thumbnail) {
      return contact.thumbnail;
    } else if (contact.callers_info) {
      let contactImage = null;

      for (const [, value] of Object.entries(contact.callers_info)) {
        if (value.thumbnail) {
          contactImage = value.thumbnail;
        }
      }

      return contactImage;
    }
  }

  return null;
};

const getContactNameForEdit = (contact: Contact) =>
  (
    contact.suggestion_name ??
    contact.name ??
    contact?.emails?.[0]?.email ??
    contact?.phones?.[0]?.phone
  ).trim();

const getContactName = (contact: Contact) =>
  (
    contact.suggestion_name ||
    contact.name ||
    contact?.emails?.[0]?.email ||
    contact?.phones?.[0]?.phone
  ).trim();

const getContactNameWithFormattedPhoneNumber = (
  contact: Contact,
  formatFunc: (phoneNumber: string, normalizedPhoneNumber: string) => string
) => {
  return (
    contact.suggestion_name ||
    contact.name ||
    contact?.emails?.[0]?.email ||
    formatFunc(contact?.phones?.[0]?.phone, contact?.phones?.[0]?.normalized_phone)
  );
};

const getContactBackgroundColor = (contactName = '') => {
  const hashCode = (() => {
    let hash = 0,
      i,
      chr;

    if (!contactName || contactName?.length === 0) return hash;

    for (i = 0; i < contactName.length; i++) {
      chr = contactName.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }

    return hash;
  })();
  const index = Math.abs(hashCode || 0) % initialsBackgroundPalette.length;

  return initialsBackgroundPalette[index];
};

export default {
  getContactImage,
  getContactName,
  getContactNameForEdit,
  getContactBackgroundColor,
  getContactNameWithFormattedPhoneNumber,
};
