import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import Overlay from '../Overlay/Overlay';
import Button from 'components/UI/Buttons/BaseButton';

import { appRoutes } from 'src/utils/constants';
import { CONTACT_SUPPORT_EXTERNAL_URL } from 'src/utils/constants/links';
import { useCurrentSubscription } from '../../hooks';

const PaymentFailedOverlay = ({ currentUser }) => {
  const { t } = useTranslation('billing', { keyPrefix: 'overlays' });
  const { subscription } = useCurrentSubscription();
  const accType = subscription.paidMembersCount > 1 ? 'collaborative' : 'individual';

  return (
    <Overlay>
      <h2>{t('paymentFailed.title')}</h2>
      {currentUser.isAdmin ? (
        <>
          <p>{t('paymentFailed.subtitle')}</p>

          <Link href={appRoutes.SUBSCRIPTION_PAGE}>
            <Button>{t('paymentFailed.updatePaymentMethodBtn')}</Button>
          </Link>
        </>
      ) : (
        <>
          <p>{t('paymentFailed.subtitleForMember')}</p>

          <Button
            target="_blank"
            href={`${CONTACT_SUPPORT_EXTERNAL_URL}?acc_type=${accType}&country=${currentUser.settings.country}&status=${subscription.plan}&fullName=${currentUser.fullName}`}
          >
            {t('common.contactSupportBtn')}
          </Button>
        </>
      )}
    </Overlay>
  );
};

export default PaymentFailedOverlay;
