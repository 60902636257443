import React from 'react';

import styles from './styles.module.scss';
import FilePreviewIcon from 'public/images/preview-error-icon.svg';
import DownloadIcon from 'icons/download-rect.svg';
import FileBC from '../../../../../domain/FileBC';
import useFilePreviewProblemViewModel from './useFilePreviewProblemViewModel';

interface IFilePreviewProblemProps {
  file: FileBC;
}

const FilePreviewProblem: React.FC<IFilePreviewProblemProps> = ({ file }) => {
  const { t, onDownloadFile } = useFilePreviewProblemViewModel(file);

  return (
    <div className={styles.Wrapper}>
      <FilePreviewIcon />
      <div className={styles.Message}>{t('preview_error_text')}</div>
      <button className={styles.Button} onClick={onDownloadFile}>
        <DownloadIcon />
        <span className={styles.ButtonText}>{t('file_action_download')}</span>
      </button>
    </div>
  );
};

export default FilePreviewProblem;
