export const getRelatedPathWithParams = (relatedPath, params) => {
  const PARAMS_START_OPERATOR = '?';
  const PARAMS_SEPARATOR = '&';
  let path = relatedPath + PARAMS_START_OPERATOR;

  for (const [key, value] of Object.entries(params)) {
    const isFirstParam = path[path.length - 1] === PARAMS_START_OPERATOR;

    path = `${path}${isFirstParam ? '' : PARAMS_SEPARATOR}${key}=${value}`;
  }

  return path;
};
