import { createContext, Dispatch, SetStateAction } from 'react';

import { UploadingQueueItem } from '../../data/FileUploadStateRepository.types';
import FileBC from '../FileBC';
import FileUploadingStatus from '../enum/FileUploadingStatus';

export interface IFilesContext {
  uploadingQueue: UploadingQueueItem[];
  addItemToQueue: (files: File[]) => void;
  setPreviewFile: Dispatch<SetStateAction<FileBC>>;
  removeItemFromQueue: (fileId: UploadingQueueItem['id']) => void;
  setCommonFilesList: (list: FileBC[]) => void;
  commonFilesList: FileBC[];
  setThumbnailsMap: (map: Map<string, string>) => void;
  thumbnailsMap: Map<string, string>;
  setUploadingStatusMap: (map: { [key: string]: FileUploadingStatus }) => void;
  uploadingStatusMap: { [key: string]: FileUploadingStatus };
  uploadingDialogTitle: string;
}

const FilesContext = createContext<IFilesContext>({
  uploadingQueue: [],
  addItemToQueue: undefined,
  setPreviewFile: undefined,
  removeItemFromQueue: undefined,
  setCommonFilesList: undefined,
  commonFilesList: [],
  setThumbnailsMap: undefined,
  thumbnailsMap: undefined,
  setUploadingStatusMap: undefined,
  uploadingStatusMap: undefined,
  uploadingDialogTitle: undefined,
});

export default FilesContext;
