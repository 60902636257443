import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';
import DollarSymbol from 'icons/dollar.svg';
import { getHighlightedOverlaps } from 'src/utils/searchUtils';
import Avatar from 'components/UI/Avatar';
import { useAppSelector } from 'src/app/hooks';
import { selectProposalSettings } from 'slices/proposalsSlice';
import SearchHistoryItemTypes from 'src/enums/SearchHistoryItemTypes';
import { selectNotHiddenContacts } from 'slices/contactsSlice';
import { addHistoryItem } from 'slices/searchSlice';
import { forwardToContactsPage } from 'src/services/router/appRouterService';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { calcTotal } from 'src/slices/proposalsSlice';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

interface IProposalsListProps {
  data: ProposalEntity[];
  searchValue: string;
  closeSearchModal: () => void;
}

const ProposalsList: React.FC<IProposalsListProps> = ({ data, searchValue, closeSearchModal }) => {
  const { t } = useTranslation('search');

  if (!data?.length) return null;

  return (
    <div className={styles.Wrapper}>
      <div>{t('title_proposals')}</div>
      <div className={styles.List}>
        {data.map((proposal) => (
          <ProposalSearchItem
            key={proposal.uuid}
            proposal={proposal}
            searchValue={searchValue}
            closeSearchModal={closeSearchModal}
          />
        ))}
      </div>
      <div className={'leader-search-divider'} />
    </div>
  );
};

interface IProposalSearchItemProps {
  proposal: ProposalEntity;
  searchValue?: string;
  closeSearchModal: () => void;
}

export const ProposalSearchItem: React.FC<IProposalSearchItemProps> = (props) => {
  const { proposal, searchValue, closeSearchModal } = props;
  const { currency } = useAppSelector(selectProposalSettings) || {};
  const dispatch = useDispatch();
  const contacts = useAppSelector(selectNotHiddenContacts);

  if (!proposal) return null;

  const {
    isDraft,
    title,
    createdAt,
    contactId,
    items,
    number,
    uuid: proposalUuid,
    settings,
    discountAmount,
    discountType,
  } = proposal;
  const { name, thumbnail } = contacts?.find(({ uuid }) => uuid === contactId) || {};
  const createdDate = new Date(createdAt);
  const month = createdDate.toLocaleDateString('en', { month: 'long' }).slice(0, 3);
  const year = createdDate.getFullYear();
  const date = createdDate.getDate();
  const dateString = `${month} ${date}, ${year}`;
  const { total } = calcTotal({
    items,
    includesTax: settings.includesTax,
    taxRate: settings.taxRate,
    discountAmount: discountAmount,
    discountType: discountType,
  });

  const onClick = async () => {
    await forwardToContactsPage();
    dispatch(setCurrentPageName('duplicates:Contacts_title'));
    dispatch(
      setRedirectEffect({
        contactId,
        targetType: RedirectEffectTargetType.QUOTE,
        targetId: proposalUuid,
      })
    );
    closeSearchModal();
    dispatch(
      addHistoryItem({
        type: SearchHistoryItemTypes.PROPOSAL,
        id: proposal.uuid,
        proposalData: proposal,
      })
    );
  };

  return (
    <button className={styles.Item} onClick={onClick}>
      <div className={styles.ItemInner}>
        <div>
          <div className={styles.Icon}>
            <DollarSymbol />
          </div>
        </div>
        <div className={styles.Info}>
          <div className={styles.InfoText}>
            <span
              dangerouslySetInnerHTML={{
                __html: `#${getHighlightedOverlaps(String(number), searchValue)}`,
              }}
            />
            <span
              dangerouslySetInnerHTML={{ __html: getHighlightedOverlaps(title, searchValue) }}
            />
          </div>
          <div className={styles.AmountInfo}>
            {isDraft ? (
              <div>draft</div>
            ) : (
              <span>
                Total&nbsp;
                <span>
                  {currency}
                  {total.toFixed(2)}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.ContactInfo}>
        <Avatar className={styles.Avatar} thumbnail={thumbnail} name={name} />
        <span
          dangerouslySetInnerHTML={{
            __html: `${getHighlightedOverlaps(name, searchValue)}&nbsp;•&nbsp;${dateString}`,
          }}
        />
      </div>
    </button>
  );
};

export default ProposalsList;
