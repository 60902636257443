import { trackEvent } from 'src/analytics/amplitude';
import { useAppDispatch, useAppStore } from 'src/app/hooks';
import { patchContactOptimistic, selectContactsMap } from '../../../slices/contactsSlice';
import { selectCurrentUserMemberData } from '../../../slices/workspacesSlice';

// uses after creating/updating task
// for re-assigning related contact to another teammate
// if relatedContact.assigned_contact !== task.assignedTo
export default function useReAssignContact() {
  const dispatch = useAppDispatch();
  const store = useAppStore();

  return async ({
    taskAssignedTo,
    taskRelatedContactId,
  }: {
    taskAssignedTo?: string;
    taskRelatedContactId?: string;
  }) => {
    const { role } = selectCurrentUserMemberData(store.getState());
    trackEvent('Change_assign_to', { type: 'Main Tab' });
    if (!!taskAssignedTo && !!taskRelatedContactId && role !== 'member') {
      const contact = selectContactsMap(store.getState())[taskRelatedContactId];

      if (contact.assigned_to !== taskAssignedTo) {
        await dispatch(
          patchContactOptimistic({
            contactId: taskRelatedContactId,
            data: {
              assigned_to: taskAssignedTo,
              updated_at: Math.floor(Date.now() / 1000),
            },
          })
        );
      }
    }
  };
}
