import { injectable, inject } from 'inversify';
import { ICustomFieldDao } from '../domain/abstractions/ICustomFieldDao';
import { TYPES } from 'src/app/ioc/types';
import { Observable, from, map, switchMap } from 'rxjs';
import { DbManager } from 'src/db/dbManager';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import type { IPersistentRepository } from 'src/services/PersistentRepository';

@injectable()
export default class CustomFieldDao implements ICustomFieldDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;
  @inject(TYPES.PersistentRepository) private localStorageRepository: IPersistentRepository;

  getAll(): Observable<CustomFieldEntity[]> {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db['custom-fields'].find({
            selector: {
              workspaceId: {
                $eq: this.localStorageRepository.getCurrentWorkspaceId(),
              },
            },
            sort: [{ createdAt: 'asc' }],
          }).$;
        })
      )
      .pipe(
        map((docs) => {
          return docs.map((doc) => doc.toMutableJSON() as CustomFieldEntity);
        })
      );
  }

  upsert(
    field: Pick<CustomFieldEntity, 'uuid' | 'name' | 'fieldType' | 'createdAt' | 'updatedAt'>
  ): Observable<CustomFieldEntity> {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db['custom-fields'].upsert({
            workspaceId: this.localStorageRepository.getCurrentWorkspaceId(),
            ...field,
          });
        })
      )
      .pipe(
        map((doc) => {
          return doc.toMutableJSON() as CustomFieldEntity;
        })
      );
  }

  deleteField(uuid: string): Observable<boolean> {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db['custom-fields'].bulkRemove([uuid]);
        })
      )
      .pipe(
        map((doc) => {
          return true;
        })
      );
  }
}
