import { useFormContext } from 'react-hook-form';
import { FormValues } from './useMainSubForm';

import { calcTotal } from 'src/slices/proposalsSlice';

const usePriceCalculation = ({ taxRate }) => {
  const { watch } = useFormContext<FormValues>();

  // price calculation
  const [items, discountType, discountAmount = 0, includesTax] = watch([
    'items',
    'discountType',
    'discountAmount',
    'includesTax',
  ]);

  const { subtotal, total } = calcTotal({
    items,
    includesTax,
    taxRate,
    discountType,
    discountAmount,
  });

  return {
    total,
    subtotal,
  };
};

export default usePriceCalculation;
