import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { DISCOUNT_TYPES } from 'src/db/entities/proposal/ProposalEntity';
import { calcSubtotal } from 'slices/proposalsSlice';

interface ProposalItem {
  description: string;
  qty: number;
  unitPrice: number;
}

export type FormValues = FieldValues & {
  title: string;
  contactId: string;
  client: string;
  items: ProposalItem[];

  currency: string;
  discountType: DISCOUNT_TYPES;
  discountAmount: number;
  comment: string;
  includesTax: boolean;
};

export default function useMainForm(defaultValuesProp: FormValues) {
  const { t } = useTranslation('proposal-form');

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t('common:validation.required')),
        contactId: yup.string().required(t('common:validation.required')),
        client: yup.string().required(t('common:validation.required')),
        items: yup.array().of(
          yup.object().shape({
            // description: yup.string().required(t('common:validation.required')),
            qty: yup
              .string()
              // .positive(t('common:validation.positive'))
              .matches(/^(0|0?[1-9]\d*)\.\d\d$/, {
                message: t('common:validation.max_2_digits_after_comma'),
              })
              .required(t('common:validation.required')),
            unitPrice: yup
              .string()
              .required(t('common:validation.required'))
              .matches(/^(0|0?[1-9]\d*)\.\d\d$/, {
                message: t('common:validation.max_2_digits_after_comma'),
              }),
          })
        ),
        currency: yup.string().required(),
        comment: yup.string(),
        discountType: yup.string().nullable(true),
        discountAmount: yup
          .number()
          .nullable(true)
          .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
          .positive(t('common:validation.positive'))
          // @ts-ignore
          .when(['discountType', 'items'], (discountType, items, schema, dd) => {
            const subtotalPrice = calcSubtotal(items);
            return discountType === DISCOUNT_TYPES.PERCENT
              ? schema
                  .min(0, t('form.totalSection.validation.from', { value: '0%' }))
                  .max(100, t('form.totalSection.validation.to', { value: '100%' }))
              : schema
                  .min(0, t('form.totalSection.validation.from', { value: '0' }))
                  .max(
                    subtotalPrice,
                    t('form.totalSection.validation.to', { value: subtotalPrice })
                  );
          }),
        includesTax: yup.boolean().required(),
      }),
    [t]
  );

  const methods = useForm<FormValues>({
    defaultValues: defaultValuesProp,
    resolver: yupResolver(validationSchema),
  });

  return methods;
}
