import React, { useState, Dispatch, SetStateAction } from 'react';

import FileBC from '../../../../../domain/FileBC';
import styles from './styles.module.scss';
import FILES_MIME_TYPES from '../../../../../domain/enum/FILES_MIME_TYPES';
import FilePreviewImage from './components/FilePreviewImage';
import FilePreviewVideo from './components/FilePreviewVideo';
import FilePreviewAudio from './components/FilePreviewAudio';
import FilePreviewGoogleEmbed from './components/FilePreviewGoogleEmbed';
import FilePreviewText from './components/FilePreviewText';
import FilePreviewHTML from './components/FilePreviewHTML';
import FilePreviewProblem from '../FilePreviewProblem';

interface IFilePreviewBody {
  file: FileBC;
}

export interface IFilePreviewUnitProps {
  file: FileBC;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const FilePreviewBody: React.FC<IFilePreviewBody> = ({ file }) => {
  const [isLoading, setIsLoading] = useState(true);

  const unitProps = {
    file,
    isLoading,
    setIsLoading,
  };

  if (FILES_MIME_TYPES.IMAGE.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewImage {...unitProps} />
      </div>
    );
  }

  if (FILES_MIME_TYPES.VIDEO.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewVideo {...unitProps} />
      </div>
    );
  }

  if (FILES_MIME_TYPES.AUDIO.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewAudio {...unitProps} />
      </div>
    );
  }

  if (FILES_MIME_TYPES.GOOGLE_DOCS_VIEWER.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewGoogleEmbed {...unitProps} />
      </div>
    );
  }

  if (FILES_MIME_TYPES.TEXT.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewText {...unitProps} />
      </div>
    );
  }

  if (FILES_MIME_TYPES.HTML.includes(file.mimeType)) {
    return (
      <div className={styles.Body}>
        <FilePreviewHTML {...unitProps} />
      </div>
    );
  }

  return <FilePreviewProblem file={file} />;
};

export default FilePreviewBody;
