import PopperMUI, { PopperProps } from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  popper: {
    maxWidth: 'fit-content',
  },
});
const Popper = (props: PopperProps) => {
  const classes = useStyles();
  return <PopperMUI className={classes.popper} placement="bottom-start" {...props} />;
};

export default Popper;
