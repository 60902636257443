type Id = string;

class PatchRequestsManager<Entity> {
  private requestsMap: Record<
    Id,
    {
      requestId: string;
      updatedData: Partial<Entity> & { updatedAt: number };
      outdatedEntity: Entity;
      promise: Promise<void>;
      resolver: () => void;
    }[]
  > = {};

  enqueueRequest = ({
    requestId,
    entityId,
    updatedData,
    outdatedEntity,
  }: {
    requestId: string;
    entityId: Id;
    updatedData: Partial<Entity> & { updatedAt: number };
    outdatedEntity: Entity;
  }) => {
    let resolver: () => void;
    const promise = new Promise<void>((resolve) => {
      resolver = resolve;

      return;
    });

    const data = {
      requestId,
      updatedData: updatedData,
      outdatedEntity,
      promise,
      resolver,
    };

    if (Array.isArray(this.requestsMap[entityId])) {
      this.requestsMap[entityId].push(data);
    } else {
      this.requestsMap[entityId] = [data];
    }
  };

  dequeueRequest = ({ entityId, requestId }: { entityId: Id; requestId: string }) => {
    const request = this.getRequestById({ entityId, requestId });

    const index = this.requestsMap[entityId].indexOf(request);

    const [deleted] = this.requestsMap[entityId].splice(index, 1);

    deleted.resolver();
  };

  clearAllRequestsForEntity = (entityId: Id) => {
    this.requestsMap[entityId] = [];
  };

  getRequestsByEntityId = (entityId: string) => {
    const requests = this.requestsMap[entityId];

    if (!Array.isArray(requests)) {
      throw new Error(`There are no requests related to the entity with id ${entityId}`);
    }

    return requests;
  };

  getRequestById = ({ entityId, requestId }: { entityId: string; requestId: string }) => {
    const request = this.getRequestsByEntityId(entityId).find(
      (requestItem) => requestItem.requestId === requestId
    );

    return request;
  };

  wait = async ({ entityId, requestId }: { entityId: string; requestId: string }) => {
    const requests = this.getRequestsByEntityId(entityId);

    const currentRequest = this.getRequestById({ entityId, requestId });
    const indexOfCurrRequest = requests.indexOf(currentRequest);

    if (indexOfCurrRequest === 0) {
      return Promise.resolve();
    } else {
      const prevRequests = requests.slice(0, indexOfCurrRequest);
      const promises = prevRequests.map(({ promise }) => promise);

      return Promise.allSettled(promises);
    }
  };
}

export default PatchRequestsManager;
