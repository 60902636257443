import {
  createRxDatabase,
  RxDatabase,
  RxCollection,
  RxDocument,
  RxJsonSchema,
  addRxPlugin,
  MigrationStrategies,
} from 'rxdb';
import Dexie from 'dexie';

import { getRxStorageDexie } from 'rxdb/plugins/dexie';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';

import { inject, injectable } from 'inversify';

import ContactEntity from './entities/contact/ContactEntity';
import contactSchema from './entities/contact/contactSchema';

import TaskEntity from './entities/task/TaskEntity';
import taskSchema from './entities/task/taskSchema';

import CallEntity from './entities/call/CallEntity';
import callSchema from './entities/call/callSchema';

import NoteEntity from './entities/note/NoteEntity';
import noteSchema from './entities/note/noteSchema';

import NotificationEntity from './entities/notification/NotificationEntity';
import notificationSchema from './entities/notification/notificationSchema';

import WorkspaceEntity from './entities/workspace/WorkspaceEntity';
import workspaceSchema from './entities/workspace/workspaceSchema';

import ProposalSettingsEntity from './entities/proposalSettings/ProposalSettingsEntity';
import proposalSettingsSchema from './entities/proposalSettings/proposalSettingsSchema';

import ProposalEntity from './entities/proposal/ProposalEntity';
import proposalSchema from './entities/proposal/proposalSchema';

import BoardColumnEntity from './entities/boardColumn/BoardColumnEntity';
import boardColumnSchema from './entities/boardColumn/boardColumnSchema';

import BoardItemEntity from './entities/boardItem/BoardItemEntity';
import boardItemSchema from './entities/boardItem/boardItemSchema';

import TagEntity from './entities/tag/TagEntity';
import tagSchema from './entities/tag/tagSchema';

import TagLinkEntity from './entities/tagLink/TagLinkEntity';
import tagLinkSchema from './entities/tagLink/tagLinkSchema';

import ProfileEntity from './entities/profile/ProfileEntity';
import profileSchema from './entities/profile/profileSchema';

import CustomFieldEntity from './entities/customField/CustomFieldEntity';
import customFieldSchema from './entities/customField/customFieldSchema';

import CustomFieldValueEntity from './entities/customFieldValue/CustomVieldValueEntity';
import customFieldValueSchema from './entities/customFieldValue/customFieldValueSchema';

import { dbName } from './constants';
import fileSchema from './entities/file/fileSchema';
import FileEntity from './entities/file/FileEntity';
import { TYPES } from '../app/ioc/types';
import { IPersistentRepository } from '../services/PersistentRepository';
import { forwardToLoginPage } from '../services/router/appRouterService';
import { dbWipeStatusHandlers } from './utils';
import dbHooks from './hooks';

addRxPlugin(RxDBMigrationPlugin);

// client only code
if (typeof window !== 'undefined') {
  // TODO: check if we have NODE_ENV === 'production' on server .env file, after than uncomment code
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  if (process.env.NODE_ENV === 'development') {
    addRxPlugin(RxDBDevModePlugin);
  }
}

type ContactDocument = RxDocument<ContactEntity>;
type ContactsCollection = RxCollection<ContactDocument>;

type TaskDocument = RxDocument<TaskEntity>;
type TaskCollection = RxCollection<TaskDocument>;

type CallDocument = RxDocument<CallEntity>;
type CallCollection = RxCollection<CallDocument>;

type NoteDocument = RxDocument<NoteEntity>;
type NoteCollection = RxCollection<NoteDocument>;

type NotificationDocument = RxDocument<NotificationEntity>;
type NotificationCollection = RxCollection<NotificationDocument>;

type WorkspaceDocument = RxDocument<WorkspaceEntity>;
type WorkspaceCollection = RxCollection<WorkspaceDocument>;

type ProposalSettingsDocument = RxDocument<ProposalSettingsEntity>;
type ProposalSettingsCollection = RxCollection<ProposalSettingsDocument>;

type ProposalDocument = RxDocument<ProposalEntity>;
type ProposalCollection = RxCollection<ProposalDocument>;

type BoardColumnDocument = RxDocument<BoardColumnEntity>;
type BoardColumnCollection = RxCollection<BoardColumnDocument>;

type BoardItemDocument = RxDocument<BoardItemEntity>;
type BoardItemCollection = RxCollection<BoardItemDocument>;

type TagDocument = RxDocument<TagEntity>;
type TagCollection = RxCollection<TagDocument>;

type TagLinkDocument = RxDocument<TagLinkEntity>;
type TagLinkCollection = RxCollection<TagLinkDocument>;

type ProfileDocument = RxDocument<ProfileEntity>;
type ProfileCollection = RxCollection<ProfileDocument>;

type FileDocument = RxDocument<FileEntity>;
type FileCollection = RxCollection<FileDocument>;

type CustomFieldDocument = RxDocument<CustomFieldEntity>;
type CustomFieldCollection = RxCollection<CustomFieldDocument>;

type CustomFieldValueDocument = RxDocument<CustomFieldValueEntity>;
type CustomFieldValueCollection = RxCollection<CustomFieldValueDocument>;

type DatabaseCollections = {
  ['contacts']: ContactsCollection;
  ['tasks']: TaskCollection;
  ['calls']: CallCollection;
  ['notes']: NoteCollection;
  ['notifications']: NotificationCollection;
  ['workspaces']: WorkspaceCollection;
  ['proposal-settings']: ProposalSettingsCollection;
  ['proposals']: ProposalCollection;
  ['board-columns']: BoardColumnCollection;
  ['board-items']: BoardItemCollection;
  ['tags']: TagCollection;
  ['tag-links']: TagLinkCollection;
  ['profile']: ProfileCollection;
  ['files']: FileCollection;
  ['custom-fields']: CustomFieldCollection;
  ['custom-field-values']: CustomFieldValueCollection;
};

export type Database = RxDatabase<DatabaseCollections>;

@injectable()
export class DbManager {
  @inject(TYPES.PersistentRepository) private persistentRepository: IPersistentRepository;

  public db: Database | null = null;
  private creationPromise: Promise<Database>;

  constructor() {
    this.creationPromise = new Promise((resolve) => {
      this.prepareDb().then((db) => {
        // extra check for case when migration needed, our DB will not be initialized, to avoid unexpected errors.
        // location.reload() doesn't stop js execution (check initMigrationStrategy function)
        if (db) {
          this.creationPromise = null;
          resolve(db);
        }
      });
    });
  }

  getDb = async (): Promise<Database> => {
    if (this.creationPromise) {
      return this.creationPromise;
    } else {
      return this.db;
    }
  };

  // migration mechanism by removing exiting collections, cleaning them and fill with data
  initMigrationStrategy = async () => {
    this.persistentRepository.clear();
    // workaround: we didn't use wipeData(), because collection isn't initialized (we clean up by collation, not entire db)
    // @ts-expect-error: TS2339: Property 'databases' does not exist on type 'IDBFactory'.
    const dbs = await (window.indexedDB?.databases?.() || Dexie.getDatabaseNames()); // mozilla doesn't support IDBFactory databases method()
    const dbsNames = dbs.map((db) => db.name || db); // cause dexie return array of strings and databases() return array of objects
    await Promise.allSettled(dbsNames.map((db) => window.indexedDB.deleteDatabase(db)));
    window.location.reload(); // fast workaround to forward login page, reset state, and reinit db
  };

  prepareDb = async () => {
    // client only code
    if (typeof window !== 'undefined') {
      const db = await createRxDatabase<Database>({
        name: dbName,
        storage: getRxStorageDexie(),
        ignoreDuplicate: true,
      });

      try {
        await this.addCollections(db);
        this.addHooks(db);
      } catch (e) {
        if (e.code === 'DB6') {
          await this.initMigrationStrategy();
          return;
        } else {
          // TODO: send event to sentry to monitor users migration statuses
          throw e;
        }
      }

      this.db = db;

      // clean db if user logged out and db wipe isn't finished after previous logout
      if (dbWipeStatusHandlers.isInProgress()) {
        await this.wipeData();
      }

      return this.db;
    }
  };

  addCollections = async (db: Database) => {
    if (db === null) {
      throw new Error('Database is not inited!');
    }

    const migrationStrategies: MigrationStrategies = {
      1: () => {
        this.persistentRepository.clear();
        this.wipeData();
        forwardToLoginPage();
        return null;
      },
    };

    await db.addCollections({
      calls: {
        schema: callSchema as RxJsonSchema<CallEntity>,
        migrationStrategies,
      },
      notes: {
        schema: noteSchema as RxJsonSchema<NoteEntity>,
        migrationStrategies,
      },
      profile: {
        schema: profileSchema as RxJsonSchema<ProfileEntity>,
        migrationStrategies,
      },
      workspaces: {
        schema: workspaceSchema as RxJsonSchema<WorkspaceEntity>,
        migrationStrategies,
      },
      notifications: {
        schema: notificationSchema as RxJsonSchema<NotificationEntity>,
        migrationStrategies,
      },
      contacts: {
        schema: contactSchema as RxJsonSchema<ContactEntity>,
        migrationStrategies,
      },
      tasks: {
        schema: taskSchema as RxJsonSchema<TaskEntity>,
        migrationStrategies,
      },
      'proposal-settings': {
        schema: proposalSettingsSchema as RxJsonSchema<ProposalSettingsEntity>,
        migrationStrategies,
      },
      proposals: {
        schema: proposalSchema as RxJsonSchema<ProposalEntity>,
        migrationStrategies,
      },
      'board-columns': {
        schema: boardColumnSchema as RxJsonSchema<BoardColumnEntity>,
        migrationStrategies,
      },
      'board-items': {
        schema: boardItemSchema as RxJsonSchema<BoardItemEntity>,
        migrationStrategies,
      },
      tags: {
        schema: tagSchema as RxJsonSchema<TagEntity>,
        migrationStrategies,
      },
      'tag-links': {
        schema: tagLinkSchema as RxJsonSchema<TagLinkEntity>,
        migrationStrategies,
      },
      files: {
        schema: fileSchema as RxJsonSchema<FileEntity>,
        migrationStrategies,
      },
      'custom-fields': {
        schema: customFieldSchema as RxJsonSchema<CustomFieldEntity>,
        migrationStrategies,
      },
      'custom-field-values': {
        schema: customFieldValueSchema as RxJsonSchema<CustomFieldValueEntity>,
        migrationStrategies,
      },
    });
  };

  addHooks(db: Database) {
    const { workspaces } = db.collections;

    workspaces.preRemove(dbHooks.workspacesPreRemove, false);
  }

  clearCollection = async (name: string) => {
    return this.db.collections[name].remove();
  };

  wipeData = async () => {
    if (this.db) {
      dbWipeStatusHandlers.startDbWipe();
      const promises = Object.keys(this.db.collections).map((collectionName) =>
        this.clearCollection(collectionName)
      );
      await Promise.allSettled(promises);
      await this.addCollections(this.db);
      dbWipeStatusHandlers.finishDbWipe();
    }

    // TODO try to remove all collection in one transaction, check db.remove() method
    // await this.db.remove();
  };
}
