import { useInjection } from 'src/app/ioc/ioc.react';

import { IProfileUseCases } from '../domain/ProfileUseCases';
import { TYPES } from '../di/types';
import { useObservable, useObservableState } from '../../../app/hooks/rx';

export const useProfile = () => {
  const useCases = useInjection<IProfileUseCases>(TYPES.ProfileUseCases);
  const profile = useObservableState(
    useObservable(() => useCases.getProfile(), []),
    null
  );
  return {
    profile,
  };
};
