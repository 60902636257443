import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import styles from './DateTimePicker.module.css';
import DateTimePicker from '../../../../../components/UI/Inputs/DatePicker/DateTimePicker';
import Button from '../../../../../components/UI/Buttons/BaseButton';

const DATEPICKER_TIME_LABEL_CLASSNAME = 'react-datepicker-time__header';

interface IProps {
  open: boolean;
  anchorEl: PopperProps['anchorEl'];
  onSave: (value: Date) => void;
  onClose: () => void;
}

const DateTimeSelect: React.FC<IProps> = ({ open, anchorEl, onSave, onClose }) => {
  const { t } = useTranslation('tasks-page');
  const [value, setValue] = useState<Date | undefined>(undefined);
  const datepickerWrapperRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    let timeout;

    if (open) {
      // RS: setTimeout is used to force find element, doesn't work without it.
      timeout = setTimeout(() => {
        const target = datepickerWrapperRef.current.getElementsByClassName(
          DATEPICKER_TIME_LABEL_CLASSNAME
        );

        if (target.length) {
          target[0].innerHTML = t('common:time');
        }
      }, 0);
    }

    return () => clearTimeout(timeout);
  }, [open]);

  useEffect(() => {
    if (!open) {
      setValue(undefined);
    }
  }, [open]);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement={'top-start'}
      className={styles.popper}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <ClickAwayListener onClickAway={onClose}>
            <Paper>
              <div className={styles.pickerWrapper} ref={datepickerWrapperRef}>
                <DateTimePicker value={value} onChange={(nextDate) => setValue(nextDate)} />
                <div>
                  <Button
                    textSize="small"
                    color="primary"
                    className={styles.saveButton}
                    onClick={() => onSave(value)}
                    disabled={!value}
                  >
                    {t('common:action.save')}
                  </Button>
                  <Button variant="text" textSize="small" color="secondary" onClick={onClose}>
                    {t('duplicates:Cancel_action')}
                  </Button>
                </div>
              </div>
            </Paper>
          </ClickAwayListener>
        </Grow>
      )}
    </Popper>
  );
};

export default DateTimeSelect;
