import appRoutes from './appRoutes';
import authProviders from './authProviders';
import endpoints from './endpoints';
import itemColors from './itemColors';
import regexpPatterns from './regexpPatterns';
import allowedFilesTypes from './allowedFilesTypes';
import imageVideoMimeTypes from './imageVideoMimeTypes';

const VERSION = process.env.NEXT_PUBLIC_APP_VERSION;
const LOCAL_STORAGE_VARIABLES = {
  redirects_counter: 'redirects_to_calls_page_counter',
};
export {
  authProviders,
  endpoints,
  appRoutes,
  itemColors,
  regexpPatterns,
  LOCAL_STORAGE_VARIABLES,
  VERSION,
  allowedFilesTypes,
  imageVideoMimeTypes,
};
