import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { AppState } from 'src/app/store/store';
import TaskEntity from 'src/db/entities/task/TaskEntity';
import {
  patchNotificationAPI,
  NotificationModel,
  NotificationDataModel,
  deleteNotificationAPI,
} from '../api/notificationsAPI';

type NotificationState = {
  notifications: NotificationModel[];
};
export const deleteNotifications = createAsyncThunk(
  'notifications/deleteNotifications',
  async (notificationIds: NotificationModel['id'][], thunkAPI) => {
    const state = thunkAPI.getState() as AppState;
    if (notificationIds.length) {
      await deleteNotificationAPI(notificationIds, state.workspaces.current);
    } else {
      await deleteNotificationAPI([]);
      await deleteNotificationAPI([], state.workspaces.current);
    }
    return true;
  }
);
export const patchNotification = createAsyncThunk(
  'notifications/patchNotification',
  async ({
    notificationId,
    data,
    workspaceId,
  }: {
    notificationId: string;
    data: Partial<NotificationModel>;
    workspaceId: NotificationDataModel['workspaceId'];
  }) => {
    await patchNotificationAPI({ id: notificationId, data, workspaceId });
    return { id: notificationId, data };
  }
);
type InitialState = { notifications: NotificationModel[] };
const sliceName = 'notifications';
const notificationSlice = createSlice({
  name: sliceName,
  initialState: { notifications: [] },
  reducers: {
    setNotifications(state, action: PayloadAction<NotificationModel[]>) {
      state.notifications = action.payload;
    },
    deleteRelatedNotifications(
      state: NotificationState,
      action: PayloadAction<{ type: 'price-proposal' | 'task' | 'contact'; items: any[] }>
    ) {
      const stateNotificationsMap = state.notifications.reduce((acc, notification) => {
        acc[notification.id] = notification;
        return acc;
      }, {});
      const separatedByTypeNotifications = state.notifications.filter(
        ({ data: { itemType } }) => itemType === action.payload.type
      );
      action.payload.items.forEach(({ uuid }) => {
        const stateNotificationsMapByDataItemId = separatedByTypeNotifications.reduce(
          (acc, notification) => {
            acc[notification.data.itemId] = notification;
            return acc;
          },
          {}
        );
        stateNotificationsMapByDataItemId[uuid] &&
          delete stateNotificationsMap[stateNotificationsMapByDataItemId[uuid].id];
      });
      state.notifications = Object.values(stateNotificationsMap);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patchNotification.fulfilled, (state: InitialState, action) => {
      const notificationIndex = state.notifications.findIndex(({ id }) => id === action.payload.id);
      state.notifications[notificationIndex] = {
        ...state.notifications[notificationIndex],
        ...action.payload.data,
      };
    });
    builder.addCase(deleteNotifications.fulfilled, (state: InitialState) => {
      state.notifications = [];
    });
  },
});

const { actions, reducer } = notificationSlice;
export const { deleteRelatedNotifications, setNotifications } = actions;

const aggregateNotifications = (arr: NotificationModel[]): NotificationModel[] => {
  const taskRemindersMap: Record<TaskEntity['id'], NotificationModel> = {};

  return arr.reduce((acc: NotificationModel[], notificationItem) => {
    if (notificationItem.type === 'task_reminder') {
      if (taskRemindersMap[notificationItem.data.itemId]) {
        const existedNotification = taskRemindersMap[notificationItem.data.itemId];

        if (notificationItem.createdAt > existedNotification.createdAt) {
          taskRemindersMap[notificationItem.data.itemId] = notificationItem;

          const index = acc.findIndex(({ id }) => id === existedNotification.id);
          acc.splice(index, 1, notificationItem);
        }
      } else {
        taskRemindersMap[notificationItem.data.itemId] = notificationItem;
        acc.push(notificationItem);
      }
    } else {
      acc.push(notificationItem);
    }

    return acc;
  }, []);
};

export const selectNotifications = createSelector(
  (state: AppState) => state.notifications.notifications,
  (state: NotificationModel[]) => {
    return aggregateNotifications(state.slice().sort((a, b) => b.createdAt - a.createdAt));
  }
);
export default reducer;
