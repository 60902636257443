import { Container } from 'inversify';
import { ICustomFieldValueApiService } from '../domain/abstractions/ICustomFieldValueApiService';
import { CUSTOM_FIELD_VALUE_TYPES } from './types';
import CustomFieldValueApiService from '../data/network/CustomFieldValueApiService';
import { ICustomFieldValueRepository } from '../domain/abstractions/ICustomFieldValueRepository';
import CustomFieldValueRepository from '../data/CustomFieldValueRepository';
import { ICustomFieldValueDao } from '../domain/abstractions/ICustomFieldValueDao';
import CustomFieldValueDao from '../data/CustomFieldValueDao';
import { ICustomFieldValueUseCase } from '../domain/abstractions/ICustomFieldValueUseCase';
import CustomFieldValueUseCase from '../domain/CustomFieldValueUseCase';

export const bindCustomFieldValueModule = (container: Container) => {
  container
    .bind<ICustomFieldValueApiService>(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueApiService)
    .to(CustomFieldValueApiService);
  container
    .bind<ICustomFieldValueRepository>(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueRepository)
    .to(CustomFieldValueRepository);
  container
    .bind<ICustomFieldValueDao>(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueDao)
    .to(CustomFieldValueDao);
  container
    .bind<ICustomFieldValueUseCase>(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueUseCase)
    .to(CustomFieldValueUseCase);
};
