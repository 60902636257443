import { forwardRef } from 'react';
import MUIInputBase, { InputBaseProps as MUIInputBaseProps } from '@material-ui/core/InputBase';
import clsx from 'clsx';
import styles from '../../../styles/components/inputs/InputBase.module.css';

const InputBase = forwardRef(function InputBase(props: MUIInputBaseProps, ref) {
  return (
    <MUIInputBase
      {...props}
      ref={ref}
      className={clsx(props.className, styles.InputBase)}
      inputProps={{ ...props.inputProps, 'data-hj-allow': '' }}
    />
  );
});

export default InputBase;
