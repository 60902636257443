import { injectable, inject } from 'inversify';
import { Observable, from, switchMap, map } from 'rxjs';
import { TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';

import WorkspaceEntity from '../../../db/entities/workspace/WorkspaceEntity';
import Workspace from '../domain/entities/Workspace';

export interface IWorkspacesDao {
  getWorkspaces(): Observable<Workspace[]>;
  upsertWorkspace(workspace: Workspace): Observable<Workspace>;
  upsertWorkspaces(workspaces: Workspace[]): Observable<Workspace[]>;
}

@injectable()
export default class WorkspacesDao implements IWorkspacesDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;

  getWorkspaces = () => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.workspaces.find().$;
      }),
      map((docs) => docs.map((doc) => new Workspace(doc.toMutableJSON() as WorkspaceEntity)))
    );
  };

  upsertWorkspace = (workspace: Workspace) => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.workspaces.upsert(workspace);
      }),
      map((document) => new Workspace(document.toMutableJSON() as WorkspaceEntity))
    );
  };
  upsertWorkspaces = (workspaces: Workspace[]) => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.workspaces.bulkUpsert(workspaces);
      }),
      map((documents) =>
        documents.map((doc) => new Workspace(doc.toMutableJSON() as WorkspaceEntity))
      )
    );
  };
}
