import ProfileDC from './ProfileDC';
import ProfileEntity from './ProfileEntity';
import { removeUndefinedKeysFromObj } from 'src/utils/helpers';

export const mapProfileDCToEntity = (dc: ProfileDC): ProfileEntity => {
  return {
    id: dc.id,
    email: dc.email,
    fullName: dc.full_name,
    blocked: dc.blocked,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    picture: dc.picture,
    hasFeed: dc.has_feed,
    hasInvitedTeammates: dc.has_invited_teammates,
    settings: {
      skipInviteTeammates: dc.settings.skip_invite_teammates,
      incomingCallAvailable: dc.settings.incoming_call_available,
      afterCallAvailable: dc.settings.after_call_available,
      assistantAvailable: dc.settings.assistant_available,
      businessCardAvailable: dc.settings.business_card_available,
      lang: dc.settings.language,
      country: dc.settings.country,
      assistantAvailableCategory: dc.settings.assistant_available_category,
      assistantDisableCaller: dc.settings.assistant_disable_caller,
      afterFirstCallAnsweredEnabled: dc.settings.after_first_call_answered_enabled,
      afterFirstCallAnsweredLinkOption: dc.settings.after_first_call_answered_link_option,
      afterFirstCallMissedEnabled: dc.settings.after_first_call_missed_enabled,
      afterFirstCallMissedMessageText: dc.settings.after_first_call_missed_message_text,
      afterFirstCallMissedLinkOption: dc.settings.after_first_call_missed_link_option,
      afterMissedCallMessage: dc.settings.after_missed_call_message,
      afterFirstCallMessage: dc.settings.after_first_call_message,
      lastProposalNumber: dc.settings.last_proposal_number,
      accountForExport: dc.settings.account_for_export,
    },
    onboardingInfo: !dc?.onboarding_info
      ? null
      : {
          companyName: dc.onboarding_info.company_name,
          industry: dc.onboarding_info.industry,
          stuffCount: dc.onboarding_info.stuff_count,
          country: dc.onboarding_info.country,
          jobField: dc.onboarding_info.job_field,
          jobRole: dc.onboarding_info.job_role,
          lang: dc.onboarding_info.language,
          userExperience: dc.onboarding_info.user_experience,
          skipInviteTeammates: dc.onboarding_info.skip_invite_teammates,
        },
    platforms: dc.platforms,
    personalWorkspaceId: dc.personal_workspace_id,
  };
};

export const mapProfileEntitySettingToDCSettings = (
  settings: Partial<ProfileEntity['settings']>
) => {
  return removeUndefinedKeysFromObj({
    skip_invite_teammates: settings?.skipInviteTeammates,
    incoming_call_available: settings?.incomingCallAvailable,
    after_call_available: settings?.afterCallAvailable,
    assistant_available: settings?.assistantAvailable,
    business_card_available: settings?.businessCardAvailable,
    language: settings?.lang,
    country: settings?.country,
    assistant_available_category: settings?.assistantAvailableCategory,
    assistant_disable_caller: settings?.assistantDisableCaller,
    after_first_call_answered_enabled: settings?.afterFirstCallAnsweredEnabled,
    after_first_call_answered_link_option: settings?.afterFirstCallAnsweredLinkOption,
    after_first_call_missed_enabled: settings?.afterFirstCallMissedEnabled,
    after_first_call_missed_message_text: settings?.afterFirstCallMissedMessageText,
    after_first_call_missed_link_option: settings?.afterFirstCallMissedLinkOption,
    after_missed_call_message: settings?.afterMissedCallMessage,
    after_first_call_message: settings?.afterFirstCallMessage,
    last_proposal_number: settings?.lastProposalNumber,
    account_for_export: settings?.accountForExport,
  });
};
export const mapProfileEntityToDC = (entity: ProfileEntity): ProfileDC => {
  return {
    id: entity.id,
    email: entity.email,
    full_name: entity.fullName,
    blocked: entity.blocked,
    created_at: entity.createdAt,
    updated_at: entity.updatedAt,
    picture: entity.picture,
    has_feed: entity.hasFeed,
    has_invited_teammates: entity.hasInvitedTeammates,
    settings: {
      skip_invite_teammates: entity.settings.skipInviteTeammates,
      incoming_call_available: entity.settings.incomingCallAvailable,
      after_call_available: entity.settings.afterCallAvailable,
      assistant_available: entity.settings.assistantAvailable,
      business_card_available: entity.settings.businessCardAvailable,
      language: entity.settings.lang,
      country: entity.settings.country,
      assistant_available_category: entity.settings.assistantAvailableCategory,
      assistant_disable_caller: entity.settings.assistantDisableCaller,
      after_first_call_answered_enabled: entity.settings.afterFirstCallAnsweredEnabled,
      after_first_call_answered_link_option: entity.settings.afterFirstCallAnsweredLinkOption,
      after_first_call_missed_enabled: entity.settings.afterFirstCallMissedEnabled,
      after_first_call_missed_message_text: entity.settings.afterFirstCallMissedMessageText,
      after_first_call_missed_link_option: entity.settings.afterFirstCallMissedLinkOption,
      after_missed_call_message: entity.settings.afterMissedCallMessage,
      after_first_call_message: entity.settings.afterFirstCallMessage,
      last_proposal_number: entity.settings.lastProposalNumber,
      account_for_export: entity.settings.accountForExport,
    },
    onboarding_info:
      entity.onboardingInfo === null
        ? null
        : {
            company_name: entity.onboardingInfo.companyName,
            industry: entity.onboardingInfo.industry,
            stuff_count: entity.onboardingInfo.stuffCount,
            country: entity.onboardingInfo.country,
            job_field: entity.onboardingInfo.jobField,
            job_role: entity.onboardingInfo.jobRole,
            language: entity.onboardingInfo.lang,
            user_experience: entity.onboardingInfo.userExperience,
            skip_invite_teammates: entity.onboardingInfo.skipInviteTeammates,
          },
    platforms: entity.platforms,
    personal_workspace_id: entity.personalWorkspaceId,
  };
};

export const mapOnboardingInfoEntityToDc = (
  entity: ProfileEntity['onboardingInfo']
): ProfileDC['onboarding_info'] => {
  return entity === null
    ? null
    : {
        company_name: entity.companyName,
        industry: entity.industry,
        stuff_count: entity.stuffCount,
        country: entity.country,
        job_field: entity.jobField,
        job_role: entity.jobRole,
        language: entity.lang,
        user_experience: entity.userExperience,
        skip_invite_teammates: entity.skipInviteTeammates,
      };
};
