import React, { useEffect, useState } from 'react';

import { useInjection } from '../../../../../../../../../app/ioc/ioc.react';
import { IFilesUseCases } from '../../../../../../../domain/abstractions/IFilesUseCases';
import { TYPES } from '../../../../../../../di/types';
import {
  chunkifyString,
  TChunkifyStringReturn,
} from '../../../../../../../../../utils/stringUtils';
import FileBC from '../../../../../../../domain/FileBC';

const TEXT_CHUNK_LENGTH = 1000;

const useFilePreviewTextViewModel = (file: FileBC) => {
  const { fetchFile, getDownloadLink } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const [chunks, setChunks] = useState<TChunkifyStringReturn>(null);
  const [chunksToRender, setChunksToRender] = useState<TChunkifyStringReturn>(null);
  const [nextChunkIndex, setNextChunkIndex] = useState(0);
  const isLoading = !chunksToRender?.length;

  useEffect(() => {
    getDownloadLink(file.uuid).subscribe((response) => {
      fetchFile(response[0].file_presigned_link).subscribe((response) =>
        response.text().then((text) => {
          const chunks = chunkifyString(text, TEXT_CHUNK_LENGTH);

          setChunks(chunks);
          setChunksToRender(chunks.slice(0, 5));
          setNextChunkIndex(6);
        })
      );
    });
  }, [file]);

  const onScroll: React.UIEventHandler<HTMLDivElement> = ({ target }) => {
    const el = target as HTMLDivElement;
    const isScrolledDown = el.clientHeight === Math.ceil(el.scrollHeight - el.scrollTop);

    if (isScrolledDown) {
      setChunksToRender(chunksToRender.concat(chunks.slice(nextChunkIndex, nextChunkIndex + 5)));
      setNextChunkIndex(nextChunkIndex + 6);
    }
  };

  return {
    onScroll,
    isLoading,
    chunks: chunksToRender,
  };
};

export default useFilePreviewTextViewModel;
