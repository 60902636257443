import { useTranslation } from 'next-i18next';
import {
  enUS,
  ar,
  zhCN,
  zhTW,
  nl,
  fr,
  de,
  he,
  hi,
  it,
  ja,
  ko,
  faIR,
  pt,
  ptBR,
  uk,
  es,
  th,
  tr,
  ru,
} from 'date-fns/locale';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import startOfWeek from 'date-fns/startOfWeek';
import LanguageCodes from 'src/enums/LanguageCodes';

const languagesMap = {
  [LanguageCodes.EN]: enUS,
  [LanguageCodes.AR]: ar,
  [LanguageCodes.ZH_CN]: zhCN,
  [LanguageCodes.ZH_TW]: zhTW,
  [LanguageCodes.NL]: nl,
  [LanguageCodes.FR]: fr,
  [LanguageCodes.DE]: de,
  [LanguageCodes.HE]: he,
  [LanguageCodes.HI]: hi,
  [LanguageCodes.IT]: it,
  [LanguageCodes.JA]: ja,
  [LanguageCodes.KO]: ko,
  [LanguageCodes.FA]: faIR,
  [LanguageCodes.PT]: pt,
  [LanguageCodes.PT_BR]: ptBR,
  [LanguageCodes.UK]: uk,
  [LanguageCodes.ES]: es,
  [LanguageCodes.TH]: th,
  [LanguageCodes.TR]: tr,
  [LanguageCodes.RU]: ru,
};

const resolveLocale = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { i18n } = useTranslation();
  return languagesMap[i18n.language] || enUS;
};

function useFormatDistance(): typeof formatDistance {
  const locale = resolveLocale();
  return (date, baseDate, options) => formatDistance(date, baseDate, { ...options, locale });
}

function useFormat(): typeof format {
  const locale = resolveLocale();
  return (date, formatStr, options) => format(date, formatStr, { ...options, locale });
}

function useStartOfWeek(): typeof startOfWeek {
  const locale = resolveLocale();
  return (date, options) => startOfWeek(date, { ...options, locale });
}

export { useFormat, useFormatDistance, useStartOfWeek };
