import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useAppStore } from '../../../../../app/hooks';
import { selectProposalSettings } from '../../../../../slices/proposalsSlice';

export type FormValues = {
  logoUrl?: string;
  businessName: string;
  description: string;
  phoneNumber: string;
  fax: string;
  email: string;
  address: string;
  website: string;
  moreDetails: string;
  startNumber: number;
  validity: number;
  taxRate: number;
  currency: string;
  includeTotalPrice: boolean;
  customerDetails: Array<'email' | 'fax' | 'address' | 'phone'>;
  footerText: string;
};

export default function useSettingsForm() {
  const store = useAppStore();
  const { t } = useTranslation('proposal-settings');

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        logoUrl: yup.string(),
        businessName: yup.string(),
        description: yup.string(),
        phoneNumber: yup.string(),
        fax: yup.lazy((value) =>
          !value ? yup.string() : yup.string().matches(/^[0-9 #*+-]+$/, t('common:validation.fax'))
        ),
        email: yup.string().email(t('common:validation.email')),
        address: yup.string(),
        website: yup.lazy((value) =>
          !value
            ? yup.string()
            : yup
                .string()
                .matches(
                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                  t('common:validation.url')
                )
        ),
        moreDetails: yup.string(),
        startNumber: yup
          .number()
          .required()
          .min(0, t('common:validation.min_value', { min: 0 })),
        validity: yup
          .number()
          .required()
          .min(0, t('common:validation.min_value', { min: 0 })),
        taxRate: yup
          .string()
          .required()
          .matches(/^(0|0?[1-9]\d*)\.\d\d$/, {
            message: t('common:validation.max_2_digits_after_comma'),
          })
          .min(0, t('common:validation.min_value', { min: 0 }))
          .max(100, t('common:validation.max_value', { max: 100 })),
        includeTotalPrice: yup.boolean().required(),
        customerDetails: yup.array().of(yup.string()),
        footerText: yup.string().max(500, t('common:validation.max_length', { max: 500 })),
      }),
    [t]
  );

  const prepareDefaultValues = (): FormValues => {
    const currentSettings = selectProposalSettings(store.getState());

    return {
      logoUrl: currentSettings.businessDetails.imageUrl,
      businessName: currentSettings.businessDetails.name,
      description: currentSettings.businessDetails.description,
      phoneNumber: currentSettings.businessDetails.phone,
      fax: currentSettings.businessDetails.fax,
      email: currentSettings.businessDetails.email,
      address: currentSettings.businessDetails.address,
      website: currentSettings.businessDetails.website,
      moreDetails: currentSettings.businessDetails.moreDetails,
      startNumber: currentSettings.startPrice,
      validity: currentSettings.validity,
      taxRate: currentSettings.taxRate,
      currency: currentSettings.currency,
      includeTotalPrice: currentSettings.includeTotalPrice,
      customerDetails: currentSettings.customerDetails
        .filter(({ enabled }) => enabled)
        .map(({ name }) => name),
      footerText: currentSettings.footerText,
    };
  };

  const methods = useForm<FormValues>({
    defaultValues: prepareDefaultValues(),
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  return methods;
}
