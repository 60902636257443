import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import { useDialog } from '../../../../app/hooks';

export interface IFileSizeNotSupportedProps {
  isLargeFile: boolean;
}

const FileSizeNotSupported: React.FC<IFileSizeNotSupportedProps> = ({ isLargeFile }) => {
  const { dialogUI, show, hide } = useDialog();
  const { t } = useTranslation('dialogs');

  useEffect(() => {
    show();

    return hide;
  }, []);

  return dialogUI({
    titleText: t('file_size_not_supported_dialog.title'),
    bodyText: isLargeFile
      ? t('file_size_not_supported_dialog.description_large_file')
      : t('file_size_not_supported_dialog.description_small_file'),
    positiveButtonText: t('storage_limit_reached_dialog.button_text'),
    negativeButtonText: '',
    onSuccess: hide,
  });
};

export default FileSizeNotSupported;
