import { Container } from 'inversify';

import BillingDetailsApiService, {
  IBillingApiService,
} from '../data/network/BillingDetailsApiService';
import BillingDetailsStateRepository from '../data/BillingDetailsStateRepository';
import { IBillingDetailsStateRepository } from '../domain/abstractions/repositories/IBillingDetailsStateRepository';

import PaymentProviderApiService, {
  IPaymentProviderApiService,
} from '../data/network/PaymentProviderApiService';
import PaymentProviderStateRepository from '../data/PaymentProviderStateRepository';
import { IPaymentProviderStateRepository } from '../domain/abstractions/repositories/IPaymentProviderStateRepository';

import SubscriptionApiService, {
  ISubscriptionApiService,
} from '../data/network/SubscriptionApiService';
import SubscriptionStateRepository from '../data/SubscriptionStateRepository';
import { ISubscriptionStateRepository } from '../domain/abstractions/repositories/ISubscriptionStateRepository';

import InvoicesApiService, { IInvoicesApiService } from '../data/network/InvoicesApiService';
import InvoicesRepository from '../data/InvoicesRepository';
import { IInvoicesRepository } from '../domain/abstractions/repositories/IInvoicesRepository';

import PromoCodeApiService, { IPromoCodeApiService } from '../data/network/PromoCodeApiService';
import PromoCodeRepository from '../data/PromoCodeRepository';
import { IPromoCodeRepository } from '../domain/abstractions/repositories/IPromoCodeRepository';

import BillingUseCases from '../domain/BillingUseCases';
import { IBillingUseCases } from '../domain/abstractions/IBillingUseCases';

import { TYPES } from './types';

export const bindBillingModule = (container: Container) => {
  // billing details
  container.bind<IBillingApiService>(TYPES.BillingDetailsApiService).to(BillingDetailsApiService);
  container
    .bind<IBillingDetailsStateRepository>(TYPES.BillingDetailsStateRepository)
    .to(BillingDetailsStateRepository)
    .inSingletonScope();

  // payment provider
  container
    .bind<IPaymentProviderApiService>(TYPES.PaymentProviderApiService)
    .to(PaymentProviderApiService);
  container
    .bind<IPaymentProviderStateRepository>(TYPES.PaymentProviderStateRepository)
    .to(PaymentProviderStateRepository)
    .inSingletonScope();

  // subscription
  container.bind<ISubscriptionApiService>(TYPES.SubscriptionApiService).to(SubscriptionApiService);
  container
    .bind<ISubscriptionStateRepository>(TYPES.SubscriptionStateRepository)
    .to(SubscriptionStateRepository)
    .inSingletonScope();

  // invoices
  container.bind<IInvoicesApiService>(TYPES.InvoicesApiService).to(InvoicesApiService);
  container.bind<IInvoicesRepository>(TYPES.InvoicesRepository).to(InvoicesRepository);

  // promo codes
  container.bind<IPromoCodeApiService>(TYPES.PromoCodeApiService).to(PromoCodeApiService);
  container.bind<IPromoCodeRepository>(TYPES.PromoCodeRepository).to(PromoCodeRepository);

  // use cases
  container.bind<IBillingUseCases>(TYPES.BillingUseCases).to(BillingUseCases);
};
