interface ProposalBusinessDetailsDC {
  uuid: string;
  image_url: null | string;
  name: null | string;
  description: null | string;
  phone: null | string;
  email: null | string;
  address: null | string;
  website: null | string;
  fax: null | string;
  more_details: null | string;
}

export enum ProposalCustomersDetailsNamesDC {
  EMAIL = 'email',
  FAX = 'fax',
  ADDRESS = 'address',
  PHONE = 'phone',
}

type ProposalCustomersDetailsDC = [
  {
    name: ProposalCustomersDetailsNamesDC.EMAIL;
    is_enabled: boolean;
  },
  {
    name: ProposalCustomersDetailsNamesDC.FAX;
    is_enabled: boolean;
  },
  {
    name: ProposalCustomersDetailsNamesDC.ADDRESS;
    is_enabled: boolean;
  },
  {
    name: ProposalCustomersDetailsNamesDC.PHONE;
    is_enabled: boolean;
  }
];

export default interface ProposalSettingsDC {
  uuid: string;
  workspace_id: string | null;
  start_price: number;
  validity: number;
  currency: string;
  includes_tax: boolean;
  tax_rate: number;
  entering_price_includes_tax: boolean | null;
  footer_text: string;
  customer_details: ProposalCustomersDetailsDC | null;
  include_total_price: boolean;
  language: string | null;
  updated_at: number | null;
  created_at: number | null;
  business_details: ProposalBusinessDetailsDC;
}
