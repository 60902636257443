enum SearchHistoryItemTypes {
  CONTACT,
  NOTE,
  PROPOSAL,
  COMPANY,
  TASK,
  FILE,
}

export default SearchHistoryItemTypes;
