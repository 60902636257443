import { PlanOptions } from 'src/features2/billing/domain/entities/SubscriptionEntity';

export const LOCAL_STORAGE_SELECTED_PLAN = 'selected_billing_plan';

export const SHOW_CONGRATULATION_QUERY_PARAM_KEY = 'showCongratulation';

export const basePricePerPlanType = {
  [PlanOptions.UNLIMITED_DAILY]: 15,
  [PlanOptions.UNLIMITED_MONTHLY]: 15,
  [PlanOptions.UNLIMITED_ANNUALLY]: 149,
};

export const GRACE_PERIOD_DURATION_IN_DAYS = 14;
