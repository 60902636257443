import { injectable, inject } from 'inversify';
import { combineLatest, map } from 'rxjs';
import { IUserUseCases } from './abstractions/IUserUseCases';
import { IUserStateRepository } from './abstractions/IUserStateRepository';
import { TYPES as USER_MODULE_TYPES } from '../di/types';
import { TYPES as WORKSPACES_MODULE_TYPES } from '../../workspaces/di/types';
import { IWorkspacesUseCases } from '../../workspaces/domain/abstractions/IWorkspacesUseCases';
import { TYPES as SYNC_MODULE_TYPES } from '../../sync/di/types';
import { ISyncStateRepository } from '../../sync/domain/abstractions/ISyncStateRepository';
import { TYPES as LOGIN_TYPES } from 'src/features2/authorization/login/di/types';
import ILoginRepository from 'src/features2/authorization/login/domain/abstractions/ILoginRepository';
@injectable()
export default class UserUseCases implements IUserUseCases {
  @inject(USER_MODULE_TYPES.UserStateRepository) private userStateRepository: IUserStateRepository;
  @inject(WORKSPACES_MODULE_TYPES.WorkspacesUseCases)
  private workspacesUseCases: IWorkspacesUseCases;
  @inject(LOGIN_TYPES.LoginRepository)
  private loginStateRepository: ILoginRepository;
  @inject(SYNC_MODULE_TYPES.SyncStateRepository)
  private syncStateRepository: ISyncStateRepository;

  logout() {
    this.syncStateRepository.restoreStatus.next('idle');
    this.loginStateRepository.logoutUser();
    return combineLatest([
      this.workspacesUseCases.changeWorkspace(undefined),
      this.userStateRepository.logout(),
    ]).pipe(map(() => true));
  }

  getCurrentUser() {
    return this.userStateRepository.getCurrentUser();
  }
}
