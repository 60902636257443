import BoardColumnDC from './BoardColumnDC';
import BoardColumnEntity from './BoardColumnEntity';
import { mapBoardItemDCtoEntity } from '../boardItem/mapper';

export function mapBoardColumnDCtoEntity(
  dc: BoardColumnDC,
  workspaceId: string
): BoardColumnEntity {
  return {
    id: dc.uuid,
    nextId: dc.next_uuid,
    title: dc.title,
    items: (dc.items || []).map((item) => mapBoardItemDCtoEntity(item, workspaceId)),
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    color: dc.color,
    workspaceId,
  };
}
