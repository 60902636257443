import { ParsedUrlQuery } from 'querystring';

import URLParams from '../../enums/URLParams';

const queryHasRedirectParams = (query: ParsedUrlQuery) =>
  query[URLParams.REDIRECT_URI] && query[URLParams.STATE];

export default {
  queryHasRedirectParams,
};
