import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';
import CongratulationDialog from './CongratulationDialog/CongratulationDialog';
import { SHOW_CONGRATULATION_QUERY_PARAM_KEY } from '../constants';

// Currently only Congratulation dialog is rendered depending on query params. Could be extended for other dialogs.

const BillingDialogs = () => {
  const router = useRouter();

  const [isDialogOpened, setIsDialogOpened] = useState(
    Boolean(router.query[SHOW_CONGRATULATION_QUERY_PARAM_KEY])
  );

  useEffect(() => {
    setIsDialogOpened(Boolean(router.query[SHOW_CONGRATULATION_QUERY_PARAM_KEY]));
  }, [router.query]);

  const removeQueryParam = (param) => {
    const { pathname, query } = router;
    // @ts-ignore
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
  };

  const closeDialog = () => {
    setIsDialogOpened(false);
    removeQueryParam(SHOW_CONGRATULATION_QUERY_PARAM_KEY);
  };

  if (isDialogOpened) {
    return <CongratulationDialog isOpened={isDialogOpened} onClose={closeDialog} />;
  }

  return null;
};

export default BillingDialogs;
