import boardItemSchema from '../boardItem/boardItemSchema';
import { dbVersion } from '../../constants';

export default {
  title: 'boardColumn schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: {
    key: 'pk_id',
    fields: ['id', 'workspaceId'],
    separator: '|',
  },
  type: 'object',
  required: [
    'pk_id',
    'id',
    'nextId',
    'title',
    'items',
    'createdAt',
    'updatedAt',
    'color',
    'workspaceId',
  ],
  properties: {
    pk_id: {
      type: 'string',
      maxLength: 100,
    },
    id: {
      type: 'string',
      maxLength: 100,
    },
    nextId: {
      type: ['string', 'null'],
    },
    title: {
      type: 'string',
    },
    items: {
      type: 'array',
      items: boardItemSchema,
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    color: {
      type: 'string',
    },
    workspaceId: {
      type: 'string',
      maxLength: 36,
    },
  },
  indexes: ['workspaceId'],
};
