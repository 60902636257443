import React from 'react';

import { selectActiveDialogs } from 'slices/uiSlice';
import { useAppSelector } from 'src/app/hooks';
import DialogTypes from 'src/enums/DialogTypes';
import DontShowInLeaderDialog, {
  IDontShowInLeaderDialogProps,
} from './DontShowInLeaderDialog/DontShowInLeaderDialog';
import StorageLimitationReachedDialog from 'components/UI/Dialogs/StorageLimitationReachedDialog';
import FileRemovingDialog, {
  IFileRemovingDialogProps,
} from 'components/UI/Dialogs/FileRemovingDialog';
import FileRenameDialog, { IFileRenameDialogProps } from 'components/UI/Dialogs/FileRenameDialog';
import FileSizeNotSupportedDialog, {
  IFileSizeNotSupportedProps,
} from 'components/UI/Dialogs/FileSizeNotSupportedDialog';

const DialogsSpawn: React.FC = () => {
  const activeDialogs = useAppSelector(selectActiveDialogs);

  return (
    <div>
      {activeDialogs.map(({ type, dialogProps }, index) => {
        switch (type) {
          case DialogTypes.DONT_SHOW_IN_LEADER_DIALOG: {
            return (
              <DontShowInLeaderDialog
                {...(dialogProps as IDontShowInLeaderDialogProps)}
                key={index}
              />
            );
          }
          case DialogTypes.STORAGE_LIMITATION_REACHED_DIALOG: {
            return <StorageLimitationReachedDialog key={index} />;
          }
          case DialogTypes.FILE_REMOVING_DIALOG: {
            return (
              <FileRemovingDialog {...(dialogProps as IFileRemovingDialogProps)} key={index} />
            );
          }
          case DialogTypes.FILE_RENAME_DIALOG: {
            return <FileRenameDialog {...(dialogProps as IFileRenameDialogProps)} key={index} />;
          }
          case DialogTypes.FILE_SIZE_NOT_SUPPORTED_DIALOG: {
            return (
              <FileSizeNotSupportedDialog
                {...(dialogProps as IFileSizeNotSupportedProps)}
                key={index}
              />
            );
          }
          default: {
            return null;
          }
        }
      })}
    </div>
  );
};

export default DialogsSpawn;
