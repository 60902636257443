import { SubscriptionEntity } from 'src/db/entities/workspace/WorkspaceEntity';

type Role = 'owner' | 'admin' | 'member';

export interface TeamMemberEntity {
  email: string;
  thumbnail: string | null;
  fullName: string | null;
  invitationStatus: 'accepted' | 'pending';
  role: Role;
  lastLoginAt: number;
  acceptedInvitationAt: number;
}

export default class Workspace {
  id: string;
  name: string | null;
  industry: string | null;
  companySize: string | null;
  thumbnail: string | null;
  updatedAt: number | null; // timestamp in milliseconds or null
  createdAt: number | null; // timestamp in milliseconds or null
  roles: {
    role: Role;
    isDefault: boolean;
    isMutable: boolean;
    permissions: Array<'view' | 'workspace_edit' | 'workspace_edit_members'>;
  }[];
  members: TeamMemberEntity[];
  subscription: SubscriptionEntity;
  memberAccessAllContacts: boolean;
  proposalsNextColId: string | null;
  lastLoginAt: number | null; // timestamp in milliseconds or null
  isPersonal: boolean;
  companyWebsite: string | null;
  businessPhone: string | null;
  constructor({
    id,
    name,
    industry,
    companySize,
    thumbnail,
    updatedAt,
    createdAt,
    roles,
    members,
    subscription,
    memberAccessAllContacts,
    proposalsNextColId,
    lastLoginAt,
    companyWebsite,
    businessPhone,
    isPersonal,
  }: {
    id: string;
    name: string | null;
    industry: string | null;
    companySize: string | null;
    thumbnail: string | null;
    updatedAt: number | null; // timestamp in milliseconds or null
    createdAt: number | null; // timestamp in milliseconds or null
    roles: {
      role: Role;
      isDefault: boolean;
      isMutable: boolean;
      permissions: Array<'view' | 'workspace_edit' | 'workspace_edit_members'>;
    }[];
    members: TeamMemberEntity[];
    subscription: SubscriptionEntity;
    memberAccessAllContacts: boolean;
    proposalsNextColId: string | null;
    lastLoginAt: number | null; // timestamp in milliseconds or null
    isPersonal: boolean;
    companyWebsite: string | null;
    businessPhone: string | null;
  }) {
    this.id = id;
    this.name = name;
    this.industry = industry;
    this.companySize = companySize;
    this.thumbnail = thumbnail;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.roles = roles;
    this.members = members;
    this.subscription = subscription;
    this.memberAccessAllContacts = memberAccessAllContacts;
    this.proposalsNextColId = proposalsNextColId;
    this.lastLoginAt = lastLoginAt;
    this.isPersonal = isPersonal;
    this.businessPhone = businessPhone;
    this.companyWebsite = companyWebsite;
  }
}
