import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import MUISelect, { SelectProps as MUISelectProps } from '@material-ui/core/Select';
import styles from 'styles/components/inputs/Select.module.scss';
import { forwardRef, useMemo } from 'react';

type TSelectProps = Omit<MUISelectProps, 'variant'> & {
  options: { value?: string | number; text: React.ReactNode; onClick?: () => void }[];
  variant?: 'standard' | 'outlined';
  menuItemClassName?: string;
  menuProps?: MUISelectProps['MenuProps'];
  renderVal?: any;
};

const Select = forwardRef<unknown, TSelectProps>((props, ref) => {
  const {
    options,
    variant = 'outlined',
    className,
    menuItemClassName,
    inputProps,
    menuProps,
    renderVal,
    ...rest
  } = props;

  const selectClassName = clsx({
    [styles.Select]: true,
    [styles.standard]: variant === 'standard',
    [styles.outlined]: variant === 'outlined',
    [className]: !!className,
  });

  const optionsMap = useMemo(() => {
    const map = {};

    options.forEach(({ value, text }) => {
      map[value] = text;
    });

    return map;
  }, [options]);

  return (
    <MUISelect
      ref={ref}
      {...rest}
      label={false}
      className={selectClassName}
      MenuProps={{ className: styles.SelectMenu, ...menuProps }}
      inputProps={{ ...inputProps, 'data-hj-allow': '' }}
      renderValue={renderVal ? () => renderVal : (val) => optionsMap[val as string]}
    >
      {options.map(({ value, text, onClick }) => (
        <MenuItem
          value={value}
          key={`${value}:${text}`}
          onClick={onClick}
          classes={{ root: menuItemClassName }}
        >
          {text}
        </MenuItem>
      ))}
    </MUISelect>
  );
});

Select.displayName = 'Select';

export default Select;
