import LanguageCodes from 'src/enums/LanguageCodes';
import flagList from './flagList';

const getLanguageData = (code: LanguageCodes): LanguageData => ({
  flag: flagList[code],
  code,
  name: `common:languages.lng_name_${code.replace('-', '_')}`,
});

export const languageData = Object.values(LanguageCodes).map(getLanguageData);
export const languageDataMap = Object.values(LanguageCodes).reduce((acc, code) => {
  acc[code] = getLanguageData(code);
  return acc;
}, {});

// TODO: remove these methods and use objects directly (preserved for backward compatibility)
export const getLanguageSelectData = () => languageData;
export const getLanguageSelectDataMap = () => languageDataMap;

export type LanguageData = {
  flag: string;
  code: LanguageCodes;
  name: string;
};
