import { SnackbarProvider, SnackbarProviderProps } from 'notistack';
// import SBContent from '../SBContent';
import SBContent from '../SBContent';
import { SnackbarUtilsConfigurator } from 'components/UI/snackbar/providers/SnackbarUtilsConfigurator';

const SBProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      autoHideDuration={6000}
      content={(
        key,
        data: { message: string | React.ReactNode; icon?: React.ReactNode; variant: MessageType }
      ) => <SBContent id={key} {...data} />}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  );
};

export enum MessageType {
  Error = 'error',
  Success = 'success',
  Default = 'default',
}

export default SBProvider;
