import { Container } from 'inversify';
import FBAnalyticsRepository from '../data/FBAnalyticsRepository';
import FBAnalyticsUseCases from '../domain/FBAnalyticsUseCases';
import { IFBAnalyticsRepository } from '../domain/abstractions/IFBAnalyticsRepository';
import { IFBAnalyticsUseCases } from '../domain/abstractions/IFBAnalyticsUseCases';
import { TYPES } from './types';
export const bindFBAnalyticsModule = (container: Container) => {
  container
    .bind<IFBAnalyticsRepository>(TYPES.FBAnalyticsRepository)
    .to(FBAnalyticsRepository)
    .inSingletonScope();
  container.bind<IFBAnalyticsUseCases>(TYPES.FBAnalyticsUseCases).to(FBAnalyticsUseCases);
};
