import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FormValues } from '../useSettingsSubForm';
import TextField from '../../../../../../components/UI/Inputs/TextField';

export default function StartNumberInput() {
  const { t } = useTranslation('proposal-settings');

  const { setValue, control } = useFormContext<FormValues>();

  return (
    <Controller
      name="startNumber"
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            // wrong type of ControllerRenderProps.onBlur
            (field.onBlur as (event: React.FocusEvent<HTMLInputElement>) => void)(event);

            if (event.target.value === '') {
              setValue('startNumber', 0, { shouldValidate: true });
            }
          }}
          id="proposal_settings_start_price"
          label={t('form.field.startNumber.label')}
          labelHint={t('form.field.startNumber.hint')}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          fullWidth
        />
      )}
    />
  );
}
