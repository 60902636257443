import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../../../../app/hooks';
import { selectMemberDataByEmail } from '../../../../slices/workspacesSlice';
import Avatar from '../../../../components/UI/Avatar';
import { useFormat } from '../../../../lib/date-fns';

import styles from './CreatedByLabel.module.css';

export default function CreatedByLabel({
  email,
  createdAt,
  className,
}: {
  email: string;
  createdAt: number;
  className?: string;
}) {
  const { t } = useTranslation('common');

  const member = useAppSelector(selectMemberDataByEmail(email));

  const format = useFormat();

  const name = member?.fullName || email;
  const date = new Date(createdAt);
  const formattedDate = format(date, 'iii d MMM HH:mm');
  const text = t('collaboration.created_by', { name, date: formattedDate });

  return (
    <div className={clsx(styles.wrapper, className)}>
      <Avatar className={styles.avatar} size={24} thumbnail={member?.thumbnail} name={name} />
      {text}
    </div>
  );
}
