import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays';
import addHours from 'date-fns/addHours';
import isSameDay from 'date-fns/isSameDay';

import { useStartOfWeek, useFormat } from '../../../../../lib/date-fns';
import FormControl from '../../../../../components/UI/Inputs/FormControl';
import InputLabel from '../../../../../components/UI/Inputs/InputLabel';
import Select from '../../../../../components/UI/Inputs/Select';

import DateTimePicker from './DateTimePicker';

export default function DateTimeSelect({
  label,
  value,
  onChange,
}: {
  label: string;
  value: Date | null;
  onChange: (nextValue: Date | null) => void;
}) {
  const anchorRef = useRef();

  const { t } = useTranslation('tasks-page');

  const startOfWeek = useStartOfWeek();
  const format = useFormat();

  const [today] = useState(new Date());

  const laterToday = addHours(today, 3);
  const laterTodayFormatted = format(laterToday, 'HH:mm');

  const tomorrow = setMinutes(setHours(addDays(today, 1), 9), 0);
  const tomorrowTimeFormatted = format(tomorrow, 'HH:mm');

  const nextWeek = addWeeks(today, 1);
  const startOfNextWeek = setMinutes(setHours(startOfWeek(nextWeek), 9), 0);

  const startOfNextWeekDay = format(startOfNextWeek, 'iii');
  const startOfNextWeekTime = format(startOfNextWeek, 'HH:mm');

  const [pickerOpen, setPickerOpen] = useState(false);
  const customValue =
    value === null
      ? null
      : value.getTime() === laterToday.getTime()
      ? null
      : value.getTime() === tomorrow.getTime()
      ? null
      : value.getTime() === nextWeek.getTime()
      ? null
      : value.getTime();

  const id = `task_form_${label}`;

  return (
    <div ref={anchorRef}>
      <FormControl fullWidth>
        <InputLabel htmlFor={id}>{label}</InputLabel>
        <Select
          variant="outlined"
          value={value === null ? 0 : value.getTime()}
          onChange={(event) => {
            const value =
              (event.target.value as number) === 0 ? null : new Date(event.target.value as number);

            onChange(value);
          }}
          inputProps={{ id: id }}
          options={[
            { value: 0, text: t('form.date_time_select.value.never') },
            {
              value: laterToday.getTime(),
              text: isSameDay(today, laterToday)
                ? t('form.date_time_select.value.later_today', { time: laterTodayFormatted })
                : t('form.date_time_select.value.tomorrow', { time: laterTodayFormatted }),
            },
            {
              value: tomorrow.getTime(),
              text: t('form.date_time_select.value.tomorrow', { time: tomorrowTimeFormatted }),
            },

            {
              value: nextWeek.getTime(),
              text: t('form.date_time_select.value.next_week', {
                day: startOfNextWeekDay,
                time: startOfNextWeekTime,
              }),
            },
            {
              value: customValue,
              text: customValue
                ? format(new Date(customValue), 'iii d MMM HH:mm')
                : t('form.date_time_select.value.pick_date_time'),
              onClick: () => setPickerOpen(true),
            },
          ]}
        />
      </FormControl>

      <DateTimePicker
        open={pickerOpen}
        anchorEl={anchorRef.current}
        onSave={(date) => {
          onChange(date);
          setPickerOpen(false);
        }}
        onClose={() => setPickerOpen(false)}
      />
    </div>
  );
}
