import ProposalEntity from './ProposalEntity';
import ProposalDC from './ProposalDC';
import {
  mapProposalSettingsDСtoEntity,
  mapProposalSettingsEntityToDC,
} from '../proposalSettings/mapper';

export const mapProposalDCToEntity = (dc: ProposalDC, workspaceId: string): ProposalEntity => {
  const contactId = dc.relations.find((relationItem) => relationItem.type === 'contact')?.id ?? '';

  const items = dc.items.map((item) => ({
    description: item.description,
    qty: item.qty,
    unitPrice: item.amount,
  }));

  let number: number;
  let title: string;

  if (dc.number === null) {
    // the proposal title and number are put in one field as a string
    // they need to be parsed
    const splittedTitle = dc.title.split('#');
    title = splittedTitle.slice(0, splittedTitle.length - 1).join('');
    number = parseInt(splittedTitle.reverse()[0]);
  } else {
    number = dc.number;
    title = dc.title;
  }

  return {
    uuid: dc.uuid,
    number: number,
    contactId: contactId,
    client: dc.client,
    title: title,
    items: items,
    description: dc.description || '',
    discountAmount: dc.discount_amount,
    discountType: dc.discount_type,
    createdAt: dc.created_at * 1000,
    updatedAt: dc.updated_at * 1000,
    sentAt: dc.sent_at === null ? null : dc.sent_at * 1000,
    isDraft: dc.is_draft,
    visible: !dc.not_show,
    settings: mapProposalSettingsDСtoEntity(dc.settings),
    createdBy: dc.created_by || undefined,
    updatedBy: dc.updated_by || undefined,
    workspaceId,
  };
};

export const mapProposalEntityToDC = (entity: ProposalEntity): ProposalDC => {
  return {
    uuid: entity.uuid,
    number: entity.number,
    client: entity.client,
    title: entity.title,
    items: entity.items.map((item, index) => ({
      order: index + 1,
      description: item.description,
      qty: item.qty,
      amount: item.unitPrice,
    })),
    description: entity.description || null,
    discount_type: entity.discountType,
    discount_amount: entity.discountAmount,
    created_at: entity.createdAt / 1000,
    updated_at: entity.updatedAt / 1000,
    sent_at: entity.sentAt === null ? null : entity.sentAt / 1000,
    settings: mapProposalSettingsEntityToDC(entity.settings),
    is_draft: entity.isDraft,
    relations: [
      {
        type: 'contact',
        id: entity.contactId,
      },
    ],
    not_show: !entity.visible,
    created_by: entity.createdBy || null,
    updated_by: entity.updatedBy || null,
  };
};
