import React, { useState, useRef, useLayoutEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import { getHighlightedOverlaps } from 'src/utils/searchUtils';
import Contact from 'types/contact';
import SearchItem from '../SearchItem';
import styles from './styles.module.scss';
import Avatar from '../../../../components/UI/Avatar';
import { addHistoryItem } from 'slices/searchSlice';
import SearchHistoryItemTypes from 'src/enums/SearchHistoryItemTypes';
import SearchIcon from 'icons/search.svg';
import { forwardToContactsPage } from 'src/services/router/appRouterService';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import NoteEntity from 'src/db/entities/note/NoteEntity';
import { FixedSizeList as List } from 'react-window';
interface INotesListProps {
  data: NoteEntity[];
  contacts: Contact[];
  searchValue: string;
  closeSearchModal: () => void;
}

const TWO_ELEMENTS_MARGIN_TOP_SUM = 30;

const NotesList: React.FC<INotesListProps> = ({
  data,
  contacts,
  searchValue,
  closeSearchModal,
}) => {
  const { t } = useTranslation('search');
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(null);
  const listRef = useRef<HTMLDivElement>(null);
  const isLongList = data?.length > 3;
  const virtualListRef = useRef<List>();
  useLayoutEffect(() => {
    if (isLongList) {
      const cl = listRef.current?.children as HTMLCollectionOf<HTMLElement>;
      cl &&
        setMaxHeight(
          cl?.[0]?.offsetHeight +
            cl?.[1]?.offsetHeight +
            cl?.[2]?.offsetHeight +
            TWO_ELEMENTS_MARGIN_TOP_SUM
        );
    }
  }, [searchValue, isLongList]);

  if (!data?.length) return null;
  const NoteRowHeight = 71;
  const onSeeAllLess = () => setIsExpanded(!isExpanded);
  const NoteRow = ({ index, style }) => {
    const note = data[index];
    const contact = contacts.find((contact) => contact.uuid === note?.links?.direct?.id);

    return (
      <div style={style}>
        <NoteSearchItem
          key={note.id}
          note={note}
          contact={contact}
          closeSearchModal={closeSearchModal}
          searchValue={searchValue}
        />
      </div>
    );
  };
  return (
    <div className={styles.Wrapper}>
      <div>{t('title_notes', { count: data.length })}</div>
      <div
        style={isLongList && !isExpanded ? { maxHeight, overflow: 'hidden' } : null}
        className={clsx(styles.List, isExpanded && styles.Expanded)}
        ref={listRef}
      >
        {isExpanded ? (
          <List
            overscanCount={5}
            ref={(instance) => (virtualListRef.current = instance)}
            itemCount={data.length}
            itemSize={NoteRowHeight}
            height={400}
            width={'100%'}
          >
            {NoteRow}
          </List>
        ) : (
          data
            .slice(0, 3)
            .map((item, index) => (
              <NoteRow key={index} index={index} style={{ height: NoteRowHeight }} />
            ))
        )}
      </div>
      {isLongList && (
        <button onClick={onSeeAllLess} className={'leader-search-see-more'}>
          <SearchIcon /> {isExpanded ? t('button_see_less') : t('button_see_all_notes')}
        </button>
      )}
      <div className={'leader-search-divider'} />
    </div>
  );
};

interface INoteSearchItemProps {
  note: NoteEntity;
  contact: Contact;
  searchValue?: string;
  closeSearchModal: () => void;
}

export const NoteSearchItem: React.FC<INoteSearchItemProps> = memo(function NoteSearchItem({
  note,
  contact,
  searchValue,
  closeSearchModal,
}) {
  const dispatch = useDispatch();

  if (!note) return null;

  const { id, content, updatedAt } = note;
  const { thumbnail, name, uuid: contactUuid } = contact || {};
  const updateDate = new Date(updatedAt);
  const updateDateString = String(updateDate);
  const month = updateDate.toLocaleDateString('en', { month: 'long' }).slice(0, 3);
  const day = updateDate.getDate();
  const hours = updateDate.getHours();
  const minutes = updateDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const gmtData = updateDateString
    .slice(updateDateString.indexOf('GMT'), updateDateString.indexOf('GMT') + 6)
    .replace('0', '')
    .replace('+', ' +');
  const contactInfoString = `${getHighlightedOverlaps(
    name,
    searchValue
  )} Last Update ${month}, ${day}, at ${hours}:${minutes} ${ampm} ${gmtData}`;

  const onClick = async () => {
    await forwardToContactsPage();
    dispatch(setCurrentPageName('duplicates:Contacts_title'));
    dispatch(
      setRedirectEffect({
        contactId: contactUuid,
        targetType: RedirectEffectTargetType.NOTE,
        targetId: id,
      })
    );
    closeSearchModal();
    dispatch(
      addHistoryItem({
        id: id,
        type: SearchHistoryItemTypes.NOTE,
        notesData: {
          note,
          contact,
        },
      })
    );
  };

  return (
    <button className={styles.ItemWrapper} onClick={onClick}>
      <SearchItem
        nameClassName={styles.Name}
        isInnerItem
        title={getHighlightedOverlaps(content, searchValue)}
      />
      <div className={styles.ContactInfo}>
        <Avatar className={styles.Avatar} thumbnail={thumbnail} name={name} />
        <span dangerouslySetInnerHTML={{ __html: contactInfoString }} />
      </div>
    </button>
  );
});

export default memo(NotesList);
