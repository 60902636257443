import React from 'react';
import { useTranslation } from 'next-i18next';

import DirectedText from 'src/features2/localization/ui/DirectedText';
import Dialog from '@material-ui/core/Dialog'; // TODO: move dialog as separate component
import Button from 'components/UI/Buttons/BaseButton';

import CongratulationIcon from 'icons/billing/congratulation.svg';

import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  isOpened: boolean;
};

const CongratulationDialog: React.FC<Props> = ({ onClose, isOpened }) => {
  const { t } = useTranslation('billing', { keyPrefix: 'congratulations' });

  return (
    <Dialog classes={{ paper: styles.container }} onClose={onClose} open={isOpened}>
      <div className={styles.wrapper}>
        <CongratulationIcon />
        <h1>
          <DirectedText>{t('title')}</DirectedText>
        </h1>
        <h2>{t('subTitle')}</h2>

        <p>
          {t('description')}
          <br />
          <span>{t('invoiceEmail')}</span>
        </p>

        <Button onClick={onClose}>{t('button')}</Button>
      </div>
    </Dialog>
  );
};

export default CongratulationDialog;
