import { injectable, inject } from 'inversify';
import type { IWorkspacesUseCases } from './abstractions/IWorkspacesUseCases';
import type { IWorkspacesStateRepository } from './abstractions/IWorkspacesStateRepository';
import { TYPES } from '../di/types';

import { TYPES as SYNC_MODULE_TYPES } from '../../sync/di/types';
import { ISyncStateRepository } from '../../sync/domain/abstractions/ISyncStateRepository';

@injectable()
export default class WorkspacesUseCases implements IWorkspacesUseCases {
  @inject(TYPES.WorkspacesStateRepository) private workspacesRepository: IWorkspacesStateRepository;
  @inject(SYNC_MODULE_TYPES.SyncStateRepository) private syncRepository: ISyncStateRepository;

  getCurrentWorkspace = () => {
    return this.workspacesRepository.getCurrentWorkspace();
  };
  getWorkspaces = () => {
    return this.workspacesRepository.getWorkspaces();
  };

  changeWorkspace = (value: string | undefined) => {
    this.syncRepository.restoreStatus.next('idle');
    return this.workspacesRepository.changeWorkspace(value);
  };

  deleteMembers = (data) => {
    return this.workspacesRepository.deleteMembers(data);
  };
}
