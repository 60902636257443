import { useInjection } from 'src/app/ioc/ioc.react';
import { TYPES } from '../di/types';
import { ICustomerIOUseCases } from '../domain/abstractions/ICustomerIOUseCases';

const useCustomerIOViewModel = () => {
  const useCases = useInjection<ICustomerIOUseCases>(TYPES.CustomerIOUseCases);
  const trackEvent = (event) => useCases.trackEvent(event);

  const customerIOInit = () => {
    useCases.initializeUser();
  };
  const customerIOReset = () => {
    useCases.resetUser();
  };

  return {
    customerIOInit,
    customerIOReset,
    trackEvent,
  };
};

export default useCustomerIOViewModel;
