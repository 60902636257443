import FileDC from './FileDC';
import FileEntity from './FileEntity';
import FileBC from '../../../features2/files/domain/FileBC';

export const mapFileDCtoEntity = (dc: FileDC): FileEntity => ({
  mimeType: dc.mime_type,
  fileSizeBytes: dc.filesize_bytes,
  itemType: dc.item_type,
  itemUuid: dc.item_uuid,
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  createdBy: dc.created_by,
  updatedBy: dc.updated_by,
  uuid: dc.uuid,
  link: dc.link,
  name: dc.name,
  thumbnail: dc.thumbnail,
});

export const mapFileBCtoFileDC = (bc: FileBC): FileDC => ({
  uuid: bc.uuid,
  link: bc.link,
  thumbnail: bc.thumbnail,
  name: bc.name,
  mime_type: bc.mimeType,
  filesize_bytes: bc.fileSizeBytes,
  item_type: bc.itemType,
  item_uuid: bc.itemUuid,
  created_at: bc.createdAt,
  updated_at: bc.updatedAt,
  created_by: bc.createdBy,
  updated_by: bc.updatedBy,
});
