import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { appRoutes } from 'src/utils/constants';
import usePlansAndPurchasePagesDetection from '../helpers';
import PlanPromoStartIcons from 'icons/plans/plans-promo-stars.svg';

import styles from './styles.module.scss';

const PlansPromo = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'promos' });

  const { subscription } = useCurrentSubscription();
  const { currentUser } = useCurrentUser();
  const { isPlansOrPurchasesPage } = usePlansAndPurchasePagesDetection();

  const { isUserOnFreePlan, isUserOnFreeTrial, isActive } = subscription;
  const { isAdmin } = currentUser;

  if (isAdmin && (isUserOnFreeTrial || isUserOnFreePlan || !isActive) && !isPlansOrPurchasesPage) {
    return (
      <Link href={appRoutes.PLANS_PAGE}>
        <div className={styles.wrapper}>
          <PlanPromoStartIcons />
          {t('seePlans')}
        </div>
      </Link>
    );
  } else {
    return null;
  }
};

export default PlansPromo;
