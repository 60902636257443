import React, { useState, memo } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import clsx from 'clsx';

import styles from './styles.module.scss';
import FilesUploadingQueueItem from '../FilesUploadingQueueItem';
import { UploadingQueueItem } from '../../../data/FileUploadStateRepository.types';
import CloseIcon from 'icons/close.svg';
import { TFilesErrorData } from '../../../data/FileUploadErrorHandlingRepository';
import useFilesUploadingDialogViewModel from './useFilesUploadingDialogViewModel';

interface IFilesUploadingDialogProps {
  uploadingQueue: UploadingQueueItem[];
  onClose(): void;
}

const FilesUploadingDialog: React.FC<IFilesUploadingDialogProps> = ({
  uploadingQueue,
  onClose,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
  const [errorsCount, setErrorsCount] = useState<TFilesErrorData[]>([]);

  const { uploadingDialogTitle } = useFilesUploadingDialogViewModel();

  if (!uploadingQueue.length) return null;

  const onAccordionExpand = () => setIsAccordionExpanded(!isAccordionExpanded);

  const addErrorToCounter = (error) => {
    setErrorsCount([...errorsCount, error]);
  };

  const onAccordionClick = () => setIsAccordionExpanded(!isAccordionExpanded);

  const stopPropagation = (e) => e.stopPropagation();

  return (
    <div className={styles.Dialog}>
      <Accordion
        expanded={isAccordionExpanded}
        onClick={onAccordionClick}
        className={styles.Accordion}
      >
        <AccordionSummary className={styles.DialogHeader} onClick={stopPropagation}>
          <div className={styles.Summary}>
            <div>{uploadingDialogTitle}</div>
            <div className={styles.IconsWrapper}>
              <div
                onClick={onAccordionExpand}
                className={clsx(styles.ExpandIcon, isAccordionExpanded && styles.Expanded)}
              />
              <CloseIcon className={styles.CloseIcon} onClick={onClose} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.Body} onClick={stopPropagation}>
          {uploadingQueue.map((file) => (
            <FilesUploadingQueueItem
              key={file.id}
              addErrorToCounter={addErrorToCounter}
              file={file}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default memo(FilesUploadingDialog);
