import { from, map, Observable } from 'rxjs';
import { injectable } from 'inversify';

import axios from '../../../../app/axios';
import { endpoints } from '../../../../utils/constants';
import RequestHeaders from '../../../../enums/RequestHeaders';
import ProfileDC from '../../../../db/entities/profile/ProfileDC';

export interface IProfileApiService {
  patchProfile(updateObject): Observable<string>;
  patchProfileSettings(updateObject: Partial<ProfileDC['settings']>): Observable<string>;
  exportProfileContacts(workspaceId: string): Observable<string>;
  deleteProfile(): Observable<string>;
  getProfile(): Observable<ProfileDC>;
}

@injectable()
export default class ProfileApiService implements IProfileApiService {
  patchProfile = (updateObject) => {
    const request = axios.patch(endpoints.account.account, updateObject);

    return from(request).pipe(map((response) => response.data));
  };

  getProfile() {
    const response = axios.get<ProfileDC>(endpoints.account.profile);

    return from(response).pipe(map((response) => response.data));
  }

  patchProfileSettings(updateObject) {
    const request = axios.patch(endpoints.account.settings, updateObject);

    return from(request).pipe(map((response) => response.data));
  }

  exportProfileContacts(workspaceId) {
    const request = axios.get(endpoints.contacts.export, {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: workspaceId,
      },
    });

    return from(request).pipe(map((response) => response.data));
  }

  deleteProfile() {
    const request = axios.delete(endpoints.account.account);

    return from(request).pipe(map((response) => response.data));
  }
}
