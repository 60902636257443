import { Container } from 'inversify';

import { TYPES } from './types';
import ProfileDao, { IProfileDao } from '../data/ProfileDao';
import ProfileApiService, { IProfileApiService } from '../data/network/ProfileApiService';
import ProfileRepository, { IProfileRepository } from '../data/ProfileRepository';
import ProfileSettingsRepository, {
  IProfileSettingsRepository,
} from '../data/ProfileSettingsRepository';
import ProfileUseCases, { IProfileUseCases } from '../domain/ProfileUseCases';
import ProfileDeleteRepository, { IProfileDeleteRepository } from '../data/ProfileDeleteRepository';
import ProfileExportRepository, { IProfileExportRepository } from '../data/ProfileExportRepository';
import ProfileDataRepository, { IProfileDataRepository } from '../data/ProfileDataRepository';

export const bindProfileModule = (container: Container) => {
  container.bind<IProfileDao>(TYPES.ProfileDao).to(ProfileDao);
  container.bind<IProfileApiService>(TYPES.ProfileApiService).to(ProfileApiService);
  container.bind<IProfileRepository>(TYPES.ProfileRepository).to(ProfileRepository);
  container
    .bind<IProfileSettingsRepository>(TYPES.ProfileSettingsRepository)
    .to(ProfileSettingsRepository);
  container.bind<IProfileUseCases>(TYPES.ProfileUseCases).to(ProfileUseCases);
  container
    .bind<IProfileDeleteRepository>(TYPES.ProfileDeleteRepository)
    .to(ProfileDeleteRepository);
  container
    .bind<IProfileExportRepository>(TYPES.ProfileExportRepository)
    .to(ProfileExportRepository);
  container.bind<IProfileDataRepository>(TYPES.ProfileDataRepository).to(ProfileDataRepository);
};
