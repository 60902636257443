import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const SWITCH_WORKSPACE_ANCHOR = '#switchWorkspace';

const useShowSwitchWorkspace = () => {
  const { asPath, push } = useRouter();
  const [showSwitchWorkspace, setShowSwitchWorkspace] = useState(false);

  useEffect(() => {
    setShowSwitchWorkspace(asPath.includes(SWITCH_WORKSPACE_ANCHOR));
  }, [asPath]);

  const clearPath = () => push(asPath.replace(SWITCH_WORKSPACE_ANCHOR, ''));

  return {
    showSwitchWorkspace,
    clearPath,
  };
};

export default useShowSwitchWorkspace;
