import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import remove from 'lodash/remove';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../app/store/store';
import { postNoteAPI, patchNoteAPI, deleteNoteAPI } from '../api/notesAPI';
import { selectCurrentWorkspaceId, selectCurrentUserMemberData } from './workspacesSlice';

import NoteEntity from '../db/entities/note/NoteEntity';

const sliceName = 'notes';

export const createNote = createAsyncThunk(
  `${sliceName}/createNote`,
  async ({ note, contactId }: { note: string; contactId: string }, { getState }) => {
    const state = getState() as AppState;
    const { email } = selectCurrentUserMemberData(state);
    const currentWorkspaceId = selectCurrentWorkspaceId(state);

    const noteModel: NoteEntity = {
      legacyId: uuidv4(),
      id: uuidv4(),
      subject: '',
      preview: null,
      content: note,
      links: {
        direct: {
          type: 'contact',
          id: contactId,
        },
      },
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
      createdBy: email,
      updatedBy: email,
      workspaceId: currentWorkspaceId,
    };

    await postNoteAPI(noteModel, currentWorkspaceId);
    return noteModel;
  }
);

export const updateNote = createAsyncThunk(
  `${sliceName}/updateNote`,
  async ({ id, diff }: { id: string; diff: Partial<NoteEntity> }, { getState }) => {
    const state = getState() as AppState;
    const { email } = selectCurrentUserMemberData(state);
    const currentWorkspaceId = selectCurrentWorkspaceId(state);

    const preparedDiff = {
      ...diff,
      updatedAt: Math.floor(Date.now() / 1000),
      updatedBy: email,
    };

    await patchNoteAPI(id, preparedDiff, currentWorkspaceId);

    return preparedDiff;
  }
);

export const deleteNote = createAsyncThunk(
  `${sliceName}/deleteNote`,
  async ({ contactId, noteId }: { contactId: string; noteId: string }, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    await deleteNoteAPI(noteId, currentWorkspaceId);
    return {
      contactId,
      noteId,
    };
  }
);

type InitialState = {
  notes: NoteEntity[];
  openFormNote: boolean;
};
const initialState: InitialState = {
  notes: [],
  openFormNote: false,
};
const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setNotes(state, action: PayloadAction<NoteEntity[]>) {
      state.notes = action.payload;
    },
    setOpenFormNote(state, action) {
      state.openFormNote = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNote.fulfilled, (state, action) => {
      state.notes.push(action.payload);
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      const currentNoteIndex = state.notes.findIndex(({ id }) => id === action.payload.id);
      const outdatedEntity = state.notes[currentNoteIndex];

      state.notes[currentNoteIndex] = { ...outdatedEntity, ...action.payload };
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      remove(state.notes, (note) => note.id == action.payload.noteId);
    });
  },
});

const { actions, reducer } = notesSlice;
export const { setOpenFormNote, setNotes } = actions;
export const selectNotes = (state: AppState) => {
  return state.notes.notes as NoteEntity[];
};
export const selectOpenFormNote = (state: AppState) => state.notes.openFormNote;
export default reducer;
