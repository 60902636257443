import React, { useState } from 'react';
import clsx from 'clsx';

import { useAppSelector } from 'src/app/hooks';
import { selectSyncFailed } from 'slices/boardSlice';
import { selectCurrentWorkspaceId } from 'slices/workspacesSlice';

import ErrorState from 'src/features/calls/Board/ErrorState';
import Sidebar from '../UI/Navigation/Sidebar/index';
import Header from 'components/UI/Headers/Header/index';
import RestoreOverlay from '../UI/RestoreOverlay';
import DialogsSpawn from 'components/UI/Dialogs/DialogsSpawn';
import commonDataProviderHoc from 'src/features2/common/provider/commonDataProviderHoc';

import { useInjection } from '../../app/ioc/ioc.react';
import { useBehaviorSubject } from '../../app/hooks/rx';
import { ISyncStateRepository } from '../../features2/sync/domain/abstractions/ISyncStateRepository';
import { TYPES as SYNC_MODULE_TYPES } from '../../features2/sync/di/types';
import useWindowResizeListener from '../../app/hooks/ui/useWindowResizeListener';
import BillingLimitsAndOverlays from 'src/features2/billing/ui/limitsAndOverlays/BillingLimitsAndOverlays';

import styles from './MainLayout.module.scss';

const MIN_SCREEN_WIDTH_TO_EXPAND_SIDEBAR = 800;

const MainLayout = ({ children, pathName }) => {
  const syncStateRepository = useInjection<ISyncStateRepository>(
    SYNC_MODULE_TYPES.SyncStateRepository
  );
  const [restoreStatus] = useBehaviorSubject(syncStateRepository.restoreStatus);
  const restored = restoreStatus === 'restored';
  const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
  const syncFailed = useAppSelector(selectSyncFailed);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [canBeExpanded, setCanBeExpanded] = useState(false);

  useWindowResizeListener(() => {
    setCanBeExpanded(window.innerWidth >= MIN_SCREEN_WIDTH_TO_EXPAND_SIDEBAR);
  });

  const handleToggleDrawer = () => {
    setIsSidebarOpened(!isSidebarOpened);
  };

  if (syncFailed) {
    return <ErrorState />;
  }

  if (!currentWorkspaceId) {
    return null;
  }

  return (
    <>
      <RestoreOverlay />
      <Sidebar
        handleToggleDrawer={canBeExpanded ? handleToggleDrawer : undefined}
        isOpened={canBeExpanded && isSidebarOpened}
        pathName={pathName}
      />
      <Header isSidebarOpened={isSidebarOpened} />
      {restored && (
        <div
          className={clsx(
            styles.wrapper,
            isSidebarOpened ? styles.WithOpenedSidebar : styles.WithSidebar
          )}
        >
          <BillingLimitsAndOverlays />
          {children}
        </div>
      )}
      <DialogsSpawn />
    </>
  );
};

export default commonDataProviderHoc(MainLayout);
