import { from, map, switchMap } from 'rxjs';
import { inject, injectable } from 'inversify';
import { addRxPlugin } from 'rxdb';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';

addRxPlugin(RxDBUpdatePlugin);

import FileBC from '../domain/FileBC';
import { TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';
import { getBase64FromBlob } from '../../../utils/files';
import { IFilesDao } from '../domain/abstractions/IFilesDao';

@injectable()
export default class FilesDao implements IFilesDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;

  getFiles: IFilesDao['getFiles'] = (itemUuid) => {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db.files.find(
            itemUuid
              ? {
                  selector: {
                    itemUuid: {
                      $eq: itemUuid,
                    },
                  },
                }
              : {}
          ).$;
        })
      )
      .pipe(
        map((docs) => {
          return docs.map((doc) => new FileBC(doc.toMutableJSON()));
        })
      );
  };

  updateFileByUuid: IFilesDao['updateFileByUuid'] = (uuid, updateData) => {
    this.dbManager.getDb().then((db) => {
      db.files
        .findOne({
          selector: {
            uuid: {
              $eq: uuid,
            },
          },
        })
        .exec()
        .then((doc) =>
          doc.atomicUpdate((oldData) => {
            return { ...oldData, ...updateData };
          })
        );
    });
  };

  updateFileThumbnail: IFilesDao['updateFileThumbnail'] = (file, thumbnail) => {
    getBase64FromBlob(thumbnail).then((base64) => {
      this.updateFileByUuid(file.uuid, { thumbnail: base64 });
    });
  };
}
