import { dbVersion } from '../../constants';

export default {
  title: 'customField schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'uuid',
  type: 'object',
  required: ['uuid', 'name', 'fieldType', 'createdAt', 'updatedAt'],
  properties: {
    uuid: {
      type: 'string',
      maxLength: 100,
    },
    name: {
      type: 'string',
    },
    fieldType: {
      type: 'string',
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    workspaceId: {
      type: 'string',
    },
  },
};
