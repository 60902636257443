import { inject, injectable } from 'inversify';
import { Observable, from, switchMap } from 'rxjs';
import { TYPES } from 'src/app/ioc/types';
import { DbManager } from 'src/db/dbManager';
import ContactEntity from 'src/db/entities/contact/ContactEntity';

export interface IContactsDao {
  getContacts(): Observable<ContactEntity[]>;
}

@injectable()
export default class ContactsDao implements IContactsDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;

  getContacts = () => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.contacts.find().$;
      })
    );
  };
}
