import { Observable } from 'rxjs';
import { inject, injectable } from 'inversify';

import FileBC from './FileBC';
import { TYPES } from '../di/types';
import { IFilesUseCases } from './abstractions/IFilesUseCases';
import { IFilesDao } from './abstractions/IFilesDao';
import { IFileUploadStateRepository } from './abstractions/IFileUploadStateRepository';
import { IFileInteractionRepository } from './abstractions/IFileInteractionRepository';
import { IFilesApiService } from './abstractions/IFilesApiService';

@injectable()
export default class FilesUseCases implements IFilesUseCases {
  @inject(TYPES.FilesDao) private dao: IFilesDao;
  @inject(TYPES.FileUploadRepository) private uploadRepository: IFileUploadStateRepository;
  @inject(TYPES.FileInteractionRepository)
  private interactionRepository: IFileInteractionRepository;
  @inject(TYPES.FilesApiService) private api: IFilesApiService;

  getFiles: IFilesUseCases['getFiles'] = (itemUuid): Observable<FileBC[]> => {
    return this.dao.getFiles(itemUuid);
  };

  deleteFile: IFilesUseCases['deleteFile'] = (fileId) => {
    return this.interactionRepository.deleteFile(fileId);
  };

  upload: IFilesUseCases['upload'] = (file, onProgress, onError) => {
    this.uploadRepository.upload(file, onProgress, onError);
  };

  cancelUpload: IFilesUseCases['cancelUpload'] = () => {
    this.uploadRepository.cancelUpload();
  };

  downloadFile: IFilesUseCases['downloadFile'] = (file) => {
    this.interactionRepository.downloadFile(file);
  };

  renameFile: IFilesUseCases['renameFile'] = (newFileName, updatedBy, file) => {
    return this.interactionRepository.renameFile(newFileName, updatedBy, file);
  };

  fetchFile: IFilesUseCases['fetchFile'] = (link) => {
    return this.api.fetchFile(link);
  };

  updateFileThumbnail: IFilesUseCases['updateFileThumbnail'] = (file, thumbnail) => {
    this.dao.updateFileThumbnail(file, thumbnail);
  };

  getDownloadLink: IFilesUseCases['getDownloadLink'] = (uuid) => {
    return this.api.getDownloadLink(uuid);
  };

  getFileUploadingStatus: IFilesUseCases['getFileUploadingStatus'] = () => {
    return this.uploadRepository.uploadingStatus;
  };

  getFileSuccessUploadData: IFilesUseCases['getFileSuccessUploadData'] = () => {
    return this.uploadRepository.successUploadingData;
  };

  getFileUuid: IFilesUseCases['getFileUuid'] = () => {
    return this.uploadRepository.fileUuid;
  };

  updateFileByUuid: IFilesUseCases['updateFileByUuid'] = (fileUuid, updateData) => {
    this.dao.updateFileByUuid(fileUuid, updateData);
  };
}
