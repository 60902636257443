import { Observable, from, map, catchError, throwError } from 'rxjs';
import { AxiosError } from 'axios';
import { injectable } from 'inversify';

import axios from 'src/app/axios';

export interface IPromoCodeApiService {
  checkPromoCode: (code: string, productLabel: string) => Observable<any>; // TODO: fix type + productLabel should be enum
}

const baseURL = '/api/v1/billing-service/promocodes';

@injectable()
export default class PromoCodeApiService implements IPromoCodeApiService {
  checkPromoCode(code, productLabel) {
    const response = axios.get(`${baseURL}/check?code=${code}&product_label=${productLabel}`);

    return from(response).pipe(
      map((response) => response.data),
      catchError((err: AxiosError) => {
        return throwError(() => err.response.status);
      })
    );
  }
}
