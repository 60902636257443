import React from 'react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import clsx from 'clsx';

import Tooltip from 'components/UI/Tooltip';
import styles from './styles.module.scss';

interface ISidebarLinkProps {
  withTooltip: boolean;
  link: string;
  title: string;
  icon: React.ReactNode;
  isActive: boolean;
  isSidebarOpened: boolean;
  tabIndex: number;
  onClick?: () => void;
}

const SidebarLink: React.FC<ISidebarLinkProps> = ({
  withTooltip,
  link,
  title,
  icon,
  isActive,
  isSidebarOpened,
  tabIndex,
  onClick,
}) => {
  const { t } = useTranslation();
  const titleTranslated = t(title);

  const onLinkClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const onEnterClick = ({ keyCode }) => {
    if (keyCode === 13) {
      onLinkClick();
    }
  };

  return (
    <Link href={link}>
      {withTooltip ? (
        <div
          tabIndex={tabIndex}
          role={'button'}
          onKeyPress={onEnterClick}
          onClick={onLinkClick}
          className={clsx(styles.SidebarLink, {
            [styles.Active]: isActive,
            [styles.SidebarClosed]: !isSidebarOpened,
          })}
        >
          <Tooltip title={titleTranslated}>
            <div className={styles.TooltipMask}>
              {icon}
              <span>{titleTranslated}</span>
            </div>
          </Tooltip>
        </div>
      ) : (
        <section
          tabIndex={tabIndex}
          role={'button'}
          onKeyPress={onEnterClick}
          onClick={onLinkClick}
          className={clsx(styles.SidebarLink, {
            [styles.Active]: isActive,
            [styles.SidebarClosed]: !isSidebarOpened,
          })}
        >
          {icon}
          <span>{titleTranslated}</span>
        </section>
      )}
    </Link>
  );
};

export default SidebarLink;
