import { useState } from 'react';
import clsx from 'clsx';
import styles from '../../styles/components/Avatar.module.css';
import ContactIcon from '../../../public/images/icons/contacts.svg';
import contactService from '../../services/contacts/contactService';

const FONT_SIZE_RATIO = 0.4;

export default function Avatar({
  thumbnail,
  name,
  size = 40,
  className,
}: {
  size?: number;
  thumbnail?: string;
  name?: string;
  className?: string;
}) {
  const [loadingFailed, setLoadingFailed] = useState(false);
  const fontSize = size * FONT_SIZE_RATIO;

  const handleLoadingError = () => {
    setLoadingFailed(true);
  };

  return thumbnail && !loadingFailed ? (
    <div className={clsx(styles.imageWrapper, className)} style={{ width: size, height: size }}>
      <img src={thumbnail} width={size} height={size} alt="avatar" onError={handleLoadingError} />
    </div>
  ) : name ? (
    <div
      className={clsx(styles.placeholder, className)}
      style={{
        fontSize,
        width: size,
        height: size,
        backgroundColor: contactService.getContactBackgroundColor(name),
      }}
    >
      {name[0]}
    </div>
  ) : (
    <div
      className={clsx(styles.placeholder, styles.placeholderDefault, className)}
      style={{
        width: size,
        height: size,
        backgroundColor: contactService.getContactBackgroundColor(name),
      }}
    >
      <ContactIcon />
    </div>
  );
}
