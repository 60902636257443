import React from 'react';
import { useTranslation } from 'next-i18next';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';
import { UploadingQueueItem } from '../../../data/FileUploadStateRepository.types';
import FileIcon from '../FileIcon';
import useFileUploadingQueueItemViewModel from './useFileUploadingQueueItemViewModel';
import CheckIcon from 'icons/check.svg';
import RetryIcon from 'public/images/icons/retry.svg';
import CloseIcon from 'icons/close.svg';
import { TFilesErrorData } from '../../../data/FileUploadErrorHandlingRepository';

interface IFilesUploadingQueueItemProps {
  file: UploadingQueueItem;
  addErrorToCounter(e: TFilesErrorData): void;
}

const FilesUploadingQueueItem: React.FC<IFilesUploadingQueueItemProps> = ({
  file,
  addErrorToCounter,
}) => {
  const { t } = useTranslation();
  const { processedFileName, progress, error, onCancelUploadClick, onRetry } =
    useFileUploadingQueueItemViewModel(file, addErrorToCounter);
  const isLoaded = progress === 100;

  return (
    <div className={styles.Item}>
      <div className={styles.Body}>
        <div className={styles.IconFileName}>
          <FileIcon fileType={file.file.type} />
          <div className={styles.FileName}>{processedFileName}</div>
        </div>
        <div className={styles.IconsWrapper}>
          {error ? (
            <>
              {error.canRetry && <RetryIcon onClick={onRetry} className={styles.RetryIcon} />}
              <div className={styles.ErrorIcon}>!</div>
            </>
          ) : isLoaded ? (
            <div className={styles.IconLoaded}>
              <CheckIcon />
            </div>
          ) : (
            <div className={styles.ProgressCancelWrapper}>
              <div className={styles.ProgressWrapper}>
                <CircularProgress
                  className={styles.Progress}
                  style={{
                    color: '#5f48e9',
                  }}
                  size={20}
                  variant={'determinate'}
                  value={progress}
                />
              </div>
              <div className={styles.CancelIcon} onClick={onCancelUploadClick}>
                <CloseIcon />
              </div>
            </div>
          )}
        </div>
      </div>
      {error?.message && <div className={styles.Message}>{t(error.message)}</div>}
    </div>
  );
};

export default FilesUploadingQueueItem;
