import React from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';

const FilePreviewLoader = () => (
  <div className={styles.LoaderWrapper}>
    <CircularProgress
      classes={{
        circle: styles.Circle,
      }}
    />
  </div>
);

export default FilePreviewLoader;
