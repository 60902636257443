import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'next-i18next';

import Avatar from 'components/UI/Avatar';
import Checkbox from 'components/UI/Inputs/Checkbox';
import styles from './styles.module.scss';
import InputBase from 'components/UI/Inputs/InputBase';
import { TeamMember } from 'src/api/workspacesAPI';
import clsx from 'clsx';

interface IFilterAssigneeProps {
  teamMembers: TeamMember[];
  selectedTeamMembers: TeamMember[];
  setSelectedTeamMembers: (cl: TeamMember[]) => void;
}

const FilterAssignee: React.FC<IFilterAssigneeProps> = ({
  teamMembers,
  selectedTeamMembers,
  setSelectedTeamMembers,
}) => {
  const { t } = useTranslation('search');
  const [visibleMembersList, setVisibleMembersList] = useState<TeamMember[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filterInputValue, setFilterInputValue] = useState<string>('');
  const [filteredMembers, setFilteredMembers] = useState<TeamMember[]>(teamMembers?.slice(4) || []);

  useEffect(() => {
    if (teamMembers.length) {
      const preparedList = [];

      teamMembers.every((c) => {
        if (!c.fullName && preparedList.length < 3) {
          return true;
        }

        if (c.fullName && preparedList.length < 3) {
          preparedList.push(c);
          return true;
        } else {
          return false;
        }
      });
      setVisibleMembersList(preparedList);
    }
  }, [teamMembers]);

  if (!visibleMembersList.length) return null;

  const isLongList = teamMembers?.length > 3;
  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'search-assignee-filter-popover' : undefined;

  const isContactChecked = ({ email }: TeamMember) =>
    !!selectedTeamMembers.find((el) => el?.email === email);

  const selectOrRemoveMember = (member: TeamMember) => {
    if (isContactChecked(member)) {
      const filteredList = selectedTeamMembers.filter((el) => el.email !== member.email);
      setSelectedTeamMembers(filteredList);
    } else {
      setSelectedTeamMembers([...selectedTeamMembers, member]);
    }
  };

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const onFilterType = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setFilterInputValue(value);
    setFilteredMembers(
      teamMembers
        .slice(4)
        .filter(({ fullName }) => fullName.toLowerCase().includes(value?.toLowerCase()))
    );
  };

  return (
    <div className={styles.Wrapper}>
      <div>{t('filter_assignee_title')}</div>
      <div className={styles.List}>
        {visibleMembersList.map((member) => {
          return (
            <ContactItem
              onClick={() => selectOrRemoveMember(member)}
              key={member.email}
              member={member}
              isChecked={isContactChecked(member)}
            />
          );
        })}
      </div>
      {isLongList && (
        <button
          className={'leader-search-see-more'}
          onClick={handleClick}
          aria-describedby={popoverId}
        >
          {t('filter_show_more')}
        </button>
      )}
      {isLongList && (
        <Popover
          id={popoverId}
          open={isOpen && isLongList}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{
            paper: styles.Dialog,
          }}
          anchorOrigin={{
            vertical: 0,
            horizontal: -1,
          }}
        >
          <InputBase
            placeholder={t('placeholder_filter_assignee')}
            onChange={onFilterType as React.ChangeEventHandler<HTMLInputElement>}
            value={filterInputValue}
            className={styles.Input}
          />
          <div className={styles.Divider}></div>
          <div>
            {filteredMembers.map((member) => (
              <ContactItem
                onClick={() => selectOrRemoveMember(member)}
                key={member.email}
                member={member}
                isChecked={isContactChecked(member)}
                className={styles.DialogItem}
              />
            ))}
          </div>
        </Popover>
      )}
    </div>
  );
};

interface IContactItemProps {
  member: TeamMember;
  onClick: () => void;
  isChecked: boolean;
  className?: string;
}

const ContactItem: React.FC<IContactItemProps> = ({
  member: { thumbnail, fullName },
  onClick,
  isChecked,
  className,
}) => {
  if (!fullName) return null;

  return (
    <button className={clsx(styles.Item, className)} onClick={onClick}>
      <Checkbox checked={isChecked} />
      <div className={styles.ContactInfo}>
        <Avatar className={styles.Avatar} name={fullName} thumbnail={thumbnail} />
        <span>{fullName}</span>
      </div>
    </button>
  );
};

export default FilterAssignee;
