import * as Sentry from '@sentry/react';
import { actions } from 'slices/boardSlice/slice';
import { selectCurrentWorkspaceId } from 'slices/workspacesSlice';
import getStreamUpdates from './getStreamUpdates';
import StreamUpdatesManager, { UpdateTokens } from './streamUpdatesManager';
import { setDataToLocalStorage, getDataFromLocalStorage } from '../../utils/helpers';
import groupBy from 'lodash/groupBy';

import { AppStore } from '../store/store';

import getDispatchDictionaryUpdate from './getDictionaryUpdate';
import getDispatchDictionaryDelete from './getDictionaryDelete';

export async function updateProcess({
  updateTokens,
  store,
  manager,
}: {
  updateTokens: UpdateTokens;
  store: AppStore;
  manager: StreamUpdatesManager;
}) {
  const dispatchDictionaryUpdate = await getDispatchDictionaryUpdate();
  const dispatchDictionaryDelete = await getDispatchDictionaryDelete();

  let isEmptyUpdates = false;
  let tokens = updateTokens;

  const state = store.getState();

  const currentWorkspaceId = selectCurrentWorkspaceId(state);
  const getUpdatesFromAllStream = async (): Promise<{
    items: any[];
    newUpdateTokens: UpdateTokens;
  }> => {
    const updateTokensEntries = Object.entries(tokens).filter((entry) => {
      const [name] = entry;

      if (name === 'personal' || name === currentWorkspaceId) {
        return true;
      }
    });

    const results = await Promise.all(
      updateTokensEntries.map((entry) => {
        const [name, value] = entry;

        if (name === 'personal') {
          return getStreamUpdates(value);
        } else {
          return getStreamUpdates(value, name);
        }
      })
    );

    return results.reduce(
      (acc: { items: any; newUpdateTokens: UpdateTokens }, resultItem, index) => {
        const [name] = updateTokensEntries[index];

        acc.items = [...acc.items, ...resultItem.items];
        acc.newUpdateTokens[name] = resultItem.update_token;

        return acc;
      },
      { items: [], newUpdateTokens: {} }
    );
  };
  let collectData = [];
  do {
    try {
      if (!manager.isClosed) {
        const { items, newUpdateTokens } = await getUpdatesFromAllStream();

        const mappped = new Map(collectData.map((item) => [item.item_uuid, item]));

        items.forEach((item) => {
          mappped.set(item.item_uuid, item);
        });

        collectData = Array.from(mappped.values());

        tokens = newUpdateTokens;
        isEmptyUpdates = !items.length;
      } else {
        return 'aborted';
      }
    } catch (err) {
      Sentry.captureException(err);

      console.warn(err);
      store.dispatch(actions.setSyncFailed(true));
      isEmptyUpdates = true;
      return 'error';
    }
  } while (!isEmptyUpdates);
  const itemsGroupByType = groupBy(collectData, 'item_type');

  try {
    const promises: Promise<unknown>[] = [];

    for (const key in itemsGroupByType) {
      const { updated, deleted } = groupBy(itemsGroupByType[key], 'status');

      if (deleted && deleted.length) {
        const deletedItems = deleted.map(({ item }) => item);
        if (dispatchDictionaryDelete[key]) {
          promises.push(dispatchDictionaryDelete[key](deletedItems));
        }
      }

      if (updated && updated.length) {
        const updatedItems = updated.map(({ item }) => item);
        const updateAction = dispatchDictionaryUpdate[key];

        if (updateAction) {
          promises.push(updateAction(updatedItems));
        }
      }
    }
    await Promise.allSettled(promises);

    const prevTokens = (getDataFromLocalStorage('update_tokens') as UpdateTokens) || {};
    const mergedTokens = { ...prevTokens, ...tokens };
    setDataToLocalStorage('update_tokens', mergedTokens);
    return 'success';
  } catch (err) {
    console.warn(err);

    const state = store.getState();
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    tokens = {
      personal: '',
      [currentWorkspaceId]: '',
    };
    setDataToLocalStorage('update_tokens', tokens);
  }
}
