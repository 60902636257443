import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
export const useCallDate = (timestamp) => {
  const { t } = useTranslation('common');
  const callDate = useMemo(() => new Date(timestamp * 1000), [timestamp]);
  const [callString, setCallString] = useState(
    callDate.toLocaleDateString('default', {
      day: 'numeric',
      month: 'short',
    })
  );
  useEffect(() => {
    const dateToday = new Date();
    const dateYesterday = new Date();

    dateToday.setHours(0, 0, 0, 0);
    dateYesterday.setDate(dateToday.getDate() - 1);

    if (callDate > dateToday) {
      const hours = callDate.getHours() < 10 ? '0' + callDate.getHours() : callDate.getHours();
      const minutes =
        callDate.getMinutes() < 10 ? '0' + callDate.getMinutes() : callDate.getMinutes();
      setCallString(`${hours}:${minutes}`);
      return;
    }
    if (callDate > dateYesterday) {
      const hours = callDate.getHours() < 10 ? '0' + callDate.getHours() : callDate.getHours();
      const minutes =
        callDate.getMinutes() < 10 ? '0' + callDate.getMinutes() : callDate.getMinutes();
      setCallString(`${t('yesterday')}, ${hours}:${minutes}`);
      return;
    }
    setCallString(
      callDate.toLocaleDateString('default', {
        day: 'numeric',
        month: 'short',
      })
    );
  }, [callDate, t]);
  return callString;
};
