import { inject, injectable } from 'inversify';
import ILoginRepository from '../domain/abstractions/ILoginRepository';
import { authProviders } from 'src/utils/constants';
import { authorizeWith } from 'src/utils';
import { IUserStateRepository } from 'src/features2/user/domain/abstractions/IUserStateRepository';
import { TYPES as USER_TYPES } from 'src/features2/user/di/types';
import { BehaviorSubject } from 'rxjs';
@injectable()
export default class LoginStateRepository implements ILoginRepository {
  private userStateRepository: IUserStateRepository;
  public provider;
  public isAuth = new BehaviorSubject(false);

  constructor(@inject(USER_TYPES.UserStateRepository) UserStateRepository: IUserStateRepository) {
    this.userStateRepository = UserStateRepository;
    this.userStateRepository.getUser().subscribe((user) => {
      this.isAuth.next(!!user);
    });
  }
  logoutUser() {
    this.isAuth.next(false);
  }
  getIsUserAuth() {
    return this.isAuth;
  }
  getAuthProviders() {
    return authProviders;
  }
  callbackAuth(provider: string, token: string) {
    this.userStateRepository
      .fetchUserByTempToken(provider, token)
      .subscribe((isAuth) => this.isAuth.next(isAuth));
  }
  authWith(provider) {
    this.provider = provider;
    authorizeWith[provider.toLowerCase()](this.callbackAuth.bind(this));
  }
}
