import { inject, injectable } from 'inversify';
import { Observable } from 'rxjs';

import LanguageCodes from '../../../enums/LanguageCodes';
import { TYPES } from '../di/types';
import ProfileEntity from '../../../db/entities/profile/ProfileEntity';
import { IProfileSettingsRepository } from '../data/ProfileSettingsRepository';
import { IProfileDeleteRepository } from '../data/ProfileDeleteRepository';
import { IProfileExportRepository } from '../data/ProfileExportRepository';
import { IProfileDataRepository } from '../data/ProfileDataRepository';
import { IProfileRepository } from '../data/ProfileRepository';

export interface IProfileUseCases {
  changeProfileSettings(settings: Partial<ProfileEntity['settings']>);
  changeProfileLanguage(language: LanguageCodes): void;
  exportProfileContacts(): Observable<string>;
  deleteProfile(): Observable<Observable<string>>;
  getProfile(): Observable<ProfileEntity>;
  updateProfile(updateData, workspaceName): Observable<void>;
  fetchProfile(): Observable<ProfileEntity>;
  changeProfileData(profileData: ProfileEntity): void;
}

@injectable()
export default class ProfileUseCases implements IProfileUseCases {
  @inject(TYPES.ProfileSettingsRepository) private settingsRepository: IProfileSettingsRepository;
  @inject(TYPES.ProfileDeleteRepository) private deleteRepository: IProfileDeleteRepository;
  @inject(TYPES.ProfileExportRepository) private exportRepository: IProfileExportRepository;
  @inject(TYPES.ProfileDataRepository) private dataRepository: IProfileDataRepository;
  @inject(TYPES.ProfileRepository) private profileRepository: IProfileRepository;
  changeProfileLanguage = (language: LanguageCodes) => {
    this.settingsRepository.changeProfileLanguage(language);
  };

  changeProfileSettings = (settings) => {
    this.settingsRepository.changeProfileSettings(settings);
  };
  exportProfileContacts = () => {
    return this.exportRepository.exportProfileContacts();
  };

  deleteProfile = () => {
    return this.deleteRepository.deleteProfile();
  };

  fetchProfile = () => {
    return this.profileRepository.fetchProfile();
  };
  getProfile = () => {
    return this.dataRepository.getProfile();
  };
  changeProfileData = (profileData: ProfileEntity) => {
    this.profileRepository.updateProfile(profileData);
  };
  updateProfile = (updateData: ProfileEntity, workspaceName: string) => {
    return this.settingsRepository.updateProfile(updateData, workspaceName);
  };
}
