import React, { useState, memo } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'next-i18next';
import { FixedSizeList as List } from 'react-window';
import Contact from '../../../../../types/contact';
import styles from './styles.module.scss';
import { getHighlightedOverlaps } from 'src/utils/searchUtils';
import SearchIcon from 'icons/search.svg';
import { addHistoryItem } from 'slices/searchSlice';
import { setCurrentContact } from 'slices/contactsSlice';
import SearchHistoryItemTypes from '../../../../enums/SearchHistoryItemTypes';
import SearchItem from '../SearchItem';
import contactService from 'src/services/contacts/contactService';
import useFormatNumber from 'src/app/hooks/ui/useFormatNumber';
import { forwardToContactsPage } from 'src/services/router/appRouterService';
import { setCurrentPageName } from 'slices/uiSlice';

interface IPeopleList {
  data: Contact[];
  searchValue: string;
  closeSearchModal: () => void;
}

const PeopleList: React.FC<IPeopleList> = ({ data, searchValue, closeSearchModal }) => {
  const { t } = useTranslation('search');
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const { formatNumber } = useFormatNumber();
  if (!data?.length) return null;
  const itemHeight = 46;
  const isLongList = data.length > 3;
  const onSeeAllLess = () => {
    setIsExpanded(!isExpanded);
  };
  const PeopleRow = ({ index, style }) => {
    const contact = data[index];
    const { job_title, uuid, suggestion_job_title, phones, is_archived } = contact;
    const subtitle =
      job_title ||
      suggestion_job_title ||
      formatNumber(phones?.[0]?.phone, phones?.[0]?.normalized_phone);
    const correctName = contactService.getContactName(contact);

    const onClick = async () => {
      await forwardToContactsPage();
      dispatch(setCurrentPageName('duplicates:Contacts_title'));
      dispatch(setCurrentContact(uuid));

      closeSearchModal();
      dispatch(
        addHistoryItem({
          type: SearchHistoryItemTypes.CONTACT,
          id: uuid,
          contactData: {
            title: correctName,
            subtitle,
            uuid,
            is_archived,
          },
        })
      );
    };

    return (
      <div style={style}>
        <SearchItem
          key={uuid}
          name={correctName}
          title={getHighlightedOverlaps(correctName, searchValue)}
          subtitle={getHighlightedOverlaps(subtitle, searchValue)}
          onClick={onClick}
          thumbnail={contactService.getContactImage(contact)}
          isArchived={is_archived}
        />
      </div>
    );
  };
  return (
    <div>
      <div>{t('title_contacts', { count: data.length })}</div>
      <div className={clsx(styles.ListWrapper, isExpanded && styles.Expanded)}>
        {isExpanded ? (
          <List
            overscanCount={5}
            itemCount={data.length}
            itemSize={itemHeight}
            height={300}
            width={'100%'}
          >
            {PeopleRow}
          </List>
        ) : (
          data
            .slice(0, 3)
            .map((item, index) => (
              <PeopleRow index={index} key={index} style={{ height: itemHeight }} />
            ))
        )}
      </div>
      {isLongList && (
        <button onClick={onSeeAllLess} className={'leader-search-see-more'}>
          <SearchIcon /> {isExpanded ? t('button_see_less') : t('button_see_all_people')}
        </button>
      )}
      <div className={'leader-search-divider'} />
    </div>
  );
};

export default memo(PeopleList);
