import { useEffect } from 'react';
import clsx from 'clsx';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import { useRouter } from 'next/router';
import {
  enUS,
  ar,
  zhCN,
  zhTW,
  nl,
  fr,
  de,
  he,
  hi,
  it,
  ja,
  ko,
  faIR,
  pt,
  ptBR,
  uk,
  es,
  th,
  tr,
  ru,
} from 'date-fns/locale';

import DatePickerHeader from './DatePickerHeader';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import commonStyles from './styles/Container.module.css';
import dateTimePickerStyles from './styles/DateTimePicker.module.css';

const DatePickerContainer = ({ className, children }) => {
  return (
    <div className={clsx(commonStyles.wrapper, dateTimePickerStyles.wrapper)}>
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  );
};

const DatePickerInput = ({
  value,
  onChange,
  timeIntervals = 15,
}: {
  value: Date;
  onChange: (nextValue: Date) => void;
  timeIntervals?: number;
}) => {
  const { locale } = useRouter();

  useEffect(() => {
    registerLocale('ar', ar);
    registerLocale('de', de);
    registerLocale('en', enUS);
    registerLocale('es', es);
    registerLocale('fa', faIR);
    registerLocale('fr', fr);
    registerLocale('he', he);
    registerLocale('hi', hi);
    registerLocale('it', it);
    registerLocale('ja', ja);
    registerLocale('ko', ko);
    registerLocale('nl-NL', nl);
    registerLocale('pt-BR', ptBR);
    registerLocale('pt-PT', pt);
    registerLocale('ru', ru);
    registerLocale('th', th);
    registerLocale('tr', tr);
    registerLocale('uk', uk);
    registerLocale('zh-CN', zhCN);
    registerLocale('zh-TW', zhTW);
  }, []);

  return (
    <DatePicker
      locale={locale}
      className={commonStyles.wrapper}
      selected={value}
      onChange={onChange}
      inline
      popperPlacement="bottom-start"
      calendarContainer={DatePickerContainer}
      renderCustomHeader={(props) => <DatePickerHeader {...props} />}
      showTimeSelect
      timeIntervals={timeIntervals}
      timeFormat={'HH:mm'}
    />
  );
};

export default DatePickerInput;
