import clsx from 'clsx';
import Avatar from '../../../../../components/UI/Avatar';
import styles from './AssignToSelect.module.css';

export default function Chip({
  className,
  thumbnail,
  email,
  onClick,
  disabled = false,
}: {
  className?: string;
  thumbnail?: string;
  email: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  return (
    <button
      className={clsx(styles.chip, className)}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <Avatar className={styles.Avatar} thumbnail={thumbnail} name={email} size={16} />
      <div className={styles.text}>{email}</div>
    </button>
  );
}
