import BillingDetailsDC from './BillingDetailsDC';
import BillingDetailsEntity from '../../../domain/entities/BillingDetailsEntity';

export const mapBillingDetailsDCtoEntity = (dc: BillingDetailsDC): BillingDetailsEntity => ({
  name: dc.name,
  firstName: dc.first_name,
  lastName: dc.last_name,
  personalIdNumber: dc.personal_id_number,
  phone: dc.phone,
  city: dc.city,
  state: dc.state,
  country: dc.country,
  postalCode: dc.postal_code,
  address: dc.address_line1,
  bluesnapPaymentSources: dc.bluesnap_payment_sources,
  businessEmail: dc.business_email || dc.email, // TODO: check, because currently this field isn't added
});

export const mapBillingDetailsEntityToDC = (entity: BillingDetailsEntity): BillingDetailsDC => ({
  name: entity.name,
  first_name: entity.firstName,
  last_name: entity.lastName,
  personal_id_number: entity.personalIdNumber,
  phone: entity.phone,
  city: entity.city,
  state: entity.state,
  country: entity.country,
  postal_code: entity.postalCode,
  address_line1: entity.address,
  bluesnap_payment_sources: entity.bluesnapPaymentSources,
  business_email: entity.businessEmail,
});
