import { OptionsObject, SnackbarMessage, SnackbarKey } from 'notistack';
import { i18n } from 'next-i18next';

import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';

type TWithErrorSnackbar = ({
  onTry,
  onCatch,
  onFinally,
  enqueueSnackbar,
}: {
  onTry: () => Promise<void>;
  onCatch?: () => void;
  onFinally?: () => void;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}) => Promise<void>;

export const withErrorSnackbar: TWithErrorSnackbar = async ({
  onTry,
  onCatch,
  onFinally,
  enqueueSnackbar,
}) => {
  try {
    await onTry();
  } catch (error) {
    if (onCatch) {
      onCatch();
    }

    const message = !error.response?.status
      ? i18n.t('common:message.network_error')
      : i18n.t('common:message.server_error');

    enqueueSnackbar({ message, variant: MessageType.Error });
  } finally {
    if (onFinally) {
      onFinally();
    }
  }
};
