import { useTranslation } from 'react-i18next';
import Drawer from 'src/components/UI/DrawerWithHeader/DrawerWithHeader';
import ScanningCSV from '../ScanningCSV';
import styles from './styles.module.scss';
import useCSVUploadingViewModel from './useCSVUploadingViewModel';
import ImportCSVUploadingErrors from 'src/features2/importCSV/domain/enums/CSVUploadingErrors';
import ImportCSVConfirmingErrors from 'src/features2/importCSV/domain/enums/CSVConfirmingErrors';
import ConfirmingCSV from '../ConfirmingCSV';
import ErrorInternetConnectionComponent from '../ErrorCases/InternetConnection';
import ErrorSomethingWentWrongComponent from '../ErrorCases/SomethingWentWrong';
import ErrorInvalidCSVComponent from '../ErrorCases/InvalidCSV';
import ErrorTooLargeCSVComponent from '../ErrorCases/TooLargeCSV';
import CSVColumnParser from '../CSVColumnParser';
import ImportDone from '../ImportDone';
import STATUSES from '../../enum/statuses';
const ImportCSVDrawerWrapper = ({
  onClose,
  isOpen,
  file,
  tagName,
  columnId,
}: {
  onClose: () => void;
  isOpen: boolean;
  file: File;
  tagName?: string;
  columnId?: string;
}) => {
  const {
    progress,
    error,
    columns,
    confirmCSVUpload,
    numberContacts,
    cancelUpload,
    status,
    onUploading,
    downloadExampleFile,
    onCloseConfirming,
    errorColumns,
  } = useCSVUploadingViewModel(file, tagName, columnId);

  const { t } = useTranslation('import-csv');
  const ErrorComponentUploadingDict = {
    [ImportCSVUploadingErrors.CSV_FILE_SIZE_ERROR]: (
      <ErrorTooLargeCSVComponent t={t} onClose={onClose} LargeByRows={false} />
    ),
    [ImportCSVUploadingErrors.CSV_FILE_SIZE_ERROR_ROW]: (
      <ErrorTooLargeCSVComponent t={t} onClose={onClose} LargeByRows={true} />
    ),
    [ImportCSVUploadingErrors.NO_INTERNET_CONNECTION]: (
      <ErrorSomethingWentWrongComponent t={t} onClose={onClose} onTryAgain={onUploading} />
    ),
    [ImportCSVUploadingErrors.CSV_FORMAT_ERROR]: (
      <ErrorInvalidCSVComponent t={t} onClose={onClose} onDownloadExample={downloadExampleFile} />
    ),
    [ImportCSVUploadingErrors.COMMON]: (
      <ErrorSomethingWentWrongComponent t={t} onClose={onClose} onTryAgain={onUploading} />
    ),
  };

  const ErrorComponentConfirmingDict = {
    [ImportCSVConfirmingErrors.NO_INTERNET_CONNECTION]: (
      <ErrorInternetConnectionComponent
        t={t}
        onClose={onCloseConfirming}
        onTryAgain={onUploading}
      />
    ),
    [ImportCSVConfirmingErrors.WRONG_COLUMN_TYPE]: (
      <CSVColumnParser
        columns={columns}
        fileName={file?.name}
        onSubmit={confirmCSVUpload}
        errorColumns={errorColumns}
      />
    ),
    [ImportCSVConfirmingErrors.COMMON]: (
      <ErrorSomethingWentWrongComponent t={t} onClose={onClose} onTryAgain={onUploading} />
    ),
  };

  if (!file) return null;

  return (
    <Drawer
      contentClassName={styles.container}
      anchor={'right'}
      open={isOpen}
      title={columns.length ? t('Drawer.title') : ''}
      onClose={() => {
        onClose();
        cancelUpload();
      }}
    >
      {
        {
          [STATUSES.Uploading]: (
            <ScanningCSV
              handleReject={() => {
                cancelUpload();
                onClose();
              }}
              fileUploadingProgress={progress}
            />
          ),
          [STATUSES.UploadSuccess]: (
            <CSVColumnParser columns={columns} fileName={file?.name} onSubmit={confirmCSVUpload} />
          ),
          [STATUSES.UploadErrors]: ErrorComponentUploadingDict[error],
          [STATUSES.Confirming]: <ConfirmingCSV handleReject={onCloseConfirming} />,
          [STATUSES.ConfirmSuccess]: (
            <ImportDone handleClose={onClose} numberContacts={numberContacts} />
          ),
          [STATUSES.ConfirmErrors]: ErrorComponentConfirmingDict[error],
        }[status]
      }
    </Drawer>
  );
};

export default ImportCSVDrawerWrapper;
