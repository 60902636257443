import { inject, injectable } from 'inversify';
import { combineLatest, map, Observable } from 'rxjs';

import LanguageCodes from '../../../enums/LanguageCodes';
import { TYPES } from '../di/types';
import { IProfileApiService } from './network/ProfileApiService';
import { IProfileDao } from './ProfileDao';
import { TYPES as GLOBAL_TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';
import ProfileEntity from '../../../db/entities/profile/ProfileEntity';
import { TYPES as WORKSPACES_TYPES } from '../../workspaces/di/types';
import { IWorkspacesStateRepository } from '../../workspaces/domain/abstractions/IWorkspacesStateRepository';
import { IWorkspacesApiService } from '../../workspaces/domain/abstractions/IWorkspacesApiService';
import Workspace from '../../workspaces/domain/entities/Workspace';
import { IWorkspacesDao } from '../../workspaces/data/WorkspacesDao';
import { mapProfileEntitySettingToDCSettings } from '../../../db/entities/profile/mapper';

export interface IProfileSettingsRepository {
  changeProfileLanguage(language: LanguageCodes): void;
  changeProfileSettings(settings: Partial<ProfileEntity['settings']>): void;
  updateProfile(updateData: ProfileEntity, workspaceName: string): Observable<void>;
}

@injectable()
export default class ProfileSettingsRepository implements IProfileSettingsRepository {
  @inject(TYPES.ProfileApiService) private api: IProfileApiService;
  @inject(TYPES.ProfileDao) private dao: IProfileDao;
  @inject(GLOBAL_TYPES.DbManager) dbManager: DbManager;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  private workspacesRepository: IWorkspacesStateRepository;
  @inject(WORKSPACES_TYPES.WorkspacesApiService)
  private workspacesApiService: IWorkspacesApiService;
  @inject(WORKSPACES_TYPES.WorkspacesDao)
  private workspacesDao: IWorkspacesDao;

  changeProfileSettings(settings: Partial<ProfileEntity['settings']>) {
    this.api.patchProfileSettings(mapProfileEntitySettingToDCSettings(settings));
    const optimisticUpdateSubscription = this.dao.getProfile().subscribe((profile) => {
      const editedProfile = {
        ...profile,
        settings: {
          ...profile.settings,
          ...settings,
        },
      };

      this.dbManager.db.profile.bulkUpsert([editedProfile]).then(() => {
        optimisticUpdateSubscription.unsubscribe();
      });
      this.dao.upsertProfile(editedProfile);
    });
  }
  changeProfileLanguage(language: LanguageCodes) {
    this.api.patchProfileSettings({ language });
    const optimisticUpdateSubscription = this.dao.getProfile().subscribe((profile) => {
      const editedProfile = {
        ...profile,
        settings: {
          ...profile.settings,
          lang: language,
        },
      };

      this.dbManager.db.profile.bulkUpsert([editedProfile]).then(() => {
        optimisticUpdateSubscription.unsubscribe();
      });
      this.dao.upsertProfile(editedProfile);
    });
  }

  updateProfile = (updateData: Partial<ProfileEntity>, workspaceName: string) => {
    return combineLatest(
      [this.dao.getProfile(), this.workspacesRepository.getCurrentWorkspace()],
      (profile, workspace) => {
        const shouldUpdateProfile =
          updateData.fullName !== profile.fullName ||
          updateData.settings.country !== profile.settings.country ||
          updateData.settings.lang !== profile.settings.lang;
        const shouldUpdateWorkspace = workspace.name !== workspaceName;

        if (shouldUpdateProfile) {
          this.api.patchProfile({
            full_name: updateData.fullName,
            settings: {
              language: updateData.settings.lang,
              country: updateData.settings.country,
            },
          });
          this.changeProfileLanguage(updateData.settings.lang as LanguageCodes); // to push data immediately to db
        }

        if (shouldUpdateWorkspace) {
          this.workspacesApiService.patchWorkspace({
            uuid: workspace.id,
            name: workspaceName,
          });
        }

        return [updateData, { ...workspace, name: workspaceName }];
      }
    ).pipe(
      map(([updatedProfile, workspace]: [ProfileEntity, Workspace]) => {
        this.dao.upsertProfile(updatedProfile);
        this.workspacesDao.upsertWorkspaces([workspace]);
      })
    );
  };
}
