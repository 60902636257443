import constants from '../../utils/constants/endpoints';
import { v4 as uuidv4 } from 'uuid';
import { selectCurrentWorkspaceId } from 'slices/workspacesSlice';
import { updateProcess } from './streamUpdate';
import {
  getDataFromLocalStorage,
  getUserLocalStorage,
  removeDataFromLocalStorage,
} from '../../utils/helpers';

import { selectShouldSync as selectShouldSyncTags } from 'slices/tagsBoardSlice';
import { selectShouldSync as selectShouldSyncBoard } from 'slices/boardSlice/slice';

import { AppStore } from '../store/store';
import { container } from '../../app/ioc/container';
import { TYPES } from '../../app/ioc/types';
import { IPersistentRepository } from '../../services/PersistentRepository';
import { TYPES as SYNC_MODULE_TYPES } from '../../features2/sync/di/types';
import { ISyncStateRepository } from '../../features2/sync/domain/abstractions/ISyncStateRepository';

type UpdateTokenName = 'personal' | string;
export type UpdateTokens = Record<UpdateTokenName, string>;

export const streamUpdatesManagerContext: { instance: StreamUpdatesManager | null } = {
  instance: null,
};

export default class StreamUpdatesManager {
  private store: AppStore;
  private sse: EventSource;
  public id: string;
  public isClosed: boolean;

  constructor(store: AppStore) {
    this.store = store;
    this.id = uuidv4();
    this.isClosed = false;
    streamUpdatesManagerContext.instance = this;
  }

  async startWithoutUpdate() {
    // disable streamUpdates on mobile
    const syncStateRepository = container.get<ISyncStateRepository>(
      SYNC_MODULE_TYPES.SyncStateRepository
    );
    const persistentRepository = container.get<IPersistentRepository>(TYPES.PersistentRepository);

    const state = this.store.getState();
    const currentWorkspaceId = selectCurrentWorkspaceId(state);
    syncStateRepository.restoreStatus.next('restored');
    persistentRepository.setWorkspaceRestored(currentWorkspaceId, true);
    return;
  }

  async start() {
    const syncStateRepository = container.get<ISyncStateRepository>(
      SYNC_MODULE_TYPES.SyncStateRepository
    );
    const persistentRepository = container.get<IPersistentRepository>(TYPES.PersistentRepository);

    const updatingWorkspaceIds = [];

    const savedUpdateTokens = getDataFromLocalStorage('update_tokens') as UpdateTokens | undefined;
    const directToken = localStorage.getItem('direct_token');
    const userData = getUserLocalStorage();
    const resultToken = userData?.idToken || directToken;
    if (!resultToken) {
      return;
    }

    const state = this.store.getState();

    const shouldMakeFullSync = !savedUpdateTokens;
    const currentWorkspaceId = selectCurrentWorkspaceId(state);

    let result;
    if (shouldMakeFullSync) {
      const initialUpdateTokens = {
        personal: '',
        [currentWorkspaceId]: '',
      };
      result = await updateProcess({
        updateTokens: initialUpdateTokens,
        store: this.store,
        manager: this,
      });
    } else if (!savedUpdateTokens[currentWorkspaceId]) {
      result = await updateProcess({
        updateTokens: { ...savedUpdateTokens, [currentWorkspaceId]: '' },
        store: this.store,
        manager: this,
      });
    } else {
      result = await updateProcess({
        updateTokens: savedUpdateTokens,
        store: this.store,
        manager: this,
      });
    }
    if (
      syncStateRepository.restoreStatus.value !== 'restored' &&
      result !== 'error' &&
      result !== 'aborted'
    ) {
      syncStateRepository.restoreStatus.next('restored');
      persistentRepository.setWorkspaceRestored(currentWorkspaceId, true);
    }

    const sse = new EventSource(
      `${process.env.NEXT_PUBLIC_API_URL}${constants.stream.sse}?token=${resultToken}`
    );
    this.sse = sse;
    sse.onmessage = async (event) => {
      const shouldSyncTags = selectShouldSyncTags(this.store.getState());
      const shouldSyncBoard = selectShouldSyncBoard(this.store.getState());

      if (!shouldSyncTags && !shouldSyncBoard) {
        try {
          const workspaceId = JSON.parse(event.data).workspace_id || 'personal';
          const savedTokens = getDataFromLocalStorage('update_tokens') as UpdateTokens;
          const isUpdating = updatingWorkspaceIds.includes(workspaceId);
          const currentWorkspaceId = selectCurrentWorkspaceId(state);

          const shouldUpdate = workspaceId === 'personal' || workspaceId === currentWorkspaceId;
          if (!isUpdating && !!savedTokens[workspaceId] && shouldUpdate) {
            updatingWorkspaceIds.push(workspaceId);
            await updateProcess({
              updateTokens: {
                [workspaceId]: savedTokens[workspaceId],
              },
              store: this.store,
              manager: this,
            });
            updatingWorkspaceIds.splice(updatingWorkspaceIds.indexOf(workspaceId), 1);
          }
        } catch {
          console.warn('SSE data parsing error!');
        }
      }
    };
    sse.onerror = (e) => {
      console.warn(e);
      sse.close();
    };
  }

  forceGetStreamUpdates = () => {
    const savedUpdateTokens = getDataFromLocalStorage('update_tokens') as UpdateTokens | undefined;

    // Extra check to avoid errors.
    // we don't have tokens on mobile since stream update is turned off on mobile web
    if (savedUpdateTokens) {
      updateProcess({
        updateTokens: savedUpdateTokens,
        store: this.store,
        manager: this,
      });
    }
  };

  fullResync = async () => {
    const persistentRepository = container.get<IPersistentRepository>(TYPES.PersistentRepository);
    const syncStateRepository = container.get<ISyncStateRepository>(
      SYNC_MODULE_TYPES.SyncStateRepository
    );
    const state = this.store.getState();
    const currentWorkspaceId = selectCurrentWorkspaceId(state);

    persistentRepository.setWorkspaceRestored(currentWorkspaceId, false);
    syncStateRepository.restoreStatus.next('isRestoring');

    const initialUpdateTokens = {
      personal: '',
      [currentWorkspaceId]: '',
    };
    syncStateRepository.restoreStatus.next('idle');
    removeDataFromLocalStorage('update_tokens');
    const result = await updateProcess({
      updateTokens: initialUpdateTokens,
      store: this.store,
      manager: this,
    });
    if (syncStateRepository.restoreStatus.value !== 'restored' && result !== 'error') {
      syncStateRepository.restoreStatus.next('restored');
      persistentRepository.setWorkspaceRestored(currentWorkspaceId, true);
    }
  };

  destroy = () => {
    this.isClosed = true;
    streamUpdatesManagerContext.instance = null;
    if (this.sse) {
      this.sse.close();
    }
  };
}
