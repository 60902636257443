import { useEffect, useState } from 'react';

import { useInjection } from '../../app/ioc/ioc.react';
import { IFilesUseCases } from '../../features2/files/domain/abstractions/IFilesUseCases';
import { TYPES } from '../../features2/files/di/types';
import FileBC from '../../features2/files/domain/FileBC';

const useSearchViewModel = () => {
  const { getFiles } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const [files, setFiles] = useState<FileBC[]>([]);

  useEffect(() => {
    getFiles().subscribe((filesList) => setFiles(filesList));
  }, []);

  return {
    files,
  };
};

export default useSearchViewModel;
