import { useTranslation } from 'next-i18next';

import Popper, { PopperProps } from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import WorkspaceSelect from '../../../../../../features/workspaces/WorkspaceSelect/WorkspaceSelect';
import { useAppSelector } from '../../../../../../app/hooks';
import { selectEnrichedWorkspaces, selectCurrentWorkspaceId } from 'slices/workspacesSlice';
import useLogout from '../../../../../../features/authorization/hooks/useLogout';
import WorkspacesPromo from 'src/features2/billing/ui/promos/WorkspacesPromo/WorkspacesPromo';

import { useInjection } from 'src/app/ioc/ioc.react';
import { IWorkspacesUseCases } from 'src/features2/workspaces/domain/abstractions/IWorkspacesUseCases';
import { TYPES } from 'src/features2/workspaces/di/types';

import Avatar from '../../../../Avatar';
import styles from './UserActionsPopup.module.css';
import { useProfile } from '../../../../../../features2/profile/ui/hooks';

export interface UserActionsPopupProps {
  onClose: () => void;
  anchorEl: PopperProps['anchorEl'];
  placement?: PopperProps['placement'];
  transformOriginStyle?: string;
}

export default function UserActionsPopup(props: UserActionsPopupProps) {
  const { t } = useTranslation();

  const { changeWorkspace } = useInjection<IWorkspacesUseCases>(TYPES.WorkspacesUseCases);

  const { onClose, anchorEl, placement, transformOriginStyle } = props;
  const { profile } = useProfile();

  const workspaces = useAppSelector(selectEnrichedWorkspaces);
  const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);

  const logout = useLogout();

  const onLogout = () => {
    logout();
  };

  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement={placement || 'top-end'}
      className={styles.Popper}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: transformOriginStyle || 'top right 0' }}
        >
          <div className={styles.contentWrapper}>
            <ClickAwayListener onClickAway={onClose}>
              <div className={styles.content}>
                <div className={styles.user}>
                  <Avatar
                    thumbnail={profile?.picture}
                    name={profile.fullName}
                    size={65}
                    className={styles.userPic}
                  />
                  <div className={styles.name}>{profile.fullName}</div>
                  <div className={styles.email}>{profile.email}</div>
                  <button className={styles.logoutButton} onClick={onLogout}>
                    {t('common:action.logout')}
                  </button>
                </div>
                {!!currentWorkspaceId && (
                  <div className={styles.workspaces}>
                    <div className={styles.title}>{t('common:workspace.workspace')}</div>
                    <div className={styles.workspacesSelectWrapper}>
                      <WorkspaceSelect
                        name="workspaces"
                        list={workspaces}
                        value={currentWorkspaceId}
                        onChange={(e) => {
                          onClose();
                          changeWorkspace(e);
                        }}
                        className={styles.workspacesSelect}
                      />
                    </div>
                  </div>
                )}
                <WorkspacesPromo onClick={onClose} />
              </div>
            </ClickAwayListener>
          </div>
        </Grow>
      )}
    </Popper>
  );
}
