import * as Sentry from '@sentry/react';

import { injectable, inject, postConstruct } from 'inversify';
import { map } from 'rxjs';
import { IMonitoringSystemRepository } from '../domain/abstractions/IMonitoringSystemRepository';

import { TYPES as USER_MODULE_TYPES } from 'src/features2/user/di/types';
import { IUserStateRepository } from 'src/features2/user/domain/abstractions/IUserStateRepository';

@injectable()
export default class MonitoringSystemRepository implements IMonitoringSystemRepository {
  private monitorSystemSdk = Sentry;
  @inject(USER_MODULE_TYPES.UserStateRepository) private userStateRepository: IUserStateRepository;

  @postConstruct()
  init() {
    this.userStateRepository
      .getCurrentUser()
      .pipe(
        map((user) => {
          if (user) {
            Sentry.setTag('user_email', user.email);
            Sentry.setTag('user_current_workspace', user.currentWorkspace);
          }
          return true;
        })
      )
      .subscribe();
  }

  captureException(e) {
    this.monitorSystemSdk.captureException(e);
  }
}
