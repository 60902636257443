import { Container } from 'inversify';
import WorkspacesDao, { IWorkspacesDao } from '../data/WorkspacesDao';
import WorkspacesApiService from '../data/network/WorkspacesApiService';
import WorkspacesStateRepository from '../data/WorkspacesStateRepository';
import { IWorkspacesStateRepository } from '../domain/abstractions/IWorkspacesStateRepository';
import { IWorkspacesUseCases } from '../domain/abstractions/IWorkspacesUseCases';
import WorkspacesUseCases from '../domain/WorkspacesUseCases';
import { TYPES } from './types';
import { IWorkspacesApiService } from '../domain/abstractions/IWorkspacesApiService';

export const bindWorkspacesModule = (container: Container) => {
  container.bind<IWorkspacesApiService>(TYPES.WorkspacesApiService).to(WorkspacesApiService);
  container.bind<IWorkspacesDao>(TYPES.WorkspacesDao).to(WorkspacesDao);
  container
    .bind<IWorkspacesStateRepository>(TYPES.WorkspacesStateRepository)
    //@ts-ignore
    .to(WorkspacesStateRepository)
    .inSingletonScope();
  //@ts-ignore
  container.bind<IWorkspacesUseCases>(TYPES.WorkspacesUseCases).to(WorkspacesUseCases);
};
