import { useEffect } from 'react';

const useWindowResizeListener = (listener) => {
  useEffect(() => {
    window.addEventListener('resize', listener);

    listener(undefined);

    return () => window.removeEventListener('resize', listener);
  }, [listener]);
};

export default useWindowResizeListener;
