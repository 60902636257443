import { forwardRef } from 'react';
import clsx from 'clsx';
import MUIMenu, { MenuProps } from '@material-ui/core/Menu';
import styles from './Menu.module.css';

export type { MenuProps };

const Menu = forwardRef(function Menu(props: MenuProps, ref) {
  return <MUIMenu ref={ref} {...props} className={clsx(styles.Menu, props.className)} />;
});

export default Menu;
