import { InputAdornment, InputBase } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';

import styles from './styles.module.scss';
import SearchIcon from 'icons/search.svg';
import { useAppSelector } from 'src/app/hooks';
import { selectNotHiddenContacts } from 'slices/contactsSlice';
import { selectSearchHistory } from 'slices/searchSlice';
import PeopleList from './components/PeopleList';
import NotesList from './components/NotesList';
import RecentlySearch from './components/RecentlySearch';
import { selectNotes } from 'slices/notesSlice';
import { selectTasks } from 'slices/tasksSlice/tasksSlice';
import TasksList from './components/TasksList';
import ProposalsList from './components/ProposalsList';
import CompaniesList from './components/CompaniesList';
import { makeSelectVisibleProposals } from 'slices/proposalsSlice';
import FilterTags from './components/FilterTags';
import FilterStatus from './components/FilterStatus';
import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import { ColumnModel } from 'src/api/boardAPI';
import SearchResults from './services/SearchResults';
import Contact from 'types/contact';
import EmptySearchBackground from './components/EmptySearchBackground';
import NoResults from './components/NoResults';
import CloseIcon from 'icons/close.svg';
import FilterAssignee from './components/FilterAssignee';
import {
  makeSelectTeamMembersAssignedContactsMap,
  selectAcceptedTeamMembers,
  selectCurrentWorkspace,
  selectIsAdminOrOwner,
} from 'slices/workspacesSlice';
import { TeamMember } from 'src/api/workspacesAPI';
import { selectUserProfile } from 'slices/userSlice';
import FilesList from './components/FilesList';
import useSearchViewModel from './useSearchViewModel';

const Search = () => {
  const { t } = useTranslation('search');
  const { files } = useSearchViewModel();
  const [searchValue, setSearchValue] = useState('');
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [filteredNotes, setFilteredNotes] = useState(null);
  const [filteredTasks, setFilteredTasks] = useState(null);
  const [filteredProposals, setFilteredProposals] = useState(null);
  const [filteredCompanies, setFilteredCompanies] = useState(null);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedTags, setSelectedTags] = useState<TagBoardColumn[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<ColumnModel[]>([]);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>([]);
  const [contactsWithCompanies, setContactsWithCompanies] = useState<Contact[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [popoverLeftCoordinates, setPopoverLeftCoordinates] = useState<number>(0);
  const [popoverTopCoordinates, setPopoverTopCoordinates] = useState<number>(0);
  const contacts = useAppSelector(selectNotHiddenContacts);
  const notes = useAppSelector(selectNotes);
  const tasks = useAppSelector(selectTasks);
  const proposals = useAppSelector(makeSelectVisibleProposals());
  const teamMembers = useAppSelector(selectAcceptedTeamMembers);
  const searchHistory = useAppSelector(selectSearchHistory);
  const teamMembersContactsMap = useAppSelector(makeSelectTeamMembersAssignedContactsMap);
  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const userData = useAppSelector(selectUserProfile);
  const userEmail = userData?.email;

  const isAllContentVisible = currentWorkspace?.memberAccessAllContacts ? true : isAdmin;
  const closeRef = useRef<HTMLButtonElement>(null);
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const inputRef = useRef(null);
  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'search-window' : undefined;
  const isNoResults =
    !filteredContacts?.length &&
    !filteredNotes?.length &&
    !filteredProposals?.length &&
    !filteredTasks?.length &&
    !filteredCompanies?.length &&
    !filteredFiles.length;
  const isFilterSelected = Boolean(
    selectedTags?.length || selectedStatuses?.length || selectedTeamMembers?.length
  );
  const isNoResultsWithFilters = !searchValue && isFilterSelected && isNoResults;
  const isRecentlySearchVisible = !isFilterSelected && !searchValue && searchHistory.length;

  useEffect(() => {
    const contactsWithCompanies = contacts.filter(
      ({ company, suggestion_company }) => !!company || !!suggestion_company
    );

    setContactsWithCompanies(contactsWithCompanies);
  }, [contacts]);

  useEffect(() => {
    makeSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags, selectedStatuses, selectedTeamMembers]);

  useLayoutEffect(() => {
    const { right, bottom } = inputRef.current.getBoundingClientRect();

    setPopoverLeftCoordinates(right);
    setPopoverTopCoordinates(bottom + 20);
  }, []);

  const makeSearch = (searchVal = searchValue) => {
    const {
      filteredContacts,
      filteredNotes,
      filteredTasks,
      filteredProposals,
      filteredContactsWithCompanies,
      filteredFiles,
    } = new SearchResults({
      searchValue: searchVal,
      selectedTags,
      selectedStatuses,
      selectedTeamMembers,
      teamMembersContactsMap,
      contactsWithCompanies,
      isAllContentVisible,
      userEmail,
      contacts,
      notes,
      tasks,
      proposals,
      files,
    });

    setFilteredContacts(filteredContacts);
    setFilteredNotes(filteredNotes);
    setFilteredTasks(filteredTasks);
    setFilteredProposals(filteredProposals);
    setFilteredCompanies(filteredContactsWithCompanies);
    setFilteredFiles(filteredFiles);
  };

  const onSearchType = (e) => {
    clearTimeout(timeout.current);

    setSearchValue(e.target.value);

    timeout.current = setTimeout(() => {
      makeSearch(e.target.value);
    }, 500);
  };

  const onFoundContactClick = () => {
    handleClose();
    setSearchValue('');
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue('');

    setFilteredContacts([]);
    setFilteredNotes([]);
    setFilteredTasks([]);
    setFilteredProposals([]);
    setFilteredCompanies([]);
    setSelectedTags([]);
    setSelectedStatuses([]);
  };

  const handleClick = (e) => setAnchorEl(e.currentTarget);
  return (
    <div className={styles.Wrapper}>
      <InputBase
        onClick={handleClick}
        placeholder={t('placeholder_search')}
        className={clsx(styles.SearchInput, isOpen && styles.Active)}
        value={searchValue}
        onChange={onSearchType}
        ref={inputRef}
        startAdornment={
          <InputAdornment position="start">
            <button
              aria-describedby={popoverId}
              className={styles.OpenButton}
              onClick={handleClick}
              ref={closeRef}
            >
              <SearchIcon />
            </button>
          </InputAdornment>
        }
        endAdornment={
          searchValue && (
            <InputAdornment position="end">
              <button className={styles.ClearButton}>
                <CloseIcon />
              </button>
            </InputAdornment>
          )
        }
        inputProps={{ 'data-hj-allow': '' }}
      />
      {isOpen && (
        <Popover
          id={popoverId}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: styles.SearchWindow }}
          open={isOpen}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorPosition={{
            top: popoverTopCoordinates,
            left: popoverLeftCoordinates,
          }}
          anchorReference={'anchorPosition'}
          disablePortal
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
        >
          <div className={styles.ResultsSection}>
            {!isRecentlySearchVisible &&
              !searchHistory.length &&
              !isFilterSelected &&
              !searchValue && <EmptySearchBackground />}
            {isRecentlySearchVisible ? (
              <RecentlySearch closeSearchModal={onFoundContactClick} history={searchHistory} />
            ) : (
              ((searchValue && isNoResults) || isNoResultsWithFilters) && <NoResults />
            )}
            <PeopleList
              closeSearchModal={onFoundContactClick}
              searchValue={searchValue}
              data={filteredContacts}
            />
            <CompaniesList
              closeSearchModal={onFoundContactClick}
              companiesList={filteredCompanies}
              searchValue={searchValue}
            />
            <NotesList
              closeSearchModal={onFoundContactClick}
              searchValue={searchValue}
              data={filteredNotes}
              contacts={contacts}
            />
            <TasksList
              closeSearchModal={onFoundContactClick}
              searchValue={searchValue}
              data={filteredTasks}
              contacts={contacts}
            />
            <ProposalsList
              closeSearchModal={onFoundContactClick}
              searchValue={searchValue}
              data={filteredProposals}
            />
            <FilesList
              data={filteredFiles}
              searchValue={searchValue}
              closeSearchModal={onFoundContactClick}
            />
          </div>
          <div className={styles.FiltersSection}>
            <FilterTags selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
            <FilterStatus
              selectedStatuses={selectedStatuses}
              setSelectedStatuses={setSelectedStatuses}
            />
            {isAllContentVisible && (
              <FilterAssignee
                teamMembers={teamMembers}
                selectedTeamMembers={selectedTeamMembers}
                setSelectedTeamMembers={setSelectedTeamMembers}
              />
            )}
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Search;
