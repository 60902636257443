import { useRouter } from 'next/router';

import routes from 'src/utils/constants/appRoutes';

const usePlansAndPurchasePagesDetection = () => {
  const router = useRouter();
  const isPlansOrPurchasesPage =
    router.asPath.includes(routes.PLANS_PAGE) ||
    router.asPath.includes(routes.PAYMENT_DETAILS_PAGE);

  return {
    isPlansOrPurchasesPage,
  };
};

export default usePlansAndPurchasePagesDetection;
