import { catchError, from, map, of } from 'rxjs';
import { inject, injectable } from 'inversify';

import axios from '../../../../app/axios';
import thirdPartyAxios from '../../../../app/thirdPartyAxios';
import { UploadDataResponse } from './FilesApiService.types';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { IFilesApiService } from '../../domain/abstractions/IFilesApiService';
import { TYPES } from '../../../../app/ioc/types';
import type { IPersistentRepository } from '../../../../services/PersistentRepository';
import RequestHeaders from '../../../../enums/RequestHeaders';
import ContentTypes from '../../../../enums/ContentTypes';

const API_MEDIA = '/api/v1/media';
const API_MEDIA_FILES = `${API_MEDIA}/files`;
const routes = {
  upload: `${API_MEDIA}/upload`,
  files: API_MEDIA_FILES,
  getDownloadLink: `${API_MEDIA_FILES}/download-links`,
};

@injectable()
export default class FilesApiService implements IFilesApiService {
  @inject(TYPES.PersistentRepository) private localStorageRepository: IPersistentRepository;

  getUploadData: IFilesApiService['getUploadData'] = (fileType, isPublic) => {
    const requestData: { mime_type: string; is_public?: boolean } = {
      mime_type: fileType,
    };

    if (isPublic) {
      requestData.is_public = true;
    }

    const request = axios.post<UploadDataResponse>(routes.upload, requestData, {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(
      map((response) => response.data),
      catchError((error: AxiosError) => {
        return of(error.response.status);
      })
    );
  };

  uploadFileToStorage: IFilesApiService['uploadFileToStorage'] = (
    uploadData,
    file,
    onProgress,
    abortController
  ) => {
    const { postParams } = uploadData;
    const formData = new FormData();
    const requestParams = postParams.params;

    for (const key in requestParams) {
      formData.append(key, requestParams[key]);
    }

    formData.append('file', file);

    const config = {
      headers: {
        [RequestHeaders.CONTENT_TYPE]: ContentTypes.MULTIPART_FORM_DATA,
      },
      signal: abortController.signal,
      onUploadProgress: onProgress
        ? (progress) => {
            onProgress(Math.floor(progress.loaded * 100) / progress.total);
          }
        : undefined,
    } as AxiosRequestConfig;

    const request = thirdPartyAxios.post(postParams.endpoint_url, formData, config);

    return from(request).pipe(map((response) => response.data));
  };

  saveFileToBackend: IFilesApiService['saveFileToBackend'] = (postData) => {
    const request = axios.post<unknown>(routes.files, postData, {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(map((response) => response.data));
  };

  deleteFile: IFilesApiService['deleteFile'] = (fileId) => {
    const request = axios.delete(routes.files, {
      data: fileId,
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(map((response) => response.data));
  };

  updateFile: IFilesApiService['updateFile'] = (file) => {
    const request = axios.patch(routes.files, file, {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(map((response) => response.data));
  };

  fetchFile: IFilesApiService['fetchFile'] = (link) => {
    return from(fetch(link)).pipe((response) => response);
  };

  getDownloadLink: IFilesApiService['getDownloadLink'] = (uuid) => {
    const request = axios.post(routes.getDownloadLink, [uuid], {
      headers: {
        [RequestHeaders.WORKSPACE_ID]: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    });

    return from(request).pipe(map((response) => response.data));
  };
}
