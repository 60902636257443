import { injectable } from 'inversify';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../utils/helpers';
import { UpdateTokens } from '../app/streamUpdate/streamUpdatesManager';

enum StorageKeys {
  CurrentWorkspaceId = 'current_workspace_id',
  UpdateTokens = 'update_tokens',
  User = 'user',
  IsRestored = 'is_restored',
  DirectToken = 'direct_token',
  RedirectAfterLogin = 'redirect_after_login',
}

export interface IPersistentRepository {
  getCurrentWorkspaceId: () => string | undefined;
  setCurrentWorkspaceId: (id: string) => void;
  setRedirectAfterLogin: (url: string) => void;
  getRedirectAfterLogin: () => string;
  getUpdateTokens: () => UpdateTokens | undefined;
  setUpdateTokens: (tokens: UpdateTokens) => void;
  getRestoredWorkspaces: () => Record<string, boolean>;
  setWorkspaceRestored: (workspaceId: string, isRestored: boolean) => void;
  clear: () => void;
}

@injectable()
export class LocalStorageRepository implements IPersistentRepository {
  getCurrentWorkspaceId = () => {
    return getDataFromLocalStorage(StorageKeys.CurrentWorkspaceId) as string | undefined;
  };

  setCurrentWorkspaceId = (id: string) => {
    setDataToLocalStorage(StorageKeys.CurrentWorkspaceId, id);
  };

  getUpdateTokens = (): UpdateTokens | undefined => {
    return getDataFromLocalStorage(StorageKeys.UpdateTokens);
  };

  setUpdateTokens = (tokens: UpdateTokens) => {
    setDataToLocalStorage(StorageKeys.UpdateTokens, tokens);
  };

  getRestoredWorkspaces = () => {
    return (getDataFromLocalStorage(StorageKeys.IsRestored) || {}) as Record<string, boolean>;
  };

  setWorkspaceRestored = (workspaceId: string, isRestored: boolean) => {
    const prevData = this.getRestoredWorkspaces();

    setDataToLocalStorage(StorageKeys.IsRestored, { ...prevData, [workspaceId]: isRestored });
  };

  setRedirectAfterLogin = (url: string): void => {
    setDataToLocalStorage(StorageKeys.RedirectAfterLogin, url);
  };

  getRedirectAfterLogin = (): string => {
    return getDataFromLocalStorage(StorageKeys.RedirectAfterLogin);
  };

  clear = () => {
    for (const key of Object.values(StorageKeys)) {
      localStorage.removeItem(key);
    }
  };
}
