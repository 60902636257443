export const enum LIMITS_TYPES {
  LIMITED = 'LIMITED',
  BLOCKED_FEATURE = 'BLOCKED_FEATURE',
}
export const enum BLOCKED_FEATURED_TYPES {
  FILES = 'files',
  INSIGHTS = 'insights',
  INVITE_TEAMMATE = 'invite_teammate',
  IMPORT_CONTACT = 'import_contact',
  INTEGRATIONS = 'integrations',
}
