import { useState } from 'react';
import ProposalDrawerContext, {
  ProposalDrawerContext as IProposalDrawerContext,
} from './ProposalDrawerContext';
import ProposalDrawer from './ProposalDrawer';
import { CreateFormOptions } from '../ProposalForm/CreateForm';
import { UpdateFormOptions } from '../ProposalForm/UpdateForm';
import { useAppStore } from '../../../app/hooks';
import { trackEvent } from 'src/analytics/amplitude';

import { useQuotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';
import FB_EVENTS from 'src/analytics/FB/domain/enums/FB_EVENTS';
import useFBAnalytics from 'src/analytics/FB/ui/useFBAnalytics';

export type Mode = 'create' | 'update';

export type FormOptions = CreateFormOptions | UpdateFormOptions;

export default function ProposalDrawerProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<{ mode?: Mode; formOptions?: FormOptions; type?: string }>({
    mode: undefined,
    formOptions: undefined,
    type: '',
  });

  const { checkLimits } = useQuotesLimits();
  const { trackEvent: FBTrackEvent } = useFBAnalytics();
  const store = useAppStore();

  const openCreateForm: IProposalDrawerContext['openCreateForm'] = ({
    defaultValues,
    onSuccess,
    shouldPinContact,
    type,
  }) => {
    checkLimits() &&
      setState({
        type,
        mode: 'create',
        formOptions: {
          defaultValues,
          onSuccess: () => {
            FBTrackEvent('trackCustom', FB_EVENTS.ADD_QUOTE);
            onSuccess();
          },
          shouldPinContact,
        },
      });
  };

  const openUpdateForm: IProposalDrawerContext['openUpdateForm'] = ({ uuid, onSuccess }) => {
    const proposal = store
      .getState()
      .proposals.list.find((proposalItem) => proposalItem.uuid === uuid);

    const onSuccessWithTracker = () => {
      onSuccess();
      trackEvent('Edit_quote');
    };
    setState({
      mode: 'update',
      formOptions: { data: proposal, onSuccess: onSuccessWithTracker },
    });
  };

  const openDuplicateForm: IProposalDrawerContext['openDuplicateForm'] = ({
    uuid,
    onSuccess,
    shouldPinContact,
  }) => {
    const proposal = store
      .getState()
      .proposals.list.find((proposalItem) => proposalItem.uuid === uuid);

    setState({
      mode: 'create',
      type: '_',
      formOptions: {
        defaultValues: proposal,
        onSuccess: () => {
          onSuccess();
          trackEvent('Duplicate_quote');
        },
        shouldPinContact,
      },
    });
  };

  const close = () => {
    setState({ mode: undefined, formOptions: undefined });
  };

  return (
    <ProposalDrawerContext.Provider
      value={{ isOpen: !!state.mode, openCreateForm, openUpdateForm, openDuplicateForm, close }}
    >
      {children}
      <ProposalDrawer
        mode={state.mode}
        formOptions={state.formOptions}
        onClose={close}
        type={state.type}
      />
    </ProposalDrawerContext.Provider>
  );
}
