import { Container } from 'inversify';
import { TYPES } from './types';
import { IContactsUseCases } from '../domain/abstractions/IContactsUseCases';
import ContactsUseCases from '../domain/ContactsUseCases';
import { IContactsStateRepository } from '../domain/abstractions/IContactsStateRepository';
import ContactsStateRepository from '../data/ContactsStateRepository';
import ContactsDao, { IContactsDao } from '../data/ContactsDao';

export const bindContactsModule = (container: Container) => {
  container.bind<IContactsUseCases>(TYPES.ContactsUseCases).to(ContactsUseCases);
  container.bind<IContactsDao>(TYPES.ContactsDao).to(ContactsDao);
  container
    .bind<IContactsStateRepository>(TYPES.ContactsStateRepository)
    .to(ContactsStateRepository)
    .inSingletonScope();
};
