import { useState } from 'react';
import { useObservable, useSubscription } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';
import { TYPES } from '../../di/types';
import { IWorkspacesUseCases } from '../../domain/abstractions/IWorkspacesUseCases';
import Workspace from '../../domain/entities/Workspace';

export const useCurrentWorkspace = () => {
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>(null);
  const useCases = useInjection<IWorkspacesUseCases>(TYPES.WorkspacesUseCases);
  const currentWorkspaceObservable = useObservable(() => {
    return useCases.getCurrentWorkspace();
  }, []);
  useSubscription(currentWorkspaceObservable, (workspaceObservable) => {
    setCurrentWorkspace(workspaceObservable);
  });

  return {
    currentWorkspace,
  };
};
