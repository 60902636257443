import ProposalSettingsDC from '../proposalSettings/ProposalSettingsDC';

export const enum DISCOUNT_TYPES {
  VALUE = 'VALUE',
  PERCENT = 'PERCENT',
}

type ProposalRelationsDC = [
  {
    type: 'contact';
    id: string;
  }
];

export default interface ProposalDC {
  uuid: string;
  number: number | null;
  title: string | null;
  client: string;
  description: string | null;
  discount_type: DISCOUNT_TYPES;
  discount_amount: number;
  relations: ProposalRelationsDC;
  items: {
    order: number;
    description: string | null;
    qty: number;
    amount: number;
  }[];
  is_draft: boolean | null;
  not_show: boolean | null;
  sent_at: number | null; // timestamp in seconds or null
  created_at: number | null; // timestamp in seconds or null
  updated_at: number | null; // timestamp in seconds or null
  created_by: string | null;
  updated_by: string | null;
  settings: ProposalSettingsDC;
}
