import { useState, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import ArrowBack from '@material-ui/icons/ArrowBack';

import DefaultDialog from '../../../components/UI/Dialogs/DefaultDialog/DefaultDialog';
import useMainForm, { FormValues as MainFormValues } from './subForms/MainSubForm/useMainSubForm';
import MainFormLayout from './subForms/MainSubForm/Layout';

import SettingsFormLayout from './subForms/SettingsSubForm/Layout';
import useSettingsForm, {
  FormValues as SettingsFormValues,
} from './subForms/SettingsSubForm/useSettingsSubForm';
import useGetRelatedContactId from './useGetRelatedContactId';
import useSaveSettings from './useSaveSettings';

import styles from './ProposalForm.module.scss';

export interface GenericProposalFormProps {
  contactPinned?: boolean;
  defaultValues?: MainFormValues;
  onFormDataChanged?: () => void;
  onSubmit: (values: MainFormValues) => Promise<void>;
  onDownload: (values: MainFormValues) => void | Promise<void>;
  onPreview: (values: MainFormValues) => void | Promise<void>;
}

export default function GenericForm({
  contactPinned,
  defaultValues,
  onFormDataChanged,
  onPreview,
  onDownload,
  onSubmit,
}: GenericProposalFormProps) {
  const { t } = useTranslation('proposal-form');

  const getRelatedContactId = useGetRelatedContactId();
  const saveSettings = useSaveSettings();

  const [currentSection, setCurrentSection] = useState<'main' | 'settings'>('main');
  const goToSettings = () => setCurrentSection('settings');
  const goToMainSection = () => {
    setCurrentSection('main');
  };

  const [discardSettingsDialogOpen, setDiscardSettingsDialogOpen] = useState(false);

  const mainForm = useMainForm({
    ...defaultValues,
  });
  const settingsForm = useSettingsForm();

  const handleSubmitSettingsForm = async (settings: SettingsFormValues) => {
    const onUpdate = () => {
      goToMainSection();

      settingsForm.reset(settings);
    };

    await saveSettings(settings, onUpdate);
  };

  const handleContactDuplication = async () => {
    const selectedContactId = mainForm.getValues().contactId;
    const relatedContactId = await getRelatedContactId(selectedContactId);

    // for the case when the contact was duplicated for the workspace
    if (selectedContactId !== relatedContactId) {
      mainForm.setValue('contactId', relatedContactId);
    }
  };

  const handlePreview = async () => {
    await handleContactDuplication();

    const proposalValues = mainForm.getValues();

    await onPreview(proposalValues);
  };

  const handleDownload = async () => {
    await handleContactDuplication();

    const proposalValues = mainForm.getValues();

    await onDownload(proposalValues);
  };

  useEffect(() => {
    if ((mainForm.formState.isDirty || settingsForm.formState.isDirty) && onFormDataChanged) {
      onFormDataChanged();
    }
  }, [mainForm.formState.isDirty, settingsForm.formState.isDirty, onFormDataChanged]);

  const backToMainForm = () => {
    goToMainSection();
    settingsForm.reset();
  };

  return (
    <section className={styles.wrapper}>
      {currentSection === 'main' && (
        <div className={styles.mainSection}>
          <button
            className={clsx(styles.navigationButton, styles.settingsButton)}
            onClick={goToSettings}
          >
            {t('duplicates:Settings_title')}
          </button>
          <div className={styles.mainSectionFormWrapper}>
            <FormProvider {...mainForm}>
              <MainFormLayout
                contactPinned={contactPinned}
                onSubmit={onSubmit}
                onPreview={handlePreview}
                onDownload={handleDownload}
              />
            </FormProvider>
          </div>
        </div>
      )}
      {currentSection === 'settings' && (
        <div className={styles.settingsSection}>
          <button
            className={clsx(styles.navigationButton, styles.backButton)}
            onClick={() => {
              if (settingsForm.formState.isDirty) {
                setDiscardSettingsDialogOpen(true);
              } else {
                backToMainForm();
              }
            }}
          >
            <ArrowBack /> {t('backToProposal')}
          </button>
          <div className={styles.settingsSectionFormWrapper}>
            <FormProvider {...settingsForm}>
              <>
                <SettingsFormLayout onSubmit={handleSubmitSettingsForm} />
                {discardSettingsDialogOpen && (
                  <DefaultDialog
                    title={t('discardSettingsDialog.title')}
                    description={t('discardSettingsDialog.body')}
                    open={discardSettingsDialogOpen}
                    onClose={() => {
                      setDiscardSettingsDialogOpen(false);
                      backToMainForm();
                    }}
                    onSuccess={() => {
                      handleSubmitSettingsForm(settingsForm.getValues());
                    }}
                    confirmText={t('common:action.save')}
                    rejectText={t('common:action.discard')}
                  />
                )}
              </>
            </FormProvider>
          </div>
        </div>
      )}
    </section>
  );
}
