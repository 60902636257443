// billing related
export const VIEW_PLANS_PAGE = 'View_plans_page';
export const CLICK_SELECT_PLAN = 'Clicks_select_plan';
export const VIEW_PAYMENT_PAGE = 'View_payment_details_page';
export const CLICKS_SUBMIT_PURCHASE = 'Clicks_submit_purchase';
export const VIEW_SUBSCRIPTION_PAGE = 'View_subscription_page';
export const VIEW_INVOICES_PAGE = 'View_invoices_page';
export const VIEW_BILLING_DETAILS_PAGE = 'View_billing_details_page';

export const PAYMENT_SUCCESS = 'Payment_success';
export const PAYMENT_FAILED = 'Payment_failed ';
export const CHANGE_SUBSCRIPTION = 'Change_subscription';

export const VIEW_MY_ACCOUNT_PAGE = 'View_my_account_page';
export const EXPORT_ACTION = 'Export_action';
export const DELETE_ACCOUNT = 'Delete_account';

export const FREE_USER_REACH_MONTHLY_LIMIT = 'Free_user_reach_monthly_limit';
export const FREE_USER_GET_BLOCKED_PREMIUM_FEATURE = 'Free_user_get_blocked_premium_feature';
export const VIEW_FREE_TRIAL_END_SCREEN = 'View_free_trial_end_screen';
