import { useState } from 'react';
import TaskDrawerContext, { TaskDrawerContext as ITaskDrawerContext } from './TaskDrawerContext';
import TaskDrawer from './TaskDrawer';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export type Mode = 'create' | 'update';

export type FormOptions = { closeAfterSave?: boolean; defaultValues?: Partial<TaskEntity> };

export default function TaskDrawerProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<{ mode?: Mode; formOptions?: FormOptions }>({
    mode: undefined,
    formOptions: undefined,
  });

  const openCreateForm: ITaskDrawerContext['openCreateForm'] = (params: {
    defaultValues?: Partial<TaskEntity>;
    closeAfterSave?: boolean;
  }) => {
    const { closeAfterSave, defaultValues } = params || {};

    setState({ mode: 'create', formOptions: { defaultValues, closeAfterSave } });
  };

  const openUpdateForm: ITaskDrawerContext['openUpdateForm'] = ({
    closeAfterSave,
    id,
  }: {
    closeAfterSave?: boolean;
    id: string;
  }) => {
    setState({ mode: 'update', formOptions: { closeAfterSave, defaultValues: { id } } });
  };

  const close = () => {
    setState({ mode: undefined, formOptions: undefined });
  };

  return (
    <TaskDrawerContext.Provider
      value={{ isOpen: !!state.mode, openCreateForm, openUpdateForm, close }}
    >
      {children}
      <TaskDrawer mode={state.mode} formOptions={state.formOptions} onClose={close} />
    </TaskDrawerContext.Provider>
  );
}
