export const TYPES = {
  BillingDetailsApiService: Symbol.for('BillingDetailsApiService'),
  BillingDetailsStateRepository: Symbol.for('BillingDetailsStateRepository'),

  PaymentProviderApiService: Symbol.for('PaymentProviderApiService'),
  PaymentProviderStateRepository: Symbol.for('PaymentProviderStateRepository'),

  SubscriptionApiService: Symbol.for('SubscriptionApiService'),
  SubscriptionStateRepository: Symbol.for('SubscriptionStateRepository'),

  InvoicesApiService: Symbol.for('InvoicesApiService'),
  InvoicesRepository: Symbol.for('InvoicesRepository'),

  PromoCodeApiService: Symbol.for('PromoCodeApiService'),
  PromoCodeRepository: Symbol.for('PromoCodeRepository'),

  BillingUseCases: Symbol.for('BillingUseCases'),
};
