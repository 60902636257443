import { Observable, from, map } from 'rxjs';
import { injectable } from 'inversify';

import axios from 'src/app/axios';
import InvoiceDC from '../mappers/invoices/InvoiceDC';

export interface IInvoicesApiService {
  getInvoices: () => Observable<InvoiceDC[]>;
}

const baseURL = '/api/v1/billing-service/bluesnap';

@injectable()
export default class InvoicesApiService implements IInvoicesApiService {
  getInvoices() {
    const response = axios.get<InvoiceDC[]>(`${baseURL}/invoices`);

    return from(response).pipe(map((response) => response.data));
  }
}
