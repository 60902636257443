import { isLocal, isStage, isPrerelease } from '../../../services/app/envService';

export enum PlanOptions {
  FREE_MONTHLY = 'free_monthly',
  UNLIMITED_TRIAL = 'unlimited_trial',
  UNLIMITED_DAILY = 'unlimited_daily',
  UNLIMITED_MONTHLY = 'unlimited_monthly',
  UNLIMITED_ANNUALLY = 'unlimited_annually',
  INDIVIDUAL_MONTHLY = 'individual_monthly',
  INDIVIDUAL_ANNUALLY = 'individual_annually',
  TEAMS_MONTHLY = 'teams_monthly',
  TEAMS_ANNUALLY = 'teams_annually',
}
export enum PurchaseTypes {
  BLUESNAP = 'bluesnap',
  GOOGLE_PLAY = 'google_play',
  GIFT = 'gift',
}

export const PLAN_OPTIONS = {
  FREE_MONTHLY: PlanOptions.FREE_MONTHLY,
  UNLIMITED_TRIAL: PlanOptions.UNLIMITED_TRIAL,
  UNLIMITED_MONTHLY:
    isStage() || isLocal() || isPrerelease()
      ? PlanOptions.UNLIMITED_DAILY
      : PlanOptions.UNLIMITED_MONTHLY, // for test billing on stage \ pre-prod
  UNLIMITED_ANNUALLY: PlanOptions.UNLIMITED_ANNUALLY,
  INDIVIDUAL_MONTHLY: PlanOptions.INDIVIDUAL_MONTHLY,
  INDIVIDUAL_ANNUALLY: PlanOptions.INDIVIDUAL_ANNUALLY,
  TEAMS_MONTHLY: PlanOptions.TEAMS_MONTHLY,
  TEAMS_ANNUALLY: PlanOptions.TEAMS_ANNUALLY,
};

export const PAID_PLANS = [
  PlanOptions.UNLIMITED_DAILY,
  PlanOptions.UNLIMITED_MONTHLY,
  PlanOptions.UNLIMITED_ANNUALLY,
  PlanOptions.INDIVIDUAL_MONTHLY,
  PlanOptions.INDIVIDUAL_ANNUALLY,
  PlanOptions.TEAMS_MONTHLY,
  PlanOptions.TEAMS_ANNUALLY,
];

type Role = 'owner' | 'admin' | 'member';

export enum CreditsTypes {
  NOTE = 'note',
  TASK = 'task',
  PRICE_PROPOSAL = 'price_proposal',
  TAG_LINK = 'tag_link',
}

export interface TeamMemberEntity {
  email: string;
  lastLoginAt: number;
  acceptedInvitationAt: number;
  thumbnail: string | null;
  fullName: string | null;
  invitationStatus: 'accepted' | 'pending';
  role: Role;
}

export interface SubscriptionEntity {
  // destructing from workspace
  workspaceId: string;
  approvedMembersCount: number;
  membersCount: number;
  adminEmail: string;

  // common
  plan: PlanOptions;
  expirationDate: number;
  startDate: number;
  paidMembersCount: number;
  isActive: boolean;
  purchaseType: PurchaseTypes;
  daysLeft: number;
  isExpired: boolean;
  isIndividual: boolean;

  // paid gift plan
  isUserOnPaidGiftPlan: boolean;

  // paid plan details
  recurringPrice: string;
  isUserOnPaidPlan: boolean;
  isUsingBluesnap: boolean;
  isPaymentFailed: boolean;
  isGracePeriod: boolean;
  isGracePeriodOver: boolean;
  isCanceled: boolean;
  isCanceledAndExpired: boolean;

  // free plan
  isUserOnFreePlan: boolean;
  credits: {
    creditType: CreditsTypes;
    limit: number;
    used: number;
    createdAt: number;
    expiresAt: number;
  }[];

  // free trial
  isUserOnFreeTrial: boolean;
  isFreeTrialEnded: boolean;
  freeTrialDayLeft: number;
}

export default interface Workspace {
  id: string;
  name: string | null;
  industry: string | null;
  companySize: string | null;
  companyWebsite: string | null;
  businessPhone: string | null;
  thumbnail: string | null;
  updatedAt: number | null; // timestamp in milliseconds or null
  createdAt: number | null; // timestamp in milliseconds or null
  roles: {
    role: Role;
    isDefault: boolean;
    isMutable: boolean;
    permissions: Array<'view' | 'workspace_edit' | 'workspace_edit_members'>;
  }[];
  members: TeamMemberEntity[];
  subscription: SubscriptionEntity;
  memberAccessAllContacts: boolean;
  proposalsNextColId: string | null;
  lastLoginAt: number | null; // timestamp in milliseconds or null
  isPersonal: boolean;
}
