const overrides = {
  MuiContainer: {
    root: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  MuiIconButton: {
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#5f48e9',
      },
    },
  },
};

export default overrides;
