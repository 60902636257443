import { omitBy, isUndefined } from 'lodash';

const getUserLocalStorage = () => {
  try {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem('user') || '{}');
    }
    return {};
  } catch (err) {
    return {};
  }
};
const getDirectTokenLocalStorage = () => {
  try {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('direct_token');
    }
    return '';
  } catch (err) {
    return '';
  }
};
const removeDataFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
const setDataToLocalStorage = (key: string, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};
const getDataFromLocalStorage = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem(key) || undefined);
    }
    return;
  } catch (err) {
    return;
  }
};

const removeUndefinedKeysFromObj = (object: { [key: string]: any }) => {
  return omitBy(object, isUndefined);
};
const convertDateTime = (timestamp: number): Date => {
  return new Date(timestamp * 1000);
};

export {
  removeUndefinedKeysFromObj,
  getUserLocalStorage,
  setDataToLocalStorage,
  getDataFromLocalStorage,
  convertDateTime,
  removeDataFromLocalStorage,
  getDirectTokenLocalStorage,
};
