import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import Button from '../../../UI/Buttons/BaseButton';
import styles from './DefaultDialog.module.scss';
import DirectedText from '../../../../features2/localization/ui/DirectedText';

interface DefaultDialog {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  title: string;
  description: string | React.ReactNode;
  confirmText?: string;
  rejectText?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  rejectButtonClassName?: string;
  confirmButtonClassName?: string;
  handleSuccessWithoutClose?: boolean;
  hide?: () => void;
}

export default function DefaultDialog({
  open,
  onClose,
  onSuccess,
  title,
  description,
  confirmText,
  rejectText,
  titleClassName,
  descriptionClassName,
  rejectButtonClassName,
  confirmButtonClassName,
  handleSuccessWithoutClose,
  hide,
}: DefaultDialog) {
  const { t } = useTranslation('common');
  const handleClose = () => {
    onClose();
  };
  const handleSucceed = () => {
    onSuccess();

    if (!handleSuccessWithoutClose) {
      onClose();
    }
  };

  return (
    <Dialog classes={{ paper: styles.container }} onClose={hide} open={open}>
      <DialogTitle className={clsx(styles.title, titleClassName)}>
        <DirectedText>{title}</DirectedText>
      </DialogTitle>
      <DialogContent>
        <div className={clsx(styles.description, descriptionClassName)}>
          <DirectedText>{description}</DirectedText>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        {rejectText !== '' && (
          <>
            <Button
              variant="text"
              className={clsx(styles.button, rejectButtonClassName)}
              onClick={handleClose}
            >
              {rejectText || t('duplicates:Cancel_action')}
            </Button>
            <Divider className={styles.divider} orientation="vertical" />
          </>
        )}
        <Button
          variant="text"
          className={clsx(styles.button, confirmButtonClassName)}
          onClick={handleSucceed}
        >
          {confirmText || t('common:action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
