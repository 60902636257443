import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import * as API from '../../../api/proposalsAPI/api';
import { MessageType } from '../../../components/UI/snackbar/providers/SnackBarProvider';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import { useCurrentUser } from 'src/features2/user/ui/hooks';

export default function useGeneratePdf() {
  const { t } = useTranslation();
  const { currentUser } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  return async (data: ProposalEntity) => {
    try {
      const tempUrl = await API.generatePdf({
        ...data,
        settings: { ...data.settings, lang: currentUser.settings.lang },
      });

      return tempUrl;
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
  };
}
