import axios from '../app/axios';
import { endpoints } from '../utils/constants';
import NoteEntity from '../db/entities/note/NoteEntity';
import { mapPartialNoteEntityToDC } from '../db/entities/note/mapper';
import RequestHeaders from '../enums/RequestHeaders';

async function postNoteAPI(note: NoteEntity, workspaceId: string) {
  const dc = mapPartialNoteEntityToDC(note);

  await axios.post(endpoints.notes, dc, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function patchNoteAPI(
  id: string,
  diff: Partial<NoteEntity> & { updatedAt: number },
  workspaceId: string
) {
  const dc = mapPartialNoteEntityToDC(diff);
  await axios.patch(endpoints.notes, dc, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function deleteNoteAPI(noteId: string, workspaceId: string) {
  await axios.delete(`${endpoints.notes}/${noteId}`, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

export { postNoteAPI, patchNoteAPI, deleteNoteAPI };
