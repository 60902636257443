export default {
  LOGIN_PAGE: '/login',
  CALLS_PAGE: '/',
  CONTACTS_PAGE: '/contacts',
  DASHBOARD: '/',
  CONTACT_PAGE: '/contacts/[contactId]',
  TASKS_PAGE: '/tasks',
  PLANS_PAGE: '/plans',
  PRICING_PAGE: '/pricing',
  PAYMENT_DETAILS_PAGE: '/purchase',
  SETTINGS_PAGE: '/settings/[[...page]]',
  NOT_FOUND: '/404',
  AUTHORIZATION_PAGE: '/authorizing',
  // settings pages
  ROOT_SETTINGS_PAGE: '/settings',
  MY_ACCOUNT_PAGE: '/settings/account',
  TEAMMATES_PAGE: '/settings/teammates',
  SUBSCRIPTION_PAGE: '/settings/subscription',
  BILLING_DETAILS_PAGE: '/settings/billing-details',
  INVOICES_PAGE: '/settings/invoices',
  DONT_SHOW_IN_LEADER_PAGE: '/settings/hide-contacts',
  ARCHIVE_PAGE: '/settings/archive',
  INTEGRATIONS_PAGE: '/settings/integrations',
  FIELDS_MANAGEMENT: '/settings/fields-management',
};
