import FileEntity from '../../../db/entities/file/FileEntity';
import { FileItemType } from '../../../db/entities/file/shared';
import { IFileBC } from './abstractions/IFilesBC';

export default class FileBC implements IFileBC {
  uuid: string;
  link: string;
  thumbnail: string;
  name: string;
  mimeType: string;
  fileSizeBytes: number;
  itemType: FileItemType;
  itemUuid: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  isOptimistic?: boolean;

  constructor(file: FileEntity, isOptimistic?: boolean) {
    this.name = file.name;
    this.createdAt = file.createdAt;
    this.updatedAt = file.updatedAt;
    this.thumbnail = file.thumbnail;
    this.uuid = file.uuid;
    this.createdBy = file.createdBy;
    this.link = file.link;
    this.mimeType = file.mimeType;
    this.fileSizeBytes = file.fileSizeBytes;
    this.itemType = file.itemType;
    this.itemUuid = file.itemUuid;
    this.createdAt = file.createdAt;
    this.updatedBy = file.updatedBy;
    this.isOptimistic = isOptimistic;
  }
}
