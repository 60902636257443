import TaskDC from './TaskDC';
import TaskEntity from './TaskEntity';

export function mapTaskDCtoEntity(
  dc: TaskDC,
  { workspaceId }: { workspaceId: string }
): TaskEntity {
  const repeatValuesMap = {
    'RRULE:FREQ=DAILY': 'daily',
    'RRULE:FREQ=WEEKLY': 'weekly',
    'RRULE:FREQ=MONTHLY': 'monthly',
  };

  return {
    legacyId: dc.id,
    id: dc.uuid,
    contactId: dc.relations?.find((relItem) => relItem.type === 'contact')?.id,
    important: dc.important,
    title: dc.title,
    type: dc.type || undefined,
    description: dc.description || undefined,
    descriptionUpdatedAt: dc.description_updated_at ? dc.description_updated_at * 1000 : undefined,
    status: dc.status,
    dueDate: dc.due_date ? dc.due_date * 1000 : undefined,
    reminder:
      Array.isArray(dc.reminders) && dc.reminders.length > 0
        ? {
            date: dc.reminders[0].date * 1000,
            type: dc.reminders[0].type || undefined,
            label: dc.reminders[0].label || undefined,
          }
        : undefined,
    repeat: dc.repeat ? (repeatValuesMap[dc.repeat] as TaskEntity['repeat']) : undefined,
    assignedTo: dc.assigned_to || undefined,
    createdBy: dc.created_by || undefined,
    updatedBy: dc.updated_by || undefined,
    createdAt: dc.created_at ? dc.created_at * 1000 : undefined,
    updatedAt: dc.updated_at ? dc.updated_at * 1000 : undefined,
    workspaceId,
  };
}

export function mapPartialTaskEntityToDC(partialModel: Partial<TaskEntity>): Partial<TaskDC> {
  const mapperFns: Record<string, (value: unknown) => { [key: string]: unknown }> = {
    legacyId: (value: TaskEntity['legacyId']) => ({
      id: value || null,
    }),
    id: (value: TaskEntity['id']) => ({
      uuid: value,
    }),
    contactId: (value: TaskEntity['contactId']) => ({
      relations: value
        ? [
            {
              type: 'contact',
              id: value,
            },
          ]
        : null,
    }),
    important: (value: TaskEntity['contactId']) => ({
      important: value,
    }),
    title: (value: TaskEntity['title']) => ({
      title: value,
    }),
    type: (value: TaskEntity['type']) => ({
      type: value,
    }),
    description: (value: TaskEntity['description']) => ({
      description: value || null,
    }),
    descriptionUpdatedAt: (value: TaskEntity['descriptionUpdatedAt']) => ({
      description_updated_at: Math.floor(value / 1000),
    }),
    status: (value: TaskEntity['status']) => ({
      status: value,
    }),
    dueDate: (value: TaskEntity['dueDate']) => ({
      due_date: value ? Math.floor(value / 1000) : null,
    }),
    reminder: (value: TaskEntity['reminder']) => ({
      reminders: value
        ? [
            {
              date: Math.floor(value.date / 1000),
              label: value.label || null,
              type: value.type || null,
            },
          ]
        : null,
    }),
    repeat: (value: TaskEntity['repeat']) => {
      const repeatValuesMap = {
        daily: 'RRULE:FREQ=DAILY',
        weekly: 'RRULE:FREQ=WEEKLY',
        monthly: 'RRULE:FREQ=MONTHLY',
      };

      return {
        repeat: repeatValuesMap[value] ? (repeatValuesMap[value] as TaskDC['repeat']) : null,
      };
    },
    createdBy: (value: TaskEntity['createdBy']) => ({
      created_by: value || null,
    }),
    updatedBy: (value: TaskEntity['updatedBy']) => ({
      updated_by: value || null,
    }),
    assignedTo: (value: TaskEntity['assignedTo']) => ({
      assigned_to: value || null,
    }),
    updatedAt: (value: TaskEntity['updatedAt']) => ({
      updated_at: Math.floor(value / 1000),
    }),
    createdAt: (value: TaskEntity['createdAt']) => ({
      created_at: Math.floor(value / 1000),
    }),
  };

  const partialDc = Object.entries(partialModel).reduce((acc: Partial<TaskDC>, item) => {
    const [key, value] = item;

    const mapperFn = mapperFns[key];
    if (mapperFn) {
      const mappedValues = mapperFn(value);

      return { ...acc, ...mappedValues };
    } else {
      return acc;
    }
  }, {});

  return partialDc;
}
