import { FC } from 'react';
import { Container } from '@material-ui/core';

import CirclesTopRight from '../../../public/images/icons/circles-top-right.svg';
import CirclesTopLeft from '../../../public/images/icons/circles-top-left.svg';
import CirclesBottomLeft from '../../../public/images/icons/circles-bottom-left.svg';
import DashesBottomRight from '../../../public/images/icons/dashes-bottom-right.svg';

import styles from './Login.module.scss';

const LoginLayout: FC = ({ children }) => {
  return (
    <Container maxWidth={false} className={styles.wrapper}>
      <div className={styles.layoutIcons}>
        <CirclesTopLeft className={styles.circlesTopLeft} />
        <CirclesTopRight className={styles.circlesTopRight} />
        <CirclesBottomLeft className={styles.circlesBottomLeft} />
        <DashesBottomRight className={styles.dashesBottomRight} />
      </div>
      {children}
    </Container>
  );
};
export default LoginLayout;
