import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { take } from 'rxjs';

import { useBehaviorSubject } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { TYPES as WORKSPACE_TYPES } from 'src/features2/workspaces/di/types';
import { IProfileRepository } from 'src/features2/profile/data/ProfileRepository';
import { TYPES as PROFILE_TYPES } from 'src/features2/profile/di/types';

export default function useInitMobileData() {
  const workspacesStateRepository = useInjection<IWorkspacesStateRepository>(
    WORKSPACE_TYPES.WorkspacesStateRepository
  );
  const profileRepository = useInjection<IProfileRepository>(PROFILE_TYPES.ProfileRepository);
  const [currentWorkspaceId] = useBehaviorSubject(workspacesStateRepository.currentWorkspaceId);

  useEffect(() => {
    if (isMobileOnly && currentWorkspaceId) {
      workspacesStateRepository.fetchWorkspaces().pipe(take(1)).subscribe();
      profileRepository.fetchProfile().pipe(take(1)).subscribe();
    }
  }, []);
}
