import ErrorIcon from 'icons/error-case-import-csv.svg';
import styles from './styles.module.scss';
import Button from 'components/UI/Buttons/BaseButton';
const InvalidCSVErrorCase = ({ onDownloadExample, onClose, t }) => {
  return (
    <div className={styles.container}>
      <ErrorIcon />
      <h3 className={styles.title}>{t('ErrorCases.InvalidCSV.title')}</h3>
      <span className={styles.description}>{t('ErrorCases.InvalidCSV.description')}</span>
      <div className={styles.actionsContainer}>
        <Button variant={'text'} onClick={onDownloadExample} className={styles.viewExampleButton}>
          {t('common:action.view_example')}
        </Button>
        <Button onClick={onClose}>{t('common:action.ok')}</Button>
      </div>
    </div>
  );
};
export default InvalidCSVErrorCase;
