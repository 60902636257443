import axios from '../app/axios';
import thirdParyAxios from '../app/thirdPartyAxios';
import endpoints from './constants/endpoints';
import RequestHeaders from '../enums/RequestHeaders';
import ContentTypes from '../enums/ContentTypes';

async function uploadImage(file: File): Promise<{ url: string }> {
  const { data: uploadFileResponse } = await axios.post(endpoints.media.uploadFile, {
    mime_type: file.type,
  });

  const formData = new FormData();

  const paramsToSent = uploadFileResponse.post_params.params;
  for (const key in paramsToSent) {
    formData.append(key, paramsToSent[key]);
  }

  formData.append('file', file);

  await thirdParyAxios.post(uploadFileResponse.post_url, formData, {
    headers: {
      [RequestHeaders.CONTENT_TYPE]: ContentTypes.MULTIPART_FORM_DATA,
    },
  });

  await axios.post(endpoints.media.confirmUploadFile.replace('{uuid}', uploadFileResponse.uuid));

  return { url: uploadFileResponse.file_url };
}

export default uploadImage;
