import { BehaviorSubject, filter, tap, switchMap, catchError, throwError } from 'rxjs';
import { injectable, inject, postConstruct } from 'inversify';
import { AxiosError } from 'axios';

import { IBillingApiService } from './network/BillingDetailsApiService';
import { IBillingDetailsStateRepository } from '../domain/abstractions/repositories/IBillingDetailsStateRepository';
import BillingDetailsEntity from '../domain/entities/BillingDetailsEntity';
import { TYPES } from '../di/types';

import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { TYPES as WORKSPACES_TYPES } from 'src/features2/workspaces/di/types';

@injectable()
export default class BillingDetailsStateRepository implements IBillingDetailsStateRepository {
  @inject(TYPES.BillingDetailsApiService) private api: IBillingApiService;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  private workspacesRepository: IWorkspacesStateRepository;
  private billingDetailsSubject = new BehaviorSubject<BillingDetailsEntity>(null);

  @postConstruct()
  init() {
    // workaround to resubscribe for collections (old subscription become inactive because we remove() collection on logout)
    this.workspacesRepository.currentWorkspaceId
      .pipe(
        filter((workspaceId) => !!workspaceId), // fetch billing details only after login
        switchMap(() => this.api.getBillingDetails())
      )
      .subscribe(this.billingDetailsSubject);
  }

  getBillingDetails() {
    return this.billingDetailsSubject;
  }

  updateBillingDetails(billingDetails) {
    return this.api.updateBillingDetails(billingDetails).pipe(
      tap((billingDetails) => {
        this.billingDetailsSubject.next(billingDetails);
      }),
      catchError((err: AxiosError) =>
        throwError(() => ({
          status: err.response.status,
          data: err.response.data,
        }))
      )
    );
  }

  updatePaymentSources(bluesnapPaymentSources) {
    this.billingDetailsSubject.next({
      ...this.billingDetailsSubject.value,
      bluesnapPaymentSources,
    });
  }
}
