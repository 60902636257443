import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'next-i18next';

import styles from './styles.module.scss';
import FileBC from '../../../../features2/files/domain/FileBC';
import { getFileNameSelectionEndIndex } from '../../../../utils/files';
import { useInjection } from '../../../../app/ioc/ioc.react';
import { TYPES } from '../../../../features2/files/di/types';
import { useAppSelector } from '../../../../app/hooks';
import { selectCurrentUserMemberData } from 'slices/workspacesSlice';
import { IFilesUseCases } from '../../../../features2/files/domain/abstractions/IFilesUseCases';
import FilesContext from '../../../../features2/files/domain/context/FilesContext';

export interface IFileRenameDialogProps {
  isOpen: boolean;
  onClose: () => void;
  file: FileBC;
  onRename: () => void;
}

const FileRenameDialog: React.FC<IFileRenameDialogProps> = ({
  isOpen,
  onClose,
  file,
  onRename: onRenameEvent,
}) => {
  const { renameFile } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const { email } = useAppSelector(selectCurrentUserMemberData);
  const { t } = useTranslation('dialogs');
  const [initialValue] = useState(file.name);
  const [value, setValue] = useState(file.name);
  const [inputElement, setInputElement] = useState<HTMLInputElement>(null);
  const { commonFilesList, setCommonFilesList } = useContext(FilesContext);

  const renameFileOptimistic = () => {
    const updatedList = commonFilesList.map((item) => {
      if (item.uuid === file.uuid) {
        return { ...file, name: value };
      }

      return item;
    });

    setCommonFilesList(updatedList);
  };

  // RS: Selection doesn't work using useRef.
  useEffect(() => {
    if (inputElement) {
      inputElement.focus();
      inputElement.setSelectionRange(0, getFileNameSelectionEndIndex(file.name));
    }
  }, [inputElement, file.name]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setValue(value);
  };

  const onRename = () => {
    if (value) {
      renameFileOptimistic();
      onClose();

      if (value !== initialValue) {
        onRenameEvent();
        renameFile(value, email, file).subscribe();
      }
    }
  };

  return (
    <Dialog classes={{ paper: styles.Dialog }} open={isOpen} onClose={onClose}>
      <div className={styles.Title}>{t('file_renaming_dialog.title')}</div>
      <input
        ref={(ref) => setInputElement(ref)}
        placeholder={file.name}
        className={styles.Input}
        onChange={onChange}
        type="text"
        value={value}
      />
      <div className={styles.Buttons}>
        <Button className={clsx(styles.Button, styles.ButtonCancel)} onClick={onClose}>
          {t('file_renaming_dialog.cancel_button_text')}
        </Button>
        <Button className={styles.Button} onClick={onRename}>
          {t('file_renaming_dialog.ok_button_text')}
        </Button>
      </div>
    </Dialog>
  );
};

export default FileRenameDialog;
