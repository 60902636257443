import { Observable, from, map } from 'rxjs';
import { injectable } from 'inversify';

import axios from 'src/app/axios';
import { PlanOptions } from '../../domain/entities/SubscriptionEntity';

import WorkspaceDC from 'src/db/entities/workspace/WorkspaceDC';

export interface ISubscriptionApiService {
  setPlan: (
    plan: PlanOptions,
    workspaceId: string,
    isRenewProcess?: boolean
  ) => Observable<WorkspaceDC>;
  purchaseSubscription: (
    workspaceId: string,
    plan: PlanOptions,
    promoCodeId?: string,
    isRenewProcess?: boolean
  ) => Observable<WorkspaceDC>;
  cancelSubscription: (workspaceId) => Observable<WorkspaceDC>;
}

const baseURL = '/api/v1';

@injectable()
export default class SubscriptionApiService implements ISubscriptionApiService {
  setPlan(plan, workspaceId, isRenewProcess = false) {
    const response = axios.patch(`${baseURL}/workspaces/${workspaceId}/subscription`, {
      plan,
      ...(isRenewProcess && { is_canceled: false }),
    });

    return from(response).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  purchaseSubscription(workspaceId, plan, promoCodeId, isRenewProcess) {
    const response = axios.patch(`${baseURL}/workspaces/${workspaceId}/subscription`, {
      billing_details_filled: true,
      plan,
      ...(promoCodeId && { promo_code_id: promoCodeId }),
      ...(isRenewProcess && { is_canceled: false }),
    });

    return from(response).pipe(
      map((response) => {
        return response.data;
      })
    );
  }

  cancelSubscription(workspaceId) {
    const response = axios.patch(`${baseURL}/workspaces/${workspaceId}/subscription`, {
      is_canceled: true,
    });

    return from(response).pipe(
      map((response) => {
        return response.data;
      })
    );
  }
}
