import { Container } from 'inversify';
import { TYPES } from './types';
import { IMonitoringSystemRepository } from '../domain/abstractions/IMonitoringSystemRepository';
import MonitoringSystemUseCases from '../domain/MonitoringSystemUseCases';
import { IMonitoringSystemUseCases } from '../domain/abstractions/IMonitoringSystemUseCases';
import MonitoringSystemRepository from '../data/MonitoringSystemRepository';

export const bindMonitoringSystemModule = (container: Container) => {
  container
    .bind<IMonitoringSystemRepository>(TYPES.MonitoringSystemRepository)
    .to(MonitoringSystemRepository)
    .inSingletonScope();
  container
    .bind<IMonitoringSystemUseCases>(TYPES.MonitoringSystemUseCases)
    .to(MonitoringSystemUseCases);

  if (typeof window !== 'undefined') {
    container.get<IMonitoringSystemRepository>(TYPES.MonitoringSystemRepository); // to instantiate immediately on client side
  }
};
