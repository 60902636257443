import { useTranslation } from 'next-i18next';
import React from 'react';

import EmptySearchIcon from '../../../../../public/images/search/search-empty-state-image.svg';
import styles from './styles.module.scss';
import DirectedText from '../../../../features2/localization/ui/DirectedText';

const NoResults: React.FC = () => {
  const { t } = useTranslation('search');

  return (
    <div className={styles.Wrapper}>
      <EmptySearchIcon />
      <span className={styles.title}>
        <DirectedText>{t('no_results_title')}</DirectedText>
      </span>
      <span className={styles.subTitle}>
        <DirectedText>{t('no_results_subTitle')}</DirectedText>
      </span>
    </div>
  );
};

export default NoResults;
