import React from 'react';

import FileBC from '../../../domain/FileBC';
import styles from './styles.module.scss';
import FileInfo from '../FileListItem/components/FileInfo';
import DownloadIcon from 'icons/download-rect.svg';
import FilePreviewBody from './components/FilePreviewBody';
import useFilePreviewViewModel from './components/FilePreviewBody/useFilePreviewViewModel';

interface IFilePreviewProps {
  file: FileBC;
  onClose(): void;
}

const FilePreview: React.FC<IFilePreviewProps> = ({ file, onClose }) => {
  const { onDownloadFile } = useFilePreviewViewModel({ file, fetchLink: false });

  return (
    <div className={styles.PreviewOverlay}>
      <div className={styles.Header}>
        <FileInfo file={file} isFilePreview />
        <div className={styles.IconsWrapper}>
          <DownloadIcon onClick={onDownloadFile} />
          <button className={styles.CloseIcon} onClick={onClose} />
        </div>
      </div>
      <FilePreviewBody file={file} />
    </div>
  );
};

export default FilePreview;
