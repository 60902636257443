import { endpoints } from '../utils/constants';
import axios from '../app/axios';
import RequestHeaders from '../enums/RequestHeaders';

interface ItemDto {
  uuid: string;
  item_type: 'contact';
  item_uuid: string;
  column_uuid: string;
  created_at: number; // timestamp
  updated_at: number; // timestamp
}

export interface ColumnDto {
  uuid: string;
  title: string;
  items: ItemDto[];
  created_at: number; // timestamp
  updated_at: number; // timestamp
  color: string; // HEX
}

interface BoardDto {
  columns: ColumnDto[];
  updated_at: number; // timestamp
}

export interface ItemModel {
  uuid: string;
  type: 'contact';
  typeUuid: string;
  columnUuid: string;
  createdAt: number; // timestamp
  updatedAt: number; // timestamp
}

export interface ColumnModel {
  id: string;
  title: string;
  items: ItemModel[];
  createdAt: number; // timestamp
  updatedAt: number; // timestamp
  color: string; // HEX
}

export interface BoardModel {
  columns: ColumnModel[];
  updatedAt?: number; // timestamp
}

const mapItemDtoToModel = (dto: ItemDto): ItemModel => {
  return {
    type: dto.item_type,
    typeUuid: dto.item_uuid,
    uuid: dto.uuid,
    columnUuid: dto.column_uuid,
    createdAt: dto.created_at,
    updatedAt: dto.updated_at,
  };
};

const mapItemModelToDto = (model: ItemModel): ItemDto => {
  return {
    item_type: model.type,
    item_uuid: model.typeUuid,
    uuid: model.uuid,
    column_uuid: model.columnUuid,
    created_at: model.createdAt,
    updated_at: model.updatedAt,
  };
};

export const mapColumnDtoToModel = (dto: ColumnDto): ColumnModel => {
  return {
    id: dto.uuid,
    title: dto.title,
    items: dto.items.map(mapItemDtoToModel),
    createdAt: dto.created_at,
    updatedAt: dto.updated_at,
    color: dto.color,
  };
};

const mapBoardDtoToModel = (dto: BoardDto): BoardModel => {
  return {
    columns: dto.columns.map(mapColumnDtoToModel),
    updatedAt: dto.updated_at,
  };
};

async function updateBoardColumn({
  column,
  workspaceId,
}: {
  column: ColumnModel;
  workspaceId: string;
}): Promise<ColumnModel> {
  const { id, title, color, createdAt, updatedAt } = column;

  const { data } = await axios.post<ColumnDto>(
    endpoints.board.updateColumns,
    {
      uuid: id,
      title: title,
      color: color,
      created_at: createdAt,
      updated_at: updatedAt,
    },
    { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
  );

  return mapColumnDtoToModel(data);
}

async function deleteColumns(uuids: string[], workspaceId: string): Promise<true> {
  const { data } = await axios.delete(endpoints.board.delete, {
    data: uuids,
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  return data;
}

async function updateColumnsOrdering(
  order: string[],
  updatedAt: number,
  workspaceId: string
): Promise<BoardModel> {
  const { data } = await axios.post<BoardDto>(
    endpoints.board.updateColumnsOrder,
    {
      order,
      updated_at: updatedAt,
    },
    { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
  );

  return mapBoardDtoToModel(data);
}

async function updateItems(
  items: ItemModel[],
  workspaceId: string
): Promise<{ updated: ItemModel['uuid'][]; failed: ItemModel['uuid'][] }> {
  const { data } = await axios.post<
    Array<
      | { result: null; status: 200; message: null }
      | { result: null; status: number; message: string }
    >
  >(endpoints.board.updateItems, items.map(mapItemModelToDto), {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  return data.reduce(
    (acc: { updated: ItemModel['uuid'][]; failed: ItemModel['uuid'][] }, item, index) => {
      const uuid = items[index].uuid;
      if (item.status === 200) {
        acc.updated.push(uuid);
      } else {
        acc.failed.push(uuid);
      }

      return acc;
    },
    { updated: [], failed: [] }
  );
}

async function updateItemsOrder({
  columnUuid,
  items,
  updatedAt,
  workspaceId,
}: {
  columnUuid: string;
  items: ItemModel[];
  updatedAt: number;
  workspaceId: string;
}): Promise<ColumnModel> {
  const { data } = await axios.post<ColumnDto>(
    endpoints.board.updateItemsOrder.replace('{column_uuid}', columnUuid),
    {
      order: items.map((item) => item.uuid),
      updated_at: updatedAt,
    },
    { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
  );

  return mapColumnDtoToModel(data);
}

async function deleteItems(uuids: string[], workspaceId: string) {
  await axios.delete<true>(endpoints.board.deleteItems, {
    data: uuids,
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

export default {
  mapItemDtoToModel,
  updateBoardColumn,
  deleteColumns,
  updateColumnsOrdering,
  updateItems,
  updateItemsOrder,
  deleteItems,
  mapColumnDtoToModel,
};
