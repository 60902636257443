import { Observable, map } from 'rxjs';
import { ICustomFieldRepository } from '../domain/abstractions/ICustomFieldRepository';
import { inject, injectable } from 'inversify';
import { CUSTOM_FIELDS_TYPES } from '../di/types';
import type ICustomFieldApiService from '../domain/abstractions/ICustomFieldApiService';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import type { ICustomFieldDao } from '../domain/abstractions/ICustomFieldDao';
import { mapCustomFieldDCtoEntity } from 'src/db/entities/customField/mapper';
import { TYPES } from 'src/app/ioc/types';
import type { IPersistentRepository } from 'src/services/PersistentRepository';

@injectable()
export default class CustomFieldRepository implements ICustomFieldRepository {
  @inject(CUSTOM_FIELDS_TYPES.CustomFieldApiService) private api: ICustomFieldApiService;
  @inject(CUSTOM_FIELDS_TYPES.CustomFieldDao) private dao: ICustomFieldDao;
  @inject(TYPES.PersistentRepository) private persistentRepository: IPersistentRepository;

  createField(data: Pick<CustomFieldEntity, 'uuid' | 'name'>): Observable<CustomFieldEntity> {
    const now = Math.floor(Date.now() / 1000);
    // @ts-ignore
    return this.dao.upsert({ ...data, fieldType: 'text', createdAt: now, updatedAt: now }).pipe(
      map(() => {
        this.api.createField(data);
      })
    );
  }

  deleteField(uuid: string): Observable<boolean> {
    // @ts-ignore
    return this.dao.deleteField(uuid).pipe(
      map(() => {
        return this.api.deleteField(uuid);
      })
    );
  }

  getAll(): Observable<CustomFieldEntity[]> {
    return this.dao.getAll();
  }

  fetchAndSaveCustomFields(): Observable<void> {
    const currentWorkspaceId = this.persistentRepository.getCurrentWorkspaceId();
    return this.api.fetchCustomFields().pipe(
      map((dcs) => {
        const entities = dcs.map((el) => mapCustomFieldDCtoEntity(el, currentWorkspaceId));

        entities.forEach((entity) => {
          this.dao.upsert(entity).subscribe();
        });
      })
    );
  }
}
