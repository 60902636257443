import { createAsyncThunk, createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import { AppState } from '../app/store/store';
import { selectCurrentWorkspaceId } from './workspacesSlice';
import orderBy from 'lodash/orderBy';
import * as API from '../api/proposalsAPI/api';

import ProposalEntity, { DISCOUNT_TYPES } from '../db/entities/proposal/ProposalEntity';
import ProposalSettingsEntity from '../db/entities/proposalSettings/ProposalSettingsEntity';

import { selectContactsMap, selectNotHiddenContacts } from './contactsSlice';

const sliceName = 'proposals';

export const createProposal = createAsyncThunk(
  `${sliceName}/createProposal`,
  async (data: ProposalEntity, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    return API.createProposal(data, currentWorkspaceId);
  }
);

export const updateProposal = createAsyncThunk(
  `${sliceName}/updateProposal`,
  async (data: ProposalEntity, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    return API.updateProposal(data, currentWorkspaceId);
  }
);

export const updateSettings = createAsyncThunk(
  `${sliceName}/updateSettings`,
  async (settings: ProposalSettingsEntity, { dispatch, getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    await API.updateSettings(settings, currentWorkspaceId);

    dispatch(actions.setSettings(settings));
  }
);

export const deleteProposal = createAsyncThunk(
  `${sliceName}/deleteProposal`,
  async (uuid: string, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    return API.deleteProposal(uuid, currentWorkspaceId);
  }
);

type SliceState = {
  list: ProposalEntity[];
  settings: ProposalSettingsEntity | null;
};

const initialState: SliceState = { list: [], settings: null };

const proposalsSlice = createSlice({
  name: sliceName,
  initialState: initialState,
  reducers: {
    setProposals: (state, action: PayloadAction<ProposalEntity[]>) => {
      state.list = action.payload;
    },
    hideProposals: (state, action: PayloadAction<ProposalEntity['uuid'][]>) => {
      const uuids = action.payload;

      state.list.forEach((proposalItem) => {
        const shouldHide = uuids.includes(proposalItem.uuid);

        if (shouldHide) {
          proposalItem.visible = false;
        }
      });
    },
    deleteProposals: (state, action: PayloadAction<ProposalEntity['uuid'][]>) => {
      const uuids = action.payload;

      const filtered = state.list.filter((proposalItem) => !uuids.includes(proposalItem.uuid));

      state.list = filtered;
    },
    setSettings: (state, action: PayloadAction<ProposalSettingsEntity>) => {
      state.settings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createProposal.fulfilled, (state, action) => {
      state.list.push({ ...action.meta.arg, number: action.payload });
    });
    builder.addCase(updateProposal.fulfilled, (state, action) => {
      const proposal = action.meta.arg;

      const index = state.list.findIndex((proposalItem) => proposalItem.uuid === proposal.uuid);

      state.list[index] = proposal;
    });
    builder.addCase(deleteProposal.fulfilled, (state, action) => {
      const uuid = action.meta.arg;
      const index = state.list.findIndex((proposalItem) => proposalItem.uuid === uuid);

      state.list.splice(index, 1);
    });
  },
});

export const { actions } = proposalsSlice;

export const selectProposals = (state: AppState) => state.proposals.list;
export const selectProposalSettings = (state: AppState) => state.proposals.settings;

export const makeSelectProposalsByContact = () =>
  createSelector(
    selectProposals,
    (_, uuid: string) => uuid,
    (proposals: ProposalEntity[], uuid: string) => {
      return orderBy(
        proposals.filter((proposalItem) => proposalItem.contactId === uuid),
        'createdAt',
        'desc'
      );
    }
  );

export const selectVisibleProposals = createSelector(
  (state: AppState) => state.proposals.list,
  selectContactsMap,
  (proposalsList, contactsMap) => {
    const visible = orderBy(proposalsList, 'createdAt')
      .filter((proposalItem) => proposalItem.visible && !!contactsMap[proposalItem.contactId])
      .reverse();

    return uniqBy<ProposalEntity>(visible, 'contactId');
  }
);

export const makeSelectVisibleProposals = () =>
  createSelector(selectVisibleProposals, selectNotHiddenContacts, (proposals, contacts) => {
    return proposals.filter((proposal) =>
      contacts.find((contact) => proposal.contactId === contact.uuid)
    );
  });

export const calcSubtotal = (items: ProposalEntity['items']): number => {
  return items.reduce((acc, item) => {
    return acc + Number(item.unitPrice || 0) * Number(item.qty || 0);
  }, 0);
};

export const calcTax = (subtotal: number, taxRate: number): number => {
  return (subtotal / 100) * taxRate;
};

const calcWithDiscount = (
  subtotal: number,
  discountType: DISCOUNT_TYPES,
  discountAmount: number
): number => {
  if (discountType === DISCOUNT_TYPES.PERCENT) {
    return subtotal - (subtotal * discountAmount) / 100;
  }

  if (discountType === DISCOUNT_TYPES.VALUE) {
    return subtotal - discountAmount;
  }

  return subtotal;
};

export const calcTotal = ({
  items,
  includesTax,
  taxRate,
  discountType,
  discountAmount,
}: {
  items: ProposalEntity['items'];
  includesTax: boolean;
  taxRate: number;
  discountType: DISCOUNT_TYPES;
  discountAmount: number;
}): {
  total: number;
  subtotal: number;
  subtotalWithDiscount: number;
  tax: number;
} => {
  const subtotal = calcSubtotal(items);
  const subtotalWithDiscount = calcWithDiscount(subtotal, discountType, discountAmount);
  const tax = includesTax ? calcTax(subtotalWithDiscount, taxRate) : 0;
  const total = subtotalWithDiscount + tax;

  return {
    total,
    subtotal,
    subtotalWithDiscount,
    tax,
  };
};

export default proposalsSlice.reducer;
