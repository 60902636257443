import React from 'react';
import { useRouter } from 'next/router';

import PaymentFailedOverlay from './CustomOverlays/PaymentFailedOverlay';
import TrialEndedOverlay from './CustomOverlays/TrialEndedOverlay';
import CanceledAndExpiredSubscriptionOverlay from './CustomOverlays/CanceledAndExpiredSubscriptionOverlay';
import ReachedMonthlyLimitOverlay from './limits/ReachedMonthlyLimitOverlay';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { appRoutes } from 'src/utils/constants';

// list of pages where we show billing overlays
const pageRoutesWithBillingOverlays = [
  appRoutes.CALLS_PAGE,
  appRoutes.DASHBOARD,
  appRoutes.CONTACTS_PAGE,
  appRoutes.TASKS_PAGE,
  appRoutes.TEAMMATES_PAGE,
];

const BillingLimitsAndOverlays = () => {
  const { subscription } = useCurrentSubscription();
  const { currentUser } = useCurrentUser();
  const router = useRouter();

  const isFeatureLimitsOnly = pageRoutesWithBillingOverlays.indexOf(router.pathname) === -1;

  let overlay = null;
  if (!subscription.isActive) {
    overlay = (
      // Just simple: Upgrade your account now and take your business to the next level!
      <CanceledAndExpiredSubscriptionOverlay
        currentUser={currentUser}
        adminEmail={subscription.adminEmail}
      />
    );
  }
  if (subscription.isGracePeriodOver) {
    overlay = <PaymentFailedOverlay currentUser={currentUser} />;
  }
  if (subscription.isFreeTrialEnded) {
    overlay = <TrialEndedOverlay currentUser={currentUser} adminEmail={subscription.adminEmail} />;
  }

  return (
    <>
      {!isFeatureLimitsOnly ? overlay : null}
      <ReachedMonthlyLimitOverlay />
    </>
  );
};

export default BillingLimitsAndOverlays;
