import { useEffect, useState } from 'react';

import { useInjection, useInjectionWithRef } from '../../../../../../../app/ioc/ioc.react';
import { TYPES } from '../../../../../di/types';
import FileBC from '../../../../../domain/FileBC';
import { IFilesUseCases } from '../../../../../domain/abstractions/IFilesUseCases';
import { IAmplitudeManager } from '../../../../../../../analytics/amplitude';
import { TYPES as GLOBAL_TYPES } from '../../../../../../../app/ioc/types';

type TArgs = {
  file: FileBC;
  fetchLink?: boolean;
  isFileSupported?: boolean;
};

type TUseFilePreviewViewModel = (args: TArgs) => {
  fileLink: string;
  onDownloadFile: () => void;
};

const useFilePreviewViewModel: TUseFilePreviewViewModel = ({
  file,
  fetchLink = true,
  isFileSupported,
}) => {
  const { getDownloadLink, downloadFile } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);
  const [fileLink, setFileLink] = useState('');

  useEffect(() => {
    if (fetchLink && isFileSupported) {
      getDownloadLink(file.uuid).subscribe((response) =>
        setFileLink(response[0].file_presigned_link)
      );
    }
  }, [file, isFileSupported]);

  const onDownloadFile = () => {
    Amplitude.trackEvent('Download_file');
    downloadFile(file);
  };

  return {
    fileLink,
    onDownloadFile,
  };
};

export default useFilePreviewViewModel;
