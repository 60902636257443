import { Observable, from, map } from 'rxjs';
import { injectable } from 'inversify';

import axios from 'src/app/axios';

export interface IPaymentProviderApiService {
  generatePFToken: () => Observable<string>;
  bindPFTokenToUser: (token: string, last4Digits: string) => Observable<any>; // TODO define bluesnapPaymentSources type
  // getTotalPriceAndTaxInfo: () => any; // POST /api/v1/billing-service/bluesnap/tax-quote
}

const baseURL = '/api/v1/billing-service/bluesnap';

// TODO: check server error handlers works correctly

@injectable()
export default class PaymentProviderApiService implements IPaymentProviderApiService {
  generatePFToken() {
    const response = axios.get<string>(`${baseURL}/pf-token`);

    return from(response).pipe(map((response) => response.data));
  }

  bindPFTokenToUser(token, last4Digits) {
    const response = axios.post<string>(`${baseURL}/pf-token?last4digits=${last4Digits}`, token);

    return from(response).pipe(map((response) => response.data));
  }
}
