import { default as MUITooltip } from '@material-ui/core/Tooltip';
import React from 'react';

import styles from './styles.module.scss';

type TPlacement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

interface ITooltipProps {
  children: React.ReactElement<any, any>;
  title: string;
  placement?: TPlacement;
  disableHoverListener?: boolean;
}

const Tooltip: React.FC<ITooltipProps> = ({ children, title, placement = 'right' }) => (
  <MUITooltip
    classes={{
      tooltip: styles.Tooltip,
    }}
    title={title}
    placement={placement}
    arrow
  >
    {children}
  </MUITooltip>
);

export default Tooltip;
