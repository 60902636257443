import { useTranslation } from 'react-i18next';

import Button from 'components/UI/Buttons/BaseButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles.module.scss';

interface ISanningCSV {
  fileUploadingProgress: number;
  handleReject: () => void;
}
const ScanningCSV = ({ fileUploadingProgress = 0, handleReject }: ISanningCSV) => {
  const { t } = useTranslation(['import-csv', 'common']);
  return (
    <div className={styles.container}>
      <CircularProgress size={75} thickness={4} color={'inherit'} />
      <h3 className={styles.title}>
        {t('import-csv:ScanningCSV.title', { percentage: fileUploadingProgress })}
      </h3>
      <span className={styles.description}>{t('import-csv:ScanningCSV.description')}</span>
      <Button onClick={handleReject}>{t('duplicates:Cancel_action')}</Button>
    </div>
  );
};
export default ScanningCSV;
