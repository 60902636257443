import { map, filter, BehaviorSubject, tap, catchError, throwError } from 'rxjs';
import { injectable, inject, postConstruct } from 'inversify';
import { AxiosError } from 'axios';

import { ISubscriptionStateRepository } from '../domain/abstractions/repositories/ISubscriptionStateRepository';
import { ISubscriptionApiService } from './network/SubscriptionApiService';
import SubscriptionEntity from '../domain/entities/SubscriptionEntity';
import { TYPES } from '../di/types';

import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { TYPES as WORKSPACES_TYPES } from 'src/features2/workspaces/di/types';

@injectable()
export default class SubscriptionStateRepository implements ISubscriptionStateRepository {
  @inject(TYPES.SubscriptionApiService) private api: ISubscriptionApiService;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  private workspacesRepository: IWorkspacesStateRepository;
  private subscriptionSubject = new BehaviorSubject<SubscriptionEntity>(null);

  @postConstruct()
  init() {
    this.workspacesRepository
      .getCurrentWorkspace()
      .pipe(
        filter((workspace) => !!workspace), // fetch billing details only after login
        map((workspace) => workspace.subscription)
      )
      .subscribe(this.subscriptionSubject);
  }

  getSubscription() {
    return this.subscriptionSubject;
  }

  setPlan(plan, workspaceId, isRenewProcess) {
    return this.api.setPlan(plan, workspaceId, isRenewProcess).pipe(
      tap((workspaceDC) => {
        this.workspacesRepository.setWorkspace(workspaceDC).subscribe();
      }),
      map(() => true)
    );
  }

  purchaseSubscription(workspaceId, plan, promoCodeId, isRenewProcess) {
    return this.api.purchaseSubscription(workspaceId, plan, promoCodeId, isRenewProcess).pipe(
      tap((workspaceDC) => {
        this.workspacesRepository.setWorkspace(workspaceDC).subscribe();
      }),
      map(() => true),
      catchError((err: AxiosError) =>
        throwError(() => ({
          status: err.response.status,
          data: err.response.data,
        }))
      )
    );
  }

  cancelSubscription(workspaceId) {
    return this.api.cancelSubscription(workspaceId).pipe(
      tap((workspaceDC) => {
        this.workspacesRepository.setWorkspace(workspaceDC).subscribe();
      }),
      map(() => true)
    );
  }
}
