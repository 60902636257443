import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';

interface IArchiveTagProps {
  className?: string;
}

const ArchiveTag: React.FC<IArchiveTagProps> = ({ className }) => {
  const { t } = useTranslation('common');

  return <div className={clsx(styles.Tag, className)}>{t('tags.archive')}</div>;
};

export default ArchiveTag;
