import Workspace from '../../features2/workspaces/domain/entities/Workspace';
import { container } from '../../app/ioc/container';
import { TYPES as WORKSPACES_TYPES } from '../../features2/workspaces/di/types';
import { TYPES as USER_TYPES } from '../../features2/user/di/types';
import { IWorkspacesStateRepository } from '../../features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { IUserUseCases } from '../../features2/user/domain/abstractions/IUserUseCases';
import { forwardToLoginPage } from '../../services/router/appRouterService';
import { setIsAuth } from 'slices/userSlice';
import Snackbar from '../../components/UI/snackbar/providers/SnackbarUtilsConfigurator';

export function workspacesPreRemove(removableWorkspace: Workspace) {
  const { currentWorkspaceId } = container.get<IWorkspacesStateRepository>(
    WORKSPACES_TYPES.WorkspacesStateRepository
  );
  const userUseCases = container.get<IUserUseCases>(USER_TYPES.UserUseCases);

  if (removableWorkspace.id === currentWorkspaceId.value) {
    userUseCases.logout().subscribe(() => {
      // RS: require(...) is used to prevent premature makeStore() call
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const { store } = require('../../app/store/store');
      Snackbar.success('notifications:remove_from_workspace_notification', {
        workspaceName: removableWorkspace.name,
      });

      store.dispatch(setIsAuth(false));
      forwardToLoginPage();
    });
  }
}
