import { dbVersion } from '../../constants';

export default {
  title: 'workspace schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'id',
  type: 'object',
  required: [
    'id',
    'name',
    'industry',
    'companySize',
    'companyWebsite',
    'businessPhone',
    'thumbnail',
    'updatedAt',
    'createdAt',
    'roles',
    'members',
    'subscription',
    'memberAccessAllContacts',
    'proposalsNextColId',
    'lastLoginAt',
    'isPersonal',
  ],
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    name: {
      type: ['string', 'null'],
    },
    industry: {
      type: ['string', 'null'],
    },
    companySize: {
      type: ['string', 'null'],
    },
    companyWebsite: {
      type: ['string', 'null'],
    },
    businessPhone: {
      type: ['string', 'null'],
    },
    thumbnail: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    createdAt: {
      type: ['number', 'null'],
    },
    roles: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          role: {
            type: 'string',
          },
          isDefault: {
            type: 'boolean',
          },
          isMutable: {
            type: 'boolean',
          },
          permissions: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
    members: {
      type: 'array',
      items: {
        type: 'object',
        required: ['role', 'isDefault', 'isMutable', 'permissions'],
        properties: {
          role: {
            type: 'string',
          },
          isDefault: {
            type: 'boolean',
          },
          isMutable: {
            type: 'boolean',
          },
          permissions: {
            type: 'array',
            items: {
              type: 'string',
            },
          },
        },
      },
    },
    subscription: {
      type: 'object',
      properties: {
        credits: {
          type: 'object',
        },
      },
    },
    memberAccessAllContacts: {
      type: 'boolean',
    },
    proposalsNextColId: {
      type: ['string', 'null'],
    },
    lastLoginAt: {
      type: ['number', 'null'],
    },
    isPersonal: {
      type: 'boolean',
    },
  },
};
