import ErrorInternetIcon from 'icons/no-internet-importCSV.svg';
import styles from './styles.module.scss';
import Button from 'components/UI/Buttons/BaseButton';
const InternetConnectionErrorCase = ({ onClose, onTryAgain, t }) => {
  return (
    <div className={styles.container}>
      <ErrorInternetIcon />
      <h3 className={styles.title}>{t('ErrorCases.InternetConnection.title')}</h3>
      <span className={styles.description}>{t('ErrorCases.InternetConnection.description')}</span>
      <div className={styles.actionsContainer}>
        <Button variant="text" onClick={onClose} className={styles.buttonCancel}>
          {t('duplicates:Cancel_action')}
        </Button>
        <Button onClick={onTryAgain} className={styles.buttonTryAgain}>
          {t('common:action.try_again')}
        </Button>
      </div>
    </div>
  );
};
export default InternetConnectionErrorCase;
