export const getCorrectCompanySize = (str: string): string => (str ? str.replace('_', '-') : '1');

export const shortWithMiddleEllipsis = (str: string, length = 20) => {
  if (str.length <= length) {
    return str;
  }

  const substrLength = Math.ceil((length - 3) / 2);

  return `${str.substr(0, substrLength)}...${str.substr(str.length - substrLength, substrLength)}`;
};

export type TChunkifyStringReturn = Array<{
  id: number;
  content: string;
}>;

export const chunkifyString = (str, size): TChunkifyStringReturn => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = {
      id: uniqueId(),
      content: str.substr(o, size),
    };
  }

  return chunks;
};

const uniqueId = (length = 16) =>
  parseInt(
    Math.ceil(Math.random() * Date.now())
      .toPrecision(length)
      .toString()
      .replace('.', '')
  );
