import React, { forwardRef, useCallback } from 'react';
import { useSnackbar, SnackbarContent } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';

import { MessageType } from './providers/SnackBarProvider';
import CloseIcon from '../../../../public/images/icons/x-mark.svg';
import WarningIcon from '../../../../public/images/icons/alert.svg';
import SuccessIcon from '../../../../public/images/icons/contact-success.svg';
import styles from './SBContent.module.css';

const SBContent = forwardRef<
  HTMLDivElement,
  {
    id: string | number;
    icon?: React.ReactNode;
    closeContent?: React.ReactNode;
    message: string | React.ReactNode;
    variant: MessageType;
    className?: string;
  }
>(function SBContent({ id, icon, message, variant, className, closeContent }, ref) {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} className={styles.SnackbarContent}>
      <div
        className={clsx({
          [styles.card]: true,
          [styles.success]: variant === MessageType.Success,
          [styles.error]: variant === MessageType.Error,
          [className]: className,
        })}
      >
        <span className={styles.iconContainer}>
          {icon ? icon : variant == MessageType.Error ? <WarningIcon /> : <SuccessIcon />}
        </span>
        <div className={styles.text}>{message}</div>
        {closeContent ? (
          closeContent
        ) : (
          <IconButton className={styles.CloseButton} onClick={handleDismiss}>
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </SnackbarContent>
  );
});

export default SBContent;
