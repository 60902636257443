import { getCountryByAlpha2 } from 'country-locale-map';
import Contact from 'types/contact';

const getContactsSortedAlphabetically = (contacts: Contact[], language: string) => {
  const preparedCountry = language && (language.split('_')[1] || language.split('_')[0]);
  const data = getCountryByAlpha2(preparedCountry);
  const collator = new Intl.Collator(data?.languages, { ignorePunctuation: true });
  return contacts
    .map((contact) => ({
      _st:
        contact?.suggestion_name ||
        contact?.name ||
        contact.emails?.[0]?.email ||
        contact.phones?.[0]?.normalized_phone,
      ...contact,
    }))
    .sort(
      (a, b) =>
        // @ts-ignore
        isFinite(a._st[0]) - isFinite(b._st[0]) || // RS: This sorting moves names starts with number to the end
        collator.compare(a._st, b._st)
    )
    .map((contactWithSortingTarget) => {
      delete contactWithSortingTarget._st;

      return contactWithSortingTarget;
    });
};

export default {
  getContactsSortedAlphabetically,
};
