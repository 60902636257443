type FileName = File['name'];

const getFileTypeFromFilename = (fn: FileName) => fn.substr(fn.lastIndexOf('.') + 1).toLowerCase();

const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

const downloadFileByLink = (fileName: string, fileUrl: string) => {
  const a = document.createElement('a');

  a.href = fileUrl;
  a.download = fileName;
  document.body.appendChild(a);

  a.click();
  a.remove();
};

const getFileNameSelectionEndIndex = (fileName: string) =>
  fileName.indexOf(getFileTypeFromFilename(fileName)) - 1;

const getBase64FromBlob = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.addEventListener('loadend', () => resolve(fr.result));
    fr.addEventListener('error', reject);
    fr.readAsDataURL(blob);
  });
};

const getVideoThumbnailBlob = (file: File): Promise<File | null> => {
  return new Promise((resolve) => {
    const videoPlayer = document.createElement('video');
    const canBePlayed = videoPlayer.canPlayType(file.type) !== '';

    if (canBePlayed) {
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.setAttribute('width', '150');
      videoPlayer.setAttribute('height', '100');
      videoPlayer.load();

      videoPlayer.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
          videoPlayer.currentTime = 0;
        }, 200);

        videoPlayer.addEventListener('seeked', () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = 150;
          canvas.height = 100;
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          ctx.canvas.toBlob(
            (blob) => {
              const fileFromBlob = new File([blob], file.name);

              resolve(fileFromBlob);
            },
            'image/jpeg',
            0.05
          );
        });
      });
    } else {
      resolve(null);
    }
  });
};

const isVideoFormatSupported = (type: string) =>
  document.createElement('video').canPlayType(type) !== '';

const isAudioFormatSupported = (type: string) =>
  document.createElement('audio').canPlayType(type) !== '';

export {
  getFileTypeFromFilename,
  formatBytes,
  downloadFileByLink,
  getFileNameSelectionEndIndex,
  getBase64FromBlob,
  getVideoThumbnailBlob,
  isVideoFormatSupported,
  isAudioFormatSupported,
};
