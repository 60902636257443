import { configureStore, ThunkAction, Action, combineReducers, AnyAction } from '@reduxjs/toolkit';

import userReducer from '../../slices/userSlice';
import { reducer as contactsReducer } from '../../slices/contactsSlice';
import { reducer as boardReducer, boardSyncQueueMiddleware } from '../../slices/boardSlice';
import {
  reducer as tagsBoardReducer,
  tagsBoardSyncQueueMiddleware,
} from '../../slices/tagsBoardSlice';
import proposalsReducer from '../../slices/proposalsSlice';
import notesReducer from '../../slices/notesSlice';
import tasksReducer from '../../slices/tasksSlice/tasksSlice';
import fullReportReducer from '../../slices/fullReportSlice';
import { reducer as workspacesReducer } from '../../slices/workspacesSlice';
import callsReducer from '../../slices/callsSlice';
import uiReducer from '../../slices/uiSlice';

import searchReducer from '../../slices/searchSlice';
import notificationReducer from 'slices/notificationSlice';

function createRootReducer() {
  const combined = combineReducers({
    contacts: contactsReducer,
    board: boardReducer,
    tagsBoard: tagsBoardReducer,
    proposals: proposalsReducer,
    tasks: tasksReducer,
    notifications: notificationReducer,
    calls: callsReducer,
    notes: notesReducer,
    user: userReducer,
    fullReport: fullReportReducer,
    search: searchReducer,
    workspaces: workspacesReducer,
    ui: uiReducer,
  });

  const rootReducer = (state: ReturnType<typeof combined>, action: AnyAction) => {
    if (action.type === 'RESET_STORE') {
      return combined(undefined, { type: undefined });
    }

    return combined(state, action);
  };

  return rootReducer;
}

export function makeStore() {
  const rootReducer = createRootReducer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(boardSyncQueueMiddleware, tagsBoardSyncQueueMiddleware);
    },
  });

  return store;
}

export const store = makeStore();
export const resetStore = () => store.dispatch({ type: 'RESET_STORE' });

export type AppStore = ReturnType<typeof makeStore>;

export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
