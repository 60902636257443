import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import { TeamMember } from 'src/api/workspacesAPI';
import TaskEntity from 'src/db/entities/task/TaskEntity';
import Contact from 'types/contact';
import { getAllowedTagsItems } from './filterHelpers';

type TGetFilteredTasksArgs = {
  contacts: Contact[];
  tasks: TaskEntity[];
  selectedTeamMembers: TeamMember[];
  searchValue: string;
  selectedTags: TagBoardColumn[];
  teamMembersContactsMap: Record<string, Contact[]>;
  isAllContentVisible: boolean;
  userEmail: string;
};
type TGetFilteredTasks = (args: TGetFilteredTasksArgs) => TaskEntity[];

export const getFilteredTasksList: TGetFilteredTasks = ({
  searchValue,
  tasks,
  contacts,
  selectedTeamMembers,
  selectedTags,
  teamMembersContactsMap,
  isAllContentVisible,
  userEmail,
}) => {
  const searchValueLower = searchValue.toLowerCase();
  const allowedTasks = isAllContentVisible
    ? tasks
    : tasks.filter((task) => task.assignedTo === userEmail);
  let filteredTasks = allowedTasks;
  if (selectedTeamMembers.length) {
    filteredTasks = [];

    selectedTeamMembers.forEach(({ email }) => {
      const relatedContacts = teamMembersContactsMap[email];

      allowedTasks.forEach((task) => {
        relatedContacts.forEach((contact) => {
          if (task.contactId === contact.uuid) {
            filteredTasks.push(task);
          }
        });
      });
    });
  }

  if (selectedTags.length) {
    const allowedTagsItems = getAllowedTagsItems(selectedTags);
    const allowedTasks = [];

    allowedTagsItems.forEach((contactId) => {
      allowedTasks.forEach((task) => {
        if (task.contactId === contactId) {
          allowedTasks.push(task);
        }
      });
    });

    filteredTasks = allowedTasks;
  }

  if (searchValueLower) {
    filteredTasks = filteredTasks.filter(({ contactId, title, description }) => {
      const contact = contacts.find((contact) => contact.uuid === contactId);
      if (contact) {
        const { name } = contact;
        return (
          name?.toLowerCase().includes(searchValueLower) ||
          title?.toLowerCase().includes(searchValueLower) ||
          description?.toLowerCase().includes(searchValueLower)
        );
      }
      return (
        title?.toLowerCase().includes(searchValueLower) ||
        description?.toLowerCase().includes(searchValueLower)
      );
    });
  }

  return filteredTasks === tasks ? [] : filteredTasks;
};
