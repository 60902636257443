import { injectable, inject } from 'inversify';
import { IFBAnalyticsRepository } from './abstractions/IFBAnalyticsRepository';
import { TYPES } from '../di/types';
import { IFBAnalyticsUseCases } from './abstractions/IFBAnalyticsUseCases';
import FB_EVENTS from './enums/FB_EVENTS';
@injectable()
export default class FBAnalyticsUseCases implements IFBAnalyticsUseCases {
  @inject(TYPES.FBAnalyticsRepository)
  private fbAnalyticsRepository: IFBAnalyticsRepository;

  trackEvent = (
    eventType: string,
    eventName: FB_EVENTS,
    props?: { [key: string]: string | number }
  ) => {
    this.fbAnalyticsRepository.trackEvent(eventType, eventName, props);
  };
}
