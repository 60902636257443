import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '../app/store/store';

const initialState = {
  reportHistory: [],
  contactDetailsData: null,
  selectedReport: undefined,
  selectedCompanyUuid: '',
  selectedPersonUuid: '',
  selectedCompanyId: '',
  selectedPersonEmail: '',
  selectedPersonPhoneNumber: '',
  isCompanyReportOpened: false,
};

const fullReportSlice = createSlice({
  name: 'fullReport',
  initialState,
  reducers: {
    setReportHistory(state, { payload }) {
      state.reportHistory = payload;
    },
    setContactDetailsData(state, { payload }) {
      state.contactDetailsData = payload;
    },
    setSelectedReport(state, { payload }) {
      state.selectedReport = payload;
    },
    setSelectedCompanyId(state, { payload }) {
      state.selectedCompanyId = payload;
    },
    setSelectedCompanyUuid(state, { payload }) {
      state.selectedCompanyUuid = payload;
    },
    setSelectedPersonUuid(state, { payload }) {
      state.selectedPersonUuid = payload;
    },
    setSelectedPersonEmail(state, { payload }) {
      state.selectedPersonEmail = payload;
    },
    setSelectedPersonPhoneNumber(state, { payload }) {
      state.selectedPersonPhoneNumber = payload;
    },
    setIsCompanyReportOpened(state, { payload }) {
      state.isCompanyReportOpened = payload;
    },
    clearReportData(state) {
      state.selectedReport = undefined;
      state.selectedCompanyUuid = '';
      state.selectedPersonUuid = '';
      state.selectedCompanyId = '';
      state.selectedPersonEmail = '';
      state.selectedPersonPhoneNumber = '';
    },
  },
});

export const selectReportHistory = (state: AppState) => state.fullReport.reportHistory;
export const selectContactDetailsData = (state: AppState) => state.fullReport.contactDetailsData;
export const selectSelectedReport = (state: AppState) => state.fullReport.selectedReport;
export const selectSelectedCompanyUuid = (state: AppState) => state.fullReport.selectedCompanyUuid;
export const selectSelectedCompanyId = (state: AppState) => state.fullReport.selectedCompanyId;
export const selectSelectedPersonUuid = (state: AppState) => state.fullReport.selectedPersonUuid;
export const selectSelectedPersonEmail = (state: AppState) => state.fullReport.selectedPersonEmail;
export const selectSelectedPersonPhoneNumber = (state: AppState) =>
  state.fullReport.selectedPersonPhoneNumber;
export const selectIsCompanyReportOpened = (state: AppState) =>
  state.fullReport.isCompanyReportOpened;

const { reducer, actions } = fullReportSlice;

export const {
  setContactDetailsData,
  setReportHistory,
  setSelectedCompanyId,
  setSelectedCompanyUuid,
  setSelectedPersonUuid,
  setSelectedReport,
  setIsCompanyReportOpened,
  setSelectedPersonEmail,
  setSelectedPersonPhoneNumber,
  clearReportData,
} = actions;

export default reducer;
