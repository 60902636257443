import React from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { appRoutes } from 'src/utils/constants';
import Button from 'components/UI/Buttons/BaseButton';
import usePlansAndPurchasePagesDetection from '../helpers';
import commonDataProviderHoc from 'src/features2/common/provider/commonDataProviderHoc';

import styles from './styles.module.scss';

const WorkspacesPromo = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('billing', { keyPrefix: 'promos' });
  const { subscription } = useCurrentSubscription();
  const { currentUser } = useCurrentUser();
  const { isPlansOrPurchasesPage } = usePlansAndPurchasePagesDetection();

  if (isPlansOrPurchasesPage) return null;

  const {
    isUserOnFreePlan,
    isUserOnFreeTrial,
    isActive,
    daysLeft,
    isPaymentFailed,
    isCanceledAndExpired,
  } = subscription;
  const { isAdmin } = currentUser;
  let text = null;

  const updatedNowLinkBasedOnPermissions = isAdmin ? (
    <Button onClick={onClick}>
      <Link href={appRoutes.PLANS_PAGE}>{t('upgradeNow')}</Link>
    </Button>
  ) : (
    <>{t('contactAdmin')}</>
  );

  const fixNowLinkBasedOnPermissions = isAdmin ? (
    <Button>
      <Link href={appRoutes.SUBSCRIPTION_PAGE}>{t('fixNow')}</Link>
    </Button>
  ) : (
    <>{t('contactAdmin')}</>
  );

  if (!isActive) {
    text = (
      <>
        <span>{t('subExpired')}</span> {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isUserOnFreeTrial) {
    if (isActive) {
      if (daysLeft > 1) {
        text = (
          <>
            <span>{t('freeTrialDaysLeft', { daysLeft })}</span> {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
      if (daysLeft === 1) {
        text = (
          <>
            <span>{t('freeTrialDayLeft')}</span> {updatedNowLinkBasedOnPermissions}
          </>
        );
      }
    } else {
      text = (
        <>
          <span>{t('freeTrialIsEnded')}</span> {updatedNowLinkBasedOnPermissions}
        </>
      );
    }
  }

  if (isUserOnFreePlan) {
    text = (
      <>
        <span>{t('onFreePlan')}</span> {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isPaymentFailed) {
    text = (
      <>
        <span>{t('issueWithLastPayment')}</span> {fixNowLinkBasedOnPermissions}
      </>
    );
  }

  if (isCanceledAndExpired) {
    text = (
      <>
        <span>{t('subCanceledAndExpired')}</span> {updatedNowLinkBasedOnPermissions}
      </>
    );
  }

  if (!text) return null; // play it safe

  return (
    <div className={styles.wrapper}>
      <span className={clsx(!isAdmin && styles.isMember)}>{text}</span>
    </div>
  );
};

export default commonDataProviderHoc(WorkspacesPromo);
