import { injectable, inject } from 'inversify';
import { ICustomerIORepository } from './abstractions/ICustomerIORepository';
import { TYPES } from '../di/types';
import { ICustomerIOUseCases } from './abstractions/ICustomerIOUseCases';
@injectable()
export default class CustomerIOUseCases implements ICustomerIOUseCases {
  @inject(TYPES.CustomerIORepository)
  private customerIORepository: ICustomerIORepository;

  trackEvent = (eventType) => {
    this.customerIORepository.trackEvent(eventType);
  };

  initializeUser = () => {
    this.customerIORepository.initializeUser();
  };

  resetUser = () => {
    this.customerIORepository.resetUser();
  };
}
