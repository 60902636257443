import React from 'react';

import PreviewArea from '../shared/PreviewArea';
import useFilePreviewViewModel from '../../useFilePreviewViewModel';
import { IFilePreviewUnitProps } from '../../index';

const FilePreviewGoogleEmbed: React.FC<IFilePreviewUnitProps> = ({ file }) => {
  const { fileLink } = useFilePreviewViewModel({ file, isFileSupported: true });
  const src1 = `https://drive.google.com/viewerng/viewer?url=${encodeURIComponent(
    fileLink
  )}&embedded=true`;

  return (
    <PreviewArea noLoader>
      {fileLink && (
        <div style={{ height: '100%' }}>
          <iframe
            style={{
              height: 'calc(100% - 129px)',
              width: '70%',
              margin: '0 auto',
              display: 'block',
            }}
            title={'docviewer'}
            src={src1}
          />
        </div>
      )}
    </PreviewArea>
  );
};

export default FilePreviewGoogleEmbed;
