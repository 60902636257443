import clsx from 'clsx';
import Avatar from '../../../components/UI/Avatar';
import styles from './Chip.module.css';
import ClearIcon from '../../../../public/images/icons/close.svg';

export default function Chip({
  className,
  thumbnail,
  name,
  phoneNumber,
  onUnSelect,
  onClick,
  disabled = false,
  email,
}: {
  className?: string;
  thumbnail?: string;
  name: string;
  email?: string;
  phoneNumber: string;
  onUnSelect: () => void;
  onClick: () => void;
  disabled?: boolean;
}) {
  const handleUnselect = (event) => {
    event.stopPropagation();
    onUnSelect();
  };

  return (
    <button
      className={clsx(styles.chip, className)}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      <Avatar className={styles.Avatar} thumbnail={thumbnail} name={name} size={16} />
      <div className={styles.text}>{name || phoneNumber || email}</div>
      <button
        onClick={handleUnselect}
        className={styles.closeButton}
        type="button"
        disabled={disabled}
      >
        <ClearIcon />
      </button>
    </button>
  );
}
