import { createContext } from 'react';
import TaskEntity from 'src/db/entities/task/TaskEntity';

export interface TaskDrawerContext {
  isOpen: boolean;
  openCreateForm: ({
    closeAfterSave,
    defaultValues,
  }: {
    closeAfterSave?: boolean;
    defaultValues?: Partial<TaskEntity>;
  }) => void;
  openUpdateForm: ({
    closeAfterSave,
    id,
  }: {
    closeAfterSave?: boolean;
    id: TaskEntity['id'];
  }) => void;
  close: () => void;
}

const TaskDrawerContext = createContext<TaskDrawerContext>({
  isOpen: false,
  openCreateForm: undefined,
  openUpdateForm: undefined,
  close: undefined,
});

export default TaskDrawerContext;
