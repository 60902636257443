import { forwardRef } from 'react';
import { StandardTextFieldProps } from '@material-ui/core/TextField';

import FormControl from './FormControl';
import InputLabel from './InputLabel';
import FormHelperText from './FormHelperText';
import InputBase from './InputBase';

const TextField = forwardRef(function TextField(
  props: Omit<StandardTextFieldProps, 'variant'> & {
    className?: string;
    labelHint?: React.ReactNode;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  },
  ref
) {
  const {
    className,
    label,
    labelHint,
    autoComplete,
    defaultValue,
    FormHelperTextProps,
    fullWidth = false,
    helperText,
    id,
    inputProps,
    InputProps,
    inputRef,
    multiline = false,
    name,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    rows,
    type,
    value,
    rowsMax,
    disabled,
    error,
    InputLabelProps,
    margin,
    endAdornment,
    startAdornment,
    children,
    required,
  } = props;

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  return (
    <FormControl
      className={className}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      margin={margin}
    >
      {label && (
        <InputLabel {...InputLabelProps} htmlFor={id} id={inputLabelId} hint={labelHint}>
          {label} {required && <span>*</span>}
        </InputLabel>
      )}
      {children || (
        <InputBase
          aria-describedby={helperTextId}
          autoComplete={autoComplete}
          defaultValue={defaultValue}
          fullWidth={fullWidth}
          multiline={multiline}
          name={name}
          rows={rows}
          rowsMax={rowsMax}
          type={type}
          value={value}
          id={id}
          inputRef={inputRef}
          error={error}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          inputProps={{ ...inputProps, 'data-hj-allow': '' }}
          {...(ref && { ref })}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          {...InputProps}
        />
      )}
      {helperText && (
        <FormHelperText id={helperTextId} {...FormHelperTextProps}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});

export default TextField;
