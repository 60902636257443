import { createSlice } from '@reduxjs/toolkit';

import { AppState } from '../app/store/store';
import { TSearchState, TSearchHistoryItem } from '../../types/search';

const initialState: TSearchState = {
  history: [],
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    addHistoryItem(state, { payload }: { payload: TSearchHistoryItem }) {
      const filteredList = state.history.filter(({ id }) => id !== payload.id);

      state.history = [payload, ...filteredList];
    },
    clearHistory(state) {
      state.history = [];
    },
  },
});

const { reducer, actions } = searchSlice;

export const selectSearchHistory = (state: AppState) => state.search.history;

export const { addHistoryItem, clearHistory } = actions;
export default reducer;
