import { useDispatch } from 'react-redux';
import { setCurrentContact } from 'slices/contactsSlice';
import { selectRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import Contact from 'types/contact';
import { TRedirectEffect } from 'types/ui';
import { useAppSelector } from '../useAppSelector';

type TUseRedirectEffect = (
  type: RedirectEffectTargetType | null
) => TRedirectEffect | Contact['uuid'];

const useRedirectEffect: TUseRedirectEffect = (type) => {
  const redirectEffect = useAppSelector(selectRedirectEffect);
  const dispatch = useDispatch();

  if (redirectEffect) {
    if (type) {
      return redirectEffect;
    } else {
      dispatch(setCurrentContact(redirectEffect.contactId));
    }
  }
};

export default useRedirectEffect;
