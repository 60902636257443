const getDDMMYYYYDateFromMs = (ms: number) => {
  const date = new Date(ms);
  const day = date.getDate().toString().length === 1 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth().toString().length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;

  return `${day}.${month}.${date.getFullYear()}`;
};

export { getDDMMYYYYDateFromMs };
