import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useWatch, useFormContext } from 'react-hook-form';

import { useAppSelector, useAppStore } from '../../../../../app/hooks';

import { FormValues } from '../GenericForm';
import TextField from '../../../../../components/UI/Inputs/TextField';
import Chip from './Chip';
import TeammembersMenu from '../../../../collaboration/components/AssignToSelect/TeammembersMenu';
import { selectAcceptedTeamMembers } from '../../../../../slices/workspacesSlice';
import { selectContactsMap } from '../../../../../slices/contactsSlice';

import styles from './AssignToSelect.module.css';

export default function AssignToSelect({
  value,
  onSelect,
}: {
  value: string;
  onSelect: (nextValue: string) => void;
}) {
  const { t } = useTranslation('tasks-page');

  const store = useAppStore();

  const anchorRef = useRef<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { setValue, control, getFieldState } = useFormContext<FormValues>();
  const conctactId = useWatch<FormValues>({ name: 'contactId', control });

  const options = useAppSelector(selectAcceptedTeamMembers);
  const currentOption = options.find((optItem) => optItem.email === value);

  useEffect(() => {
    if (conctactId) {
      const contactsMap = selectContactsMap(store.getState());
      const contact = contactsMap[conctactId as string];
      const contactId = getFieldState('contactId');

      if (contact && contact.assigned_to && contactId.isDirty) {
        setValue('assignedTo', contact.assigned_to);
      }
    }
  }, [conctactId, store, setValue, getFieldState]);

  return (
    <div>
      <TextField
        ref={anchorRef}
        type="string"
        id="task_form_assigned_to"
        label={t('form.field.assigned_to.label')}
        placeholder={!value && t('form.field.assigned_to.placeholder')}
        fullWidth
        inputProps={{
          readOnly: true,
          onClick: () => {
            setAnchorEl(anchorRef.current);
          },
        }}
        InputProps={{
          startAdornment: !!currentOption && (
            <Chip
              thumbnail={currentOption.thumbnail}
              email={currentOption.email}
              onClick={() => setAnchorEl(anchorRef.current)}
            />
          ),
        }}
      />
      <TeammembersMenu
        className={styles.Menu}
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        value={value}
        options={options}
        onSelect={onSelect}
        onClose={() => setAnchorEl(null)}
      />
    </div>
  );
}
