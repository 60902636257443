import { injectable, inject } from 'inversify';

import { IBillingUseCases } from './abstractions/IBillingUseCases';
import { IBillingDetailsStateRepository } from './abstractions/repositories/IBillingDetailsStateRepository';
import { IPaymentProviderStateRepository } from './abstractions/repositories/IPaymentProviderStateRepository';
import { ISubscriptionStateRepository } from './abstractions/repositories/ISubscriptionStateRepository';
import { IInvoicesRepository } from './abstractions/repositories/IInvoicesRepository';
import { IPromoCodeRepository } from './abstractions/repositories/IPromoCodeRepository';

import { TYPES } from '../di/types';

@injectable()
export default class BillingUseCases implements IBillingUseCases {
  @inject(TYPES.BillingDetailsStateRepository)
  private billingDetailsStateRepository: IBillingDetailsStateRepository;
  @inject(TYPES.PaymentProviderStateRepository)
  private paymentProviderStateRepository: IPaymentProviderStateRepository;
  @inject(TYPES.SubscriptionStateRepository)
  private subscriptionStateRepository: ISubscriptionStateRepository;
  @inject(TYPES.InvoicesRepository)
  private invoicesRepository: IInvoicesRepository;
  @inject(TYPES.PromoCodeRepository)
  private promoCodeRepository: IPromoCodeRepository;

  getBillingDetails() {
    return this.billingDetailsStateRepository.getBillingDetails();
  }

  updateBillingDetails(billingDetails) {
    return this.billingDetailsStateRepository.updateBillingDetails(billingDetails);
  }

  getPFToken() {
    return this.paymentProviderStateRepository.getPFToken();
  }

  updatePFToken() {
    return this.paymentProviderStateRepository.updatePFToken();
  }

  bindPFTokenToUser(token, last4Digits) {
    return this.paymentProviderStateRepository.bindPFTokenToUser(token, last4Digits);
  }

  getSubscription() {
    return this.subscriptionStateRepository.getSubscription();
  }

  setPlan(plan, workspaceId, isRenewProcess) {
    return this.subscriptionStateRepository.setPlan(plan, workspaceId, isRenewProcess);
  }

  purchaseSubscription(workspaceId, plan, promoCodeId, isRenewProcess) {
    return this.subscriptionStateRepository.purchaseSubscription(
      workspaceId,
      plan,
      promoCodeId,
      isRenewProcess
    );
  }

  cancelSubscription(workspaceId) {
    return this.subscriptionStateRepository.cancelSubscription(workspaceId);
  }

  getInvoices() {
    return this.invoicesRepository.getInvoices();
  }

  checkPromoCode(code, productLabel) {
    return this.promoCodeRepository.checkPromoCode(code, productLabel);
  }
}
