import { Container } from 'inversify';
import { TYPES } from './types';
import { ISyncStateRepository } from '../domain/abstractions/ISyncStateRepository';
import SyncStateRepository from '../data/SyncStateRepository';

export const bindSyncModule = (container: Container) => {
  container
    .bind<ISyncStateRepository>(TYPES.SyncStateRepository)
    .to(SyncStateRepository)
    .inSingletonScope();
};
