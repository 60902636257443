import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { data } from 'currency-codes';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DownloadIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';

import {
  filterAssignedOrPersonalContacts,
  filterByAnchors,
  selectNotHiddenContacts,
} from '../../../../../slices/contactsSlice';
import {
  selectIsAdminOrOwner,
  selectCurrentWorkspace,
  selectCurrentUserMemberData,
} from '../../../../../slices/workspacesSlice';

import { useAppSelector } from '../../../../../app/hooks';

import { MessageType } from '../../../../../components/UI/snackbar/providers/SnackBarProvider';
import SearchContactInput from '../../../../contacts/SearchContactInput';
import styles from '../../ProposalForm.module.scss';
import FormControl from '../../../../../components/UI/Inputs/FormControl';
import InputLabel from '../../../../../components/UI/Inputs/InputLabel';
import TextField from '../../../../../components/UI/Inputs/TextField';
import Select from '../../../../../components/UI/Inputs/Select';
import Checkbox from '../../../../../components/UI/Inputs/Checkbox';
import Button from '../../../../../components/UI/Buttons/BaseButton';
import Spinner from '../../../../../components/UI/Spinner';
import ItemsField from './Inputs/ItemsField';
import { FormValues } from './useMainSubForm';
import TotalSection from './TotalSection/TotalSection';

export default function Layout({
  contactPinned,
  onPreview,
  onDownload,
  onSubmit,
}: {
  contactPinned?: boolean;
  onPreview: (values: FormValues) => Promise<void>;
  onDownload: (values: FormValues) => Promise<void>;
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation('proposal-form');
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, getValues, trigger } = useFormContext<FormValues>();

  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);

  const { email } = useAppSelector(selectCurrentUserMemberData);

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        // no intersection with screen
        if (entries[0].intersectionRatio === 0) setIsSticky(true);
        // fully intersects with screen
        else if (entries[0].intersectionRatio === 1) setIsSticky(false);
      },
      { threshold: [0, 1] }
    );

    observer.observe(document.querySelector('#sticky-container-bottom'));
    return () => {
      observer.disconnect();
    };
  }, []);

  const allContactsArray = useAppSelector(selectNotHiddenContacts);

  const getShowedContacts = () => {
    if (isAdmin || memberAccessAllContacts) {
      return filterByAnchors(allContactsArray);
    } else {
      return filterAssignedOrPersonalContacts(allContactsArray, email);
    }
  };

  const [previewLoading, setPreviewLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { isSubmitting } = useFormState({ control });

  const handlePreviewClick = async () => {
    const isValid = await trigger();

    if (isValid) {
      setPreviewLoading(true);

      try {
        await onPreview(getValues());
      } catch (error) {
        const message = !error.response?.status
          ? t('common:message.network_error')
          : t('common:message.server_error');

        enqueueSnackbar({
          message,
          variant: MessageType.Error,
        });
      } finally {
        setPreviewLoading(false);
      }
    }
  };

  const handleDownloadClick = async () => {
    const isValid = await trigger();

    if (isValid) {
      setDownloading(true);

      try {
        const formValues = getValues();
        await onDownload(formValues);
      } catch (error) {
        const message = !error.response?.status
          ? t('common:message.network_error')
          : t('common:message.server_error');

        enqueueSnackbar({
          message,
          variant: MessageType.Error,
        });
      } finally {
        setDownloading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <Box>
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              id="proposal_form_title"
              label={t('form.field.title.label')}
              placeholder={t('form.field.title.placeholder')}
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
        />
      </Box>
      <Box width="100%">
        <Box marginX={-0.5} display="flex">
          <Box width="50%" paddingX={0.5}>
            <Controller
              name="contactId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <SearchContactInput
                    contacts={getShowedContacts()}
                    id="proposal_form_contact"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label={t('form.field.contact.label')}
                    placeholder={t('form.field.contact.placeholder')}
                    value={field.value}
                    onChange={field.onChange}
                    disabled={contactPinned || false}
                  />
                );
              }}
            />
          </Box>
          <Box width="50%" paddingX={0.5}>
            <Controller
              name="client"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="proposal_form_client"
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  label={t('form.field.client.label')}
                  placeholder={t('form.field.client.placeholder')}
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <ItemsField />
      </Box>
      <Box width="100%">
        <Box marginX={-0.5} display="flex">
          <Box width="50%" paddingX={0.5}>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="proposal_form_currency">
                    {t('duplicates:Quote_settings_currency')}
                  </InputLabel>
                  <Select
                    {...field}
                    inputProps={{ id: 'proposal_form_currency' }}
                    options={data.map(({ code, currency }) => ({
                      value: code,
                      text: `${code} (${currency})`,
                    }))}
                    fullWidth
                    disabled={!isAdmin}
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
      </Box>

      <TotalSection />

      <Controller
        name="comment"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="proposal_form_comment"
            label={t('form.field.comment.label')}
            placeholder={t('form.field.comment.placeholder')}
            multiline
            rows={4}
            fullWidth
          />
        )}
      />
      <Controller
        name="includesTax"
        control={control}
        render={({ field }) => {
          return (
            <FormControlLabel
              {...field}
              checked={field.value}
              id="proposal_form_includes_tax"
              control={<Checkbox />}
              label={t('form.field.includeTax.label')}
            />
          );
        }}
      />
      <Box
        mt={3}
        mb={6}
        display="flex"
        className={clsx({
          [styles.bottom]: true,
          ['saveButtonContainer']: true,
          [styles.saveButtonContainer]: isSticky,
        })}
      >
        <div>
          {previewLoading ? (
            <Spinner className={styles.PreviewSpinner} />
          ) : (
            <Button
              onClick={handlePreviewClick}
              variant="text"
              textSize="small"
              type="button"
              startIcon={<VisibilityIcon />}
            >
              {t('previewProposal')}
            </Button>
          )}
        </div>
        <Box ml="auto" display="flex">
          {isSubmitting ? (
            <Spinner className={styles.Spinner} />
          ) : (
            <Button variant="text" textSize="small" color="secondary" type="submit">
              {t('common:action.save')}
            </Button>
          )}

          {/* <Button
            onClick={() => {
              alert('it will send proposal, currently not implemented');
            }}
            type="button"
            variant="contained"
            textSize="small"
            className={styles.SendButton}
          >
            {t('common:action.send')}
          </Button> */}
          <Button
            startIcon={<DownloadIcon />}
            onClick={handleDownloadClick}
            type="button"
            variant="contained"
            textSize="small"
            className={styles.DownloadButton}
            disabled={downloading}
          >
            {t('common:action.download')}
          </Button>
        </Box>
      </Box>
      <div id="sticky-container-bottom" style={{ height: 1 }} />
    </form>
  );
}
