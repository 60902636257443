import { Observable, from, map, switchMap } from 'rxjs';
import { injectable, inject } from 'inversify';
import { TYPES } from '../../../app/ioc/types';
import { DbManager } from '../../../db/dbManager';
import ProfileEntity from 'src/db/entities/profile/ProfileEntity';

export interface IUserDao {
  wipeData(): Observable<boolean>;
  upsertProfile(profile: ProfileEntity): Observable<ProfileEntity>;
}

@injectable()
export default class UserDao implements IUserDao {
  @inject(TYPES.DbManager) dbManager: DbManager;

  upsertProfile = (profile) => {
    return from(this.dbManager.getDb()).pipe(
      switchMap((db) => {
        return db.profile.upsert(profile);
      }),
      map((document) => {
        return document.toMutableJSON() as ProfileEntity;
      })
    );
  };
  wipeData = () => {
    return from(this.dbManager.wipeData()).pipe(map(() => true));
  };
}
