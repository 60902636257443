export default {
  stream: {
    sse: 'api/v1/stream/sse',
    updates: 'api/v2/stream/updates',
  },
  vendor: {
    connect: '/api/v1/vendor/connect/',
  },
  account: {
    onboardingInfo: '/api/v1/account/onboarding-info',
    inviteTeammates: '/api/v1/account/invite-teammates',
    profile: '/api/v1/account/profile',
    settings: '/api/v1/account/settings',
    account: '/api/v1/account',
  },
  calls: {
    delete: '/api/v1/calls',
    get: '/api/v1/calls',
    put: '/api/v1/calls/sync',
  },
  tags: '/api/v1/tags',
  tagsBoard: {
    get: '/api/v1/tag-board',
    delete: '/api/v1/tag-board',
    updateColumns: '/api/v1/tag-board',
    updateItems: '/api/v1/tag-board/items',
    deleteItems: '/api/v1/tag-board/items',
    updateItemsOrder: '/api/v1/tag-board/{column_uuid}/order',
    updateColumnsOrder: '/api/v1/tag-board/order',
  },
  notes: '/api/v1/notes',
  notifications: {
    patch: '/api/v1/notifications',
    delete: '/api/v1/notifications',
  },
  board: {
    get: '/api/v2/board',
    updateColumns: '/api/v2/board',
    delete: '/api/v2/board',
    patch: '/api/v2/board',
    updateColumnsOrder: '/api/v2/board/order',
    updateItems: '/api/v2/board/items',
    deleteItems: '/api/v2/board/items',
    patchItems: '/api/v2/board/items',
    updateItemsOrder: '/api/v2/board/{column_uuid}/order',
  },
  delete_contacts: '/api/v1/debug/delete-contacts',
  delete_calls: '/api/v1/debug/delete-calls',
  media: {
    uploadFile: '/api/v1/media/file',
    confirmUploadFile: '/api/v1/media/file/{uuid}/confirm',
  },
  contacts: {
    createUpdateContact: '/api/v1/contacts',
    patch: '/api/v1/contacts',
    export: '/api/v1/contacts/export',
  },
  workspaces: {
    get: '/api/v1/workspaces',
    post: '/api/v1/workspaces',
    patch: '/api/v1/workspaces',
    members: {
      put: '/api/v1/workspaces/{workspace_uuid}/members',
    },
  },
  proposals: {
    get: '/api/v1/price-proposal',
    post: '/api/v1/price-proposal',
    patch: '/api/v1/price-proposal',
    delete: '/api/v1/price-proposal/{uuid}',
    pdf: '/api/v1/price-proposal-pdf',
  },
  'proposal-settings': {
    post: '/api/v1/price-proposal-settings',
  },
  tasks: {
    get: '/api/v1/tasks',
    post: '/api/v1/tasks',
    patch: '/api/v1/tasks',
    delete: '/api/v1/tasks',
  },
};
