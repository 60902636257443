import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import { TeamMember } from 'src/api/workspacesAPI';
import NoteEntity from 'src/db/entities/note/NoteEntity';
import Contact from 'types/contact';
import { getAllowedTagsItems } from './filterHelpers';

type TGetFilteredNotesArgs = {
  contacts: Contact[];
  selectedTeamMembers: TeamMember[];
  selectedTags: TagBoardColumn[];
  notes: NoteEntity[];
  isAllContentVisible: boolean;
  userEmail: string;
  searchValue: string;
  teamMembersContactsMap: Record<string, Contact[]>;
};
type TGetFilteredNotes = (args: TGetFilteredNotesArgs) => NoteEntity[];

export const getFilteredNotes: TGetFilteredNotes = ({
  searchValue,
  notes,
  contacts,
  selectedTeamMembers,
  selectedTags,
  isAllContentVisible,
  userEmail,
  teamMembersContactsMap,
}) => {
  const searchValueLower = searchValue.toLowerCase();
  let filteredNotes = notes;

  if (selectedTeamMembers.length) {
    filteredNotes = [];

    selectedTeamMembers.forEach(({ email }) => {
      const relatedContacts = teamMembersContactsMap[email];

      notes.forEach((note) => {
        relatedContacts.forEach((contact) => {
          if (note.links.direct.id === contact.uuid) {
            filteredNotes.push(note);
          }
        });
      });
    });
  }

  if (selectedTags.length) {
    const allowedTagsItems = getAllowedTagsItems(selectedTags);
    const allowedNotes = [];

    allowedTagsItems.forEach((contactId) => {
      notes.forEach((note) => {
        if (note.links.direct.id === contactId) {
          allowedNotes.push(note);
        }
      });
    });

    filteredNotes = allowedNotes;
  }

  if (searchValueLower) {
    filteredNotes = filteredNotes?.filter(({ content, links: { direct } }) => {
      const contact = contacts.find((contact) => contact.uuid === direct.id);

      if (contact) {
        const { name, assigned_to } = contact;
        if (!isAllContentVisible && assigned_to !== userEmail) {
          return false;
        }
        return (
          content?.toLowerCase().includes(searchValueLower) ||
          name?.toLowerCase().includes(searchValueLower)
        );
      }

      return false;
    });
  }

  return filteredNotes === notes ? [] : filteredNotes;
};
