import { forwardRef } from 'react';
import clsx from 'clsx';
import MUIMenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import styles from './Menu.module.css';

type Props = MenuItemProps & { button?: true };

const MenuItem = forwardRef<HTMLLIElement, Props>(function MenuItem(props, ref) {
  return <MUIMenuItem ref={ref} {...props} className={clsx(styles.MenuItem, props.className)} />;
});

export default MenuItem;
