import { Observable } from 'rxjs';
import { inject, injectable } from 'inversify';

import { TYPES } from '../di/types';
import { TYPES as WORKSPACES_TYPES } from '../../workspaces/di/types';
import { IProfileApiService } from './network/ProfileApiService';
import { IWorkspacesStateRepository } from '../../workspaces/domain/abstractions/IWorkspacesStateRepository';

export interface IProfileExportRepository {
  exportProfileContacts(): Observable<string>;
}

@injectable()
export default class ProfileExportRepository implements IProfileExportRepository {
  @inject(TYPES.ProfileApiService) private api: IProfileApiService;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  workspacesStateRepository: IWorkspacesStateRepository;

  exportProfileContacts(): Observable<string> {
    return this.api.exportProfileContacts(this.workspacesStateRepository.currentWorkspaceId.value);
  }
}
