enum FileMimeType {
  MP3 = 'audio/mpeg',
  M4A = 'audio/m4a',
  X_M4A = 'audio/x-m4a',
  X_WAV = 'audio/x-wav',
  WAV = 'audio/wav',
  AUDIO_MP4 = 'audio/mp4',
  CSV = 'text/csv',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  HTML = 'text/html',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
  PNG = 'image/png',
  TIFF = 'image/tiff',
  HEIC = 'image/heic',
  HEIF = 'image/heif',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  RTF_TEXT = 'text/rtf',
  RTF_APPLICATION = 'application/rtf',
  SVG = 'image/svg+xml',
  TXT = 'text/plain',
  VIDEO_MP4 = 'video/mp4',
  AVI_MSVIDEO = 'video/x-msvideo',
  AVI = 'video/avi',
  MOV = 'video/quicktime',
  FLV = 'video/x-flv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ZIP = 'application/zip',
  RAR = 'application/x-rar-compressed',
}

export default FileMimeType;
