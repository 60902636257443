import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

import StreamUpdatesManager, {
  streamUpdatesManagerContext,
} from '../app/streamUpdate/streamUpdatesManager';
import { useAppSelector } from '../app/hooks/useAppSelector';
import { selectCurrentWorkspaceId } from '../slices/workspacesSlice';
import { useRouter } from 'next/router';
import appRoutes from 'src/utils/constants/appRoutes';
import useInitMobileData from 'src/app/hooks/useInitMobileData';

export default function StreamUpdatesProvider({
  children,
  store,
}: {
  children: React.ReactNode;
  store: any;
}) {
  useInitMobileData(); // to re-fetch data on mobile since stream update is turn off on mobile web
  const router = useRouter();
  const currentWorkspaceId = useAppSelector(selectCurrentWorkspaceId);
  const shouldStreamUpdateBeSkipped = isMobileOnly; // turn off stream update on mobile

  useEffect(() => {
    if (currentWorkspaceId && !router.pathname.includes(appRoutes.LOGIN_PAGE)) {
      if (!streamUpdatesManagerContext.instance) {
        const streamUpdatesManager = new StreamUpdatesManager(store);
        if (shouldStreamUpdateBeSkipped) {
          streamUpdatesManager.startWithoutUpdate();
        } else {
          streamUpdatesManager.start();
        }
      }
    }

    return () => {
      if (streamUpdatesManagerContext.instance) {
        streamUpdatesManagerContext.instance.destroy();
      }
    };
  }, [currentWorkspaceId, router.pathname]);

  return <>{children}</>;
}
