import { endpoints } from '../utils/constants';
import axios from '../app/axios';
import { mapCallEntityToDC } from '../db/entities/call/mapper';
import CallEntity from '../db/entities/call/CallEntity';

async function updateManyCalls(calls: CallEntity[]): Promise<true> {
  const dcs = calls.map(mapCallEntityToDC);

  await axios.put<string>(endpoints.calls.put, { feed: dcs });
  return true;
}

async function deleteManyCalls(calls: CallEntity['id'][]): Promise<true> {
  await axios.delete<string>(endpoints.calls.delete, { data: calls });
  return true;
}

async function updateCall(call: CallEntity): Promise<true> {
  const dc = mapCallEntityToDC(call);
  await axios.put<string>(endpoints.calls.put, { feed: [dc] });
  return true;
}

export { updateCall, updateManyCalls, deleteManyCalls };
