import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { AppState } from '../app/store/store';
import {
  updateCall as updateCallAPI,
  updateManyCalls as updateManyCallsAPI,
  deleteManyCalls as deleteManyCallsAPI,
} from '../api/callsAPI';
import { filterByAnchors, selectNotHiddenContacts } from './contactsSlice';
import Contact from 'types/contact';
import CallEntity from '../db/entities/call/CallEntity';

interface CallsState {
  list: CallEntity[];
}

const initialState: CallsState = {
  list: [],
};

const sliceName = 'calls';

export const hideCall = createAsyncThunk(
  `${sliceName}/hideCall`,
  async (callId: CallEntity['id'], { getState }) => {
    const calls = selectCalls(getState() as AppState);

    const call = calls.find((callItem) => callItem.id === callId);

    const updatedCall: CallEntity = {
      ...call,
      notShow: true,
    };

    await updateCallAPI(updatedCall);

    return updatedCall;
  }
);

export const hideCallsBatch = createAsyncThunk(
  `${sliceName}/hideCallsBatch`,
  async (callNumbers: CallEntity['phoneNumber'][], { getState, dispatch }) => {
    const calls = selectCalls(getState() as AppState);
    const callsMap = calls.reduce<Record<string, CallEntity>>((acc, call) => {
      acc[call.id] = call;
      return acc;
    }, {});

    const callsNumberMap = calls.reduce((acc, call) => {
      if (acc[call.normalizedPhone]) {
        acc[call.normalizedPhone] = [...acc[call.normalizedPhone], call.id];
      } else {
        acc[call.normalizedPhone] = [call.id];
      }
      return acc;
    }, {});
    const callsUuidsForDelete = [];
    callNumbers.forEach((phone) => {
      callsNumberMap[phone] && callsUuidsForDelete.push(callsNumberMap[phone]);
    });
    const updatedCalls: CallEntity[] = callsUuidsForDelete
      .flat()
      .filter((id) => !callsMap[id].notShow)
      .map((id) => {
        return { ...callsMap[id], notShow: true, updatedAt: Math.floor(Date.now() / 1000) };
      });
    if (updatedCalls.length) {
      dispatch(updateCalls(updatedCalls));
      await updateManyCallsAPI(updatedCalls);
    }
    return updatedCalls;
  }
);
export const deleteCallsBatch = createAsyncThunk(
  `${sliceName}/deleteCallsBatch`,
  async (callIds: CallEntity['id'][]) => {
    await deleteManyCallsAPI(callIds);
    return callIds;
  }
);

const callsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCalls(state, action: PayloadAction<CallEntity[]>) {
      state.list = action.payload;
    },
    updateCalls(state, action: PayloadAction<CallEntity[]>) {
      const updatedCalls = action.payload;

      for (const callItem of updatedCalls) {
        const index = state.list.findIndex(({ id }) => callItem.id === id);
        state.list.splice(index, 1, callItem);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideCallsBatch.rejected, (state, action) => {
      const callNumbers = action.meta.arg;
      const calls = state.list;
      const callsMap = calls.reduce((acc, call) => {
        acc[call.id] = call;
        return acc;
      }, {});
      const callsNumberMap = calls.reduce((acc, call) => {
        if (acc[call.phoneNumber]) {
          acc[call.phoneNumber] = [...acc[call.phoneNumber], call.id];
        } else {
          acc[call.phoneNumber] = [call.id];
        }
        return acc;
      }, {});
      const callsUuidsForUpdate = callNumbers.map((phone) => callsNumberMap[phone]).flat();
      for (const callUuid of callsUuidsForUpdate) {
        const index = state.list.findIndex(({ id }) => id === callUuid);
        state.list.splice(index, 1, { ...callsMap[callUuid], notShow: false });
      }
    }),
      builder.addCase(deleteCallsBatch.fulfilled, (state, action) => {
        const callIds = action.payload;
        state.list = state.list.filter(({ id }) => !callIds.includes(id));
      }),
      builder.addCase(hideCall.pending, (state, action) => {
        const callId = action.meta.arg;

        const call = state.list.find((callItem) => callItem.id === callId);

        call.notShow = true;
      }),
      builder.addCase(hideCall.rejected, (state, action) => {
        const callId = action.meta.arg;

        const call = state.list.find((callItem) => callItem.id === callId);

        call.notShow = false;
      });
  },
});

export const { actions } = callsSlice;
export const { updateCalls } = actions;

export const selectCalls = (state: AppState) => state.calls.list;

export const selectRelatedContact = (state: AppState, callId: string) => {
  const calls = selectCalls(state);
  const currentCall = calls.find((callItem) => callItem.id === callId);

  return findRelatedContactByNormalizedPhone(
    currentCall.normalizedPhone,
    selectNotHiddenContacts(state)
  );
};

export const findRelatedContactByNormalizedPhone = (
  normalizedPhone: string,
  contacts: Contact[]
) => {
  return filterByAnchors(contacts).find((contactItem) =>
    contactItem.phones.some((phoneItem) => phoneItem.normalized_phone === normalizedPhone)
  ) as Contact | undefined;
};

export default callsSlice.reducer;
