import { Observable, map } from 'rxjs';

import { inject, injectable } from 'inversify';
import { TYPES } from '../di/types';
import { ITagLinksDao } from './TagLinksDao';

export interface ITagLinksRepository {
  getUniqueContactsByTagLinksUpdatedAfter(timestamp: number): Observable<string[]>;
}

@injectable()
export default class TagLinksRepository implements ITagLinksRepository {
  @inject(TYPES.TagLinksDao) private dao: ITagLinksDao;

  getUniqueContactsByTagLinksUpdatedAfter(timestamp) {
    return this.dao.getTagLinks({ createdAt: { $gte: timestamp } }).pipe(
      map((tags) => tags.map((tag) => tag.itemTypeId)),
      map((contacts) => contacts.filter((contact, i, arr) => arr.indexOf(contact) === i))
    );
  }
}
