import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import FileBC from '../../../../../../../domain/FileBC';
import {
  isVideoFormatSupported,
  isAudioFormatSupported,
} from '../../../../../../../../../utils/files';

const useFilePreviewMediaViewModel = (
  file: FileBC,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
  isAudio?: boolean
) => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const { mimeType } = file;
    const isMediaSupported = isAudio
      ? isAudioFormatSupported(mimeType)
      : isVideoFormatSupported(mimeType);

    setIsSupported(isMediaSupported);

    if (!isMediaSupported) {
      setIsLoading(false);
    }
  }, [file]);

  return {
    isSupported,
  };
};

export default useFilePreviewMediaViewModel;
