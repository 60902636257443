import CustomFieldValueDC from './CustomFieldValueDC';
import CustomFieldValueEntity from './CustomVieldValueEntity';

export const mapCustomFieldValueDCtoEntity = (
  dc: CustomFieldValueDC,
  workspaceId
): CustomFieldValueEntity => ({
  uuid: dc.uuid,
  customFieldUuid: dc.custom_field_uuid,
  contactUuid: dc.contact_uuid,
  value: dc.value,
  createdAt: dc.created_at,
  updatedAt: dc.updated_at,
  workspaceId,
});

export const mapCustomFieldValueEntityToDC = (
  entity: CustomFieldValueEntity
): CustomFieldValueDC => ({
  uuid: entity.uuid,
  custom_field_uuid: entity.customFieldUuid,
  contact_uuid: entity.contactUuid,
  value: entity.value,
  created_at: entity.createdAt,
  updated_at: entity.updatedAt,
  workspace_id: entity.workspaceId,
});
