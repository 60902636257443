import { dbVersion } from '../../constants';

export interface CallerEntity {
  phone: string;
  name: string | null;
  jobTitle: string | null;
  geospace: {
    latitude: number | null;
    longitude: number | null;
    country: string | null;
    countryCode: string | null;
    region: string | null;
  } | null;
  thumbnail: string | null;
  business: boolean;
  spam: number | null;
  bigSpammer: boolean;
}

export default {
  title: 'caller schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'phone',
  type: 'object',
  required: [
    'phone',
    'name',
    'jobTitle',
    'geospace',
    'thumbnail',
    'business',
    'spam',
    'bigSpammer',
  ],
  properties: {
    phone: {
      type: 'string',
      maxLength: 200,
    },
    name: {
      type: ['string', 'null'],
    },
    jobTitle: {
      type: ['string', 'null'],
    },
    geospace: {
      type: ['object', 'null'],
      required: ['latitude', 'longitude', 'country', 'countryCode', 'region'],
      properties: {
        latitude: {
          type: ['number', 'null'],
        },
        longitude: {
          type: ['number', 'null'],
        },
        country: {
          type: ['string', 'null'],
        },
        countryCode: {
          type: ['string', 'null'],
        },
        region: {
          type: ['string', 'null'],
        },
      },
    },
    thumbnail: {
      type: ['string', 'null'],
    },
    business: {
      type: 'boolean',
    },
    spam: {
      type: ['number', 'null'],
    },
    bigSpammer: {
      type: 'boolean',
    },
  },
};
