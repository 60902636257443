export default [
  '#5f48e9',
  '#9c26b0',
  '#3f51b4',
  '#2196f3',
  '#00bcd4',
  '#009688',
  '#4daf50',
  '#8bc34a',
  '#cddc39',
  '#ffeb3a',
  '#ffc008',
  '#ff9800',
  '#ff5821',
  '#f44336',
  '#e91e63',
  '#795548',
  '#9e9e9e',
  '#607d8b',
];
