const SelectFieldsOptions = [
  'name',
  'job_title',
  'company',
  'thumbnail',
  'phones(type=mobile)',
  'phones(type=home)',
  'phones(type=work_fax)',
  'emails',
  'addresses(type=home)',
  'addresses(type=work)',
  'addresses(type=other)',
  'dates(type=birthday)',
  'dates(type=anniversary)',
  'dates(type=other)',
  'notes',
  'urls',
  'not_import',
];
export default SelectFieldsOptions;
