import React from 'react';

import styles from './styles.module.scss';

const Overlay = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>{children}</div>
    </div>
  );
};

export default Overlay;
