import { injectable } from 'inversify';
import { forwardToLoginPage } from '../../services/router/appRouterService';

export interface IUserRouter {
  goToLoginPage: () => Promise<void>;
}

@injectable()
export class UserRouter implements IUserRouter {
  async goToLoginPage() {
    await forwardToLoginPage();
  }
}
