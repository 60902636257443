import { map } from 'rxjs';
import { inject, injectable } from 'inversify';

import { IInvoicesRepository } from '../domain/abstractions/repositories/IInvoicesRepository';
import { IInvoicesApiService } from './network/InvoicesApiService';
import { mapInvoiceDcToEntity } from './mappers/invoices/mapper';
import { TYPES } from '../di/types';

@injectable()
export default class InvoicesRepository implements IInvoicesRepository {
  @inject(TYPES.InvoicesApiService) private api: IInvoicesApiService;

  getInvoices() {
    return this.api.getInvoices().pipe(map((invoicesDC) => invoicesDC.map(mapInvoiceDcToEntity)));
  }
}
