import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'next-i18next';

import styles from 'styles/components/DeleteDialog.module.css';
import DirectedText from '../../../../features2/localization/ui/DirectedText';
import Button from '../../../UI/Buttons/BaseButton';

interface DeleteDialog {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  title: string;
  description: string;
  cancelButtonText?: string;
  deleteButtonText?: string;
  titleClassName?: string;
  descriptionClassName?: string;
}

export default function DeleteDialog({
  open,
  onClose,
  onSuccess,
  title,
  description,
  cancelButtonText,
  deleteButtonText,
  titleClassName,
  descriptionClassName,
}: DeleteDialog) {
  const { t } = useTranslation('common');
  const handleClose = () => {
    onClose();
  };
  const handleSucceed = () => {
    onClose();
    onSuccess();
  };
  return (
    <Dialog classes={{ paper: styles.container }} onClose={handleClose} open={open}>
      <DialogTitle className={clsx(styles.title, titleClassName)}>{title}</DialogTitle>
      <DialogContent>
        <div className={clsx(styles.description, descriptionClassName)}>
          <DirectedText>{description}</DirectedText>
        </div>
      </DialogContent>
      <DialogActions className={styles.actions}>
        <Button
          variant="text"
          className={clsx(styles.button, styles.cancelButton)}
          onClick={handleClose}
        >
          {cancelButtonText || t('duplicates:Cancel_action')}
        </Button>
        <Divider className={styles.divider} orientation="vertical" />
        <Button
          variant="text"
          color="error"
          className={clsx(styles.button, styles.deleteButton)}
          onClick={handleSucceed}
        >
          {deleteButtonText || t('duplicates:Delete_action')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
