import { Container } from 'inversify';

import { TYPES } from './types';
import FilesApiService from '../data/network/FilesApiService';
import FileUploadStateRepository from '../data/FileUploadStateRepository';
import FileUploadErrorHandlingRepository from '../data/FileUploadErrorHandlingRepository';
import FilesDao from '../data/FilesDao';
import FilesUseCases from '../domain/FilesUseCases';
import FileInteractionRepository from '../data/FileInteractionRepository';
import { IFilesApiService } from '../domain/abstractions/IFilesApiService';
import { IFileUploadStateRepository } from '../domain/abstractions/IFileUploadStateRepository';
import { IFileUploadingErrorHandlingRepository } from '../domain/abstractions/IFileUploadErrorHandlingRepository';
import { IFilesDao } from '../domain/abstractions/IFilesDao';
import { IFilesUseCases } from '../domain/abstractions/IFilesUseCases';
import { IFileInteractionRepository } from '../domain/abstractions/IFileInteractionRepository';

export const bindFilesModule = (container: Container) => {
  container.bind<IFilesApiService>(TYPES.FilesApiService).to(FilesApiService);
  container
    .bind<IFileUploadStateRepository>(TYPES.FileUploadRepository)
    .to(FileUploadStateRepository);
  container
    .bind<IFileUploadingErrorHandlingRepository>(TYPES.FileUploadErrorHandlingRepository)
    .to(FileUploadErrorHandlingRepository);
  container.bind<IFilesDao>(TYPES.FilesDao).to(FilesDao);
  container.bind<IFilesUseCases>(TYPES.FilesUseCases).to(FilesUseCases);
  container
    .bind<IFileInteractionRepository>(TYPES.FileInteractionRepository)
    .to(FileInteractionRepository);
};
