import Contact from '../../../types/contact';

export const filterByAnchors = (contacts: Contact[]): Contact[] => {
  const contactsMap = new Map<Contact['uuid'], Contact>();
  const withAnchors: Contact[] = [];

  for (const contactItem of contacts) {
    if (
      Array.isArray(contactItem.anchor_contact_ids) &&
      contactItem.anchor_contact_ids.length > 0
    ) {
      withAnchors.push(contactItem);
    } else {
      contactsMap.set(contactItem.uuid, contactItem);
    }
  }

  for (const contactWithAnchors of withAnchors) {
    const { anchor_contact_ids } = contactWithAnchors;

    for (const [index, anchor_contact_id] of anchor_contact_ids.entries()) {
      if (index === 0) {
        contactsMap.set(anchor_contact_id, contactWithAnchors);
      } else {
        contactsMap.delete(anchor_contact_id);
      }
    }
  }

  return Array.from(contactsMap.values());
};

export const findAnchors = (contactsArr: Contact[], contact: Contact): Contact[] => {
  const { uuid, phones, emails } = contact;

  if (phones.length) {
    const normalizedPhones = phones.map(({ normalized_phone }) => normalized_phone);

    const comparePhoneNumbers = (numbers1: string[], numbers2: string[]): boolean => {
      let samePhones = true;

      for (const number of numbers1) {
        if (!numbers2.includes(number)) {
          samePhones = false;
          break;
        }
      }

      return samePhones && numbers1.length === numbers2.length;
    };

    return contactsArr.filter((contact) => {
      if (contact.uuid === uuid) {
        return false;
      }

      const currentNormalizedPhones = contact.phones.map(
        ({ normalized_phone }) => normalized_phone
      );

      return comparePhoneNumbers(normalizedPhones, currentNormalizedPhones);
    });
  } else if (emails.length) {
    const emailsList = emails.map(({ email }) => email);

    const compareEmails = (emails1: string[], emails2: string[]): boolean => {
      let sameEmails = true;

      for (const number of emails1) {
        if (!emails2.includes(number)) {
          sameEmails = false;
          break;
        }
      }

      return sameEmails && emails1.length === emails2.length;
    };

    return contactsArr.filter((contact) => {
      if (contact.uuid === uuid) {
        return false;
      }

      const currentEmails = contact.emails.map(({ email }) => email);

      return compareEmails(emailsList, currentEmails);
    });
  } else {
    return [];
  }
};
