import axios from '../app/axios';
import { endpoints } from 'src/utils/constants';
import RequestHeaders from '../enums/RequestHeaders';

export type NotificationDataDC = {
  admin_name: string;
  company_name: string;
  item_type: string;
  item_uuid: string;
  notification_id: string;
  ordered_params: string[];
  title: string;
  workspace_id: string | null;
};
export type NotificationDC = {
  uuid: string;
  type: string;
  is_read: boolean;
  data: NotificationDataDC;
  created_at: number;
  updated_at: number;
};
export type NotificationDataModel = {
  adminName: string;
  companyName: string;
  itemType: string;
  itemId: string;
  notificationId: string;
  orderedParams: string[];
  title: string;
  workspaceId: string | null;
};
export type NotificationModel = {
  id: string;
  type: string;
  isRead: boolean;
  data: NotificationDataModel;
  createdAt: number;
  updatedAt: number;
};
export const mapNotificationDataModelToDC = (
  notificationDataModel: NotificationDataModel
): NotificationDataDC => {
  return {
    admin_name: notificationDataModel.adminName,
    company_name: notificationDataModel.companyName,
    item_type: notificationDataModel.itemType,
    item_uuid: notificationDataModel.itemId,
    notification_id: notificationDataModel.notificationId,
    ordered_params: notificationDataModel.orderedParams,
    title: notificationDataModel.title,
    workspace_id: notificationDataModel.workspaceId,
  };
};
export const mapNotificationModelToDC = (notificationModel: NotificationModel): NotificationDC => {
  return {
    uuid: notificationModel.id,
    type: notificationModel.type,
    is_read: notificationModel.isRead,
    data: mapNotificationDataModelToDC(notificationModel.data),
    created_at: notificationModel.createdAt,
    updated_at: notificationModel.updatedAt,
  };
};
export const mapNotificationDataDCToModel = (
  notificationDataDC: NotificationDataDC
): NotificationDataModel => {
  return {
    adminName: notificationDataDC.admin_name,
    companyName: notificationDataDC.company_name,
    itemType: notificationDataDC.item_type,
    itemId: notificationDataDC.item_uuid,
    notificationId: notificationDataDC.notification_id,
    orderedParams: notificationDataDC.ordered_params,
    title: notificationDataDC.title,
    workspaceId: notificationDataDC.workspace_id,
  };
};
export const mapNotificationDCtoModel = (notificationDC: NotificationDC): NotificationModel => {
  return {
    id: notificationDC.uuid,
    type: notificationDC.type,
    isRead: notificationDC.is_read,
    data: mapNotificationDataDCToModel(notificationDC.data),
    createdAt: notificationDC.created_at,
    updatedAt: notificationDC.updated_at,
  };
};
export const mapPartialNotificationModelToDC = (notificationData: Partial<NotificationModel>) => {
  const preparedObject = {
    uuid: notificationData?.id,
    type: notificationData?.type,
    is_read: notificationData?.isRead,
    data: notificationData?.data && mapNotificationDataModelToDC(notificationData?.data),
    created_at: notificationData?.createdAt,
    updated_at: notificationData?.updatedAt,
  };

  const newObject = {};
  for (const key in preparedObject) {
    if (preparedObject[key]) {
      newObject[key] = preparedObject[key];
    }
  }
  return newObject;
};
export async function deleteNotificationAPI(
  notificationIds: NotificationModel['id'][],
  workspaceId?: string
) {
  if (notificationIds.length) {
    await axios.delete<boolean>(endpoints.notifications.delete, {
      data: notificationIds,
      ...(workspaceId && { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }),
    });
  } else {
    await axios.delete<boolean>(`${endpoints.notifications.delete}?clear_all=True`, {
      ...(workspaceId && { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }),
    });
  }
}
export async function patchNotificationAPI({
  id,
  data,
  workspaceId,
}: {
  id: NotificationModel['id'];
  data: Partial<NotificationModel>;
  workspaceId: NotificationDataModel['workspaceId'];
}) {
  const preparedData = mapPartialNotificationModelToDC(data);
  await axios.patch<NotificationDC>(
    endpoints.notifications.patch,
    {
      ...preparedData,
      uuid: id,
    },
    workspaceId && { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
  );
}
