import TagLinkDC from './TagLinkDC';
import TagLinkEntity from './TagLinkEntity';

export function mapTagLinkDCtoEntity(dc: TagLinkDC, workspaceId: string): TagLinkEntity {
  return {
    type: dc.item_type,
    itemTypeId: dc.item_uuid,
    id: dc.uuid,
    nextId: dc.next_uuid,
    colId: dc.tag_uuid,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
    workspaceId,
  };
}
