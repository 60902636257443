import { inject, injectable } from 'inversify';
import ContactEntity from 'src/db/entities/contact/ContactEntity';
import { TYPES } from '../di/types';
import { IContactsDao } from './ContactsDao';
import { IContactsStateRepository } from '../domain/abstractions/IContactsStateRepository';
import { BehaviorSubject } from 'rxjs';
@injectable()
export default class ContactsStateRepository implements IContactsStateRepository {
  @inject(TYPES.ContactsDao) private dao: IContactsDao;
  private contactsSubject = new BehaviorSubject<ContactEntity[]>(null);

  getContacts = () => {
    if (this.contactsSubject.value) {
      return this.contactsSubject;
    } else {
      this.dao.getContacts().subscribe(this.contactsSubject);
    }
    return this.contactsSubject;
  };
}
