export default (items = [], nextUuid: string, id: string) => {
  const itemsNextUuidMap = {};
  const sortedList = [];

  const lastItems = items.filter((item) => item[nextUuid] === null);
  const hasNextItems = items.filter((item) => item[nextUuid] !== null);

  hasNextItems.forEach((item) => {
    itemsNextUuidMap[item[nextUuid]] = item;
  });

  // addition processing for cases when we have several nextId=null items:
  // It's common during bulkUpsert (which is divided internally to bulkInsert and atomicUpdate).
  // Between these operations we can have two or more items with nextId=null;
  const lastItemWhichIsNotIsolated =
    lastItems.find((item) => itemsNextUuidMap[item[id]]) || lastItems[0];
  const otherIsolatedItems = lastItems.filter((item) => item !== lastItemWhichIsNotIsolated);
  sortedList.push(...otherIsolatedItems);

  let linkedListCurrentItem = lastItemWhichIsNotIsolated;
  while (linkedListCurrentItem) {
    sortedList.push(linkedListCurrentItem);
    linkedListCurrentItem = itemsNextUuidMap[linkedListCurrentItem[id]];
  }

  return sortedList.reverse();
};
