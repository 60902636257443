import IconGoogle from '../../../public/images/icons/google-logo.svg';
import IconApple from '../../../public/images/icons/apple-logo.svg';
import IconOffice from '../../../public/images/icons/office-logo.svg';

export default [
  {
    provider: 'Google',
    icon: <IconGoogle />,
    button_color: '#4285f4',
  },
  {
    provider: 'Apple',
    icon: <IconApple />,
    button_color: '#000000',
  },
  {
    provider: 'Office',
    icon: <IconOffice />,
    button_color: '#eb3b00',
  },
];

const authConfigs = {
  google: {
    clientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_GOOGLE_AUTHORITY,
    redirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI,
  },
  microsoft: {
    clientId: process.env.NEXT_PUBLIC_MICROSOFT_CLIENT_ID,
    authority: process.env.NEXT_PUBLIC_MICROSOFT_AUTHORITY,
    redirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI,
  },
};
export { authConfigs };
