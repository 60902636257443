import { useTranslation } from 'next-i18next';

import { useInjection } from '../../../../../../../app/ioc/ioc.react';
import { TYPES } from '../../../../../di/types';
import { IFilesUseCases } from '../../../../../domain/abstractions/IFilesUseCases';
import FileBC from '../../../../../domain/FileBC';

const useFilePreviewProblemViewModel = (file: FileBC) => {
  const { t } = useTranslation('files');
  const { downloadFile } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);

  const onDownloadFile = () => downloadFile(file);

  return {
    t,
    onDownloadFile,
  };
};

export default useFilePreviewProblemViewModel;
