import { dbVersion } from '../../constants';

export default {
  title: 'notification schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'id',
  type: 'object',
  required: ['id', 'type', 'isRead', 'data', 'createdAt', 'updatedAt'],
  properties: {
    id: {
      type: 'string',
      maxLength: 100,
    },
    type: {
      type: 'string',
    },
    isRead: {
      type: 'boolean',
    },
    data: {
      type: 'object',
      required: ['notificationId', 'workspaceId', 'title', 'orderedParams'],
      properties: {
        notificationId: {
          type: 'string',
        },
        workspaceId: {
          type: ['string', 'null'],
        },
        title: {
          type: 'string',
        },
        orderedParams: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        itemType: {
          type: 'string',
        },
        itemId: {
          type: 'string',
        },
        adminName: {
          type: 'string',
        },
        companyName: {
          type: 'string',
        },
      },
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
  },
};
