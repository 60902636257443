import WorkspaceDC from './WorkspaceDC';
import WorkspaceEntity, { PlanOptions, PurchaseTypes, PAID_PLANS } from './WorkspaceEntity';

const mapSubscriptionDCToEntity = (dc) => {
  const subscription = dc.subscription;
  const daysLeft = Math.ceil(
    (subscription.expiration_date - new Date().getTime() / 1000) / (24 * 60 * 60)
  );

  const isOnPaidPlan = PAID_PLANS.includes(subscription.plan);

  return {
    // destructing from workspace entry
    workspaceId: dc.uuid,
    approvedMembersCount: dc.approved_members_count,
    membersCount: dc.members_count,
    adminEmail: dc.members.find((member) => member.role === 'owner')?.email,

    // common
    plan: subscription.plan,
    purchaseType: subscription.purchase_type,
    paidMembersCount: subscription.paid_members_count,
    startDate: subscription.start_date,
    expirationDate: subscription.expiration_date,
    isActive: subscription.is_active,
    daysLeft,
    isExpired: !subscription.is_active && daysLeft <= 0,
    isIndividual: subscription.is_individual,

    // gift
    isUserOnPaidGiftPlan: isOnPaidPlan && subscription.purchase_type === PurchaseTypes.GIFT,

    // paid plan details
    recurringPrice: subscription.recurring_price,
    isUserOnPaidPlan: isOnPaidPlan,
    isUsingBluesnap: subscription.purchase_type === PurchaseTypes.BLUESNAP,
    isPaymentFailed: subscription.is_grace_period,
    isGracePeriod: subscription.is_grace_period,
    isGracePeriodOver:
      subscription.is_grace_period && !subscription.paid && !subscription.is_active,
    isCanceled: subscription.is_canceled,
    isCanceledAndExpired: subscription.is_canceled && !subscription.paid && !subscription.is_active,

    // free plan
    isUserOnFreePlan: subscription.plan === PlanOptions.FREE_MONTHLY,
    credits: subscription.credits?.map((credit) => ({
      creditType: credit.credit_type,
      limit: credit.limit,
      used: credit.used,
      createdAt: credit.created_at,
      expiresAt: credit.expires_at,
    })),

    // free trial
    isUserOnFreeTrial: subscription.plan === PlanOptions.UNLIMITED_TRIAL,
    freeTrialDayLeft: daysLeft,
    isFreeTrialEnded: subscription.plan === PlanOptions.UNLIMITED_TRIAL && !subscription.is_active,
  };
};

export const mapWorkspaceDCtoEntity = (dc: WorkspaceDC): WorkspaceEntity => {
  return {
    id: dc.uuid,
    companyWebsite: dc.company_website,
    businessPhone: dc.business_phone,
    name: dc.name,
    industry: dc.industry,
    companySize: dc.company_size,
    thumbnail: dc.thumbnail,
    roles: dc.roles.map((roleItem) => ({
      role: roleItem.role,
      isDefault: roleItem.is_default,
      isMutable: roleItem.is_mutable,
      permissions: roleItem.permissions,
    })),
    memberAccessAllContacts: dc.member_access_all_contacts,
    proposalsNextColId: dc.price_proposals_next_column_uuid,
    members: dc.members.map((memberItem) => {
      return {
        acceptedInvitationAt: memberItem.accepted_invitation_at,
        lastLoginAt: memberItem.last_login_at,
        email: memberItem.email,
        thumbnail: memberItem.thumbnail,
        fullName: memberItem.full_name,
        invitationStatus: memberItem.invitation_status,
        role: memberItem.role,
      };
    }),
    subscription: mapSubscriptionDCToEntity(dc),
    isPersonal:
      typeof dc.is_personal === 'undefined' || dc.is_personal === null ? true : dc.is_personal,
    lastLoginAt: dc.last_login_at === null ? null : dc.last_login_at * 1000,
    updatedAt: dc.updated_at === null ? null : dc.updated_at * 1000,
    createdAt: dc.created_at === null ? null : dc.created_at * 1000,
  };
};

export const mapWorkspaceEntityToDC = (
  workspace: WorkspaceEntity
): Partial<Omit<WorkspaceDC, 'subscription'>> => {
  return {
    uuid: workspace.id,
    name: workspace.name,
    industry: workspace.industry,
    company_size: workspace.companySize,
    thumbnail: workspace.thumbnail,
    created_at: Math.floor(workspace.createdAt / 1000),
    updated_at: Math.floor(workspace.updatedAt / 1000),
    member_access_all_contacts: workspace.memberAccessAllContacts,
    price_proposals_next_column_uuid: workspace.proposalsNextColId,
    business_phone: workspace.businessPhone,
    company_website: workspace.companyWebsite,
    roles: workspace.roles.map((roleItem) => ({
      role: roleItem.role,
      is_default: roleItem.isDefault,
      is_mutable: roleItem.isMutable,
      permissions: roleItem.permissions,
    })),
    members: workspace.members.map((memberItem) => ({
      last_login_at: memberItem.lastLoginAt,
      accepted_invitation_at: memberItem.acceptedInvitationAt,
      email: memberItem.email,
      thumbnail: memberItem.thumbnail,
      full_name: memberItem.fullName,
      invitation_status: memberItem.invitationStatus,
      role: memberItem.role,
    })),
    is_personal: workspace.isPersonal,
    last_login_at: Math.floor(workspace.lastLoginAt / 1000),
  };
};
