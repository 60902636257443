enum RequestHeaders {
  WORKSPACE_ID = 'x-syncai-workspace-id',
  CONTENT_TYPE = 'Content-Type',
  APP_ID = 'x-syncai-app-id',
  APP_VERSION = 'x-syncai-app-version',
  APP_UUID = 'x-syncai-app-uuid',
  APP_PLATFORM = 'x-syncai-app-platform',
}

export default RequestHeaders;
