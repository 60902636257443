import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { useTranslation } from 'next-i18next';

import { useAppStore } from '../app/hooks';
import { selectShouldSync } from '../slices/boardSlice';
import { useAppSelector } from '../app/hooks';
import { selectUserProfile } from '../slices/userSlice';
import { VERSION } from '../utils/constants';
import envService from 'src/services/app/envService';
import { selectCurrentWorkspace } from 'slices/workspacesSlice';

import useLogout from '../features/authorization/hooks/useLogout';
import BillingDialogs from 'src/features2/billing/ui/dialogs/BillingDialogs';

// you can use this HOC for injecting some logic that you want to use for all pages
export default function PagesWrapper({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('common');
  const store = useAppStore();
  const profile = useAppSelector(selectUserProfile);
  const logout = useLogout();

  const currentWorkspace = useAppSelector(selectCurrentWorkspace);
  useEffect(() => {
    global.addEventListener('beforeunload', function (e) {
      const shouldSync = selectShouldSync(store.getState());

      if (shouldSync) {
        e.preventDefault();
        e.returnValue = '';
      }
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(
        'storage',
        (event) => {
          const key = event.key;
          if (key === 'user') {
            const newValue = JSON.parse(event.newValue);
            const prevValue = JSON.parse(event.oldValue);

            const newToken = newValue?.id_token;
            const prevToken = prevValue?.id_token;

            const shouldMoveToLogin = prevToken && !newToken;
            const userUsesDifferentAccounts = prevToken && newToken && prevToken !== newToken;

            if (shouldMoveToLogin || userUsesDifferentAccounts) {
              if (userUsesDifferentAccounts) {
                alert(t('error.userUsesDifferentAccounts'));
              }
              logout();
            }
          }
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile && currentWorkspace && (envService.isProd() || envService.isPrerelease())) {
      let params: Record<string, any> = { email: profile.email, app_version: VERSION };

      if (currentWorkspace.members.length > 1)
        params = {
          ...params,
          workspace_id: currentWorkspace.id,
        };

      hotjar.identify(profile.id, params);
    }
  }, [profile, currentWorkspace]);

  return (
    <>
      <BillingDialogs />
      {children}
    </>
  );
}
