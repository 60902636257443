import { createSlice } from '@reduxjs/toolkit';
import DialogTypes from 'src/enums/DialogTypes';

import { TDialogData, TRedirectEffect, TUIState } from 'types/ui';
import { AppState } from '../app/store/store';

const initialState: TUIState = {
  activeDialogs: [],
  redirectEffect: null,
  currentPageName: '',
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showDialog(state, { payload }: { payload: TDialogData }) {
      state.activeDialogs = [...state.activeDialogs, payload];
    },
    hideDialog(state, { payload }: { payload: DialogTypes }) {
      state.activeDialogs = state.activeDialogs.filter(({ type }) => type !== payload);
    },
    setRedirectEffect(state, { payload }: { payload: TRedirectEffect | null }) {
      state.redirectEffect = payload;
    },
    setCurrentPageName(state, { payload }: { payload: string }) {
      state.currentPageName = payload;
    },
  },
});

const { reducer, actions } = uiSlice;

export const selectActiveDialogs = (state: AppState) => state.ui.activeDialogs;

export const selectRedirectEffect = (state: AppState) => state.ui.redirectEffect;

export const selectCurrentPageName = (state: AppState) => state.ui.currentPageName;

export const { showDialog, hideDialog, setRedirectEffect, setCurrentPageName } = actions;
export default reducer;
