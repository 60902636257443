import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import Box from '@material-ui/core/Box';

import Button from '../../../../../../components/UI/Buttons/BaseButton';
import TextField from '../../../../../../components/UI/Inputs/TextField';
import InputLabel from '../../../../../../components/UI/Inputs/InputLabel';
import { FormValues } from '../useMainSubForm';
import styles from '../../../ProposalForm.module.scss';

function getCurrencySymbol(locale: string, currency: string) {
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export default function ItemsField() {
  const { t, i18n } = useTranslation('proposal-form');

  const { control, watch } = useFormContext<FormValues>();

  const { fields, append, remove } = useFieldArray<FormValues, 'items'>({
    control,
    name: 'items',
  });

  const currencyCode = watch('currency');
  const currencySymbol = getCurrencySymbol(i18n.language, currencyCode);

  return (
    <div>
      <InputLabel>{t('form.field.items.label')}</InputLabel>
      <ul className={styles.ItemsFieldList}>
        {fields.map((item, index) => {
          const number = index + 1;
          return (
            <li key={item.id} className={styles.ItemsFieldItem}>
              <Controller
                name={`items.${index}.description`}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id={`proposal_form_items${index}.description`}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    placeholder={t('form.field.description.placeholder', {
                      number,
                    })}
                    fullWidth
                    margin="dense"
                    InputProps={{
                      startAdornment: field.value ? (
                        <span className={styles.indexItem}>#{number}</span>
                      ) : null,
                    }}
                  />
                )}
              />
              <Box marginX={-0.5} display="flex">
                <Box width="50%" paddingX={0.5}>
                  <Controller
                    name={`items.${index}.qty`}
                    // rules={{ pattern: /^(0|0?[1-9]\d*)\.\d\d$/ }}
                    control={control}
                    render={({ field, fieldState }) => {
                      console.log(fieldState.error);

                      return (
                        <TextField
                          {...field}
                          id={`proposal_form_items${index}.qty`}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          name="qty"
                          placeholder={t('form.field.qty.placeholder')}
                          type="number"
                          fullWidth
                          margin="dense"
                          inputProps={{ step: 1 }}
                        />
                      );
                    }}
                  />
                </Box>
                <Box width="50%" paddingX={0.5}>
                  <Controller
                    name={`items.${index}.unitPrice`}
                    control={control}
                    // rules={{ pattern: /^(0|0?[1-9]\d*)\.\d\d$/ }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        id={`proposal_form_items${index}.unit_price`}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        placeholder={t('form.field.unitPrice.placeholder')}
                        type="number"
                        fullWidth
                        margin="dense"
                        InputProps={{
                          startAdornment: field.value ? (
                            <span className={styles.currencySymbol}>{currencySymbol}</span>
                          ) : null,
                        }}
                        inputProps={{ step: 0.01 }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <Box display="flex">
                {index + 1 === fields.length && (
                  <Button
                    onClick={() =>
                      append({ description: '', qty: undefined, unitPrice: undefined })
                    }
                    variant="text"
                    textSize="small"
                  >
                    {t('form.field.items.action.addItem')}
                  </Button>
                )}

                {fields.length > 1 && (
                  <Button
                    color="error"
                    variant="text"
                    textSize="small"
                    onClick={() => remove(index)}
                    style={{ marginLeft: 'auto' }}
                  >
                    {t('form.field.items.action.removeItem')}
                  </Button>
                )}
              </Box>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
