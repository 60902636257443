import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../app/store/store';
import * as tasksAPI from '../../api/tasksAPI/api';
import TaskEntity from '../../db/entities/task/TaskEntity';

import { selectCurrentWorkspaceId } from '../workspacesSlice';
import PatchRequestsManager from '../../utils/patchRequestsManager';

import { sortByReminder, sortByDueDate, sortByImportancy, filterByCompleted } from './utils';
import { selectHiddenContacts } from 'slices/contactsSlice';

type SliceState = {
  list: TaskEntity[];
};

export const deleteTasks = createAsyncThunk(
  'tasks/deleteTasks',
  async (ids: TaskEntity['id'][], { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    await tasksAPI.deleteTasks(ids, currentWorkspaceId);

    return ids;
  }
);

export const createTask = createAsyncThunk(
  'tasks/createTask',
  async (task: TaskEntity, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    await tasksAPI.createTask(task, currentWorkspaceId);

    return task;
  }
);

export const updateTask = createAsyncThunk(
  'tasks/updateTask',
  async (task: TaskEntity, { getState }) => {
    const currentWorkspaceId = selectCurrentWorkspaceId(getState() as AppState);

    await tasksAPI.updateTask(task, currentWorkspaceId);

    return task;
  }
);

const patchRequestsManager = new PatchRequestsManager<TaskEntity>();

export const patchTaskOptimistic = createAsyncThunk(
  'tasks/patchTaskOptimistic',
  async (
    { id, data }: { id: TaskEntity['id']; data: Partial<TaskEntity> & { updatedAt: number } },
    { getState, dispatch, requestId }
  ) => {
    const state = getState() as AppState;
    const outdatedEntity = selectTasks(state).find((taskItem) => taskItem.id === id);

    patchRequestsManager.enqueueRequest({
      entityId: id,
      requestId: requestId,
      updatedData: data,
      outdatedEntity,
    });

    dispatch(actions.patchTask({ id, data }));

    await patchRequestsManager.wait({ entityId: id, requestId });

    const requestAborted = !patchRequestsManager.getRequestById({ entityId: id, requestId });
    if (requestAborted) {
      return false;
    } else {
      const currentWorkspaceId = selectCurrentWorkspaceId(state);
      await tasksAPI.patchTask({ id, workspaceId: currentWorkspaceId, data });

      return { id, data };
    }
  }
);

const initialState: SliceState = {
  list: [],
};

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: initialState,
  reducers: {
    setTasks(state, action: PayloadAction<TaskEntity[]>) {
      state.list = action.payload;
    },
    patchTask(state, action: PayloadAction<{ id: string; data: Partial<TaskEntity> }>) {
      const { id, data } = action.payload;

      const index = state.list.findIndex((taskItem) => taskItem.id === id);
      const task = state.list[index];

      const updatedTask = {
        ...task,
        ...data,
      };

      state.list.splice(index, 1, updatedTask);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteTasks.fulfilled, (state, action) => {
      const ids = action.payload;

      const filtered = state.list.filter((taskItem) => !ids.includes(taskItem.id));

      state.list = filtered;
    });
    builder.addCase(createTask.fulfilled, (state, action) => {
      state.list.unshift(action.payload);
    });
    builder.addCase(updateTask.fulfilled, (state, action) => {
      const updatedTask = action.payload;
      const index = state.list.findIndex((taskItem) => taskItem.id === updatedTask.id);

      state.list.splice(index, 1, updatedTask);
    });
    builder.addCase(patchTaskOptimistic.fulfilled, (state, action) => {
      const { arg, requestId } = action.meta;

      const aborted = action.payload === false;

      if (!aborted) {
        patchRequestsManager.dequeueRequest({ entityId: arg.id, requestId });
      }
    });
    builder.addCase(patchTaskOptimistic.rejected, (state, action) => {
      const { arg, requestId } = action.meta;

      const { outdatedEntity } = patchRequestsManager.getRequestById({
        entityId: arg.id,
        requestId,
      });
      const index = state.list.findIndex((taskItem) => taskItem.id === arg.id);

      if (index !== -1) {
        const task = state.list[index];

        const data = {
          ...task,
          ...outdatedEntity,
        };

        state.list.splice(index, 1, data);
      }

      patchRequestsManager.dequeueRequest({ entityId: arg.id, requestId });
      patchRequestsManager.clearAllRequestsForEntity(arg.id);
    });
  },
});

export const selectTasks = (state: AppState) => {
  return state.tasks.list;
};

export const selectTasksByReminder = (state: AppState) => {
  return sortByReminder(state.tasks.list);
};

export const selectTasksByDueDate = (state: AppState) => {
  return sortByDueDate(selectTasks(state));
};

export const selectTasksByImportancy = (state: AppState) => {
  return sortByImportancy(selectTasks(state));
};

export const selectTasksByCompleted = (state: AppState) => {
  return filterByCompleted(selectTasks(state));
};

export const selectTasksByContactId = (state: AppState, contactId: TaskEntity['contactId']) => {
  if (!contactId) {
    return [];
  }

  return state.tasks.list.filter((taskItem) => taskItem.contactId === contactId);
};

export const makeSelectVisibleTasks = () =>
  createSelector(selectTasks, selectHiddenContacts, (tasks, contacts) => {
    const hiddenContactIds = contacts.map(({ uuid }) => uuid);

    return tasks.filter((task) => {
      return !task.contactId || !hiddenContactIds.includes(task.contactId);
    });
  });

const { actions, reducer } = tasksSlice;
export const { setTasks } = tasksSlice.actions;
export default reducer;
