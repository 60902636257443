import { useState, useEffect, useRef } from 'react';

import { store as defaultStore, AppStore } from '../store/store';
import { RxDbConnector } from '../store/rxDbConnector';
import { DbManager } from '../../db/dbManager';
import { container } from '../ioc/container';
import { TYPES } from '../ioc/types';
import { TYPES as WORKSPACES_MODULE_TYPES } from '../../features2/workspaces/di/types';
import { IWorkspacesStateRepository } from '../../features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { useRouter } from 'next/router';
import routerService from '../../services/router/routerService';

export default function useCreateStore() {
  const rxDbConnectorRef = useRef<RxDbConnector | null>(null);
  const [store, setStore] = useState<AppStore | null>(null);
  const { query } = useRouter();

  const dbManager = container.get<DbManager>(TYPES.DbManager);
  const workspacesRepository = container.get<IWorkspacesStateRepository>(
    WORKSPACES_MODULE_TYPES.WorkspacesStateRepository
  );

  useEffect(() => {
    // TODO: Create StoreInitializationManager and move that logic there
    if (!routerService.queryHasRedirectParams(query)) {
      dbManager.getDb().then(async (db) => {
        const rxdbConnector = new RxDbConnector(defaultStore, db, workspacesRepository);
        rxDbConnectorRef.current = rxdbConnector;
        await rxdbConnector.connect();

        setStore(defaultStore);
      });
    } else {
      setStore(defaultStore);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return store;
}
