import { inject, injectable } from 'inversify';
import { IImportCSVUseCases } from '../abstractions/IImportCSVUseCases';
import { TYPES } from '../../di/types';
import { catchError, map, throwError } from 'rxjs';
import { IImportCSVUploadStateRepository } from '../abstractions/IImportCSVUploadStateRepository';
import CSVUploadingErrors from '../enums/CSVUploadingErrors';
import CSVConfirmingErrors from '../enums/CSVConfirmingErrors';
import CSVFileSizeLimits from '../enums/CSVFileSizeLimits';
import ResponseColumnDC from '../entities/ResponseDC';
import ResponseColumnDTO from '../entities/ResponseDTO';
import { IImportCSVUploadConfirmError } from '../abstractions/IImportCSVUploadConfirmError';
import ImportCSVUploadConfirmError from '../../data/ImportCSVUploadConfirmError';
@injectable()
export default class ImportCSVUseCases implements IImportCSVUseCases {
  @inject(TYPES.ImportCSVUploadRepository)
  private uploadRepository: IImportCSVUploadStateRepository;

  cancelUpload: IImportCSVUseCases['cancelUpload'] = () => {
    this.uploadRepository.cancelUpload();
  };

  confirmUpload: IImportCSVUseCases['confirmUpload'] = (columns, tagName, statusId) => {
    const columnsDTO = columns.map((columnDC) => new ResponseColumnDTO(columnDC));

    if (!navigator.onLine) {
      return throwError(
        () => new ImportCSVUploadConfirmError(CSVConfirmingErrors.NO_INTERNET_CONNECTION)
      );
    }

    return this.uploadRepository.confirmCSV(columnsDTO, tagName, statusId).pipe(
      catchError((err: IImportCSVUploadConfirmError) => throwError(err)),
      //@ts-ignore
      map((response) => response.length)
    );
  };

  upload: IImportCSVUseCases['upload'] = (file, onProgress) => {
    if (!navigator.onLine) {
      return throwError(() => CSVUploadingErrors.NO_INTERNET_CONNECTION);
    }
    if (file.size > CSVFileSizeLimits.MAX_CSV_FILE_SIZE) {
      return throwError(() => CSVUploadingErrors.CSV_FILE_SIZE_ERROR);
    }

    const response = this.uploadRepository.upload(file, onProgress).pipe(
      catchError((err: CSVUploadingErrors) => {
        return throwError(() => err);
      }),
      map((response: ResponseColumnDTO[]) => {
        return response.map((columnDTO) => new ResponseColumnDC(columnDTO));
      })
    );
    return response;
  };
}
