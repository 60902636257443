import * as tagsBoardAPI from '../../api/tagsBoardAPI';
import { TagBoardColumn, TagItem } from '../../api/tagsBoardAPI';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AppState } from '../../app/store/store';
import { selectCurrentWorkspaceId } from '../../slices/workspacesSlice';

export const attachTagsToContact = createAsyncThunk<
  {
    updated: TagItem[];
    failed: string[];
  },
  { contactUuid: string; tagsIds: TagItem['id'][] }
>('tagsBoard/attachTagsToContact', async ({ contactUuid, tagsIds }, { getState }) => {
  const items = tagsIds.map((tagId) => {
    const item: TagItem = {
      id: uuidv4(),
      type: 'contact',
      itemTypeId: contactUuid,
      tagId,
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
    };

    return item;
  });

  const state = getState() as AppState;
  const workspaceId = selectCurrentWorkspaceId(state);

  const data = await tagsBoardAPI.updateItems(items, workspaceId);

  return data;
});
export const removeItemFromTagBoardThunk = createAsyncThunk<
  {
    itemUuid: string;
    tagItemId: string;
    type: string;
    columnId: string;
  }[],
  { itemUuid: string; tagItemId: string; type: string; columnId: string }[]
>('tagsBoard/removeItemFromTagBoard', async (items, { getState }) => {
  const state = getState() as AppState;
  const workspaceId = selectCurrentWorkspaceId(state);

  const itemsIds = items.map(({ tagItemId }) => tagItemId);
  await tagsBoardAPI.deleteItems(itemsIds, workspaceId);
  return items;
});

export const addTagThunk = createAsyncThunk<TagBoardColumn, { title: string; color: string }>(
  'tagsBoard/addTagThunk',
  async ({ title, color }, { getState }) => {
    const tag: TagBoardColumn = {
      title,
      color,
      id: uuidv4(),
      items: [],
      createdAt: Math.floor(Date.now() / 1000),
      updatedAt: Math.floor(Date.now() / 1000),
    };

    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.postTag(tag, workspaceId);

    return tag;
  }
);

export const addManyTags = createAsyncThunk<TagBoardColumn[], TagBoardColumn[]>(
  'tagsBoard/addManyTags',
  async (tags, { getState }) => {
    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.postTag(tags, workspaceId);

    return tags;
  }
);

export const patchTagThunk = createAsyncThunk(
  'tagsBoard/patchTagThunk',
  async (
    {
      id,
      data,
    }: {
      id: TagBoardColumn['id'];
      data: Partial<TagBoardColumn> & { updatedAt: number };
    },
    { getState }
  ) => {
    const params = { id, ...data };

    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.patchTag(params, workspaceId);
  }
);

export const patchManyTags = createAsyncThunk(
  'tagsBoard/patchManyTags',
  async (
    tags: [TagBoardColumn['id'], Partial<TagBoardColumn> & { updatedAt: number }][],
    { getState }
  ) => {
    const params = tags.map((tag) => {
      const [id, data] = tag;
      return { id, ...data };
    });

    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.patchTag(params, workspaceId);
  }
);

export const removeManyTags = createAsyncThunk(
  'tagsBoard/removeManyTags',
  async (ids: TagBoardColumn['id'][], { getState }) => {
    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.deleteTagsColumns(ids, workspaceId);
  }
);

export const updateTagsOrder = createAsyncThunk(
  'tagsBoard/updateTagsOrder',
  async (
    { order, updatedAt }: { order: TagBoardColumn['id'][]; updatedAt: number },
    { getState }
  ) => {
    const state = getState() as AppState;
    const workspaceId = selectCurrentWorkspaceId(state);

    await tagsBoardAPI.updateTagsColumnsOrdering({ order, updatedAt, workspaceId });
  }
);
