import { InputBase, Popover } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React, { useState, useRef, useEffect } from 'react';

import LanguageCodes from 'src/enums/LanguageCodes';
import { handleEnterClick } from 'src/utils/handleEnterClick';
import { getLanguageSelectData, LanguageData } from './languageSelectData';
import styles from './styles.module.scss';
import SearchIcon from 'icons/search.svg';
import useLocalization from 'src/app/hooks/localization/useLocalization';

const LanguageSelect: React.FC = () => {
  const { t, i18n } = useTranslation();
  const data = getLanguageSelectData();
  const { selectedLanguage, setLocale } = useLocalization();
  const [isOpened, setIsOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filteredData, setFilteredData] = useState<LanguageData[]>(data);
  const ahchorElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (searchValue) {
      setFilteredData(
        data.filter(({ name }) => t(name).toLowerCase().includes(searchValue.toLowerCase()))
      );
    } else {
      setFilteredData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const onLanguageSelectClick = () => {
    setIsOpened(!isOpened);
    setAnchorEl(ahchorElRef.current);
  };

  const onCloseModal = () => {
    setIsOpened(false);
    setAnchorEl(null);
  };

  const onItemClick = (code: LanguageCodes) => {
    i18n.changeLanguage(code, () => {
      setLocale(code);
      onCloseModal();
    });
  };

  const onSearchType: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setSearchValue(target.value);
  };

  return (
    <div>
      <div
        ref={ahchorElRef}
        role="button"
        className={styles.Wrapper}
        onClick={onLanguageSelectClick}
        tabIndex={0}
        onKeyDown={(e) => handleEnterClick(e, onLanguageSelectClick)}
      >
        <img src={selectedLanguage.flag} alt={selectedLanguage.name} />
        <div className={styles.LanguageName}>{t(selectedLanguage.name).slice(0, 2)}</div>
        <div className={styles.Arrow} />
      </div>
      <Popover
        anchorOrigin={{
          horizontal: -100,
          vertical: 40,
        }}
        classes={{
          paper: styles.Modal,
        }}
        onClose={onCloseModal}
        open={isOpened}
        anchorEl={anchorEl}
      >
        <div className={styles.ModalHeader}>
          <InputBase
            onChange={onSearchType}
            value={searchValue}
            placeholder={t('common:language_select_input_placeholder_text')}
            className={styles.Input}
            endAdornment={<SearchIcon />}
            inputProps={{ 'data-hj-allow': '' }}
          />
        </div>
        {filteredData.map(({ flag, name, code }) => (
          <div
            tabIndex={0}
            onKeyDown={(e) => handleEnterClick(e, () => onItemClick(code))}
            role="button"
            onClick={() => onItemClick(code)}
            key={code}
            className={styles.Item}
          >
            <img src={flag} alt={name} />
            <span>{t(name)}</span>
          </div>
        ))}
      </Popover>
    </div>
  );
};

export default LanguageSelect;
