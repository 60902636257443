import { ColumnModel } from 'src/api/boardAPI';
import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import { TeamMember } from 'src/api/workspacesAPI';
import Contact from 'types/contact';

type TGetFilteredContactsArgs = {
  contacts: Contact[];
  searchValue: string;
  selectedTags: TagBoardColumn[];
  selectedStatuses: ColumnModel[];
  isAllContentVisible: boolean;
  selectedTeamMembers: TeamMember[];
  teamMembersContactsMap: Record<string, Contact[]>;
  userEmail: string;
};
type TGetFilteredContacts = (args: TGetFilteredContactsArgs) => Contact[];

export const getFilteredContacts: TGetFilteredContacts = (args) => {
  const {
    contacts,
    searchValue,
    selectedTags,
    selectedStatuses,
    selectedTeamMembers,
    teamMembersContactsMap,
    isAllContentVisible,
    userEmail,
  } = args;

  const searchValueLower = searchValue.toLowerCase();
  let filteredContacts = isAllContentVisible
    ? contacts
    : contacts.filter((contact) => contact.assigned_to == userEmail);

  if (selectedTeamMembers.length) {
    filteredContacts = [];

    selectedTeamMembers.forEach(({ email }) => {
      filteredContacts.push(...teamMembersContactsMap[email]);
    });
  }

  if (selectedTags.length) {
    filteredContacts = filteredContacts.filter(({ uuid }) => {
      const approves = [];

      selectedTags.forEach(({ items }) => {
        if (items.find(({ itemTypeId }) => itemTypeId === uuid)) {
          approves.push(true);
        }
      });
      return approves.length === selectedTags.length;
    });
  }

  if (selectedStatuses.length) {
    filteredContacts = filteredContacts.filter(({ uuid }) => {
      const approves = [];

      selectedStatuses.forEach(({ items }) => {
        if (items.find(({ typeUuid }) => typeUuid === uuid)) {
          approves.push(true);
        }
      });
      return approves.length === selectedStatuses.length;
    });
  }

  if (searchValueLower) {
    filteredContacts = filteredContacts.filter(
      ({ name, suggestion_name, job_title, suggestion_job_title, phones, callers_info, emails }) =>
        suggestion_name?.toLowerCase().includes(searchValueLower) ||
        name?.toLowerCase().includes(searchValueLower) ||
        emails?.[0]?.email?.toLowerCase().includes(searchValueLower) ||
        callers_info?.[0]?.name?.toLowerCase().includes(searchValueLower) ||
        job_title?.toLowerCase().includes(searchValueLower) ||
        suggestion_job_title?.toLowerCase().includes(searchValueLower) ||
        String(phones?.[0]?.phone)?.toLowerCase().includes(searchValueLower)
    );
  }
  const anchorIdsSet = filteredContacts.reduce((setAnchorId, contact) => {
    contact.anchor_contact_ids.forEach((id) => {
      setAnchorId.add(id);
    });
    return setAnchorId;
  }, new Set());

  filteredContacts = filteredContacts.filter(({ uuid }) => {
    return !anchorIdsSet.has(uuid);
  });

  return filteredContacts === contacts ? [] : filteredContacts;
};
