import PromoCodeDC from './PromoCodeDC';
import PromoCodeEntity from '../../../domain/entities/PromoCodeEntity';

export const mapPromoCodeDCtoEntity = (dc: PromoCodeDC): PromoCodeEntity => ({
  id: dc.id,
  amount: dc.amount,
  code: dc.code,
  discountType: dc.discount_type,
  isActive: dc.is_active,
  isPublished: dc.is_published,
  name: dc.name,
  priceWithDiscount: dc.price_with_discount,
  usageLimit: dc.usage_limit,
  usedCount: dc.used_count,
  billingCyclesLimit: dc.billing_cycles_limit,
});
