const HEIC = 'image/heic';
const HEIF = 'image/heif';
const TIFF = 'image/tiff';

const FILES_MIME_TYPES = {
  IMAGE: ['image/jpeg', 'image/gif', 'image/svg+xml', 'image/png', HEIC, HEIF, TIFF],
  VIDEO: ['video/mp4', 'video/x-msvideo', 'video/avi', 'video/quicktime', 'video/x-flv'],
  AUDIO: ['audio/mpeg', 'audio/mp4', 'audio/x-wav', 'audio/wav', 'audio/m4a', 'audio/x-m4a'],
  GOOGLE_DOCS_VIEWER: [
    'application/msword',
    'application/doc',
    'application/ms-doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/rtf',
    'text/rtf',
    'text/csv',
  ],
  TEXT: ['text/plain'],
  ODF: [
    'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.text',
  ],
  HTML: ['text/html'],
};

export { HEIC, HEIF, TIFF };

export default FILES_MIME_TYPES;
