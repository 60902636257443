import { useRouter } from 'next/router';
import { useEffect } from 'react';

import LanguageCodes from 'src/enums/LanguageCodes';
import {
  getLanguageSelectDataMap,
  LanguageData,
} from 'components/LanguageSelect/languageSelectData';
import { useInjection } from '../../ioc/ioc.react';
import { TYPES as PROFILE_TYPES } from '../../../features2/profile/di/types';
import { IProfileUseCases } from '../../../features2/profile/domain/ProfileUseCases';
import { useProfile } from '../../../features2/profile/ui/hooks';

type TUseLocalization = () => {
  setLocale: (c: LanguageCodes) => void;
  selectedLanguage: LanguageData;
};

const getVerifiedLanguageCode = (code: string): LanguageCodes => {
  if (code) {
    let verifiedLanguageCode: LanguageCodes;

    for (const [, value] of Object.entries(LanguageCodes)) {
      if (code.replace('_', '-') === value) {
        verifiedLanguageCode = value;
      }
    }

    return verifiedLanguageCode ? verifiedLanguageCode : LanguageCodes.EN;
  } else {
    return LanguageCodes.EN;
  }
};

const useLocalization: TUseLocalization = () => {
  const { changeProfileLanguage } = useInjection<IProfileUseCases>(PROFILE_TYPES.ProfileUseCases);
  const { profile } = useProfile();

  const { query, replace, pathname, asPath, locale } = useRouter();
  const data = getLanguageSelectDataMap();

  const userLanguage = profile?.settings?.lang;
  useEffect(() => {
    if (typeof userLanguage !== 'undefined' && userLanguage !== locale) {
      if (userLanguage === getVerifiedLanguageCode(userLanguage)) {
        replace({ pathname, query }, asPath, { locale: profile.settings.lang });
      }
    }
  }, [userLanguage, locale]);

  const setLocale = (code: LanguageCodes) => {
    if (code === getVerifiedLanguageCode(code)) {
      changeProfileLanguage(code as LanguageCodes);
    }
  };

  return { setLocale, selectedLanguage: data[locale] };
};

export default useLocalization;
