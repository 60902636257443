import { useTranslation } from 'next-i18next';
import React, { useState, MouseEvent } from 'react';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import Link from 'next/link';

import Search from '../../../../features/search';
import NotificationIcon from '../../../../../public/images/icons/header/notification.svg';
import NotificationDotIcon from '../../../../../public/images/icons/header/notification-dot.svg';
import UserInfo from './components/UserInfo/UserInfo';
import Notifications from '../../../../features/notifications';
import { useAppSelector } from 'src/app/hooks';
import { selectNotifications } from 'slices/notificationSlice';
import HelpMenu from 'components/UI/Headers/Header/components/HelpMenu';
import LanguageSelect from 'components/LanguageSelect';
import { selectCurrentPageName } from 'slices/uiSlice';
import AppRoutes from 'src/utils/constants/appRoutes';
import HeaderPromo from 'src/features2/billing/ui/promos/HeaderPromo/HeaderPromo';

import MobileLogo from 'icons/header/mobile-logo.svg';

import styles from './styles.module.scss';
import TutorialButton from 'components/UI/Buttons/TutorialButton';
import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { PLAN_OPTIONS } from 'src/db/entities/workspace/WorkspaceEntity';
import { Divider } from '@material-ui/core';
interface IHeaderProps {
  isSidebarOpened: boolean;
  hideNotifications?: boolean;
  hideSearch?: boolean;
  className?: string;
}

const Header: React.FC<IHeaderProps> = ({
  isSidebarOpened,
  hideNotifications,
  hideSearch,
  className,
}) => {
  const { t } = useTranslation();
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const notifications = useAppSelector(selectNotifications);
  const countNotReadNotifications = notifications.filter(({ isRead }) => !isRead);
  const currentPageName = useAppSelector(selectCurrentPageName);

  const handleOpenNotifications = (e: MouseEvent<HTMLButtonElement>) => {
    setNotificationsAnchorEl(e.currentTarget);
  };

  const { subscription } = useCurrentSubscription();
  const handleCloseNotifications = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <header
      className={clsx(
        styles.Header,
        isSidebarOpened ? styles.WithOpenedSidebar : styles.WithSidebar,
        className
      )}
    >
      <div className={styles.PageName}>{t(currentPageName)}</div>
      <div className={styles.MobileLogo}>
        <Link href={AppRoutes.ROOT_SETTINGS_PAGE}>
          <MobileLogo />
        </Link>
      </div>
      <div className={styles.Controls}>
        <div className={styles.LeftControls}>
          {subscription?.plan === PLAN_OPTIONS.UNLIMITED_TRIAL && (
            <>
              <TutorialButton />
              <Divider className={styles.Divider} flexItem orientation="vertical" />
            </>
          )}
          {!hideSearch && (
            <>
              <HeaderPromo />
              <div>
                <Search />
              </div>
            </>
          )}
          <LanguageSelect />

          <HelpMenu />
          {!hideNotifications && (
            <div className={styles.Icon}>
              {countNotReadNotifications.length ? (
                <IconButton
                  className={styles.notificationIconWithDot}
                  onClick={handleOpenNotifications}
                >
                  <span className={styles.count}>{countNotReadNotifications.length}</span>
                  <NotificationDotIcon />
                </IconButton>
              ) : (
                <IconButton onClick={handleOpenNotifications}>
                  <NotificationIcon />
                </IconButton>
              )}
            </div>
          )}
          <div className={styles.divider}></div>
          <Notifications onClose={handleCloseNotifications} anchorEl={notificationsAnchorEl} />
        </div>
        <UserInfo />
      </div>
    </header>
  );
};

export default Header;
