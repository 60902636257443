import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useCurrentUser } from 'src/features2/user/ui/hooks';
import { getDataFromLocalStorage, setDataToLocalStorage } from 'src/utils';
import { appRoutes } from 'src/utils/constants';
import { LOCAL_STORAGE_VARIABLES } from 'src/utils/constants';
const useTutorialDialog = () => {
  const { currentUser } = useCurrentUser();
  const router = useRouter();

  const [needToShowTurorial, setNeedToShowTutorial] = useState<boolean>(false);

  useEffect(() => {
    const redirectsToCallsPage =
      getDataFromLocalStorage(LOCAL_STORAGE_VARIABLES.redirects_counter) || 0;
    if (currentUser?.isNew) {
      if (router.pathname === appRoutes.CALLS_PAGE) {
        if (redirectsToCallsPage < 2) {
          setDataToLocalStorage(
            LOCAL_STORAGE_VARIABLES.redirects_counter,
            redirectsToCallsPage + 1
          );
        }
        if (redirectsToCallsPage == 2) {
          setNeedToShowTutorial(true);
        }
      }
    }
  }, [currentUser, router.pathname]);
  return { needToShowTurorial };
};
export default useTutorialDialog;
