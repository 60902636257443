import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';

import {
  hideProposal as hideProposalAPI,
  deleteProposal as deleteProposalAPI,
} from '../../api/proposalsAPI/api';
import { actions as proposalsActions } from '../../slices/proposalsSlice';
import { selectCurrentWorkspaceId } from '../../slices/workspacesSlice';
import { AppState } from '../../app/store/store';
import boardAPI, { ItemModel, ColumnModel, BoardModel } from '../../api/boardAPI';
import { selectBoard, actions as boardActions } from './slice';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

class SyncQueue {
  private columns: { order?: string[]; orderUpdatedAt?: number; toDelete: string[] } = {
    order: undefined,
    orderUpdatedAt: undefined,
    toDelete: [],
  };
  private items: {
    toUpdate: Map<ItemModel['uuid'], ItemModel>;
    toDelete: ItemModel['uuid'][];
    order: Map<ColumnModel['id'], { order: ItemModel[]; updatedAt: number }>;
  } = {
    toUpdate: new Map(),
    toDelete: [],
    order: new Map(),
  };
  private proposals: {
    toHide: ProposalEntity['uuid'][];
    toDelete: ProposalEntity['uuid'][];
  } = {
    toHide: [],
    toDelete: [],
  };

  get shouldHideProposals() {
    return this.proposals.toHide.length > 0;
  }

  getProposalsToHideQueue() {
    return [...this.proposals.toHide];
  }

  addProposalsToHideQueue(uuids: ProposalEntity['uuid'][]) {
    uuids.forEach((uuid) => {
      if (!this.proposals.toHide.includes(uuid)) {
        this.proposals.toHide.push(uuid);
      }
    });
  }

  clearProposalsToHideQueue() {
    this.proposals.toHide = [];
  }

  get shouldDeleteProposals() {
    return this.proposals.toDelete.length > 0;
  }

  getProposalsToDeleteQueue() {
    return [...this.proposals.toDelete];
  }

  addProposalsToDeleteQueue(uuids: ProposalEntity['uuid'][]) {
    uuids.forEach((uuid) => {
      if (!this.proposals.toDelete.includes(uuid)) {
        this.proposals.toDelete.push(uuid);
      }
    });
  }

  clearProposalsToDeleteQueue() {
    this.proposals.toDelete = [];
  }

  get shouldUpdateColumnsOrder() {
    return !!this.columns.order;
  }

  getColumnsOrderQueue() {
    return {
      order: [...this.columns.order],
      updatedAt: this.columns.orderUpdatedAt,
    };
  }

  setColumnsOrderQueue(order: string[], updatedAt: number) {
    this.columns.order = order;
    this.columns.orderUpdatedAt = updatedAt;
  }

  clearColumnsOrderQueue() {
    this.columns.order = undefined;
    this.columns.orderUpdatedAt = undefined;
  }

  get shouldDeleteColumns() {
    return this.columns.toDelete.length > 0;
  }

  getColumnsToDeleteQueue() {
    return [...this.columns.toDelete];
  }

  setColumnsToDeleteQueue(uuids: string[]) {
    this.columns.toDelete = uuids;
  }

  addColumnsToDeleteQueue(uuids: string[]) {
    uuids.forEach((uuid) => {
      if (!this.columns.toDelete.includes(uuid)) {
        this.columns.toDelete.push(uuid);
      }
    });
  }

  clearColumnsToDeleteQueue() {
    this.columns.toDelete = [];
  }

  get shouldDeleteItems() {
    return this.items.toDelete.length > 0;
  }

  getItemsToDeleteQueue() {
    return [...this.items.toDelete];
  }

  addItemsToDeleteQueue(itemsUuids: string[]) {
    itemsUuids.forEach((uuid) => {
      if (!this.items.toDelete.includes(uuid)) {
        this.items.toDelete.push(uuid);
      }
    });
  }

  clearItemsToDeleteQueue() {
    this.items.toDelete = [];
  }

  get shouldUpdateItems() {
    return this.items.toUpdate.size > 0;
  }

  getItemsToUpdateQueue() {
    return Array.from(this.items.toUpdate.values());
  }

  addItemsToUpdateQueue(items: ItemModel[]) {
    items.forEach((item: ItemModel) => {
      const prevItem = this.items.toUpdate.get(item.uuid);

      if (!prevItem) {
        this.items.toUpdate.set(item.uuid, item);
      } else {
        if (prevItem.updatedAt < item.updatedAt) {
          this.items.toUpdate.set(item.uuid, item);
        }
      }
    });
  }

  clearItemsToUpdateQueue() {
    this.items.toUpdate.clear();
  }

  get shouldUpdateItemsOrder() {
    return this.items.order.size > 0;
  }

  getItemsOrderQueue() {
    return Array.from(this.items.order);
  }

  addItemsOrderQueue(colUuid: string, order: ItemModel[], updatedAt: number) {
    const prevOrder = this.items.order.get(colUuid);

    if (!prevOrder) {
      this.items.order.set(colUuid, { order, updatedAt });
    } else {
      if (updatedAt > prevOrder.updatedAt) {
        this.items.order.set(colUuid, { order, updatedAt });
      }
    }
  }

  clearItemsOrderQueue() {
    this.items.order.clear();
  }

  get shouldSync() {
    return (
      this.shouldDeleteProposals ||
      this.shouldHideProposals ||
      this.shouldDeleteColumns ||
      this.shouldUpdateColumnsOrder ||
      this.shouldDeleteItems ||
      this.shouldUpdateItems ||
      this.shouldUpdateItemsOrder
    );
  }

  clearQueue() {
    this.clearProposalsToHideQueue();
    this.clearColumnsToDeleteQueue();
    this.clearColumnsOrderQueue();
    this.clearItemsToDeleteQueue();
    this.clearItemsToUpdateQueue();
    this.clearItemsOrderQueue();
  }
}

class SyncState {
  syncing = false;

  setSyncing(value: boolean) {
    this.syncing = value;
  }
}

const syncQueueMiddleware = (store) => {
  const syncQueue = new SyncQueue();
  const syncState = new SyncState();

  const updateBoardItemsOrder = async () => {
    if (!syncQueue.shouldUpdateItemsOrder) {
      return;
    }

    const orders = syncQueue.getItemsOrderQueue();
    syncQueue.clearItemsOrderQueue();

    try {
      const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);

      const promises = await Promise.allSettled(
        orders.map(([uuid, col]) =>
          boardAPI.updateItemsOrder({
            columnUuid: uuid,
            items: col.order,
            updatedAt: col.updatedAt,
            workspaceId,
          })
        )
      );

      const toRetry = promises.reduce((acc, promise, index) => {
        if (promise.status === 'rejected') {
          const order = orders[index];

          return [...acc, order];
        }
        return acc;
      }, []);

      if (toRetry.length > 0) {
        throw { toRetry };
      }
    } catch (error) {
      const { toRetry } = error;

      toRetry.forEach(([colUuid, columnn]) => {
        syncQueue.addItemsOrderQueue(colUuid, columnn.order, columnn.updatedAt);
      });

      throw 'An error occurred during the board items ordering updating';
    }
  };

  const updateColumnsOrdering = async () => {
    if (!syncQueue.shouldUpdateColumnsOrder) {
      return;
    }

    const { order, updatedAt } = syncQueue.getColumnsOrderQueue();

    syncQueue.clearColumnsOrderQueue();

    try {
      const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);

      await boardAPI.updateColumnsOrdering(order, updatedAt, workspaceId);
    } catch {
      if (!syncQueue.shouldUpdateColumnsOrder) {
        syncQueue.setColumnsOrderQueue(order, updatedAt);
      }

      throw 'An error occurred during the board columns order updating';
    }
  };

  const deleteColumns = async () => {
    if (!syncQueue.shouldDeleteColumns) {
      return;
    }

    const uuids = syncQueue.getColumnsToDeleteQueue();
    syncQueue.clearColumnsToDeleteQueue();

    try {
      const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);

      await boardAPI.deleteColumns(uuids, workspaceId);
    } catch {
      syncQueue.addColumnsToDeleteQueue(uuids);

      throw 'An error occurred during the board columns deleting';
    }
  };

  const deleteBoardItems = async () => {
    if (!syncQueue.shouldDeleteItems) {
      return;
    }

    const itemsToDelete = syncQueue.getItemsToDeleteQueue();
    syncQueue.clearItemsToDeleteQueue();

    try {
      const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);
      await boardAPI.deleteItems(itemsToDelete, workspaceId);
    } catch {
      syncQueue.addItemsToDeleteQueue(itemsToDelete);

      throw 'An error occurred during the board items deleting';
    }
  };

  const updateBoardItems = async () => {
    if (!syncQueue.shouldUpdateItems) {
      return;
    }

    const items = syncQueue.getItemsToUpdateQueue();
    syncQueue.clearItemsToUpdateQueue();

    try {
      const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);

      const { failed } = await boardAPI.updateItems(items, workspaceId);

      if (failed.length > 0) {
        throw { toRetry: items.filter(({ uuid }) => failed.includes(uuid)) };
      }
    } catch (error) {
      const itemsToRetry = (error.toRetry as ItemModel[]) || items;
      syncQueue.addItemsToUpdateQueue(itemsToRetry);

      throw 'An error occurred during the board items updating';
    }
  };

  const hideProposals = async () => {
    if (!syncQueue.shouldHideProposals) {
      return;
    }
    const uuids = syncQueue.getProposalsToHideQueue();
    syncQueue.clearProposalsToHideQueue();

    const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);
    const promises = await Promise.allSettled(
      uuids.map((uuid) => {
        return hideProposalAPI(uuid, workspaceId);
      })
    );

    const toRetry = promises.reduce((acc, promise, index) => {
      if (promise.status === 'rejected') {
        return [...acc, uuids[index]];
      }
      return acc;
    }, []);

    if (toRetry.length > 0) {
      syncQueue.addProposalsToHideQueue(toRetry);

      throw 'An error occurred during the price proposals hiding';
    }
  };

  const deleteProposals = async () => {
    if (!syncQueue.shouldDeleteProposals) {
      return;
    }
    const uuids = syncQueue.getProposalsToDeleteQueue();
    syncQueue.clearProposalsToDeleteQueue();

    const workspaceId = selectCurrentWorkspaceId(store.getState() as AppState);

    const promises = await Promise.allSettled(
      uuids.map((uuid) => deleteProposalAPI(uuid, workspaceId))
    );

    const toRetry = promises.reduce((acc, promise, index) => {
      if (promise.status === 'rejected') {
        return [...acc, uuids[index]];
      }
      return acc;
    }, []);

    if (toRetry.length > 0) {
      syncQueue.addProposalsToDeleteQueue(toRetry);

      throw 'An error occurred during the price proposals deleting';
    }
  };

  const syncBoard = async () => {
    if (syncState.syncing) {
      return;
    }

    syncState.setSyncing(true);

    try {
      await deleteProposals();
      await hideProposals();
      await deleteColumns();
      await updateColumnsOrdering();
      await deleteBoardItems();
      await updateBoardItems();
      await updateBoardItemsOrder();

      syncState.setSyncing(false);
      store.dispatch(boardActions.setShouldSync(false));

      if (syncQueue.shouldSync) {
        syncBoard();
      }
    } catch (error) {
      syncState.setSyncing(false);
      store.dispatch(boardActions.setShouldSync(false));
      store.dispatch(boardActions.setSyncFailed(true));
      syncQueue.clearQueue();

      Sentry.captureException(error);
    }
  };

  const debouncedSyncBoard = debounce(syncBoard, 2000);

  const actionTypesToSync = {
    [boardActions.moveColumn.type]: (boardState: BoardModel) => {
      syncQueue.setColumnsOrderQueue(
        boardState.columns.map(({ id }) => id),
        Math.floor(Date.now() / 1000)
      );
    },
    [boardActions.deleteColumn.type]: (_, action) => {
      const uuid = action.payload as string;

      syncQueue.addColumnsToDeleteQueue([uuid]);
    },
    [boardActions.deleteItems.type]: (_, action) => {
      const { itemsUuids } = action.payload;

      syncQueue.addItemsToDeleteQueue(itemsUuids);
    },
    [boardActions.addItem.type]: (boardState: BoardModel, action) => {
      const { item } = action.payload;
      syncQueue.addItemsToUpdateQueue([item]);
    },
    [boardActions.moveItem.type]: (boardState: BoardModel, action) => {
      const { itemUuid, destColumnUuid } = action.payload;

      const item = boardState.columns
        .find((colItem) => colItem.id === destColumnUuid)
        .items.find(({ uuid }) => uuid === itemUuid);

      syncQueue.addItemsToUpdateQueue([item]);
    },
    [boardActions.changeItemOrder.type]: (boardState: BoardModel, action) => {
      const { columnUuid } = action.payload;

      syncQueue.addItemsOrderQueue(
        action.payload.columnUuid,
        boardState.columns.find((colItem) => colItem.id === columnUuid).items,
        Math.floor(Date.now() / 1000)
      );
    },
    [proposalsActions.hideProposals.type]: (_, action) => {
      const uuids = action.payload;

      syncQueue.addProposalsToHideQueue(uuids);
    },
    [proposalsActions.deleteProposals.type]: (_, action) => {
      const uuids = action.payload;

      syncQueue.addProposalsToDeleteQueue(uuids);
    },
  };

  return (next) => (action) => {
    if (actionTypesToSync[action.type]) {
      const result = next(action);

      const state = store.getState() as AppState;
      const boardState = selectBoard(state);

      const addChangesToQueue = actionTypesToSync[action.type];

      addChangesToQueue(boardState, action);
      store.dispatch(boardActions.setShouldSync(true));
      debouncedSyncBoard();

      return result;
    } else {
      return next(action);
    }
  };
};

export { syncQueueMiddleware };
