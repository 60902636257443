import { endpoints } from '../../utils/constants';
import axios from '../../app/axios';
import {
  mapColumnModelToDC,
  mapDCItemToModel,
  mapDCColumnToModel,
  mapItemModelToDC,
  mapDCBoardToModel,
  mapPartialColumnModelToDC,
} from './mappers';
import RequestHeaders from '../../enums/RequestHeaders';

export interface DCTagBoardItem {
  uuid: string;
  item_type: 'contact';
  item_uuid: string;
  tag_uuid: string;
  created_at: number; // timestamp in seconds
  updated_at: number; // timestamp in seconds
}

export interface DCTagBoardColumn {
  uuid: string;
  title: string;
  color: string; // color HEX
  items: DCTagBoardItem[];
  created_at: number; // timestamp in seconds
  updated_at: number; // timestamp in seconds
}

export interface DCTagBoard {
  updated_at: number; // timestamp in seconds
  columns: DCTagBoardColumn[];
}

export interface TagItem {
  id: string;
  type: 'contact';
  itemTypeId: string;
  tagId: string;
  createdAt: number; // timestamp in seconds
  updatedAt: number; // timestamp in seconds
}

export interface TagBoardColumn {
  id: string;
  title: string;
  color: string; // color HEX
  items: TagItem[];
  createdAt: number; // timestamp in seconds
  updatedAt: number; // timestamp in seconds
}

export interface TagBoard {
  columns: TagBoardColumn[];
  updatedAt?: number; // timestamp
}

async function deleteItems(itemsUuids: string[], workspaceId: string) {
  const data = await axios.delete(endpoints.tagsBoard.deleteItems, {
    data: itemsUuids,
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
  return data;
}

async function patchTag(
  params: Partial<TagBoardColumn> | Partial<TagBoardColumn>[],
  workspaceId: string
): Promise<void> {
  const payload = Array.isArray(params)
    ? params.map(mapPartialColumnModelToDC)
    : mapPartialColumnModelToDC(params);
  await axios.patch<DCTagBoardColumn>(endpoints.tagsBoard.updateColumns, payload, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function postTag(
  params: TagBoardColumn | TagBoardColumn[],
  workspaceId: string
): Promise<void> {
  const payload = Array.isArray(params)
    ? params.map(mapColumnModelToDC)
    : mapColumnModelToDC(params);

  await axios.post<DCTagBoardColumn>(endpoints.tagsBoard.updateColumns, payload, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function updateItems(
  itemsToUpdate: TagItem[],
  workspaceId: string
): Promise<{ updated: TagItem[]; failed: TagItem['id'][] }> {
  const { data } = await axios.post<
    Array<
      | { result: DCTagBoardItem; status: 200; message: null }
      | { result: null; status: number; message: string }
    >
  >(endpoints.tagsBoard.updateItems, itemsToUpdate.map(mapItemModelToDC), {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  const dcItems = data
    .filter((item) => item.result !== null)
    .map((item) => item.result) as DCTagBoardItem[];

  return {
    updated: dcItems.map(mapDCItemToModel),
    failed: itemsToUpdate
      .filter((item) => !dcItems.find((dc) => dc.uuid === item.id))
      .map(({ id }) => id),
  };
}
async function updateItemsOrder({
  columnUuid,
  items,
  updatedAt,
  workspaceId,
}: {
  columnUuid: string;
  items: TagItem[];
  updatedAt: number;
  workspaceId: string;
}): Promise<TagBoardColumn> {
  const { data } = await axios.post<DCTagBoardColumn>(
    endpoints.tagsBoard.updateItemsOrder.replace('{column_uuid}', columnUuid),
    {
      order: items.map((item) => item.id),
      updated_at: updatedAt,
    },
    {
      headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
    }
  );

  return mapDCColumnToModel(data);
}
async function deleteTagsColumns(uuids: string[], workspaceId: string): Promise<true> {
  const { data } = await axios.delete(endpoints.tagsBoard.delete, {
    data: uuids,
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
  return data;
}

async function updateTagsColumnsOrdering({
  order,
  updatedAt,
  workspaceId,
}: {
  order: string[];
  updatedAt: number;
  workspaceId: string;
}): Promise<TagBoard> {
  const { data } = await axios.post<DCTagBoard>(
    endpoints.tagsBoard.updateColumnsOrder,
    {
      order,
      updated_at: updatedAt,
    },
    { headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
  );
  return mapDCBoardToModel(data);
}

export {
  updateItems,
  patchTag,
  postTag,
  deleteItems,
  updateItemsOrder,
  updateTagsColumnsOrdering,
  deleteTagsColumns,
};
