import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useAppSelector } from '../../../../app/hooks/useAppSelector';
import { selectNotHiddenContacts, filterByAnchors } from '../../../../slices/contactsSlice';
import {
  selectCurrentUserMemberData,
  selectCurrentWorkspace,
  selectIsCurrentWorkspaceCollaborative,
} from 'slices/workspacesSlice';
import Spinner from '../../../../components/UI/Spinner';
import Button from '../../../../components/UI/Buttons/BaseButton';
import Checkbox from '../../../../components/UI/Inputs/Checkbox';
import TextField from '../../../../components/UI/Inputs/TextField';
import SearchContactInput from '../../../contacts/SearchContactInput';
import DateTimeSelect from './DateTimeSelect/DateTimeSelect';
import AssignToSelect from './AssignToSelect/AssignToSelect';

import styles from './GenericForm.module.css';

export type FormValues = {
  title: string;
  contactId: string | null;
  assignedTo: string | null;
  reminder: Date | null;
  dueDate: Date | null;
  description: string;
  important: boolean;
};

interface GenericForm {
  className?: string;
  saveButtonText?: string;
  defaultValues?: Partial<FormValues>;
  onSubmit: (formValues: FormValues) => Promise<void>;
  onFormDataChanged: () => void;
}

export default function GenericForm(props: GenericForm) {
  const {
    className,
    saveButtonText,
    defaultValues: defaultValuesProp = {},
    onSubmit,
    onFormDataChanged,
  } = props;

  const { t } = useTranslation('tasks-page');

  const contacts = useAppSelector(selectNotHiddenContacts);
  const { memberAccessAllContacts } = useAppSelector(selectCurrentWorkspace);
  const { role, email } = useAppSelector(selectCurrentUserMemberData);

  const filteredContacts = useMemo(() => {
    if (role === 'member' && !memberAccessAllContacts) {
      const filtered = filterByAnchors(contacts.filter(({ assigned_to }) => assigned_to === email));

      if (!props.defaultValues.contactId) {
        return filtered;
      } else {
        const currentContact = contacts.find(({ uuid }) => uuid === props.defaultValues.contactId);

        if (currentContact?.assigned_to !== email) {
          return [currentContact, ...filtered];
        } else {
          return filtered;
        }
      }
    } else {
      return filterByAnchors(contacts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        // no intersection with screen
        if (entries[0].intersectionRatio === 0) setIsSticky(true);
        // fully intersects with screen
        else if (entries[0].intersectionRatio === 1) setIsSticky(false);
      },
      { threshold: [0, 1] }
    );

    observer.observe(document.querySelector('#sticky-container-bottom'));
    return () => {
      observer.disconnect();
    };
  }, []);

  const isWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const shouldShowAssignToInput = isWorkspaceCollaborative;

  const defaultValuesPropSanitized = Object.keys(defaultValuesProp).reduce(
    (acc: Partial<FormValues>, fieldName) => {
      if (typeof defaultValuesProp[fieldName] !== 'undefined') {
        acc[fieldName] = defaultValuesProp[fieldName];
      }

      return acc;
    },
    {}
  );

  const form = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      contactId: null,
      assignedTo: null,
      reminder: null,
      dueDate: null,
      description: '',
      important: false,
      ...defaultValuesPropSanitized,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = form;

  useEffect(() => {
    if (isDirty) {
      onFormDataChanged();
    }
  }, [isDirty, onFormDataChanged]);

  return (
    <FormProvider {...form}>
      <form className={clsx(styles.wrapper, className)} onSubmit={handleSubmit(onSubmit)}>
        <Box width="100%">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="string"
                id="task_form_title"
                label={t('form.field.title.label')}
                placeholder={t('form.field.title.placeholder')}
                fullWidth
                error={!!fieldState.error}
                helperText={
                  fieldState.error?.type === 'required' && t('common:validation.required')
                }
              />
            )}
          />
        </Box>

        <Box marginX={-1.5}>
          <Box width="100%" display="flex" flexWrap="wrap">
            <Box width="50%" paddingX={1.5}>
              <Controller
                name="contactId"
                control={control}
                render={({ field, fieldState }) => (
                  <SearchContactInput
                    {...field}
                    contacts={filteredContacts}
                    onChange={(value) => {
                      field.onChange(value ? value : null);
                    }}
                    id="task_form_contact_id"
                    label={t('form.field.contact_id.label')}
                    placeholder={t('form.field.contact_id.placeholder')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Box>
            {shouldShowAssignToInput && (
              <Box width="50%" paddingX={1.5}>
                <Controller
                  name="assignedTo"
                  control={control}
                  render={({ field }) => (
                    <AssignToSelect value={field.value} onSelect={field.onChange} />
                  )}
                />
              </Box>
            )}
            <Box width="50%" paddingX={1.5}>
              <Controller
                name="reminder"
                control={control}
                render={({ field }) => (
                  <DateTimeSelect
                    label={t('form.field.reminder.label')}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
            <Box width="50%" paddingX={1.5}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field }) => (
                  <DateTimeSelect
                    label={t('form.field.due_date.label')}
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box width="100%" mt={2}>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="string"
                id="task_form_description"
                label={t('form.field.description.label')}
                placeholder={t('form.field.description.placeholder')}
                fullWidth
                multiline
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
        </Box>
        <Box width="100%">
          <Controller
            name="important"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                id="task_form_important"
                control={<Checkbox />}
                label={t('form.field.important.label')}
              />
            )}
          />
        </Box>

        <Box
          display="flex"
          justifyContent="end"
          mt={1}
          className={clsx({
            ['saveButtonContainer']: true,
            [styles.saveButtonContainer]: isSticky,
          })}
        >
          <div className={styles.saveButtonPlace}>
            {isSubmitting && (
              <div className={styles.spinnerPlace}>
                <Spinner size={24} className={styles.spinner} />
              </div>
            )}
            <Button textSize="small" type="submit" disabled={isSubmitting}>
              {saveButtonText || t('common:action.save')}
            </Button>
          </div>
        </Box>
        <div id="sticky-container-bottom" style={{ height: 1 }} />
      </form>
    </FormProvider>
  );
}
