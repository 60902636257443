import proposalSettingsSchema from '../proposalSettings/proposalSettingsSchema';
import { dbVersion } from '../../constants';

export default {
  title: 'proposal schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'uuid',
  type: 'object',
  required: [
    'uuid',
    'number',
    'title',
    'client',
    'description',
    'contactId',
    'items',
    'isDraft',
    'visible',
    'sentAt',
    'createdAt',
    'updatedAt',
    'createdBy',
    'updatedBy',
    'settings',
    'workspaceId',
  ],
  properties: {
    uuid: {
      type: 'string',
      maxLength: 100,
    },
    number: {
      type: ['number', 'null'],
    },
    title: {
      type: ['string', 'null'],
    },
    client: {
      type: 'string',
    },
    description: {
      type: ['string', 'null'],
    },
    discountType: {
      type: 'string',
    },
    discountAmount: {
      type: 'string',
    },
    contactId: {
      type: 'string',
    },
    items: {
      type: 'array',
      items: {
        type: 'object',
        required: ['description', 'qty', 'unitPrice'],
        properties: {
          description: {
            type: 'string',
          },
          qty: {
            type: 'number',
          },
          unitPrice: {
            type: 'number',
          },
        },
      },
    },
    isDraft: {
      type: 'boolean',
    },
    visible: {
      type: 'boolean',
    },
    sentAt: {
      type: ['number', 'null'],
    },
    createdAt: {
      type: ['number', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    createdBy: {
      type: ['string', 'null'],
    },
    updatedBy: {
      type: ['string', 'null'],
    },
    settings: proposalSettingsSchema,
    workspaceId: {
      type: 'string',
      maxLength: 36,
    },
  },
  indexes: ['workspaceId'],
};
