import { useTranslation } from 'next-i18next';
import React from 'react';

import EmptySearchIcon from '../../../../../public/images/search/search-empty-state-image.svg';
import styles from './styles.module.scss';

const EmptySearchBackground: React.FC = () => {
  const { t } = useTranslation('search');

  return (
    <div className={styles.Wrapper}>
      <EmptySearchIcon />
      <span>{t('empty_state_text')}</span>
    </div>
  );
};

export default EmptySearchBackground;
