import React from 'react';

import { useCurrentSubscription } from 'src/features2/billing/ui/hooks';
import { useCurrentUser } from 'src/features2/user/ui/hooks';

// component is used to initialize commonly used subjects with data (user, subscription) to avoid extra checks
export default function commonDataProviderHoc(Component) {
  const ComponentHoc = ({ ...props }) => {
    const { subscription } = useCurrentSubscription();
    const { currentUser } = useCurrentUser();

    if (!subscription || !currentUser) return null;

    return (
      <>
        <Component {...props} />
      </>
    );
  };
  return ComponentHoc;
}
