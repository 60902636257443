import { Controller, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import Box from '@material-ui/core/Box';
import { data } from 'currency-codes';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

import Button from '../../../../../components/UI/Buttons/BaseButton';
import InputLabel from '../../../../../components/UI/Inputs/InputLabel';
import FormControl from '../../../../../components/UI/Inputs/FormControl';
import TextField from '../../../../../components/UI/Inputs/TextField';
import Select from '../../../../../components/UI/Inputs/Select';
import LogoInput from './Inputs/LogoInput';
import TaxRateInput from './Inputs/TaxRateInput';
import StartNumberInput from './Inputs/StartNumberInput';
import ValidityInput from './Inputs/ValidityInput';
import CustomerDetailsInput from './Inputs/CustomerDetailsInput/CustomerDetailsInput';

import styles from '../../ProposalForm.module.scss';
import { FormValues } from './useSettingsSubForm';
import { selectIsAdminOrOwner } from '../../../../../slices/workspacesSlice';
import { useAppSelector } from '../../../../../app/hooks';

export default function ProposalSettingsForm(props: {
  onSubmit: (values: FormValues) => Promise<void>;
}) {
  const { t } = useTranslation('proposal-settings');
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useFormContext<FormValues>();
  const { isSubmitting } = useFormState({ control });
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      function (entries) {
        // no intersection with screen
        if (entries[0].intersectionRatio === 0) setIsSticky(true);
        // fully intersects with screen
        else if (entries[0].intersectionRatio === 1) setIsSticky(false);
      },
      { threshold: [0, 1] }
    );

    observer.observe(document.querySelector('#sticky-container-bottom'));
    return () => {
      observer.disconnect();
    };
  }, []);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);

  return (
    <form className={styles.settingsForm} onSubmit={handleSubmit(props.onSubmit)} noValidate>
      {!isAdmin && (
        <div className={styles.noPermission}>
          <div className={styles.noPermissionText}>
            <span>*</span> {t('no_permission')}
          </div>
        </div>
      )}
      <div className={styles.logoWrapper}>
        <LogoInput />
      </div>
      <Box>
        <Controller
          name="businessName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="proposal_settings_business_name"
              label={t('form.field.businessName.label')}
              placeholder={t('form.field.businessName.placeholder')}
              fullWidth
            />
          )}
        />
      </Box>

      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="proposal_settings_description"
                  label={t('form.field.description.label')}
                  placeholder={t('form.field.description.placeholder')}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box width="50%" paddingX={1}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="proposal_settings_email"
                  label={t('duplicates:Email_field')}
                  placeholder={t('duplicates:Email_field')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="proposal_settings_phone_number"
                  label={t('duplicates:Phone_number_field')}
                  placeholder={t('duplicates:Phone_number_field')}
                  fullWidth
                />
              )}
            />
          </Box>

          <Box width="50%" paddingX={1}>
            <Controller
              name="fax"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="proposal_settings_fax"
                  label={t('form.field.fax.label')}
                  placeholder={t('duplicates:Phone_number_field')}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="proposal_settings_address"
                  label={t('form.field.address.label')}
                  placeholder={t('form.field.address.placeholder')}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box width="50%" paddingX={1}>
            <Controller
              name="website"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  id="proposal_settings_url"
                  label={t('duplicates:Website_field')}
                  placeholder={t('form.field.website.placeholder')}
                  fullWidth
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Controller
        name="moreDetails"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            id="proposal_settings_more_details"
            label={t('form.field.moreDetails.label')}
            placeholder={t('form.field.moreDetails.placeholder')}
            fullWidth
          />
        )}
      />
      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <StartNumberInput />
          </Box>
          <Box width="50%" paddingX={1}>
            <ValidityInput />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <TaxRateInput />
          </Box>
          <Box width="50%" paddingX={1}>
            <Controller
              name="currency"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel htmlFor="proposal_settings_currency">
                    {t('duplicates:Quote_settings_currency')}
                  </InputLabel>
                  <Select
                    {...field}
                    inputProps={{ id: 'proposal_settings_currency' }}
                    options={data.map(({ code, currency }) => ({
                      value: code,
                      text: `${code} (${currency})`,
                    }))}
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Box display="flex" marginX={-1}>
          <Box width="50%" paddingX={1}>
            <Controller
              name="includeTotalPrice"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="proposal_form_include_total_price"
                    hint={t('form.field.includeTotalPrice.hint')}
                  >
                    {t('form.field.includeTotalPrice.label')}
                  </InputLabel>
                  <Select
                    {...field}
                    inputProps={{ id: 'proposal_form_include_total_price' }}
                    value={Number(field.value)}
                    onChange={(event) => {
                      field.onChange(Boolean(event.target.value));
                    }}
                    options={[
                      {
                        value: 0,
                        text: t('form.field.includeTotalPrice.value.dontInclude'),
                      },
                      {
                        value: 1,
                        text: t('form.field.includeTotalPrice.value.include'),
                      },
                    ]}
                    fullWidth
                  />
                </FormControl>
              )}
            />
          </Box>
          <Box width="50%" paddingX={1}>
            <Controller
              name="customerDetails"
              control={control}
              render={({ field }) => (
                <CustomerDetailsInput value={field.value} onChange={field.onChange} />
              )}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Controller
          name="footerText"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="proposal_settings_footer_text"
              label={t('form.field.footerText.label')}
              placeholder={t('form.field.footerText.placeholder')}
              fullWidth
              multiline
              rows={2}
            />
          )}
        />
      </Box>
      <Box
        mt={1}
        mb={3}
        textAlign="right"
        className={clsx({
          [styles.bottom]: true,
          ['saveButtonContainer']: true,
          [styles.saveButtonContainer]: isSticky,
        })}
      >
        <Button variant="contained" type="submit" disabled={!isDirty || isSubmitting}>
          {t('common:action.save')}
        </Button>
      </Box>
      <div id="sticky-container-bottom" style={{ height: 1 }} />
    </form>
  );
}
