import { from } from 'rxjs';
import axios from '../../../../../app/axios';
import { IImportCSVApiService } from '../../../domain/abstractions/IImportApiService';

import { injectable } from 'inversify';
import { AxiosRequestConfig } from 'axios';
import RequestHeaders from '../../../../../enums/RequestHeaders';
import ContentTypes from '../../../../../enums/ContentTypes';
const routes = {
  import: 'api/v1/contacts/import',
  confirmImport: 'api/v1/contacts/import-confirm',
};

@injectable()
export default class ImportCSVApiService implements IImportCSVApiService {
  //@ts-ignore
  confirmUploadCSVToBackend: IImportCSVApiService['confirmUploadCSVToBackend'] = (
    columns,
    tag_name,
    board_column_id,
    file_id,
    abortController,
    workspaceId
  ) => {
    const request = axios.post<unknown>(
      routes.confirmImport,
      {
        columns,
        tag_name,
        board_column_id,
        file_id,
      },
      { signal: abortController.signal, headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId } }
    );
    return from(request);
  };

  //@ts-ignore
  uploadCSVToBackend: IImportCSVApiService['uploadCSVToBackend'] = (
    postData,
    onProgress,
    abortController,
    workspaceId
  ) => {
    const requestConfig = {
      headers: {
        [RequestHeaders.CONTENT_TYPE]: ContentTypes.MULTIPART_FORM_DATA,
        [RequestHeaders.WORKSPACE_ID]: workspaceId,
      },
      signal: abortController.signal,
      onUploadProgress: (progress) => {
        onProgress(Math.floor((progress.loaded * 100) / progress.total));
      },
    } as AxiosRequestConfig;
    const request = axios.post<unknown>(routes.import, postData, requestConfig);
    return from(request);
  };
}
