import { inject, injectable } from 'inversify';
import { TYPES } from '../di/types';
import { IContactsUseCases } from './abstractions/IContactsUseCases';
import { IContactsStateRepository } from './abstractions/IContactsStateRepository';

@injectable()
export default class ContactsUseCases implements IContactsUseCases {
  @inject(TYPES.ContactsStateRepository) private contactsStateRepository: IContactsStateRepository;
  getContacts = () => {
    return this.contactsStateRepository.getContacts();
  };
}
