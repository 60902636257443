import { injectable, inject } from 'inversify';
import { map } from 'rxjs';

import { IPromoCodeRepository } from '../domain/abstractions/repositories/IPromoCodeRepository';
import { IPromoCodeApiService } from './network/PromoCodeApiService';
import { mapPromoCodeDCtoEntity } from './mappers/promoCode/mapper';
import { TYPES } from '../di/types';

@injectable()
export default class PromoCodeRepository implements IPromoCodeRepository {
  @inject(TYPES.PromoCodeApiService) private api: IPromoCodeApiService;

  checkPromoCode(code, productLabel) {
    return this.api.checkPromoCode(code, productLabel).pipe(
      map((promoCode) => {
        return mapPromoCodeDCtoEntity(promoCode);
      })
    );
  }
}
