import { inject, injectable } from 'inversify';
import { ICustomFieldValueDao } from '../domain/abstractions/ICustomFieldValueDao';
import { TYPES } from 'src/app/ioc/types';
import { DbManager } from 'src/db/dbManager';
import { Observable, from, map, switchMap } from 'rxjs';
import CustomFieldValueEntity from 'src/db/entities/customFieldValue/CustomVieldValueEntity';
import type { IPersistentRepository } from 'src/services/PersistentRepository';

@injectable()
export default class CustomFieldValueDao implements ICustomFieldValueDao {
  @inject(TYPES.DbManager) private dbManager: DbManager;
  @inject(TYPES.PersistentRepository) private localStorageRepository: IPersistentRepository;

  getAll(contactUuid?: string): Observable<CustomFieldValueEntity[]> {
    const workspaceSelector = {
      workspaceId: {
        $eq: this.localStorageRepository.getCurrentWorkspaceId(),
      },
    };
    const query = contactUuid
      ? {
          selector: {
            contactUuid,
            ...workspaceSelector,
          },
        }
      : {
          selector: {
            workspaceSelector,
          },
        };

    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db['custom-field-values'].find(query).$;
        })
      )
      .pipe(
        map((docs) => {
          return docs.map((doc) => doc.toMutableJSON() as CustomFieldValueEntity);
        })
      );
  }

  upsert(data: Pick<CustomFieldValueEntity, 'uuid' | 'value'>): Observable<CustomFieldValueEntity> {
    return from(this.dbManager.getDb())
      .pipe(
        switchMap((db) => {
          return db['custom-field-values'].upsert(data);
        })
      )
      .pipe(
        map((doc) => {
          return doc.toMutableJSON() as CustomFieldValueEntity;
        })
      );
  }
}
