import {
  DCTagBoard,
  DCTagBoardColumn,
  DCTagBoardItem,
  TagBoard,
  TagBoardColumn,
  TagItem,
} from './api';

const mapDCItemToModel = (dc: DCTagBoardItem): TagItem => {
  return {
    type: dc.item_type,
    itemTypeId: dc.item_uuid,
    id: dc.uuid,
    tagId: dc.tag_uuid,
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
  };
};

const mapItemModelToDC = (model: TagItem): DCTagBoardItem => {
  return {
    item_type: model.type,
    item_uuid: model.itemTypeId,
    uuid: model.id,
    tag_uuid: model.tagId,
    created_at: model.createdAt,
    updated_at: model.updatedAt,
  };
};

const mapDCColumnToModel = (dc: DCTagBoardColumn): TagBoardColumn => {
  return {
    id: dc.uuid,
    title: dc.title,
    color: dc.color,
    items: dc.items.map(mapDCItemToModel),
    createdAt: dc.created_at,
    updatedAt: dc.updated_at,
  };
};

const mapColumnModelToDC = (model: TagBoardColumn): DCTagBoardColumn => {
  return {
    uuid: model.id,
    title: model.title,
    color: model.color,
    items: model.items.map(mapItemModelToDC),
    created_at: model.createdAt,
    updated_at: model.updatedAt,
  };
};

const mapDCBoardToModel = (dc: DCTagBoard): TagBoard => {
  return {
    columns: dc.columns.map(mapDCColumnToModel),
    updatedAt: dc.updated_at,
  };
};

const mapPartialColumnModelToDC = (
  partilModel: Partial<TagBoardColumn>
): Partial<DCTagBoardColumn> => {
  const mapperFns: Record<string, (value: unknown) => { [key: string]: unknown }> = {
    id: (value: TagBoardColumn['id']) => ({
      uuid: value,
    }),
    title: (value: TagBoardColumn['title']) => ({
      title: value,
    }),

    color: (value: TagBoardColumn['color']) => ({
      color: value,
    }),
    items: (value: TagBoardColumn['items']) => ({
      items: value.map(mapItemModelToDC),
    }),
    createdAt: (value: TagBoardColumn['createdAt']) => ({
      created_at: value,
    }),
    updatedAt: (value: TagBoardColumn['updatedAt']) => ({
      updated_at: value,
    }),
  };
  return Object.entries(partilModel).reduce((acc: Partial<DCTagBoardColumn>, item) => {
    const [key, value] = item;

    const mapperFn = mapperFns[key];
    if (mapperFn) {
      const mappedValues = mapperFn(value);
      return { ...acc, ...mappedValues };
    }

    return { ...acc };
  }, {});
};

export {
  mapItemModelToDC,
  mapDCBoardToModel,
  mapColumnModelToDC,
  mapDCColumnToModel,
  mapDCItemToModel,
  mapPartialColumnModelToDC,
};
