import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'next-i18next';
import hexAlpha from 'hex-alpha';
import clsx from 'clsx';

import { selectBoard } from 'slices/boardSlice';
import { useAppSelector } from 'src/app/hooks';
import Checkbox from 'components/UI/Inputs/Checkbox';
import styles from './styles.module.scss';
import { ColumnModel } from 'src/api/boardAPI';
import InputBase from 'components/UI/Inputs/InputBase';
import { selectVisibleProposals } from 'slices/proposalsSlice';
import { getFakeQuotesStatus } from '../../services/filters/filterHelpers';

interface IFilterStatusProps {
  selectedStatuses: ColumnModel[];
  setSelectedStatuses: Dispatch<SetStateAction<ColumnModel[]>>;
}

const FilterStatus: React.FC<IFilterStatusProps> = ({ selectedStatuses, setSelectedStatuses }) => {
  const { t } = useTranslation('search');
  const { columns: statuses } = useAppSelector(selectBoard);
  const visibleProposals = useAppSelector(selectVisibleProposals);
  const quoteStatus = useMemo(() => getFakeQuotesStatus(visibleProposals), [visibleProposals]);
  const statusesWithQuotes = [...statuses, quoteStatus];
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filteredStatuses, setFilteredStatuses] = useState(statusesWithQuotes.slice(3) || []);
  const [filterInputValue, setFilterInputValue] = useState<string>('');

  if (!statusesWithQuotes || !statusesWithQuotes.length) return null;

  const visibleStatuses = statusesWithQuotes.slice(0, 3);
  const isLongList = statusesWithQuotes.length > 3;
  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'search-statuses-filter-popover' : undefined;

  const isStatusChecked = ({ title }: ColumnModel) =>
    !!selectedStatuses.find((el) => el?.title === title);

  const selectOrRemoveStatus = (status) => {
    if (isStatusChecked(status)) {
      const filteredList = selectedStatuses.filter((el) => el.title !== status.title);
      setSelectedStatuses(filteredList);
    } else {
      setSelectedStatuses([...selectedStatuses, status]);
    }
  };

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const onFilterType = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setFilterInputValue(value);
    setFilteredStatuses(
      statuses.slice(3).filter(({ title }) => title.toLowerCase().includes(value?.toLowerCase()))
    );
  };

  return (
    <div className={styles.Wrapper}>
      <div>{t('filter_statuses_title')}</div>
      <div className={styles.List}>
        {visibleStatuses.map((status) => (
          <Status
            key={status.id}
            status={status}
            isStatusChecked={isStatusChecked(status)}
            onClick={selectOrRemoveStatus}
          />
        ))}
      </div>
      {isLongList && (
        <button
          className={'leader-search-see-more'}
          onClick={handleClick}
          aria-describedby={popoverId}
        >
          {t('filter_show_more')}
        </button>
      )}
      <Popover
        id={popoverId}
        open={isOpen && isLongList}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: styles.Dialog,
        }}
        anchorOrigin={{
          vertical: 0,
          horizontal: -1,
        }}
      >
        <InputBase
          placeholder={t('placeholder_filter_statuses')}
          onChange={onFilterType as React.ChangeEventHandler<HTMLInputElement>}
          value={filterInputValue}
          className={styles.Input}
        />
        <div className={styles.Divider}></div>
        <div>
          {filteredStatuses.map((status) => {
            return (
              <Status
                key={status.id}
                status={status}
                onClick={selectOrRemoveStatus}
                isStatusChecked={isStatusChecked(status)}
                className={styles.StatusItem}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

interface IStatusProps {
  isStatusChecked: boolean;
  status: ColumnModel;
  onClick: (s: ColumnModel) => void;
  className?: string;
}

const Status: React.FC<IStatusProps> = ({ isStatusChecked, status, onClick, className }) => {
  const { title, color } = status;
  const style = {
    color,
    background: hexAlpha(color, 0.2),
  };
  const { t } = useTranslation();

  return (
    <button className={clsx(styles.ItemWrapper, className)} onClick={() => onClick(status)}>
      <Checkbox checked={isStatusChecked} />
      <div className={styles.Item} style={style}>
        {t(title)}
      </div>
    </button>
  );
};

export default FilterStatus;
