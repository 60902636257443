import { Observable } from 'rxjs';
import { CreateCustomFieldValueData } from './abstractions/ICustomFieldValueApiService';
import { ICustomFieldValueUseCase } from './abstractions/ICustomFieldValueUseCase';
import { inject, injectable } from 'inversify';
import { CUSTOM_FIELD_VALUE_TYPES } from '../di/types';
import type { ICustomFieldValueRepository } from './abstractions/ICustomFieldValueRepository';
import CustomFieldValueEntity from 'src/db/entities/customFieldValue/CustomVieldValueEntity';

@injectable()
export default class CustomFieldValueUseCase implements ICustomFieldValueUseCase {
  @inject(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueRepository)
  private repository: ICustomFieldValueRepository;

  addValue(data: CreateCustomFieldValueData): Observable<string> {
    return this.repository.addValue(data);
  }

  getAll(contactUuid?: string): Observable<CustomFieldValueEntity[]> {
    return this.repository.getAll(contactUuid);
  }
}
