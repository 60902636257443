import { ChangeEvent, useState } from 'react';
import {
  Popover,
  FormControl,
  InputBase,
  FormGroup,
  FormControlLabel,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { FixedSizeList } from 'react-window';
import { useTranslation } from 'next-i18next';

import SearchIcon from '../../../../../public/images/icons/search.svg';
import styles from './ContactsDialog.module.css';
import ContactsDialogLabel from './ContactsDialogLabel';
import Contact from '../../../../../types/contact';

export type ContactsDialogProps = {
  contacts: Contact[];
  anchorEl: HTMLElement;
  selected: string[];
  onChange: ({ type, uuid }: { type: 'add' | 'remove'; uuid: string; columnId: string }) => void;
  onClose: () => void;
  isTagBoard?: boolean;
  columnId?: string;
};

const ContactsTagsList = ({
  contacts,
  selectedContactsSet,
  onChange,
}: {
  contacts: Contact[];
  selectedContactsSet: Set<string>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <>
      {contacts.length > 100 ? (
        <FixedSizeList height={347} itemCount={contacts.length} itemSize={42} width={250}>
          {({ index, style }) => {
            return (
              <div style={style}>
                <FormControlLabel
                  labelPlacement="start"
                  key={contacts[index].uuid}
                  className={styles.contactContainer}
                  classes={{ label: styles.label }}
                  control={
                    <Checkbox
                      classes={{ checked: styles.checked, root: styles.checkbox }}
                      checked={selectedContactsSet.has(contacts[index].uuid)}
                      onChange={onChange}
                      name={contacts[index].uuid}
                    />
                  }
                  label={<ContactsDialogLabel contact={contacts[index]} />}
                />
              </div>
            );
          }}
        </FixedSizeList>
      ) : (
        contacts.map((contact) => (
          <FormControlLabel
            labelPlacement="start"
            key={contact.uuid}
            className={styles.contactContainer}
            classes={{ label: styles.label }}
            control={
              <Checkbox
                classes={{ checked: styles.checked, root: styles.checkbox }}
                checked={selectedContactsSet.has(contact.uuid)}
                onChange={onChange}
                name={contact.uuid}
              />
            }
            label={<ContactsDialogLabel contact={contact} />}
          />
        ))
      )}
    </>
  );
};

const ContactsBoardList = ({
  contacts,
  selectedContactsSet,
  onChange,
}: {
  contacts: Contact[];
  selectedContactsSet: Set<string>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <>
      {contacts.length > 100 ? (
        <FixedSizeList height={347} itemCount={contacts.length} itemSize={42} width={250}>
          {({ index, style }) => {
            if (selectedContactsSet.has(contacts[index].uuid)) {
              return (
                <div className={styles.disabledContact} style={style} key={contacts[index].uuid}>
                  <ContactsDialogLabel contact={contacts[index]} disabled />
                </div>
              );
            } else {
              return (
                <div style={style}>
                  <FormControlLabel
                    labelPlacement="start"
                    key={contacts[index].uuid}
                    className={styles.contactContainer}
                    classes={{ label: styles.label }}
                    control={
                      <Checkbox
                        classes={{ checked: styles.checked, root: styles.checkbox }}
                        checked={selectedContactsSet.has(contacts[index].uuid)}
                        onChange={onChange}
                        name={contacts[index].uuid}
                      />
                    }
                    label={<ContactsDialogLabel contact={contacts[index]} />}
                  />
                </div>
              );
            }
          }}
        </FixedSizeList>
      ) : (
        contacts?.map((contact) => {
          if (selectedContactsSet.has(contact.uuid)) {
            return (
              <div className={styles.disabledContact} key={contact.uuid}>
                <ContactsDialogLabel contact={contact} disabled />
              </div>
            );
          } else {
            return (
              <FormControlLabel
                labelPlacement="start"
                key={contact.uuid}
                className={styles.contactContainer}
                classes={{ label: styles.label }}
                control={
                  <Checkbox
                    classes={{ checked: styles.checked, root: styles.checkbox }}
                    checked={selectedContactsSet.has(contact.uuid)}
                    onChange={onChange}
                    name={contact.uuid}
                  />
                }
                label={<ContactsDialogLabel contact={contact} />}
              />
            );
          }
        })
      )}
    </>
  );
};
const ContactsDialog = ({
  contacts,
  anchorEl,
  onClose,
  selected,
  onChange,
  isTagBoard,
  columnId,
}: ContactsDialogProps) => {
  const [searchStr, setSearchString] = useState<string>('');
  const handleClose = () => {
    setSearchString('');
    onClose();
  };
  const copyContacts = contacts.filter((contact) => {
    return (
      contact.name?.toLowerCase().includes(searchStr.toLowerCase()) ||
      contact.suggestion_name?.toLowerCase().includes(searchStr.toLowerCase()) ||
      contact.phones.some(({ phone }) => phone.includes(searchStr.toLowerCase())) ||
      contact.emails.some(({ email }) => email.includes(searchStr.toLowerCase()))
    );
  });
  const { t } = useTranslation('common');
  const selectedContactsSet = selected.reduce<Set<string>>((acc, contactId) => {
    acc.add(contactId);
    return acc;
  }, new Set());

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.checked ? 'add' : 'remove';

    onChange({ type, uuid: e.target.name as string, columnId });
  };
  const open = !!anchorEl;

  return (
    <Popover
      open={open}
      classes={{ paper: styles.container }}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <FormControl component="fieldset">
        <FormGroup>
          <InputBase
            endAdornment={
              <div className={styles.InputAdornment}>
                <SearchIcon style={{ width: 14, height: 14 }} />
              </div>
            }
            onChange={(e) => setSearchString(e.target.value)}
            value={searchStr}
            placeholder={t('contactsDialog.placeholder')}
            className={styles.searchInput}
            inputProps={{ 'data-hj-allow': '' }}
          />
          <Divider />
          {isTagBoard ? (
            <ContactsTagsList
              contacts={copyContacts}
              onChange={handleChange}
              selectedContactsSet={selectedContactsSet}
            />
          ) : (
            <ContactsBoardList
              contacts={copyContacts}
              onChange={handleChange}
              selectedContactsSet={selectedContactsSet}
            />
          )}
        </FormGroup>
      </FormControl>
    </Popover>
  );
};

export default ContactsDialog;
