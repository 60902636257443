import omit from 'lodash/omit';
import axios from '../app/axios';
import { endpoints } from '../utils/constants';

import WorkspaceEntity, { TeamMemberEntity } from '../db/entities/workspace/WorkspaceEntity';
import WorkspaceDC from '../db/entities/workspace/WorkspaceDC';
import { mapWorkspaceDCtoEntity, mapWorkspaceEntityToDC } from '../db/entities/workspace/mapper';

// to preserve naming for old architecture
export type DCWorkspace = WorkspaceDC;
export type Workspace = WorkspaceEntity;
export type TeamMember = TeamMemberEntity;
export const mapWorkspaceModelToDC = mapWorkspaceEntityToDC;
export const mapDCWorkspaceToModel = mapWorkspaceDCtoEntity;

const mapPartialModelToDC = (partialModel: Partial<Workspace>): Partial<DCWorkspace> => {
  const mapperFns: Record<string, (value: unknown) => { [key: string]: unknown }> = {
    id: (value: Workspace['id']) => ({
      uuid: value,
    }),
    name: (value: Workspace['name']) => ({
      name: value,
    }),
    industry: (value: Workspace['industry']) => ({
      industry: value,
    }),
    companySize: (value: Workspace['companySize']) => ({
      company_size: value,
    }),
    thumbnail: (value: Workspace['thumbnail']) => ({
      thumbnail: value,
    }),
    createdAt: (value: Workspace['createdAt']) => ({
      created_at: Math.floor(value / 1000),
    }),
    updatedAt: (value: Workspace['updatedAt']) => ({
      updated_at: Math.floor(value / 1000),
    }),
    roles: (value: Workspace['roles']) => ({
      roles: value.map((roleItem) => ({
        role: roleItem.role,
        is_default: roleItem.isDefault,
        is_mutable: roleItem.isMutable,
        permissions: roleItem.permissions,
      })),
    }),
    members: (value: Workspace['members']) => ({
      members: value.map((memberItem) => {
        return {
          email: memberItem.email,
          thumbnail: memberItem.thumbnail,
          full_name: memberItem.fullName,
          invitation_status: memberItem.invitationStatus,
          role: memberItem.role,
          last_login_at: memberItem.lastLoginAt,
          accepted_invitation_at: memberItem.acceptedInvitationAt,
        };
      }),
    }),
    memberAccessAllContacts: (value: Workspace['memberAccessAllContacts']) => ({
      member_access_all_contacts: value,
    }),
    proposalsNextColId: (value: Workspace['proposalsNextColId']) => ({
      price_proposals_next_column_uuid: value,
    }),
    lastLoginAt: (value: Workspace['lastLoginAt']) => ({
      last_login_at: Math.floor(value / 1000),
    }),
    isPersonal: (value: Workspace['isPersonal']) => ({
      is_personal: value,
    }),
  };

  const partialDc = Object.entries(partialModel).reduce((acc: Partial<DCWorkspace>, item) => {
    const [key, value] = item;

    const mapperFn = mapperFns[key];
    const mappedValues = mapperFn(value);

    return { ...acc, ...mappedValues };
  }, {});

  return partialDc;
};

export async function updateWorkspaceAPI(workspace: Workspace): Promise<void> {
  const workspaceDC = mapWorkspaceModelToDC(workspace);

  await axios.post<DCWorkspace>(
    endpoints.workspaces.post,
    omit(workspaceDC, ['roles', 'members', 'is_personal', 'last_login_at'])
  );
}

export async function patchWorkspaceAPI(
  id: string,
  data: Partial<Workspace> & { updatedAt: number }
): Promise<void> {
  const dc = mapPartialModelToDC({ id, ...data });

  await axios.patch(endpoints.workspaces.patch, dc);
}

export async function addTeammembersAPI({
  workspaceId,
  members,
  resend = false,
}: {
  members: Workspace['members'];
  workspaceId: string;
  resend?: boolean;
}): Promise<Workspace> {
  const { data } = await axios.put<DCWorkspace>(
    endpoints.workspaces.members.put.replace('{workspace_uuid}', workspaceId),
    members,
    { params: { resend_invitation: resend } }
  );
  return mapDCWorkspaceToModel(data);
}
export interface DeleteTeammates {
  email: string;
  reassign_to: string;
}
export async function removeTeammembersAPI(
  members: DeleteTeammates[] | DeleteTeammates,
  workspaceId: string
): Promise<boolean> {
  const { data } = await axios.delete<boolean>(
    endpoints.workspaces.members.put.replace('{workspace_uuid}', workspaceId),
    { data: members }
  );
  return data;
}
