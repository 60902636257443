import { useState } from 'react';
import DefaultDialog from '../../components/UI/Dialogs/DefaultDialog/DefaultDialog';

type TDialogUIArgs = {
  titleText?: string;
  bodyText?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  onSuccess?: () => void;
  onNegative?: () => void;
  titleClassName?: string;
  bodyClassName?: string;
  positiveButtonClassName?: string;
  negativeButtonClassName?: string;
  handleSuccessWithoutClose?: boolean;
};

export const useDialog = () => {
  const [opened, setOpened] = useState(false);

  const show = () => {
    setOpened(true);
  };

  const hide = () => {
    setOpened(false);
  };

  const hideWithSuccess = (successCB) => {
    successCB();
    hide();
  };

  const toggle = () => {
    setOpened(!opened);
  };

  const dialogUI = ({
    bodyText,
    positiveButtonText,
    negativeButtonText,
    onSuccess,
    onNegative,
    titleText,
    titleClassName,
    bodyClassName,
    positiveButtonClassName,
    negativeButtonClassName,
    handleSuccessWithoutClose,
  }: TDialogUIArgs) => {
    return (
      <DefaultDialog
        open={opened}
        onClose={onNegative ? onNegative : hide}
        hide={hide}
        onSuccess={() => hideWithSuccess(onSuccess)}
        title={titleText}
        description={bodyText}
        confirmText={positiveButtonText}
        rejectText={negativeButtonText}
        titleClassName={titleClassName}
        descriptionClassName={bodyClassName}
        confirmButtonClassName={positiveButtonClassName}
        rejectButtonClassName={negativeButtonClassName}
        handleSuccessWithoutClose={handleSuccessWithoutClose}
      />
    );
  };

  return {
    show,
    hide,
    toggle,
    dialogUI,
  };
};
