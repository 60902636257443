import { useState, useEffect } from 'react';
import { useInjectionWithRef } from 'src/app/ioc/ioc.react';
import { TYPES } from 'src/features2/importCSV/di/types';
import { TYPES as GLOBAL_TYPES } from 'src/app/ioc/types';
import { IImportCSVUseCases } from 'src/features2/importCSV/domain/abstractions/IImportCSVUseCases';
import ResponseColumnDC from 'src/features2/importCSV/domain/entities/ResponseDC';
import STATUSES from '../../enum/statuses';
import ImportCSVUploadingErrors from 'src/features2/importCSV/domain/enums/CSVUploadingErrors';
import ImportCSVConfirmingErrors from 'src/features2/importCSV/domain/enums/CSVConfirmingErrors';
import { IAmplitudeManager } from 'src/analytics/amplitude';

type IUseCSVUploadingViewModel = (
  file: File,
  tagName?: string,
  statusId?: string
) => {
  progress: number;
  status: STATUSES;
  error: string;
  errorColumns: any[];
  columns: ResponseColumnDC[];
  numberContacts: number;
  cancelUpload(): void;
  downloadExampleFile(): void;
  onUploading: () => void;
  confirmCSVUpload: (columns: ResponseColumnDC[]) => void;
  onCloseConfirming(): void;
};

const useCSVUploadingViewModel: IUseCSVUploadingViewModel = (file, tagName, statusId) => {
  const { upload, confirmUpload, cancelUpload } = useInjectionWithRef<IImportCSVUseCases>(
    TYPES.ImportCSVUseCases
  );
  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);

  const [status, setCurrentStatus] = useState<STATUSES>();
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState(null);
  const [errorColumns, setErrorColumns] = useState([]);
  const [columns, setColumns] = useState<ResponseColumnDC[]>([]);
  const [numberContacts, setNumberContacts] = useState<number>();

  const onProgressEvent = (progressPercentage: number) => {
    setProgress(progressPercentage);
  };

  const onCloseConfirming = () => {
    cancelUpload();
    setCurrentStatus(STATUSES.UploadSuccess);
  };

  const uploadCSV = (file) => upload(file, onProgressEvent);

  const confirmCSVUpload = (columns: ResponseColumnDC[]) => {
    setCurrentStatus(STATUSES.Confirming);
    setColumns(columns);
    setErrorColumns([]);

    confirmUpload(columns, tagName, statusId).subscribe({
      next: (length) => {
        setNumberContacts(length);
        setCurrentStatus(STATUSES.ConfirmSuccess);
        Amplitude.trackEvent('Import_csv_complete');
      },
      error: (error) => {
        setCurrentStatus(STATUSES.ConfirmErrors);
        if (error?.type) {
          switch (error.type) {
            case ImportCSVConfirmingErrors.NO_INTERNET_CONNECTION:
              setError(ImportCSVConfirmingErrors.NO_INTERNET_CONNECTION);
              break;
            case ImportCSVConfirmingErrors.WRONG_COLUMN_TYPE:
              setErrorColumns(error?.value);
              setError(ImportCSVConfirmingErrors.WRONG_COLUMN_TYPE);
              break;
            case ImportCSVConfirmingErrors.CANCELED:
              setError(null);
              setCurrentStatus(STATUSES.UploadSuccess);
              break;
            default:
              setError(ImportCSVConfirmingErrors.COMMON);
              break;
          }
        } else {
          setError(ImportCSVConfirmingErrors.COMMON);
        }
      },
    });
  };

  const downloadExampleFile = () => {
    const link = document.createElement('a');
    link.download = 'example.csv';
    link.href = './files/example.csv';
    link.click();
  };

  const onUploading = () => {
    setCurrentStatus(STATUSES.Uploading);

    Amplitude.trackEvent('Import_csv_file_upload_started');

    uploadCSV(file).subscribe({
      next: (columns: ResponseColumnDC[]) => {
        setColumns(columns);

        Amplitude.trackEvent('Import_csv_match_fields_appeared');

        setCurrentStatus(STATUSES.UploadSuccess);
      },
      error: (err: ImportCSVUploadingErrors) => {
        Amplitude.trackEvent('Import_csv_file_upload_error', { type: err });
        setError(err);
        setCurrentStatus(STATUSES.UploadErrors);
      },
    });
  };

  useEffect(() => {
    if (file) {
      onUploading();
    } else {
      setProgress(0);
      setError(null);
      setErrorColumns([]);
      setNumberContacts(undefined);
      setColumns([]);
      setCurrentStatus(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return {
    progress,
    error,
    columns,
    status,
    cancelUpload,
    confirmCSVUpload,
    numberContacts,
    onUploading,
    downloadExampleFile,
    onCloseConfirming,
    errorColumns,
  };
};

export default useCSVUploadingViewModel;
