import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import { updateProposal } from '../../../../slices/proposalsSlice';

import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';
import { useAppDispatch } from '../../../../app/hooks';
import DollarSymbol from '../../../../../public/images/icons/dollar-symbol.svg';
import useGetRelatedContactId from '../useGetRelatedContactId';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';

export default function useUpdateProposal() {
  const { t } = useTranslation('proposal-form');

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();

  const getRelatedContactId = useGetRelatedContactId();

  const update = async (
    proposal: ProposalEntity,
    onSuccess?: (data: ProposalEntity) => void | Promise<void>
  ) => {
    try {
      const relatedContactId = await getRelatedContactId(proposal.contactId);

      const data: ProposalEntity = {
        ...proposal,
        contactId: relatedContactId,
        updatedAt: Date.now(),
      };

      await dispatch(updateProposal(data)).unwrap();

      enqueueSnackbar({
        message: t('form.notification.updated'),
        variant: MessageType.Success,
        icon: <DollarSymbol />,
      });

      if (onSuccess) {
        await onSuccess(data);
      }
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    }
  };

  return update;
}
