import { useObservable, useObservableState } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';

import { ITagLinksRepository } from '../data/TagLinksRepository';
import { TYPES } from '../di/types';

export const useTagLinks = ({ updatedAfter }) => {
  const useCases = useInjection<ITagLinksRepository>(TYPES.TagLinksRepository);
  const contactsWithUpdatedLabels = useObservableState(
    useObservable(() => useCases.getUniqueContactsByTagLinksUpdatedAfter(updatedAfter), []),
    []
  );

  return {
    contactsWithUpdatedLabels,
  };
};
