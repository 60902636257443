import React, { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'next-i18next';

import { selectTagsBoard } from 'slices/tagsBoardSlice/slice';
import { useAppSelector } from 'src/app/hooks';
import Checkbox from 'components/UI/Inputs/Checkbox';
import styles from './styles.module.scss';
import { TagBoardColumn } from 'src/api/tagsBoardAPI';
import InputBase from 'components/UI/Inputs/InputBase';
import SearchIcon from 'icons/search.svg';

interface IFilterTagsProps {
  selectedTags: TagBoardColumn[];
  setSelectedTags: Dispatch<SetStateAction<TagBoardColumn[]>>;
}

const FilterTags: React.FC<IFilterTagsProps> = ({ selectedTags, setSelectedTags }) => {
  const basicVisibleTagsNumber = 5;

  const { t } = useTranslation('search');
  const { columns: tags } = useAppSelector(selectTagsBoard) || {};
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [filterInputValue, setFilterInputValue] = useState<string>('');
  const [filteredTags, setFilteredTags] = useState<TagBoardColumn[]>(
    tags?.slice(basicVisibleTagsNumber) || []
  );

  if (!tags) return null;

  const visibleTags = tags.slice(0, basicVisibleTagsNumber);
  const isLongList = tags.length > basicVisibleTagsNumber;
  const isOpen = Boolean(anchorEl);
  const popoverId = isOpen ? 'search-tags-filter-popover' : undefined;

  const isTagChecked = ({ title }: TagBoardColumn) =>
    !!selectedTags.find((el) => el?.title === title);

  const selectOrRemoveTag = (tag: TagBoardColumn) => {
    const { title } = tag;
    const isChecked = isTagChecked(tag);

    if (isChecked) {
      const filteredList = selectedTags.filter((el) => el?.title !== title);
      setSelectedTags(filteredList);
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleClose = () => setAnchorEl(null);
  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const onFilterType = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>) => {
    setFilterInputValue(value);
    setFilteredTags(
      tags
        .slice(basicVisibleTagsNumber)
        .filter(({ title }) => title.toLowerCase().includes(value?.toLowerCase()))
    );
  };

  return (
    <div className={styles.Wrapper}>
      <div>{t('filter_tags_title')}</div>
      <div>
        {visibleTags.map((tag, index) => {
          return (
            <Tag
              key={index}
              tag={tag}
              onClick={() => selectOrRemoveTag(tag)}
              isChecked={isTagChecked(tag)}
            />
          );
        })}
      </div>
      {isLongList && (
        <button
          className={'leader-search-see-more'}
          onClick={handleClick}
          aria-describedby={popoverId}
        >
          {t('filter_show_more')}
        </button>
      )}
      <Popover
        id={popoverId}
        open={isOpen && isLongList}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{
          paper: styles.Dialog,
        }}
        anchorOrigin={{
          vertical: 0,
          horizontal: -1,
        }}
      >
        <InputBase
          placeholder={t('placeholder_filter_tags')}
          onChange={onFilterType as React.ChangeEventHandler<HTMLInputElement>}
          value={filterInputValue}
          className={styles.Input}
          endAdornment={<SearchIcon width={19} />}
        />
        <div className={styles.Divider} />
        <div>
          {filteredTags.map((tag, index) => {
            return (
              <Tag
                key={index}
                onClick={() => selectOrRemoveTag(tag)}
                isChecked={isTagChecked(tag)}
                className={styles.FilterTag}
                isSmall
                tag={tag}
              />
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

interface ITagProps {
  tag: TagBoardColumn;
  onClick?: () => void;
  isChecked: boolean;
  isSmall?: boolean;
  className?: string;
}

const Tag: React.FC<ITagProps> = ({ tag, onClick, isChecked, isSmall, className }) => {
  const { color, title } = tag;

  return (
    <button className={clsx(styles.Item, isSmall && styles.Small, className)} onClick={onClick}>
      <Checkbox checked={isChecked} aria-label={title} />
      <div aria-label={title} className={styles.Tag} style={{ background: color }}>
        {title}
      </div>
    </button>
  );
};

export default FilterTags;
