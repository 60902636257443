import { combineLatest, map, Observable } from 'rxjs';
import { inject, injectable } from 'inversify';

import { TYPES } from '../di/types';
import { TYPES as WORKSPACES_TYPES } from '../../workspaces/di/types';
import { IProfileApiService } from './network/ProfileApiService';
import { IProfileDao } from './ProfileDao';
import { IWorkspacesStateRepository } from '../../workspaces/domain/abstractions/IWorkspacesStateRepository';

export interface IProfileDeleteRepository {
  deleteProfile(): Observable<Observable<string>>;
}

@injectable()
export default class ProfileDeleteRepository implements IProfileDeleteRepository {
  @inject(TYPES.ProfileApiService) private api: IProfileApiService;
  @inject(TYPES.ProfileDao) private dao: IProfileDao;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  private workspacesStateRepository: IWorkspacesStateRepository;

  deleteProfile = () => {
    return combineLatest(
      [
        this.dao.getProfile(),
        this.workspacesStateRepository.getCurrentWorkspace(),
        this.workspacesStateRepository.getWorkspaces(),
      ],
      (profile, currentWorkspace, workspaces) => {
        const { email } = profile;
        const member = currentWorkspace.members.find((member) => member.email === email);

        return { profile, currentWorkspace, role: member.role, workspacesCount: workspaces.length };
      }
    ).pipe(
      map(({ profile, currentWorkspace, role, workspacesCount }) => {
        const isSingleWorkspace = workspacesCount === 1;

        if (isSingleWorkspace) {
          return this.api.deleteProfile();
        } else {
          const isOwner = role === 'owner';

          if (isOwner) {
            return this.api.deleteProfile();
          } else {
            return this.workspacesStateRepository.deleteMembers([
              {
                email: profile.email,
                reassignTo: currentWorkspace.members.find(({ role }) => role === 'owner').email,
              },
            ]);
          }
        }
      })
    );
  };
}
