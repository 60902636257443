import Router from 'next/router';
import appRoutes from 'src/utils/constants/appRoutes';
import { getRelatedPathWithParams } from '../../utils/urlUtils';

const forwardTo = async (path: string) => await Router.push(path);

export const forwardToLoginPage = (saveUrlParams?: boolean) => {
  if (saveUrlParams) {
    forwardTo(`${appRoutes.LOGIN_PAGE}${window.location.search}`);
  } else {
    forwardTo(appRoutes.LOGIN_PAGE);
  }
};

export const forwardToDashboard = () => forwardTo(appRoutes.DASHBOARD);

export const forwardToCallsPage = () => forwardTo(appRoutes.CALLS_PAGE);

export const forwardToContactsPage = async () => await forwardTo(appRoutes.CONTACTS_PAGE);

export const forwardToTasksPage = () => forwardTo(appRoutes.TASKS_PAGE);

export const forwardToLoginPageWithParams = (params) =>
  forwardTo(getRelatedPathWithParams(appRoutes.LOGIN_PAGE, params));
