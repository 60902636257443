import { inject, injectable } from 'inversify';
import { BehaviorSubject, catchError, Observable } from 'rxjs';
import { uuid4 } from '@sentry/utils';
import Compressor from 'compressorjs';

import { TYPES } from '../di/types';
import { getXMLPropertyValue } from '../../../utils/xml';
import {
  TGetUploadData,
  THandleGetUploadResponse,
  TSuccessUploadingData,
  TUpload,
  TUploadingState,
  TUploadingStatus,
} from './FileUploadStateRepository.types';
import { UploadData, UploadDataResponse } from './network/FilesApiService.types';
import FilesUploadingError from '../domain/enum/FilesUploadingError';
import FileSizeLimits from '../domain/enum/FileSizeLimits';
import { IFileUploadStateRepository } from '../domain/abstractions/IFileUploadStateRepository';
import { IFilesApiService } from '../domain/abstractions/IFilesApiService';
import { IFileUploadingErrorHandlingRepository } from '../domain/abstractions/IFileUploadErrorHandlingRepository';
import FileUploadingStatus from '../domain/enum/FileUploadingStatus';
import FILES_MIME_TYPES from '../domain/enum/FILES_MIME_TYPES';
import { getVideoThumbnailBlob } from '../../../utils/files';
import FileDC from '../../../db/entities/file/FileDC';
import { mapFileDCtoEntity } from '../../../db/entities/file/mapper';

const XML_LOCATION_PROPERTY_NAME = 'Location';

@injectable()
export default class FileUploadStateRepository implements IFileUploadStateRepository {
  @inject(TYPES.FilesApiService) private api: IFilesApiService;
  @inject(TYPES.FileUploadErrorHandlingRepository)
  private errorHandler: IFileUploadingErrorHandlingRepository;

  uploadingState: TUploadingState;
  abortController: AbortController;
  public uploadingStatus: TUploadingStatus;
  // @ts-ignore
  public successUploadingData: TSuccessUploadingData;
  public fileUuid: string;
  compressedThumbnail: File;

  constructor() {
    this.uploadingState = new BehaviorSubject({
      fileUploadResponse: '',
      thumbnailUploadResponse: '',
      fileUploadingCanStarts: false,
    });
    this.abortController = new AbortController();
    this.uploadingStatus = new BehaviorSubject(FileUploadingStatus.UPLOADING);
    this.successUploadingData = new BehaviorSubject(null);
    this.fileUuid = uuid4();
  }

  upload: TUpload = (file, onProgress, onError) => {
    if (!navigator.onLine) {
      onError(this.errorHandler.getHandleData(FilesUploadingError.OFFLINE));
      this.uploadingStatus.next(FileUploadingStatus.ERROR);
      return;
    }

    if (file.file.size > FileSizeLimits.MAX_FILE_SIZE) {
      onError(this.errorHandler.getHandleData(FilesUploadingError.ENTITY_TOO_LARGE));
      this.uploadingStatus.next(FileUploadingStatus.ERROR);
      return;
    }

    if (file.file.size < FileSizeLimits.MIN_FILE_SIZE) {
      onError(this.errorHandler.getHandleData(FilesUploadingError.ENTITY_TOO_SMALL));
      this.uploadingStatus.next(FileUploadingStatus.ERROR);
      return;
    }

    const processedFile$ = new BehaviorSubject(file);
    const pf = processedFile$.getValue();
    const fileType = pf.file.type;
    const isThumbnailPossibleImage = FILES_MIME_TYPES.IMAGE.includes(fileType);
    const isThumbnailPossibleVideo = FILES_MIME_TYPES.VIDEO.includes(fileType);
    let isThumbnailPossible = isThumbnailPossibleImage || isThumbnailPossibleVideo;

    if (isThumbnailPossible && !this.compressedThumbnail) {
      const processThumbnail = (thumbnail: File) => {
        this.compressedThumbnail = thumbnail;

        this.api
          .getUploadData(thumbnail.type, true)
          .pipe(catchError((err: Observable<number>) => err))
          .subscribe((response) => {
            this._handleGetUploadResponse(
              response,
              () => {
                processedFile$.next({
                  ...processedFile$.getValue(),
                  thumbnailPostData: this._getUploadData({
                    file: thumbnail,
                    itemUuid: file.itemUuid,
                    response: response as UploadDataResponse,
                    creatorEmail: file.creatorEmail,
                  }),
                });
              },
              onError
            );
          });
      };

      if (isThumbnailPossibleImage) {
        new Compressor(file.file, {
          quality: 0.05,
          success: (compressedThumbnail: File) => {
            processThumbnail(compressedThumbnail);
          },
          error: () => {
            isThumbnailPossible = false;

            this.uploadingState.next({
              ...this.uploadingState.getValue(),
              fileUploadingCanStarts: true,
            });
          },
        });
      } else if (isThumbnailPossibleVideo) {
        getVideoThumbnailBlob(file.file).then((compressedThumbnail) => {
          if (compressedThumbnail === null) {
            isThumbnailPossible = false;

            this.uploadingState.next({
              ...this.uploadingState.getValue(),
              fileUploadingCanStarts: true,
            });
          } else {
            processThumbnail(compressedThumbnail);
          }
        });
      }
    } else {
      this.uploadingState.next({ ...this.uploadingState.getValue(), fileUploadingCanStarts: true });
    }

    const thumbnailUploadingSubscription = processedFile$.subscribe((file) => {
      if (file.thumbnailPostData) {
        this.api
          .uploadFileToStorage(
            file.thumbnailPostData,
            this.compressedThumbnail,
            () => {},
            this.abortController
          )
          .pipe(
            catchError(() => {
              return 'isError';
            })
          )
          .subscribe((response) => {
            if (response === 'isError') {
              this.uploadingStatus.next(FileUploadingStatus.ERROR);

              const errorData = this.errorHandler.getHandleData(FilesUploadingError.COMMON);

              onError(errorData);
            } else {
              this.uploadingState.next({
                ...this.uploadingState.getValue(),
                thumbnailUploadResponse: response,
                fileUploadingCanStarts: true,
              });
              thumbnailUploadingSubscription.unsubscribe();
            }
          });
      }
    });

    const getFileUploadDataSubscription = this.uploadingState.subscribe((state) => {
      if (state.fileUploadingCanStarts) {
        this.api
          .getUploadData(fileType)
          .pipe(catchError((err: Observable<number>) => err))
          .subscribe((response) => {
            this._handleGetUploadResponse(
              response,
              () => {
                processedFile$.next({
                  ...processedFile$.getValue(),
                  filePostData: this._getUploadData({
                    file: pf.file,
                    itemUuid: file.itemUuid,
                    response: response as UploadDataResponse,
                    creatorEmail: file.creatorEmail,
                    thumbnail: state.thumbnailUploadResponse
                      ? getXMLPropertyValue(
                          state.thumbnailUploadResponse,
                          XML_LOCATION_PROPERTY_NAME
                        )
                      : undefined,
                  }),
                });

                getFileUploadDataSubscription.unsubscribe();
              },
              onError
            );
          });
      }
    });

    const fileUploadingSubscription = processedFile$.subscribe((file) => {
      if (
        file.filePostData &&
        this.uploadingState.getValue().fileUploadingCanStarts &&
        this.uploadingStatus.getValue() === FileUploadingStatus.UPLOADING
      ) {
        this.api
          .uploadFileToStorage(file.filePostData, file.file, onProgress, this.abortController)
          .pipe(
            catchError(() => {
              return 'isError';
            })
          )
          .subscribe((response) => {
            if (response === 'isError') {
              this.uploadingStatus.next(FileUploadingStatus.ERROR);

              const errorData = this.errorHandler.getHandleData(FilesUploadingError.COMMON);

              onError(errorData);
            } else {
              this.uploadingStatus.next(FileUploadingStatus.UPLOADED_TO_STORAGE);

              this.uploadingState.next({
                ...this.uploadingState.getValue(),
                fileUploadResponse: response,
              });

              fileUploadingSubscription.unsubscribe();
            }
          });
      }
    });

    const saveDataToBackendSubscription = this.uploadingState.subscribe((state) => {
      const isThumbnailLoadedSuccessfully = isThumbnailPossible
        ? getXMLPropertyValue(state.thumbnailUploadResponse, XML_LOCATION_PROPERTY_NAME)
        : true;
      const isFileLoadedSuccessfully = getXMLPropertyValue(
        state.fileUploadResponse,
        XML_LOCATION_PROPERTY_NAME
      );
      const isLoadingSuccess = isThumbnailLoadedSuccessfully && isFileLoadedSuccessfully;

      if (isLoadingSuccess) {
        this.api
          .saveFileToBackend(processedFile$.getValue().filePostData.postData)
          .pipe(
            catchError(() => {
              return 'isError';
            })
          )
          .subscribe((response: FileDC | string) => {
            if (response === 'isError') {
              this.uploadingStatus.next(FileUploadingStatus.ERROR);

              const errorData = this.errorHandler.getHandleData(FilesUploadingError.COMMON);

              onError(errorData);
            } else {
              const file = [response as FileDC].map(mapFileDCtoEntity)[0];

              this.successUploadingData.next(file);
              this.uploadingStatus.next(FileUploadingStatus.SUCCESS);

              saveDataToBackendSubscription.unsubscribe();
            }
          });
      }
    });
  };

  cancelUpload() {
    this.abortController.abort();
  }

  _getUploadData: TGetUploadData = ({ file, itemUuid, response, creatorEmail, thumbnail }) => {
    const now = new Date().toISOString();

    const data: UploadData = {
      postData: {
        uuid: this.fileUuid,
        link: response.file_url,
        name: file.name,
        mime_type: file.type,
        filesize_bytes: file.size,
        item_type: 'contact',
        item_uuid: itemUuid,
        created_at: now,
        updated_at: now,
        created_by: creatorEmail,
        updated_by: creatorEmail,
      },
      postParams: response.post_params,
    };

    if (thumbnail) {
      data.postData.thumbnail = thumbnail;
    }

    return data;
  };

  _handleGetUploadResponse: THandleGetUploadResponse = (response, onSuccess, onError) => {
    if (typeof response === 'number') {
      this.uploadingStatus.next(FileUploadingStatus.ERROR);

      if (response === 429) {
        const errorData = this.errorHandler.getHandleData(
          FilesUploadingError.STORAGE_LIMIT_EXCEEDED
        );

        onError(errorData);
        return;
      } else {
        const errorData = this.errorHandler.getHandleData(FilesUploadingError.COMMON);

        onError(errorData);
      }
    } else {
      onSuccess();
    }
  };
}
