import NoteEntity from 'src/db/entities/note/NoteEntity';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import TaskEntity from 'src/db/entities/task/TaskEntity';
import Contact from 'types/contact';
import { getFilteredCompanies } from './filters/companies';
import { getFilteredContacts } from './filters/contacts';
import { getFilteredNotes } from './filters/notes';
import { getFilteredProposalsList } from './filters/proposals';
import { getFilteredTasksList } from './filters/tasks';
import FileBC from '../../../features2/files/domain/FileBC';
import { getFilteredFiles } from './filters/files';

export default class SearchResults {
  public readonly filteredContacts: Contact[];
  public readonly filteredContactsWithCompanies: Contact[];
  public readonly filteredNotes: NoteEntity[];
  public readonly filteredTasks: TaskEntity[];
  public readonly filteredProposals: ProposalEntity[];
  public readonly filteredFiles: FileBC[];

  constructor(args) {
    const {
      searchValue,
      selectedStatuses,
      selectedTags,
      selectedTeamMembers,
      contacts,
      notes,
      tasks,
      proposals,
      contactsWithCompanies,
      teamMembersContactsMap,
      isAllContentVisible,
      userEmail,
      files,
    } = args;

    const defaultArgs = {
      searchValue,
      selectedTeamMembers,
      contacts,
    };

    this.filteredContacts = getFilteredContacts({
      selectedStatuses,
      selectedTags,
      teamMembersContactsMap,
      isAllContentVisible,
      userEmail,
      ...defaultArgs,
    });

    this.filteredNotes = getFilteredNotes({
      notes,
      selectedTags,
      teamMembersContactsMap,
      isAllContentVisible,
      userEmail,
      ...defaultArgs,
    });

    this.filteredTasks = getFilteredTasksList({
      tasks,
      selectedTags,
      teamMembersContactsMap,
      isAllContentVisible,
      userEmail,
      ...defaultArgs,
    });

    this.filteredProposals = getFilteredProposalsList({
      proposals,
      selectedTags,
      teamMembersContactsMap,
      userEmail,
      isAllContentVisible,
      ...defaultArgs,
    });

    this.filteredContactsWithCompanies = getFilteredCompanies({
      searchValue,
      contactsWithCompanies,
    });
    this.filteredFiles = getFilteredFiles({
      files,
      searchValue,
      isAllContentVisible,
      userEmail,
      contacts,
    });
  }
}
