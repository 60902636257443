import { inject, injectable } from 'inversify';
import { TYPES } from 'src/features2/user/di/types';
import { TYPES as WORKSPACES_TYPES } from 'src/features2/workspaces/di/types';
import { combineLatest } from 'rxjs';
import { IUserStateRepository } from 'src/features2/user/domain/abstractions/IUserStateRepository';
import { ICustomerIORepository } from '../domain/abstractions/ICustomerIORepository';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';
import { PlanOptions } from 'src/features2/billing/domain/entities/SubscriptionEntity';

declare const _cio: {
  identify: (props: { [key: string]: string | number }) => void;
  track: (eventName: string, props: { [key: string]: string | number }) => void;
};

const MOBILE_PLATFORM_VALUE = 'none';
const ACCOUNT_TYPES = {
  INDIVIDUAL: 'individual',
  COLLABORATION: 'collaboration',
};
const UNKNOWN_PLAN_TYPE_VALUE = 'UNKNOWN';

@injectable()
class CustomerIORepository implements ICustomerIORepository {
  private cio;
  @inject(TYPES.UserStateRepository) userStateRepository: IUserStateRepository;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  workspacesStateRepository: IWorkspacesStateRepository;

  trackEvent = (eventType) => {
    this.cio.track(eventType);
  };

  resetUser = () => {
    this.cio?.reset?.();
  };

  initializeUser = () => {
    this.cio = _cio;

    combineLatest([
      this.workspacesStateRepository.getCurrentWorkspace(),
      this.userStateRepository.getCurrentUser(),
    ]).subscribe(([currentWorkspace, user]) => {
      if (user && currentWorkspace) {
        const workspaceId = currentWorkspace.id;
        const unixTimestamp = Math.floor(Date.now() / 1000);
        // https://caniuse.com/mdn-javascript_builtins_intl_datetimeformat_resolvedoptions_computed_timezone
        const timezone = Intl.DateTimeFormat().resolvedOptions?.()?.timeZone;
        const { subscription } = currentWorkspace;
        const isFreeTrial = subscription?.plan === PlanOptions.UNLIMITED_TRIAL;

        const customerAttributes = {
          id: `${user.email}:${workspaceId}`,

          full_name: user.fullName,
          email_address: user.email,
          country: user.settings.country,
          language: user.settings.lang,
          using_web: true,
          using_ios: user.platforms.some((platform) => platform === 'ios'),
          using_android: user.platforms.some((platform) => platform === 'android'),
          mobile_platform: MOBILE_PLATFORM_VALUE,
          ...(timezone && { timezone }),

          workspace_id: workspaceId,
          account_role: currentWorkspace.members.find((member) => member.email === user.email).role,
          last_activity_date: unixTimestamp,
          _last_visit: unixTimestamp,
          account_type: currentWorkspace.isPersonal
            ? ACCOUNT_TYPES.INDIVIDUAL
            : ACCOUNT_TYPES.COLLABORATION,

          ...(isFreeTrial && { free_trial_start_date: subscription?.startDate }),
          ...(isFreeTrial && { free_trial_end_date: subscription?.expirationDate }),

          subscription_plan_type: currentWorkspace.subscription?.plan || UNKNOWN_PLAN_TYPE_VALUE,
          subscription_purchase_type: currentWorkspace.subscription?.purchaseType,
        };

        this.cio.identify(customerAttributes);
      }
    });
  };
}
export default CustomerIORepository;
