import { useAppSelector } from '../../../app/hooks/useAppSelector';
import { selectIsCurrentWorkspaceCollaborative } from '../../../slices/workspacesSlice';
import { selectUserProfile } from '../../../slices/userSlice';

export default function useShouldShowCreatedBy(createdBy?: string) {
  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const { email } = useAppSelector(selectUserProfile);

  return !!createdBy && (isCurrentWorkspaceCollaborative || createdBy !== email);
}
