import EnvTypes from 'src/enums/EnvTypes';

export const isPrerelease = () => process.env.NEXT_PUBLIC_CURRENT_ENV === EnvTypes.PRERELEASE;
export const isProd = () => process.env.NEXT_PUBLIC_CURRENT_ENV === EnvTypes.PROD;
export const isStage = () => process.env.NEXT_PUBLIC_CURRENT_ENV === EnvTypes.STAGE;
export const isLocal = () => process.env.NEXT_PUBLIC_CURRENT_ENV === EnvTypes.LOCAL;

export default {
  isPrerelease,
  isProd,
  isStage,
  isLocal,
};
