import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FormValues } from '../useSettingsSubForm';
import TextField from '../../../../../../components/UI/Inputs/TextField';

export default function TaxRateInput() {
  const { t } = useTranslation('proposal-settings');

  const { setValue, control } = useFormContext<FormValues>();

  return (
    <Controller
      name="taxRate"
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          type="number"
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            // wrong type of ControllerRenderProps.onBlur
            (field.onBlur as (event: React.FocusEvent<HTMLInputElement>) => void)(event);

            if (event.target.value === '') {
              setValue('taxRate', 10, { shouldValidate: true });
            }
          }}
          id="proposal_settings_tax_rate"
          label={t('duplicates:Quote_settings_taxrate')}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          fullWidth
          InputProps={{
            endAdornment: '%',
          }}
        />
      )}
    />
  );
}
