import React from 'react';

import { useSnackbar, WithSnackbarProps } from 'notistack';
import { TFunction, useTranslation } from 'next-i18next';

import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';

let useSnackbarRef: WithSnackbarProps;
let tFuncRef: TFunction;

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  tFuncRef = useTranslation().t;

  return null;
};

export default {
  success(message: string, params: any) {
    this.toast(message, params, MessageType.Success);
  },
  error(message: string, params: any) {
    this.toast(message, params, MessageType.Error);
  },
  toast(message: string, params: any, variant = MessageType.Default) {
    useSnackbarRef.enqueueSnackbar({ message: tFuncRef(message, params), variant });
  },
};
