import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import BillingLimitsContext from './BillingLimitsContext';
import { BLOCKED_FEATURED_TYPES, LIMITS_TYPES } from './types';

export default function BillingLimitsProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();

  const [state, setState] = useState<{
    isOpened: boolean;
    limitsType: LIMITS_TYPES;
    featureType?: BLOCKED_FEATURED_TYPES;
  }>({
    isOpened: false,
    limitsType: null,
    featureType: null,
  });

  // reset limits if routes changed (e.g. user go to plans page)
  useEffect(() => {
    close();
  }, [router.pathname]);

  const open = ({ limitsType, featureType }) =>
    setState((currentState) => ({
      isOpened: true,
      limitsType,
      featureType,
    }));
  const close = () => setState((currentState) => ({ ...currentState, isOpened: false }));

  return (
    <BillingLimitsContext.Provider
      value={{
        isOpen: state.isOpened,
        open,
        close,
        limitsType: state.limitsType,
        featureType: state.featureType,
      }}
    >
      {children}
    </BillingLimitsContext.Provider>
  );
}
