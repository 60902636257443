import { useContext, useEffect, useState } from 'react';

import { UploadingQueueItem } from '../../../data/FileUploadStateRepository.types';
import { useInjectionWithRef } from '../../../../../app/ioc/ioc.react';
import { TYPES } from '../../../di/types';
import usePreparedDialogs from '../../../../../app/hooks/ui/usePreparedDialogs';
import { TFilesErrorData } from '../../../data/FileUploadErrorHandlingRepository';
import FilesUploadingErrorActions from '../../../domain/enum/FilesUploadingErrorActions';
import DialogTypes from '../../../../../enums/DialogTypes';
import { IFileSizeNotSupportedProps } from 'components/UI/Dialogs/FileSizeNotSupportedDialog';
import { IFilesUseCases } from '../../../domain/abstractions/IFilesUseCases';
import FilesContext from '../../../domain/context/FilesContext';
import FileUploadingStatus from '../../../domain/enum/FileUploadingStatus';
import FileBC from '../../../domain/FileBC';
import { IAmplitudeManager } from '../../../../../analytics/amplitude';
import { TYPES as GLOBAL_TYPES } from '../../../../../app/ioc/types';
import FilesUploadingError from '../../../domain/enum/FilesUploadingError';

type IUseFileUploadingQueueItemViewModel = (
  f: UploadingQueueItem,
  a: (e: TFilesErrorData) => void
) => {
  processedFileName: string;
  progress: number;
  error: TFilesErrorData | null;
  onCancelUploadClick(): void;
  onRetry(): void;
};

const NAME_LENGTH_TO_CUT = 37;
const NAME_LENGTH_TO_CUT_ERROR = 34;

const useFileUploadingQueueItemViewModel: IUseFileUploadingQueueItemViewModel = (
  file,
  addErrorToCounter
) => {
  const { showDialog } = usePreparedDialogs();
  const {
    removeItemFromQueue,
    commonFilesList,
    setCommonFilesList,
    uploadingStatusMap,
    setUploadingStatusMap,
  } = useContext(FilesContext);
  const { upload, cancelUpload, getFileUploadingStatus, getFileSuccessUploadData, getFileUuid } =
    useInjectionWithRef<IFilesUseCases>(TYPES.FilesUseCases);
  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);
  const [fileUuid] = useState(getFileUuid());
  const [progress, setProgress] = useState<number>(0);
  const [error, setError] = useState<TFilesErrorData>(null);
  const [uploadingStatus, setUploadingStatus] = useState<FileUploadingStatus>(
    FileUploadingStatus.UPLOADING
  );
  const [successUploadingData, setSuccessUploadingData] = useState<FileBC>(null);
  const [eventTracked, setEventTracked] = useState(false);
  const [updatedOptomistically, setUpdatedOptimistically] = useState(false);

  const onProgressEvent = (progressPercentage) => {
    setProgress(progressPercentage);
  };

  const onErrorEvent = (error: TFilesErrorData) => {
    addErrorToCounter(error);
    setError(error);
  };

  const uploadFile = () => upload(file, onProgressEvent, onErrorEvent);

  useEffect(() => {
    const statusSubscription = getFileUploadingStatus().subscribe((status) => {
      setUploadingStatus(status);

      if (status === FileUploadingStatus.ERROR || status === FileUploadingStatus.SUCCESS) {
        statusSubscription?.unsubscribe();

        if (status === FileUploadingStatus.SUCCESS && !eventTracked) {
          setEventTracked(true);
          Amplitude.trackEvent('Add_file');
        }
      }
    });

    const successUploadingDataSubscription = getFileSuccessUploadData().subscribe((data) => {
      if (data) {
        setSuccessUploadingData(data);

        successUploadingDataSubscription?.unsubscribe();
      }
    });

    return () => {
      statusSubscription.unsubscribe();
      successUploadingDataSubscription.unsubscribe();
    };
  }, [fileUuid, uploadingStatusMap, eventTracked]);

  useEffect(() => {
    if (uploadingStatusMap[fileUuid] !== uploadingStatus) {
      setUploadingStatusMap({ ...uploadingStatusMap, [fileUuid]: uploadingStatus });
    }
  }, [uploadingStatus, uploadingStatusMap]);

  useEffect(() => {
    if (!commonFilesList.find((listItem) => listItem.uuid === fileUuid)) {
      if (uploadingStatus === FileUploadingStatus.SUCCESS && successUploadingData) {
        const updateOptimistically = () => {
          setUpdatedOptimistically(true);

          const optimisticFileListItem = new FileBC(successUploadingData);

          setCommonFilesList([optimisticFileListItem, ...commonFilesList]);
        };

        if (!updatedOptomistically) {
          updateOptimistically();
        }
      }
    }
  }, [commonFilesList, uploadingStatus, updatedOptomistically]);

  useEffect(() => {
    uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      switch (error?.type) {
        case FilesUploadingError.ENTITY_TOO_LARGE:
          Amplitude.trackEvent('Add_file_failed', { Type: 'Too large' });
          break;

        default:
          Amplitude.trackEvent('Add_file_failed', { Type: 'server error' });
          break;
      }

      if (error?.action !== undefined) {
        handleErrorAction(error.action);
      }
    }
  }, [error]);

  const handleErrorAction = (action: FilesUploadingErrorActions) => {
    switch (action) {
      case FilesUploadingErrorActions.STORAGE_LIMITATION_REACHED_DIALOG: {
        showDialog({
          type: DialogTypes.STORAGE_LIMITATION_REACHED_DIALOG,
        });

        break;
      }

      case FilesUploadingErrorActions.FILE_SIZE_NOT_SUPPORTED_DIALOG_LARGE: {
        showDialog({
          type: DialogTypes.FILE_SIZE_NOT_SUPPORTED_DIALOG,
          dialogProps: {
            isLargeFile: true,
          } as IFileSizeNotSupportedProps,
        });

        break;
      }

      case FilesUploadingErrorActions.FILE_SIZE_NOT_SUPPORTED_DIALOG_SMALL: {
        showDialog({
          type: DialogTypes.FILE_SIZE_NOT_SUPPORTED_DIALOG,
          dialogProps: {
            isLargeFile: false,
          } as IFileSizeNotSupportedProps,
        });

        break;
      }
    }
  };

  const processFileName = (fn: string) => {
    if (fn.length >= (error ? NAME_LENGTH_TO_CUT_ERROR : NAME_LENGTH_TO_CUT)) {
      return `${fn.substr(0, 10)}...${fn.substr(-10, 10)}`;
    }

    return fn;
  };

  const onCancelUploadClick = () => {
    cancelUpload();
    removeItemFromQueue(file.id);
  };

  const onRetry = () => {
    setError(null);
    uploadFile();
  };

  return {
    processedFileName: processFileName(file.file.name),
    progress,
    error,
    onCancelUploadClick,
    onRetry,
  };
};

export default useFileUploadingQueueItemViewModel;
