import { dbVersion } from '../../constants';

export default {
  title: 'proposal setting schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: 'uuid',
  type: 'object',
  required: [
    'uuid',
    'workspaceId',
    'startPrice',
    'validity',
    'currency',
    'currency',
    'includesTax',
    'taxRate',
    'enteringPriceIncludesTax',
    'footerText',
    'customerDetails',
    'includeTotalPrice',
    'lang',
    'updatedAt',
    'createdAt',
    'businessDetails',
  ],
  properties: {
    uuid: {
      type: 'string',
      maxLength: 100,
    },
    workspaceId: {
      type: ['string', 'null'],
    },
    startPrice: {
      type: 'number',
    },
    validity: {
      type: 'number',
    },
    currency: {
      type: 'number',
    },
    includesTax: {
      type: 'boolean',
    },
    taxRate: {
      type: 'number',
    },
    enteringPriceIncludesTax: {
      type: 'boolean',
    },
    footerText: {
      type: 'string',
    },
    customerDetails: {
      type: 'array',
      items: {
        type: 'object',
        required: ['name', 'enabled'],
        properties: {
          name: {
            type: 'string',
          },
          enabled: {
            type: 'boolean',
          },
        },
      },
    },
    includeTotalPrice: {
      type: 'boolean',
    },
    lang: {
      type: ['string', 'null'],
    },
    updatedAt: {
      type: ['number', 'null'],
    },
    createdAt: {
      type: ['number', 'null'],
    },
    businessDetails: {
      type: 'object',
      required: [
        'uuid',
        'imageUrl',
        'name',
        'description',
        'phone',
        'email',
        'address',
        'website',
        'fax',
        'moreDetails',
      ],
      properties: {
        uuid: {
          type: 'string',
        },
        imageUrl: {
          type: ['string', 'null'],
        },
        name: {
          type: ['string', 'null'],
        },
        description: {
          type: ['string', 'null'],
        },
        phone: {
          type: ['string', 'null'],
        },
        email: {
          type: ['string', 'null'],
        },
        address: {
          type: ['string', 'null'],
        },
        website: {
          type: ['string', 'null'],
        },
        fax: {
          type: ['string', 'null'],
        },
        moreDetails: {
          type: ['string', 'null'],
        },
      },
    },
  },
};
