import { useContext, useEffect } from 'react';
import FilesContext from '../../../domain/context/FilesContext';

const useFilesUploadingDialogViewModel = () => {
  const { setUploadingStatusMap, uploadingDialogTitle } = useContext(FilesContext);

  useEffect(() => {
    return () => setUploadingStatusMap({});
  }, []);

  return {
    uploadingDialogTitle,
  };
};

export default useFilesUploadingDialogViewModel;
