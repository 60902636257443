import { dbVersion } from '../../constants';

export default {
  title: 'task schema',
  version: dbVersion,
  keyCompression: true,
  primaryKey: {
    key: 'pk_id',
    fields: ['id', 'workspaceId'],
    separator: '|',
  },
  type: 'object',
  required: ['pk_id', 'id', 'important', 'title', 'status', 'workspaceId'],
  properties: {
    pk_id: {
      type: 'string',
      maxLength: 100,
    },
    legacyId: {
      type: 'string',
    },
    id: {
      type: 'string',
      maxLength: 100,
    },
    contactId: {
      type: 'string',
    },
    important: {
      type: 'boolean',
    },
    title: {
      type: 'string',
    },
    type: {
      type: 'string', // 'regular' | 'callback'
    },
    description: {
      type: 'string',
    },
    descriptionUpdatedAt: {
      type: 'number',
    },
    status: {
      type: 'string', // 'new' | 'completed'
    },
    dueDate: {
      type: 'number',
    },
    reminder: {
      type: 'object',
      required: ['date'],
      properties: {
        date: {
          type: 'number',
        },
        label: {
          type: 'string',
        },
        type: {
          type: 'string',
        },
      },
    },
    repeat: {
      type: 'string', // 'daily' | 'weekly' | 'monthly'
    },
    assignedTo: {
      type: 'string',
    },
    createdBy: {
      type: 'string',
    },
    updatedBy: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    workspaceId: {
      type: 'string',
      maxLength: 36,
    },
  },
  indexes: ['workspaceId'],
};
