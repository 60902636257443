import { appRoutes } from '../utils/constants';
import { isMobileOnly } from 'react-device-detect';

import useMobileVersion from '../app/hooks/useMobileVersion';
import routes from '../utils/constants/appRoutes';
import { useCurrentUser } from '../features2/user/ui/hooks';
import { useInjection } from 'src/app/ioc/ioc.react';
import { TYPES } from 'src/app/ioc/types';
import { IPersistentRepository } from 'src/services/PersistentRepository';

//check if you are on the client (browser) or server

const ProtectedRoute = ({ router, children }) => {
  const { currentUser } = useCurrentUser();
  const persistentRepository = useInjection<IPersistentRepository>(TYPES.PersistentRepository);

  const { isInvitedUser } = currentUser || {};

  //check if user finished onboarding
  const isOnboardingPassed =
    isInvitedUser ||
    (currentUser?.isOnboardingFilled &&
      (currentUser?.hasInvitedTeammates || currentUser?.settings.skipInviteTeammates));

  const isAuthenticated = currentUser && (isMobileOnly || isOnboardingPassed);
  const returnBackAfterLogin = router.state.asPath.startsWith('/settings') && !isAuthenticated;

  if (returnBackAfterLogin) {
    persistentRepository.setRedirectAfterLogin(router.asPath);
  }

  // redirect to settings for mobile pages + don't render if redirect is processing (nextjs redirect is async)
  const { isRedirectingOnMobile } = useMobileVersion(isAuthenticated);
  if (isRedirectingOnMobile) {
    return null;
  }

  const unprotectedRoutes = [appRoutes.LOGIN_PAGE, appRoutes.AUTHORIZATION_PAGE];
  const pathIsProtected = unprotectedRoutes.indexOf(router.pathname) === -1;

  if (!isAuthenticated && pathIsProtected && router.route !== appRoutes.LOGIN_PAGE) {
    router.replace(routes.LOGIN_PAGE);
    return;
  }
  return children;
};

export default ProtectedRoute;
