import typography from './typography';
import overrides from './overrides';
import palette from './palette';

import { createTheme } from '@material-ui/core/styles';

/**
 * material-ui theme color pallete
 * @see https://material-ui.com/style/color/
 */
const MuiTheme = createTheme({
  typography,
  palette,
  overrides,
});
export default MuiTheme;
