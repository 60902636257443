import { useSnackbar } from 'notistack';

import { useAppDispatch } from '../useAppDispatch';
import { setDontShowInLeader } from 'src/services/contacts/actionService';
import { selectContactsMap } from 'slices/contactsSlice';
import { useAppStore } from '../useAppStore';

type TUseSetContactHiddenReturn = ({
  contactId,
  value,
  callback,
}: {
  contactId: string;
  value: boolean;
  callback: () => void;
}) => Promise<void>;

type TUseSetContactHidden = () => TUseSetContactHiddenReturn;

const useSetContactHidden: TUseSetContactHidden = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const store = useAppStore();

  return async ({ contactId, value, callback }) => {
    const contactsMap = selectContactsMap(store.getState());

    await setDontShowInLeader({
      enqueueSnackbar,
      dispatch,
      contact: contactsMap[contactId],
      notShowValue: value,
      callback,
    });
  };
};

export default useSetContactHidden;
