import { useInjectionWithRef } from 'src/app/ioc/ioc.react';
import { IAmplitudeManager } from '../index';
import { TYPES as GLOBAL_TYPES } from '../../../app/ioc/types';
export * as ANALYTICS_EVENTS from '../domain/events';
import { UserProperties } from '../index';
const useAmplitude = () => {
  const amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);

  const trackEvent = (event, eventProperties?) => amplitude?.trackEvent(event, eventProperties);
  const setUserForTracking = (data: UserProperties) => amplitude?.setUserForTracking(data);
  return {
    trackEvent,
    setUserForTracking,
  };
};

export default useAmplitude;
