import React from 'react';
import Link from 'next/link';
import { useTranslation, Trans } from 'next-i18next';

import Overlay from '../Overlay/Overlay';
import Button from 'components/UI/Buttons/BaseButton';
import { appRoutes } from 'src/utils/constants';
import { CONTACT_SUPPORT_EXTERNAL_URL } from 'src/utils/constants/links';

import ContactSupportIcon from 'icons/billing/contact-support-team.svg';
import { useCurrentSubscription } from '../../hooks';

const CanceledAndExpiredSubscriptionOverlay = ({ currentUser, adminEmail }) => {
  const { t } = useTranslation('billing', { keyPrefix: 'overlays' });
  const { subscription } = useCurrentSubscription();
  const accType = subscription.paidMembersCount > 1 ? 'collaborative' : 'individual';

  return (
    <Overlay>
      <h2>{t('common.upgradeAccount')}</h2>
      {currentUser.isAdmin ? (
        <>
          <p>{t('canceledAndExpired.subtitle')}</p>

          <Link href={appRoutes.PLANS_PAGE}>
            <Button>{t('common.upgradeNowBtn')}</Button>
          </Link>

          <a
            target="_blank"
            href={`${CONTACT_SUPPORT_EXTERNAL_URL}?acc_type=${accType}&country=${currentUser.settings.country}&status=${subscription.plan}&fullName=${currentUser.fullName}`}
            rel="noreferrer"
          >
            <ContactSupportIcon />
            {t('common.contactSupportTeam')}
          </a>
        </>
      ) : (
        <>
          <p>
            <Trans
              t={t}
              i18nKey="canceledAndExpired.subtitleForMember"
              values={{ email: adminEmail }}
            >
              Please contact your account admin <a href={`mailto: ${adminEmail}`}>{adminEmail}</a>
              to upgrade your account.
            </Trans>
          </p>

          <Button
            target="_blank"
            href={`${CONTACT_SUPPORT_EXTERNAL_URL}?acc_type=${accType}&country=${currentUser.settings.country}&status=${subscription.plan}&fullName=${currentUser.fullName}`}
          >
            {t('common.talkToSupportBtn')}
          </Button>
        </>
      )}
    </Overlay>
  );
};

export default CanceledAndExpiredSubscriptionOverlay;
