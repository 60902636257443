import axios from '../../app/axios';
import { endpoints } from '../../utils/constants';
import TaskDC from '../../db/entities/task/TaskDC';
import TaskEntity from '../../db/entities/task/TaskEntity';
import { mapPartialTaskEntityToDC } from '../../db/entities/task/mapper';
import RequestHeaders from '../../enums/RequestHeaders';

async function deleteTasks(ids: TaskEntity['id'][], workspaceId: string): Promise<true> {
  await axios.delete<true>(endpoints.tasks.delete, {
    data: ids,
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  return true;
}

async function createTask(task: TaskEntity, workspaceId: string): Promise<void> {
  const params = mapPartialTaskEntityToDC(task);

  await axios.post(endpoints.tasks.post, params, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function updateTask(task: TaskEntity, workspaceId: string): Promise<void> {
  const params = mapPartialTaskEntityToDC(task);

  await axios.patch(endpoints.tasks.post, params, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });
}

async function patchTask({
  id,
  workspaceId,
  data,
}: {
  id: TaskEntity['id'];
  workspaceId: string;
  data: Partial<TaskEntity> & { updatedAt: number };
}): Promise<true> {
  const params: Partial<TaskDC> = mapPartialTaskEntityToDC({ id, ...data });

  await axios.patch(endpoints.tasks.patch, params, {
    headers: { [RequestHeaders.WORKSPACE_ID]: workspaceId },
  });

  return true;
}

export { createTask, updateTask, patchTask, deleteTasks };
