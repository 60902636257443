import axios from '../app/axios';
import { endpoints } from '../utils/constants';
import ProfileEntity from '../db/entities/profile/ProfileEntity';
import ProfileDC from '../db/entities/profile/ProfileDC';
import { mapProfileDCToEntity, mapOnboardingInfoEntityToDc } from '../db/entities/profile/mapper';

async function fetchUserByTempToken({
  provider,
  token,
}: {
  provider: string;
  token: string;
}): Promise<{
  connection_state: string;
  id_token: string;
  refresh_token: string;
  access_token: string;
  token_type: string;
  profile: ProfileEntity;
}> {
  const { data: rawData } = await axios.post<{
    connection_state: string;
    id_token: string;
    refresh_token: string;
    access_token: string;
    token_type: string;
    profile: ProfileDC;
  }>(`${endpoints.vendor.connect}${provider}`, {
    code: token,
    redirect_url: process.env.NEXT_PUBLIC_REDIRECT_URI,
  });

  return {
    connection_state: rawData.connection_state,
    id_token: rawData.id_token,
    refresh_token: rawData.refresh_token,
    access_token: rawData.access_token,
    token_type: rawData.token_type,
    profile: mapProfileDCToEntity(rawData.profile),
  };
}

async function fetchUserAPI(): Promise<ProfileEntity> {
  const { data } = await axios.get<ProfileDC>(endpoints.account.profile);

  return mapProfileDCToEntity(data);
}

async function updateOnboardingInfo(data: ProfileEntity['onboardingInfo']): Promise<void> {
  await axios.post(`${endpoints.account.onboardingInfo}`, mapOnboardingInfoEntityToDc(data));
}

export { fetchUserByTempToken, updateOnboardingInfo, fetchUserAPI };
