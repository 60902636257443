import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import PreviewArea from '../shared/PreviewArea';
import { useInjection } from '../../../../../../../../../app/ioc/ioc.react';
import { TYPES } from '../../../../../../../di/types';
import { getBase64FromBlob } from '../../../../../../../../../utils/files';
import { IFilesUseCases } from '../../../../../../../domain/abstractions/IFilesUseCases';
import { HEIC, HEIF, TIFF } from '../../../../../../../domain/enum/FILES_MIME_TYPES';
import deviceService from '../../../../../../../../../services/device/deviceService';
import FilePreviewProblem from '../../../FilePreviewProblem';
import { IFilePreviewUnitProps } from '../../index';

const FilePreviewImage: React.FC<IFilePreviewUnitProps> = ({ file, isLoading, setIsLoading }) => {
  const { getDownloadLink } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const [fileLink, setFileLink] = useState('');
  const [isImageSupported, setIsImageSupported] = useState(false);

  useEffect(() => {
    const { mimeType } = file;

    if (mimeType === TIFF) {
      const isTiffSupported = deviceService.isTIFFImageSupported();

      setIsImageSupported(isTiffSupported);

      if (!isTiffSupported) {
        setIsLoading(false);
      }
    } else if ([HEIC, HEIF].includes(mimeType)) {
      setIsImageSupported(false);
      setIsLoading(false);
    } else {
      setIsImageSupported(true);
    }
  }, [file]);

  useEffect(() => {
    if (isImageSupported) {
      const subscription = getDownloadLink(file.uuid).subscribe((response) => {
        fetch(response[0].file_presigned_link)
          .then((response) => response.blob())
          .then((blob) => {
            getBase64FromBlob(blob).then((base64: string) => {
              setFileLink(base64);

              subscription.unsubscribe();
            });
          });
      });
    }
  }, [file, isImageSupported]);

  return (
    <PreviewArea isLoading={isLoading}>
      {isImageSupported ? (
        <div className={styles.ImageContainer}>
          {fileLink && (
            <img
              src={fileLink}
              alt={file.name}
              className={clsx(styles.Image, !isLoading && styles.Visible)}
              onLoad={() => {
                setIsLoading(false);
              }}
            />
          )}
        </div>
      ) : (
        <FilePreviewProblem file={file} />
      )}
    </PreviewArea>
  );
};

export default FilePreviewImage;
