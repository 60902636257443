import { Observable, map } from 'rxjs';
import type {
  CreateCustomFieldValueData,
  ICustomFieldValueApiService,
} from '../domain/abstractions/ICustomFieldValueApiService';
import { ICustomFieldValueRepository } from '../domain/abstractions/ICustomFieldValueRepository';
import { inject, injectable } from 'inversify';
import { CUSTOM_FIELD_VALUE_TYPES } from '../di/types';
import CustomFieldValueEntity from 'src/db/entities/customFieldValue/CustomVieldValueEntity';
import type { ICustomFieldValueDao } from '../domain/abstractions/ICustomFieldValueDao';

@injectable()
export default class CustomFieldValueRepository implements ICustomFieldValueRepository {
  @inject(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueApiService)
  private api: ICustomFieldValueApiService;
  @inject(CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueDao) private dao: ICustomFieldValueDao;

  addValue(data: CreateCustomFieldValueData): Observable<string> {
    // @ts-ignore
    return this.dao.upsert(data).pipe(
      map(() => {
        this.api.addValue(data);
      })
    );
  }

  getAll(contactUuid?: string): Observable<CustomFieldValueEntity[]> {
    return this.dao.getAll(contactUuid);
  }
}
