import Box from '@material-ui/core/Box';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import DiscountSelect from '../DiscountSelect/DiscountSelect';
import usePriceCalculation from '../usePriceCalculation';
import { FormValues } from '../useMainSubForm';
import { useAppStore } from '../../../../../../app/hooks';
import { selectProposalSettings } from 'slices/proposalsSlice';

import styles from '../../../ProposalForm.module.scss';

const TotalSection = () => {
  const { t } = useTranslation('proposal-form', { keyPrefix: 'form.totalSection' });

  const { formState, watch, control, getFieldState } = useFormContext<FormValues>();

  const discountAmountErrorMessage = getFieldState('discountAmount', formState).error?.message;
  const [currency, includesTax] = watch(['currency', 'includesTax']);

  const store = useAppStore();
  const proposalSettings = selectProposalSettings(store.getState());

  const { total, subtotal } = usePriceCalculation({
    taxRate: proposalSettings.taxRate,
  });

  return (
    <Box className={styles.priceSummary}>
      <Box component="h3">{t('title')}</Box>
      <Box>
        <Box className={styles.priceSummaryRow}>
          <Box component="h4">{t('subtotal')}</Box>
          <Box>
            {getSymbolFromCurrency(currency) || currency} {subtotal.toFixed(2)}
          </Box>
        </Box>
        <Box className={styles.priceSummaryRow}>
          <Box component="h4">{t('discount')}</Box>
          <DiscountSelect
            control={control}
            currency={currency}
            amountError={discountAmountErrorMessage}
          />
        </Box>
        {includesTax && (
          <Box className={styles.priceSummaryRow}>
            <Box component="h4">{t('taxRate')}</Box>
            <Box>{proposalSettings.taxRate}%</Box>
          </Box>
        )}
        <Box className={styles.priceSummaryRow}>
          <Box component="h4">{t('totalAmount')}</Box>
          <Box component="b">
            {getSymbolFromCurrency(currency) || currency} {total.toFixed(2)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TotalSection;
