import { IImportCSVUploadConfirmError } from '../domain/abstractions/IImportCSVUploadConfirmError';
import CSVConfirmingErrors from '../domain/enums/CSVConfirmingErrors';

export default class ImportCSVUploadConfirmError implements IImportCSVUploadConfirmError {
  public type: CSVConfirmingErrors;
  public value: string;
  constructor(type: CSVConfirmingErrors, value?: string) {
    this.type = type;
    this.value = value;
  }
}
