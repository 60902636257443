import ContactDC from '../../db/entities/contact/ContactDC';
import { mapContactDCToEntity } from '../../db/entities/contact/mapper';

import TaskDC from '../../db/entities/task/TaskDC';
import { mapTaskDCtoEntity } from '../../db/entities/task/mapper';

import WorkspaceDC from '../../db/entities/workspace/WorkspaceDC';
import { mapWorkspaceDCtoEntity } from '../../db/entities/workspace/mapper';

import { DbManager } from '../../db/dbManager';
import { container } from '../ioc/container';
import { TYPES } from '../ioc/types';
import { TYPES as WORKSPACES_MODULE_TYPES } from '../../features2/workspaces/di/types';
import { IWorkspacesStateRepository } from '../../features2/workspaces/domain/abstractions/IWorkspacesStateRepository';

import NoteDC from '../../db/entities/note/NoteDC';
import { mapNoteDCToEntity } from '../../db/entities/note/mapper';

import TagDC from '../../db/entities/tag/TagDC';
import { mapTagDCtoEntity } from '../../db/entities/tag/mapper';

import TagLinkDC from '../../db/entities/tagLink/TagLinkDC';
import { mapTagLinkDCtoEntity } from '../../db/entities/tagLink/mapper';

import BoardColumnDC from '../../db/entities/boardColumn/BoardColumnDC';
import { mapBoardColumnDCtoEntity } from '../../db/entities/boardColumn/mapper';

import BoardItemDC from '../../db/entities/boardItem/BoardItemDC';
import { mapBoardItemDCtoEntity } from '../../db/entities/boardItem/mapper';

import ProposalDC from '../../db/entities/proposal/ProposalDC';
import { mapProposalDCToEntity } from '../../db/entities/proposal/mapper';

import CallDC from '../../db/entities/call/CallDC';
import { mapCallDCtoEntity } from '../../db/entities/call/mapper';

import NotificationDC from '../../db/entities/notification/NotificationDC';
import { mapNotificationDCtoEntity } from '../../db/entities/notification/mapper';

import ProposalSettingsDC from '../../db/entities/proposalSettings/ProposalSettingsDC';
import { mapProposalSettingsDСtoEntity } from '../../db/entities/proposalSettings/mapper';

import CustomFieldDC from 'src/db/entities/customField/CustomFieldDC';
import { mapCustomFieldDCtoEntity } from 'src/db/entities/customField/mapper';

import CustomFieldValueDC from 'src/db/entities/customFieldValue/CustomFieldValueDC';
import { mapCustomFieldValueDCtoEntity } from 'src/db/entities/customFieldValue/mapper';

import FileDC from '../../db/entities/file/FileDC';
import { mapFileDCtoEntity } from '../../db/entities/file/mapper';
import ProfileDC from '../../db/entities/profile/ProfileDC';
import { mapProfileDCToEntity } from '../../db/entities/profile/mapper';

const getDispatchDictionaryUpdate = async () => {
  const dbManager = container.get<DbManager>(TYPES.DbManager);
  const workspacesRepository = container.get<IWorkspacesStateRepository>(
    WORKSPACES_MODULE_TYPES.WorkspacesStateRepository
  );
  const db = await dbManager.getDb();
  const currentWorkspaceId = workspacesRepository.currentWorkspaceId.value;

  return {
    task: (items: TaskDC[]) => {
      const entities = items.map((dc) =>
        mapTaskDCtoEntity(dc, { workspaceId: currentWorkspaceId })
      );
      return db.tasks.bulkUpsert(entities);
    },
    workspace: (items: WorkspaceDC[]) => {
      const entities = items.map(mapWorkspaceDCtoEntity);
      return db.workspaces.bulkUpsert(entities);
    },
    note: (items: NoteDC[]) => {
      const entities = items.map((dc) => mapNoteDCToEntity(dc, currentWorkspaceId));
      return db.notes.bulkUpsert(entities);
    },
    call: (items: CallDC[]) => {
      const entities = items.map(mapCallDCtoEntity);
      return db.calls.bulkUpsert(entities);
    },
    price_proposal: (items: ProposalDC[]) => {
      const entities = items.map((dc) => mapProposalDCToEntity(dc, currentWorkspaceId));
      return db.proposals.bulkUpsert(entities);
    },
    price_proposal_settings: (items: ProposalSettingsDC[]) => {
      const entities = items.map(mapProposalSettingsDСtoEntity);
      return db['proposal-settings'].bulkUpsert(entities);
    },
    notification: (items: NotificationDC[]) => {
      const entities = items.map(mapNotificationDCtoEntity);
      return db.notifications.bulkUpsert(entities);
    },
    contact: (items: ContactDC[]) => {
      const entities = items.map(mapContactDCToEntity);
      return db.contacts.bulkUpsert(entities);
    },
    board_column: (items: BoardColumnDC[]) => {
      const entities = items.map((dc) => mapBoardColumnDCtoEntity(dc, currentWorkspaceId));

      return db['board-columns'].bulkUpsert(entities);
    },
    board_item: (items: BoardItemDC[]) => {
      const entities = items.map((dc) => mapBoardItemDCtoEntity(dc, currentWorkspaceId));
      return db['board-items'].bulkUpsert(entities);
    },
    tag: (items: TagDC[]) => {
      const entities = items.map((dc) => mapTagDCtoEntity(dc, currentWorkspaceId));
      return db.tags.bulkUpsert(entities);
    },
    tag_link: (items: TagLinkDC[]) => {
      const entities = items.map((dc) => mapTagLinkDCtoEntity(dc, currentWorkspaceId));
      return db['tag-links'].bulkUpsert(entities);
    },
    file: (items: FileDC[]) => {
      const entities = items.map((dc) => mapFileDCtoEntity(dc));
      return db.files.bulkUpsert(entities);
    },
    account: (items: ProfileDC[]) => {
      const entities = items.map((dc) => mapProfileDCToEntity(dc));
      return db.profile.bulkUpsert(entities);
    },
    custom_field: (items: CustomFieldDC[]) => {
      const entities = items.map((dc) => mapCustomFieldDCtoEntity(dc, currentWorkspaceId));

      return db['custom-fields'].bulkUpsert(entities);
    },
    custom_field_value: (items: CustomFieldValueDC[]) => {
      const entities = items.map((dc) => mapCustomFieldValueDCtoEntity(dc, currentWorkspaceId));
      return db['custom-field-values'].bulkUpsert(entities);
    },
  };
};
export default getDispatchDictionaryUpdate;
