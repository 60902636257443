import { mapProfileDCToEntity } from '../profile/mapper';
import { mapWorkspaceDCtoEntity } from '../workspace/mapper';
import ProviderInfoDC from './ProviderInfoDC';
import ProviderInfoEntity from './ProviderInfoEntity';

export const mapProviderInfoDCToEntity = (dc: ProviderInfoDC): ProviderInfoEntity => {
  return {
    idToken: dc.id_token,
    refreshToken: dc.refresh_token,
    accessToken: dc.access_token,
    connectionState: dc.connection_state,
    tokenType: dc.token_type,
    profile: mapProfileDCToEntity(dc.profile),
    workspaces: dc.workspaces.map(mapWorkspaceDCtoEntity),
  };
};
