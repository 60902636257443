enum LanguageCodes {
  EN = 'en',
  AR = 'ar',
  ZH_CN = 'zh-CN',
  ZH_TW = 'zh-TW',
  NL = 'nl-NL',
  FR = 'fr',
  DE = 'de',
  HE = 'he',
  HI = 'hi',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  FA = 'fa',
  PT = 'pt-PT',
  PT_BR = 'pt-BR',
  UK = 'uk',
  ES = 'es',
  TH = 'th',
  TR = 'tr',
  RU = 'ru',
}

export default LanguageCodes;
