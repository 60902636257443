import React, { useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import DirectedText from 'src/features2/localization/ui/DirectedText';
import Overlay from '../Overlay/Overlay';
import Button from 'components/UI/Buttons/BaseButton';
import { appRoutes } from 'src/utils/constants';
import { LIMITS_TYPES, BLOCKED_FEATURED_TYPES } from './types';
import BillingLimitsContext from './BillingLimitsContext';

import styles from '../Overlay/styles.module.scss';
import { useCurrentSubscription } from '../../hooks';

const ReachedMonthlyLimitOverlay = () => {
  const { t } = useTranslation('billing', { keyPrefix: 'overlays' });
  const { subscription } = useCurrentSubscription();
  const context = useContext(BillingLimitsContext);

  if (!context.isOpen) return null;

  // individual paid plan is possible only via google play or gift subscription
  // if subscription isn't active (e.g. expired we allow to upgrade subscription on web as well
  const isActiveIndividualPaidPlan =
    subscription.isUserOnPaidPlan && subscription.isIndividual && subscription.isActive;
  const isInviteTeammateFeatureBlocked =
    context.limitsType === LIMITS_TYPES.BLOCKED_FEATURE &&
    context.featureType === BLOCKED_FEATURED_TYPES.INVITE_TEAMMATE;

  return (
    <Overlay>
      <h2>{t('common.upgradeAccount')}</h2>
      {
        /* this feature can be blocked by different reason (free plan OR paid individual) */
        isInviteTeammateFeatureBlocked && isActiveIndividualPaidPlan ? (
          <>
            <p>
              <DirectedText>{t('limitsOverlay.inviteTeammatesPaidIndividualPlan')}</DirectedText>
            </p>

            <div className={styles.buttonsWrapper}>
              <Button onClick={context.close}>{t('limitsOverlay.gotIt')}</Button>
            </div>
          </>
        ) : (
          <>
            <p>
              <DirectedText>
                {context.limitsType === LIMITS_TYPES.LIMITED && t('limitsOverlay.limitedInfo')}
                {context.limitsType === LIMITS_TYPES.BLOCKED_FEATURE &&
                  t('limitsOverlay.blockedInfo')}
                <br />
                {t('limitsOverlay.subtitle')}
              </DirectedText>
            </p>

            <div className={styles.buttonsWrapper}>
              <Button variant="outlined" onClick={context.close}>
                {t('limitsOverlay.notNow')}
              </Button>
              <Link href={appRoutes.PLANS_PAGE}>
                <Button>{t('common.upgradeNowBtn')}</Button>
              </Link>
            </div>
          </>
        )
      }
    </Overlay>
  );
};

export default ReachedMonthlyLimitOverlay;
