import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDropzone, ErrorCode } from 'react-dropzone';
import clsx from 'clsx';

import { FormValues } from '../../useSettingsSubForm';
import FormHelperText from '../../../../../../../components/UI/Inputs/FormHelperText';
import InputLabel from '../../../../../../../components/UI/Inputs/InputLabel';
import Spinner from '../../../../../../../components/UI/Spinner';
import ImagePlaceholderIcon from '../../../../../../../../public/images/icons/image-placeholder.svg';
import WarningIcon from '../../../../../../../../public/images/icons/warning.svg';
import AttachmentIcon from '../../../../../../../../public/images/icons/attachment.svg';
import uploadPhoto from '../../../../../../../utils/uploadImage';
import styles from './LogoInput.module.css';

export default function LogoInput() {
  const { t } = useTranslation('proposal-settings');

  const { setValue } = useFormContext<FormValues>();

  const logoUrl = useWatch<FormValues>({ name: 'logoUrl' });

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    uploadLogo(acceptedFiles[0]);
  };

  const [errorText, setErrorText] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    disabled: uploading,
    accept: '.jpeg, .jpg, .png, .svg',
    maxSize: 5242880, //5 MB in bytes
    maxFiles: 1,
    onDrop,
    onDropRejected: (fileRejections) => {
      const errorCode = fileRejections[0].errors[0].code;

      if (errorCode === ErrorCode.FileTooLarge) {
        setErrorText(t('common:validation.file_too_large', { max_size: '5MB' }));
      } else if (errorCode === ErrorCode.FileInvalidType) {
        setErrorText(t('common:validation.file_invalid_type', { types: 'PNG, JPG, SVG' }));
      } else if (errorCode === ErrorCode.TooManyFiles) {
        setErrorText(t('common:validation.too_many_files'));
      }
    },
  });

  const uploadLogo = async (file: File) => {
    try {
      setErrorText(null);
      setUploading(true);

      const { url } = await uploadPhoto(file);

      setValue('logoUrl', url, { shouldDirty: true });
    } catch (error) {
      if (!error.response?.status) {
        setErrorText(t('common:message.network_error'));
      } else {
        setErrorText(t('common:message.server_error'));
      }
    } finally {
      setUploading(false);
    }
  };

  const removeLogo = () => {
    setValue('logoUrl', undefined, { shouldDirty: true });
  };

  return (
    <div className={styles.wrapper}>
      <InputLabel hint={t('form.field.logoUrl.labelHint')} htmlFor="proposal_settings_logo_url">
        {t('form.field.logoUrl.label')}
      </InputLabel>
      <div
        {...getRootProps()}
        className={clsx({
          [styles.dropzone]: true,
          [styles.active]: isDragActive || uploading,
          [styles.uploading]: uploading,
        })}
      >
        <input {...getInputProps()} id="proposal_settings_logo_url" data-hj-allow={''} />
        {!uploading && !!logoUrl ? (
          <>
            <Image src={logoUrl as string} layout="fill" objectFit="scale-down" alt="logo" />
            <div className={styles.attachment}>
              <AttachmentIcon />
            </div>
          </>
        ) : uploading ? (
          <div className={styles.spinnerWrapper}>
            <Spinner className={styles.Spinner} />
          </div>
        ) : (
          <>
            <div className={styles.iconWrapper}>
              {errorText ? (
                <WarningIcon className={styles.warning} />
              ) : (
                <ImagePlaceholderIcon className={styles.imagePlaceholder} />
              )}
            </div>
            <span className={styles.hint}>{t('form.field.logoUrl.dragHint')}</span>
            <div className={styles.attachment}>
              <AttachmentIcon />
            </div>
          </>
        )}
      </div>
      <div className={styles.helpers}>
        {errorText && <FormHelperText className={styles.error}>{errorText}</FormHelperText>}
        {!uploading && !!logoUrl && (
          <button type="button" className={styles.removeImage} onClick={removeLogo}>
            {t('form.field.logoUrl.remove')}
          </button>
        )}
      </div>
    </div>
  );
}
