import { ContainerModule } from 'inversify';
import { TYPES } from './types';

export const getThirdPartyDependencies = new ContainerModule((bind) => {
  bind<AbortController>(TYPES.AbortControllerFactory).toFactory<AbortController>(() => {
    return () => {
      return new AbortController();
    };
  });
});
