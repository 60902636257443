import ErrorIcon from 'icons/error-case-import-csv.svg';
import { MB_LIMIT } from '../../../domain/enums/CSVFileSizeLimits';
import styles from './styles.module.scss';
import Button from 'components/UI/Buttons/BaseButton';
const TooLargeErrorCase = ({ onClose, t, LargeByRows }) => {
  return (
    <div className={styles.container}>
      <ErrorIcon />
      <h3 className={styles.title}>{t('ErrorCases.TooLarge.title')}</h3>
      <span className={styles.description}>
        {LargeByRows
          ? t('ErrorCases.TooLarge.description2')
          : t('ErrorCases.TooLarge.description', { size: MB_LIMIT })}
      </span>
      <div className={styles.actionsContainer}>
        <Button onClick={onClose}>{t('common:action.ok')}</Button>
      </div>
    </div>
  );
};
export default TooLargeErrorCase;
