import { FC, ReactNode, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import LanguageCodes from '../../../../enums/LanguageCodes';
import LocalizationContext from '../LocalizationContext';

const RTL_LANGUAGES = [LanguageCodes.AR, LanguageCodes.HE];

interface IProps {
  children: ReactNode;
}

const LocalizationProvider: FC<IProps> = ({ children }) => {
  const { locale } = useRouter();
  const [isRTL, setIsRTL] = useState(false);

  useEffect(() => {
    setIsRTL(RTL_LANGUAGES.includes(locale as LanguageCodes));
  }, [locale]);

  return <LocalizationContext.Provider value={{ isRTL }}>{children}</LocalizationContext.Provider>;
};

export default LocalizationProvider;
