import { useState } from 'react';

import { useAppStore, useAppDispatch } from '../../../../app/hooks';
import { selectProposalSettings, updateProposal } from '../../../../slices/proposalsSlice';
import useShouldShowCreatedBy from '../../../collaboration/hooks/useShouldShowCreatedBy';
import CreatedByLabel from '../../../collaboration/components/CreatedByLabel/CreatedByLabel';
import downloadFileByLink from '../../../../utils/downloadFileByLink';
import GenericForm, { GenericProposalFormProps } from '../GenericForm';
import useGeneratePdf from '../useGeneratePdf';
import useUpdateProposal from './useUpdateProposal';
import styles from './UpdateForm.module.css';
import { FormValues } from '../subForms/MainSubForm/useMainSubForm';
import { selectContactsMap } from 'slices/contactsSlice';
import { hideCallsBatch } from 'slices/callsSlice';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import { useSnackbar } from 'notistack';
import ProposalEntity, { DISCOUNT_TYPES } from 'src/db/entities/proposal/ProposalEntity';

export interface UpdateFormOptions {
  data: ProposalEntity;
  onFormDataChanged?: () => void;
  onSuccess?: () => void;
}

export default function UpdateForm({ data, onFormDataChanged, onSuccess }: UpdateFormOptions) {
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const shouldShowCreatedBy = useShouldShowCreatedBy(data.createdBy);

  const [formIsDirty, setFormIsDirty] = useState(false);

  const defaultValues: FormValues = {
    title: data.title,
    contactId: data.contactId,
    client: data.client,
    items: data.items,
    comment: data.description,
    includesTax: data.settings.includesTax,
    currency: data.settings.currency,
    discountAmount: data.discountAmount,
    discountType: data.discountType || DISCOUNT_TYPES.VALUE,
  };
  const { enqueueSnackbar } = useSnackbar();

  const update = useUpdateProposal();

  const generatePdf = useGeneratePdf();

  const preparePayloadForUpdate = (formValues: FormValues): ProposalEntity => {
    const settings = selectProposalSettings(store.getState());

    return {
      ...data,
      contactId: formValues.contactId,
      client: formValues.client,
      title: formValues.title,
      items: formValues.items,
      description: formValues.comment,
      discountAmount: formValues.discountAmount,
      discountType: formValues.discountType,
      settings: {
        ...settings,
        currency: formValues.currency,
        includesTax: formValues.includesTax,
      },
    };
  };

  const handleSubmit: GenericProposalFormProps['onSubmit'] = (formValues) => {
    withErrorSnackbar({
      onTry: async () => {
        const contactsMap = selectContactsMap(store.getState());
        const contact = contactsMap[formValues.contactId];
        const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
        dispatch(hideCallsBatch(contactPhones));
      },
      enqueueSnackbar,
    });
    const params = preparePayloadForUpdate(formValues);
    return update(params, onSuccess);
  };

  const handleDownload: GenericProposalFormProps['onDownload'] = (formValues) => {
    withErrorSnackbar({
      onTry: async () => {
        const contactsMap = selectContactsMap(store.getState());
        const contact = contactsMap[formValues.contactId];
        const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
        dispatch(hideCallsBatch(contactPhones));
      },
      enqueueSnackbar,
    });
    const handleSuccess = async (data: ProposalEntity) => {
      const tempUrl = await generatePdf(data);

      if (data.isDraft) {
        await dispatch(
          updateProposal({ ...data, isDraft: false, updatedAt: Date.now(), sentAt: Date.now() })
        );
      }

      downloadFileByLink(tempUrl, data.title);
    };

    if (formIsDirty) {
      const params = preparePayloadForUpdate(formValues);

      return update(params, handleSuccess);
    } else {
      return handleSuccess(data);
    }
  };

  const handlePreview: GenericProposalFormProps['onPreview'] = async (formValues) => {
    const params = preparePayloadForUpdate(formValues);
    const tempUrl = await generatePdf(params);

    if (tempUrl) {
      window.open(tempUrl);
    }
  };

  const handleFormDataChanged = () => {
    setFormIsDirty(true);

    onFormDataChanged();
  };

  return (
    <div className={styles.wrapper}>
      <GenericForm
        defaultValues={defaultValues}
        contactPinned={true}
        onFormDataChanged={handleFormDataChanged}
        onDownload={handleDownload}
        onPreview={handlePreview}
        onSubmit={handleSubmit}
      />
      {shouldShowCreatedBy && (
        <CreatedByLabel
          className={styles.createdBy}
          email={data.createdBy}
          createdAt={data.createdAt}
        />
      )}
    </div>
  );
}
