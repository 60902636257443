import { inject, injectable, postConstruct } from 'inversify';
import { IFBAnalyticsRepository } from '../domain/abstractions/IFBAnalyticsRepository';
import FB_EVENTS from '../domain/enums/FB_EVENTS';
import { TYPES as WORKSPACES_TYPES } from 'src/features2/workspaces/di/types';
import { IWorkspacesStateRepository } from 'src/features2/workspaces/domain/abstractions/IWorkspacesStateRepository';

declare const fbq: (
  eventType: string,
  eventName: FB_EVENTS,
  props: { [key: string]: string | number }
) => void;

@injectable()
class FBAnalyticsRepository implements IFBAnalyticsRepository {
  private fbq = fbq;
  @inject(WORKSPACES_TYPES.WorkspacesStateRepository)
  workspacesStateRepository: IWorkspacesStateRepository;
  @postConstruct()
  init() {
    this.workspacesStateRepository.getCurrentWorkspace().subscribe((currentWorkspace) => {
      if (currentWorkspace) {
        const acceptedMembers = currentWorkspace.members.filter(
          (member) => member.invitationStatus === 'accepted'
        );
        acceptedMembers.length >= 2 &&
          this.trackEvent('trackCustom', FB_EVENTS.COLLABORATION_ACCOUNT);
      }
    });
  }
  trackEvent = (
    eventType: string,
    eventName: FB_EVENTS,
    props?: { [key: string]: string | number }
  ) => {
    this.fbq(eventType, eventName, props);
  };
}
export default FBAnalyticsRepository;
