import React, { useState, useRef, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import StarIcon from '@material-ui/icons/Star';

import Contact from 'types/contact';
import styles from './styles.module.scss';
import Avatar from 'components/UI/Avatar';
import BellIcon from 'public/images/icons/bell.svg';
import { getHighlightedOverlaps } from 'src/utils/searchUtils';
import DueBadge from '../../../tasks/components/TimeBadge/DueBadge';
import SearchIcon from 'icons/search.svg';
import { useDispatch } from 'react-redux';
import { addHistoryItem } from 'slices/searchSlice';
import SearchHistoryItemTypes from 'src/enums/SearchHistoryItemTypes';
import { forwardToTasksPage } from 'src/services/router/appRouterService';
import { setCurrentPageName, setRedirectEffect } from 'slices/uiSlice';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import TaskEntity from 'src/db/entities/task/TaskEntity';

interface ITasksListProps {
  data: TaskEntity[];
  contacts: Contact[];
  searchValue: string;
  closeSearchModal: () => void;
}

const TasksList: React.FC<ITasksListProps> = ({
  data,
  contacts,
  searchValue,
  closeSearchModal,
}) => {
  const { t } = useTranslation('search');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<string | number>('unset');
  const listWrapper = useRef<HTMLDivElement>(null);
  const isLongList = data?.length > 3;

  useLayoutEffect(() => {
    if (listWrapper.current && isLongList) {
      const cl = listWrapper.current.children as HTMLCollectionOf<HTMLElement>;
      setMaxHeight(cl[0].offsetHeight + cl[1].offsetHeight + cl[2].offsetHeight);
    }
  }, [searchValue, isLongList]);

  if (!data?.length) return null;

  const onSeeAllLess = () => setIsExpanded(!isExpanded);

  return (
    <div className={styles.Wrapper}>
      <div>{t('duplicates:Tasks_title')}</div>
      <div
        className={clsx(styles.List, isExpanded && styles.Expanded)}
        ref={listWrapper}
        style={maxHeight && !isExpanded ? { maxHeight, overflow: 'hidden' } : null}
      >
        {data.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            contacts={contacts}
            closeSearchModal={closeSearchModal}
            searchValue={searchValue}
          />
        ))}
      </div>
      {isLongList && maxHeight !== 'unset' && (
        <button onClick={onSeeAllLess} className={'leader-search-see-more'}>
          <SearchIcon /> {isExpanded ? t('button_see_less') : t('button_see_all_tasks')}
        </button>
      )}
      <div className={'leader-search-divider'} />
    </div>
  );
};

interface ITaskItemProps {
  closeSearchModal: () => void;
  task: TaskEntity;
  contacts: Contact[];
  searchValue?: string;
}

export const TaskItem: React.FC<ITaskItemProps> = ({
  closeSearchModal,
  task,
  contacts,
  searchValue,
}) => {
  const { title, contactId, dueDate, repeat, status, important, id } = task;
  const { name, thumbnail } = contacts.find(({ uuid }) => uuid === contactId) || {};
  const dispatch = useDispatch();

  const onClick = () => {
    forwardToTasksPage();
    dispatch(setCurrentPageName('duplicates:Tasks_title'));
    dispatch(
      setRedirectEffect({
        contactId,
        targetType: RedirectEffectTargetType.TASK,
        targetId: id,
      })
    );
    closeSearchModal();
    dispatch(
      addHistoryItem({
        type: SearchHistoryItemTypes.TASK,
        id: task.id,
        taskData: {
          task,
          contacts,
        },
      })
    );
  };

  return (
    <button className={styles.Item} onClick={onClick}>
      <div className={styles.IconWrapper}>
        <div className={styles.TaskIcon}>
          <BellIcon />
        </div>
      </div>
      <div>
        <div className={styles.TaskWrapper}>
          <div
            className={styles.Title}
            dangerouslySetInnerHTML={{ __html: getHighlightedOverlaps(title, searchValue) }}
          />
          {important && <StarIcon />}
        </div>
        {dueDate && (
          <DueBadge timestamp={dueDate} repeat={repeat} completed={status === 'completed'} />
        )}
        {name && (
          <div className={styles.ContactInfo}>
            <Avatar className={styles.Avatar} thumbnail={thumbnail} name={name} />
            <span dangerouslySetInnerHTML={{ __html: getHighlightedOverlaps(name, searchValue) }} />
          </div>
        )}
      </div>
    </button>
  );
};

export default TasksList;
