import MUIFormControl, {
  FormControlProps as MUIFormControlProps,
} from '@material-ui/core/FormControl';
import clsx from 'clsx';
import styles from '../../../styles/components/inputs/FormControl.module.css';

export default function FormControl(props: MUIFormControlProps) {
  const { className, margin = 'normal', ...rest } = props;

  return (
    <MUIFormControl margin={margin} className={clsx(styles.FormControl, className)} {...rest} />
  );
}
