import { inject, injectable } from 'inversify';
import { TYPES } from '../di/types';
import IOnboardingUseCases from './abstractions/IOnboardingUseCases';
import IOnboardingRepository from './abstractions/IOnboardingRepository';
@injectable()
export default class OnboardingUseCases implements IOnboardingUseCases {
  @inject(TYPES.OnboardingRepository) private onboardingRepository: IOnboardingRepository;

  getOnboardingInfoTemporary() {
    return this.onboardingRepository.getOnboardingInfoTemporary();
  }
  setOnboardingInfoTemporary(data) {
    this.onboardingRepository.setOnboardingInfoTemporary(data);
  }
  removeOnboardingInfoTemporary() {
    this.onboardingRepository.removeOnboardingInfoTemporary();
  }
  updateOnboardingInfo(data) {
    const preparedData = { ...data, businessPhone: data.phoneCode + data.phoneNumber };
    this.onboardingRepository.updateOnboardingInfo(preparedData);
  }
}
