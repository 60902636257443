import { createContext } from 'react';
import { LIMITS_TYPES, BLOCKED_FEATURED_TYPES } from './types';

export interface IBillingLimitsContext {
  isOpen: boolean;
  close?: () => void;
  open: ({
    limitsType,
    featureType,
  }: {
    limitsType: LIMITS_TYPES;
    featureType?: BLOCKED_FEATURED_TYPES;
  }) => void;
  limitsType: LIMITS_TYPES;
  featureType: BLOCKED_FEATURED_TYPES;
}

const BillingLimitsContext = createContext<IBillingLimitsContext>({
  isOpen: false,
  close: undefined,
  open: undefined,
  limitsType: undefined,
  featureType: undefined,
});

export default BillingLimitsContext;
