import React from 'react';
import clsx from 'clsx';

import FileBC from '../../../../../domain/FileBC';
import styles from './styles.module.scss';
import FileIcon from '../../../FileIcon';
import { getDDMMYYYYDateFromMs } from '../../../../../../../utils/date';
import { formatBytes } from '../../../../../../../utils/files';

interface IFileInfoProps {
  file: FileBC;
  isFilePreview?: boolean;
  isSearch?: boolean;
  onFileClick?: () => void;
  name?: string;
}

const FileInfo: React.FC<IFileInfoProps> = ({
  file,
  onFileClick,
  isFilePreview,
  name,
  isSearch,
}) => {
  return (
    <div className={clsx(styles.Info, isFilePreview && styles.LightMode)}>
      <div onClick={onFileClick} className={styles.IconWrapper}>
        {file.thumbnail ? (
          <div className={styles.Thumbnail} style={{ backgroundImage: `url(${file.thumbnail})` }} />
        ) : (
          <FileIcon className={styles.Icon} fileType={file.mimeType} width={27} height={36} />
        )}
      </div>
      <div className={styles.InfoText}>
        <div
          className={clsx(
            styles.Name,
            isFilePreview && styles.FullName,
            isSearch && styles.FullNameSearch
          )}
          onClick={onFileClick}
          dangerouslySetInnerHTML={{ __html: name || file.name }}
        />
        <div>
          <span>{getDDMMYYYYDateFromMs(parseInt(file.updatedAt) * 1000)}</span>
          <span>・</span>
          <span>{formatBytes(file.fileSizeBytes)}</span>
        </div>
      </div>
    </div>
  );
};

export default FileInfo;
