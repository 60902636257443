function reorder<T>(array: Array<T>, fromIndex: number, toIndex: number): Array<T> {
  const copiedArray = [...array];

  const element = copiedArray[fromIndex];
  copiedArray.splice(fromIndex, 1);
  copiedArray.splice(toIndex, 0, element);

  return copiedArray;
}

function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}
export { reorder, findLastIndex };
