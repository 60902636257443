import { inject, injectable } from 'inversify';
import { TYPES } from '../di/types';
import ILoginRepository from './abstractions/ILoginRepository';
import ILoginUseCases from './abstractions/ILoginUseCases';
@injectable()
export default class LoginUseCases implements ILoginUseCases {
  @inject(TYPES.LoginRepository) private loginRepository: ILoginRepository;

  getUserIsAuth() {
    return this.loginRepository.getIsUserAuth();
  }
  getAuthProviders() {
    return this.loginRepository.getAuthProviders();
  }
  authWith(provider) {
    this.loginRepository.authWith(provider);
  }
}
