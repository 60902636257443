interface ProposalBusinessDetails {
  uuid: string;
  imageUrl: null | string;
  name: null | string;
  description: null | string;
  phone: null | string;
  email: null | string;
  address: null | string;
  website: null | string;
  fax: null | string;
  moreDetails: null | string;
}

export enum CustomersDetailsNames {
  EMAIL = 'email',
  FAX = 'fax',
  ADDRESS = 'address',
  PHONE = 'phone',
}

type CustomersDetails = [
  {
    name: CustomersDetailsNames.EMAIL;
    enabled: boolean;
  },
  {
    name: CustomersDetailsNames.FAX;
    enabled: boolean;
  },
  {
    name: CustomersDetailsNames.ADDRESS;
    enabled: boolean;
  },
  {
    name: CustomersDetailsNames.PHONE;
    enabled: boolean;
  }
];

export default interface ProposalSettings {
  uuid: string;
  workspaceId: string | null;
  startPrice: number;
  validity: number;
  currency: string;
  includesTax: boolean;
  taxRate: number;
  enteringPriceIncludesTax: boolean;
  footerText: string;
  customerDetails: CustomersDetails;
  includeTotalPrice: boolean;
  lang: string | null;
  updatedAt: number | null;
  createdAt: number | null;
  businessDetails: ProposalBusinessDetails;
}
